'use strict';

var cookies = require('./cookie');

var cookieName = 'Parkers.Session';

module.exports = {
	deleteCookie: function () {
		cookies.deleteCookie(cookieName);
	},

	getCookie: function () {
		return cookies.getCookie(cookieName);
	},

	setCookie: function (cookieData) {
		cookies.setCookieDefaultDomain(cookieName, cookieData);
	}
};