/** @jsx React.DOM */'use strict';

var assign = require('object-assign');
var numeral = require('numeral');
var React = require('react');
var $ = require('jquery');

var logger = require('../../utils/logger.js');
var searchFilters = require('../../components/search-filters.js');
var layout = require('../../ui/layout.js');
var sticky = require('../../ui/sticky.js');
var analytics = require('../../utils/analytics.js');

var Actions = require('../actions/leasing-search-filters-actions.js');
var Store = require('../stores/leasing-search-filters-store.js');

var Make = require('./search-filters/make-view.jsx');
var Model = require('./search-filters/model-view.jsx');
var Range = require('./search-filters/range-view.jsx');
var Trim = require('./search-filters/trim-view.jsx');
var PriceRange = require('./search-filters/price-range-view.jsx');
var FuelType = require('./search-filters/fuel-type-view.jsx');
var Gearbox = require('./search-filters/gearbox-view.jsx');
var LeaseTerm = require('./search-filters/lease-term-view.jsx');
var InitialPayment = require('./search-filters/initial-payment-view.jsx');
var AnnualMileage = require('./search-filters/annual-mileage-view.jsx');

var CarType = require('./search-filters/car-type-view.jsx');
var ResetFilters = require('./search-filters/reset-filters-view.jsx');

var config = require('../../utils/config.js');
var tagManager = require('../../utils/tagManager.js');

var layout = require('../../ui/layout.js')

var tracking = {
	category: {
		added: 'Leasing Filters',
		removed: 'Leasing Filters Removed'
	}
};

var modelTemplateSegment = '.Model';
var rangeTemplateSegment = '.Range';

var itemNames = {
	manufacturer: 'manufacturer',
	range: 'range',
	model: 'model'
};

var rangeRendered = false;

function getDefaultTemplateName(templateName) {
	var segments  = templateName.split('.');
	return segments[0] + '.' + segments[1];
}

function setVirtualTemplateName(name, isRemoving) {
	if (layout.isDesktop() && itemNames.hasOwnProperty(name)){
		var virtualTemplate = getDefaultTemplateName(config.dataLayer.page.templateName);

		if (isRemoving) {
			switch(name) {
				case itemNames.model:
					virtualTemplate += modelTemplateSegment;
					break;
				case itemNames.range:
					virtualTemplate += rangeTemplateSegment;
					break;
			}
		} else {
			switch(name) {
				case itemNames.manufacturer:
					virtualTemplate += rangeTemplateSegment
					break;
				case itemNames.range:
					virtualTemplate += modelTemplateSegment
					rangeRendered = true;
					break;
			}
		}

		tagManager.pushToDataLayer({
			'page.templateName' : virtualTemplate
		});
	}
}

function updateFilterState(changedItem, newValue) {
	searchFilters.hideSeoCrawlPaths();

	var filterRequest = Store.getFilterRequest();
	filterRequest[changedItem] = newValue;

	var dynamicallyUpdate = layout.isDesktop() || layout.isTablet();
	Actions.updateFilterState(filterRequest, dynamicallyUpdate);
}

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		searchType: React.PropTypes.string.isRequired,
		filterRequest: React.PropTypes.object
	},

	getInitialState: function () {
		return Store.getState();
	},

	componentWillMount: function () {
		Store.addChangeListener(this.onStoreChange);
		Actions.initStoreData({
			searchType: this.props.searchType
		});
	},

	componentDidMount: function () {
		var filterRequest = this.props.filterRequest;

		Actions.getFilterState(filterRequest);

		searchFilters.showSeoCrawlPaths();
	},

	componentDidUpdate: function () {
		searchFilters.init();
		sticky.resetStickySidebar();
	},

	componentWillUnmount: function () {
		Store.removeChangeListener(this.onStoreChange);
	},

	onStoreChange: function () {
		this.setState(Store.getState());
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		logger.debug('Leasing Search Filters', {
			message: 'Render',
			state: this.state
		});

		var cssClass = 'leasing-search-filters search-filters';

		if (this.state.isLoading) {
			cssClass += ' search-filters--loading';
		}

		if (this.state.filterStateError) {
			cssClass += ' search-filters--error';
		}

		var errorMessage = this.getErrorMessage();
		var updateButton = this.getUpdateButton();
		var resultMessage = this.getResultMessage();

		var isDisabled = false;

		var priceOptions = [0, 100, 200, 300, 400, 500, 700, 1000];
		var termOptions = [24, 36, 48];

		// If we have a single item on the model - it will get auto selected.
		// and will not trigger a change state.
		// Therefor we have to override in order to cater for the correct element being selected.
		if (layout.isDesktop() && rangeRendered && this.state.filterState.modelItems && this.state.filterState.modelItems.length === 1)
		{
			rangeRendered = false;

			tagManager.pushToDataLayer({
				'page.templateName' :  getDefaultTemplateName(config.dataLayer.page.templateName)
			});
		}

		return (
			<div>
				<form action={this.state.filterState.searchResultsUrl} className={cssClass} onSubmit={this.onFormSubmit} data-update={this.state.updateResults}>
					
					<div className="search-filters__heading search-filters__heading--leasing">
						<div className="search-filters__heading__inner-container hide-for-large-up">
							<ResetFilters
								onFiltersReset={this.onFiltersReset}
								filterState={this.state.filterState} />
						</div>

						{resultMessage}

						<div className="search-filters__heading__inner-container hide-for-large-up">
							{this.getCloseButton()}
						</div>
					</div>

					{errorMessage}

					<div className="site-sidebar-left__search-filters__container">
						<section className="search-filters__group">
							<Make
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={isDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
							<Range
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={isDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
							<Model
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={isDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
							<Trim
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={isDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
							<CarType
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={isDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
						</section>

						<PriceRange
							filterState={this.state.filterState}
							isLoading={this.state.isLoading}
							isDisabled={isDisabled}
							label="Monthly budget"
							priceOptions={priceOptions}
							validationErrors={this.state.validationErrors}
							onValidationError={this.onValidationError}
							onValueSet={this.onValueSet} />

						<section className="search-filters__group">
							<FuelType
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={isDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
							<Gearbox
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={isDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
						</section>

						<section className="search-filters__leasing-filters">
							<span className="search-filters__leasing-filters__section-heading">Lease Filters</span>
							<LeaseTerm
								filterState={this.state.filterState}
								termOptions={termOptions}
								isLoading={this.state.isLoading}
								isDisabled={isDisabled}
								onValueSet={this.onValueSet} />
							
							<InitialPayment
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={isDisabled}
								onValueSet={this.onValueSet} />
							
							<AnnualMileage
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={isDisabled}
								onValueSet={this.onValueSet} />
						</section>
					</div>
					{updateButton}
				</form>
			</div>
		);
	},

	getCloseButton: function () {
		return (
			<button type="button" className="search-filters__heading__button search-filters__heading__button--leasing left-off-canvas-toggle hide-for-large-up"></button>
		);
	},

	getErrorMessage: function () {
		if (this.state.filterStateError) {
			return (
				<div className="search-filters__result-count search-filters__result-count--error">Something went wrong! Please refresh your page.</div>
			);
		}
	},

	getUpdateButton: function () {
		var disabled = this.state.isLoading;
		var cssClass = 'sticky-off-canvas-bottom__button';
		
		if (disabled) {
			cssClass = 'sticky-off-canvas-bottom__button--disabled ' + cssClass;

			return (
				<div className="sticky-off-canvas-bottom">
					<button type="submit" className={cssClass} disabled={disabled}>
						<div className="sticky-off-canvas-bottom__spinner"></div>
						<span className="sticky-off-canvas-bottom__result">Loading results...</span>
					</button>
				</div>
			);
		}
		else {
			var results = this.getFormattedResultCount();
			var buttonContent = (
				<span>View {results}</span>
			);

			return (
				<div className="sticky-off-canvas-bottom">
					<button type="submit" className={cssClass} disabled={disabled}>{buttonContent}</button>
				</div>
			);
		}
	},

	getResultMessage: function () {
		var message;

		if (this.state.isLoading) {
			message = 'Loading...'
		}
		else {
			var count = this.getFormattedResultCount();

			message = (<span>{count} found</span>);
		}

		return (
			<div className='search-filters__result-count search-filters__result-count--leasing'><span>{message}</span></div>
		);

	},

	getFormattedResultCount: function () {
		var count = this.state.filterState.totalResults || 0;
		var suffix = (count === 1) ? 'lease result' : 'lease results';
		var formattedCount = numeral(count).format('0,0');

		return (
			<span><span className='sticky-off-canvas-bottom__count'>{formattedCount}</span> {suffix}</span>
		);
	},

	onFormSubmit: function (e) {
		e.preventDefault();
		if (!this.state.isLoading) {
			Actions.updateResults(e.target.action, this.state.updateResults);
		}
	},

	onItemRemove: function (name) {

		setVirtualTemplateName(name, true);

		Actions.removeItem(name);
		updateFilterState(name, null);
		analytics.trackEvent(tracking.category.removed, name, '-');
	},

	onItemSet: function (name, value, text) {
		
		setVirtualTemplateName(name, false);

		Actions.setItem(name, value, text);
		updateFilterState(name, value);
		analytics.trackEvent(tracking.category.added, name, value);
	},

	onValidationError: function (name, message) {
		Actions.validationError(name, message);
	},

	onValueSet: function (name, value) {
		Actions.setValue(name, value);
		updateFilterState(name, value);
		analytics.trackEvent(tracking.category.added, name, value !== '' ? value : '-');
	},

	onFiltersReset: function () {
		var data = Store.getResetRequest();
		Actions.resetFilters(data, !layout.isDesktop());
	}

	/* #endregion */
});
