'use strict';

var keyMirror = require('keymirror');

module.exports = {
	ActionTypes: keyMirror({
		CAR_CHOOSER_INIT: null,
		CAR_CHOOSER_TOGGLE_CLASSIFICATION: null,
		CAR_CHOOSER_TOGGLE_CLASSIFICATION_DISPLAY: null,
		CAR_CHOOSER_TOGGLE_LIFESTYLE: null,
		CAR_CHOOSER_TOGGLE_LIFESTYLE_DISPLAY: null,
		CAR_CHOOSER_SET_PRICE_RANGE: null,
		CAR_CHOOSER_SET_MONTHLY_PRICE_RANGE: null,
		CAR_CHOOSER_SWITCH_SEARCH_TYPE: null,
		CAR_CHOOSER_SWITCH_PRICE_TYPE: null,
		CAR_CHOOSER_TOGGLE_PRICES_DISPLAY: null,
		CAR_CHOOSER_SEARCH_FILTERS_UPDATE_STATE: null,
		CAR_CHOOSER_SEARCH_FILTERS_UPDATE_STATE_SUCCESS: null,
		CAR_CHOOSER_SEARCH_FILTERS_UPDATE_STATE_ERROR: null
	}),

	Lifestyles: keyMirror({
		NEW_DRIVERS: null,
		COMPANY_CAR: null,
		LONG_JOURNEY: null,
		ALL_SEASONS: null,
		BIG_BOOT: null,
		EASY_ACCESS: null,
		CITY_DRIVING: null,
		STATEMENT: null
	})
};