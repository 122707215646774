'use strict';

var assign 			= require('object-assign');
var EventEmitter	= require('events');

var CHANGE_EVENT = 'change';

var FluxChangeStore = function () {
	return assign({}, EventEmitter.prototype, {  
		emitChange: function () {
			this.emit(CHANGE_EVENT);
		},

		addChangeListener: function (callback) {
			this.addListener(CHANGE_EVENT, callback);
		},

		removeChangeListener: function (callback) {
			this.removeListener(CHANGE_EVENT, callback);
		}
	});
};

module.exports = FluxChangeStore;