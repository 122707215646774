'use strict';

var assign = require('object-assign');
var arrays = require('../../utils/arrays.js');
var logger = require('../../utils/logger.js');

var FluxChangeStore	= require('./flux-change-store.js');
var FluxConstants = require('../constants/flux-constants.js');
var FluxDispatcher = require('../dispatchers/flux-dispatcher.js');
var Constants = require('../constants/company-car-tax-quick-find-constants.js');

var ActionTypes = Constants.ActionTypes;
var PayloadSources = FluxConstants.PayloadSources;

var defaultStoreData = {
	isLoading: true,
	yearPlates: [],
	manufacturers: [],
	ranges: [],
	rangeYears: [],
	taxYears: [],
	taxRates: [],
	selectedManufacturer: null,
	selectedRange: null,
	selectedModel: null,
	selectedYearPlate: null,
	selectedDerivative: null,
	selectedTaxYear: null,
	selectedTaxRate: null,
	loadingModels: false,
	loadingYearPlates: false,

	validationErrors: {}
};

var storeData = assign({}, defaultStoreData);

var Store = assign(new FluxChangeStore(), {
	getState: function () {
		return storeData;
	}
});

function setSelectedManufacturer(manufacturerKey) {
	var item = arrays.findArrayItemByField(storeData.manufacturers, 'key', manufacturerKey);

	if (item !== null) {
		storeData.selectedManufacturer = item;
		storeData.ranges = item.ranges || [];
		return true;
	}

	return false;
}

function setSelectedRange(rangeKey) {
	var item = arrays.findArrayItemByField(storeData.ranges, 'key', rangeKey);

	if (item !== null) {
		storeData.selectedRange  = item;
		storeData.rangeYears = item.rangeYears || [];
		return true;
	}

	return false;
}

function setSelectedModel(modelKey) {
	var rangeYears = storeData.rangeYears;
	if (rangeYears) {
		var index, item = null, rangeYear;

		for (index = 0; index < rangeYears.length; index++) {
			rangeYear = rangeYears[index];
			item = arrays.findArrayItemByField(rangeYear.models, 'key', modelKey);

			if (item !== null) {
				break;
			}
		}

		if (item !== null) {
			storeData.selectedModel = item;
			return true;
		}
	}

	return false;
}

function setSelectedYearPlate(yearPlateKey) {	
	var item = arrays.findArrayItemByField(storeData.yearPlates, 'key', yearPlateKey);

	if (item !== null) {
		storeData.selectedYearPlate =  item;
		return true;
	}

	return false;
}

function setSelectedDerivative(derivativeKey) {
	var trims = storeData.selectedYearPlate.trims;
	if (trims) {
		var index, item = null, trim;

		for (index = 0; index < trims.length; index++) {
			trim = trims[index];
			item = arrays.findArrayItemByField(trim.derivatives, 'key', derivativeKey);

			if (item !== null) {
				break;
			}
		}

		if (item !== null) {
			storeData.selectedDerivative = item;
			return true;
		}
	}

	return false;
}

function setSelectedTaxYear(taxYearKey) {
	var item = arrays.findArrayItemByField(storeData.taxYears, 'key', taxYearKey);

	if (item !== null) {
		storeData.selectedTaxYear  = item;
		storeData.taxRates = item.rates || [];
		return true;
	}

	return false;
}

function setSelectedTaxRate(taxRateKey) {
	var item = arrays.findArrayItemByField(storeData.taxRates, 'key', taxRateKey);

	if (item !== null) {
		storeData.selectedTaxRate  = item;
		return true;
	}

	return false;
}

function handleServerAction(action) {
	if (action) {
		var handled = false;

		switch(action.type) {
			case ActionTypes.COMPANY_CAR_TAX_LOAD_INITIAL_STATE:
				handled = true;
				storeData.isLoading = true;
				storeData.manufacturers = [];
				storeData.ranges = [];
				storeData.selectedManufacturer = null;
				storeData.selectedRange = null;
				storeData.selectedModel = null;
				storeData.selectedDerivative = null;

				break;
			case ActionTypes.COMPANY_CAR_TAX_LOAD_INITIAL_STATE_SUCCESS:
				handled = true;
				storeData.isLoading = false;
				storeData.manufacturers = action.data.manufacturers;
				storeData.taxYears = action.data.taxYears;

				if (storeData.initialManufacturerKey) {
					// pre-select the manufacturer and set the ranges
					if (setSelectedManufacturer(storeData.initialManufacturerKey)) {
						storeData.initialManufacturerKey = null;

						if (storeData.initialRangeKey) {
							// pre-select the range and set the range years
							if (setSelectedRange(storeData.initialRangeKey)) {
								storeData.initialRangeKey = null;
							}
						}
					}
				}

				if (storeData.initialTaxYearKey) {
					// pre-select the tax year and rates
					if (setSelectedTaxYear(storeData.initialTaxYearKey)) {
						storeData.initialTaxYearKey = null;

						if (storeData.initialTaxRateKey) {
							if (setSelectedTaxRate(storeData.initialTaxRateKey)) {
								storeData.initialTaxRateKey = null;
							}
						}
					}
				}

				break;
			case ActionTypes.COMPANY_CAR_TAX_LOAD_INITIAL_STATE_ERROR:
				handled = true;
				storeData.isLoading = false;
				storeData.manufacturers = [];

				break;
			case ActionTypes.COMPANY_CAR_TAX_LOAD_RANGES:
				handled = true;
				storeData.rangeYears = [];
				storeData.selectedModel = null;
				storeData.selectedYearPlate = null;
				storeData.selectedDerivative = null;
				storeData.loadingModels = true;

				break;
			case ActionTypes.COMPANY_CAR_TAX_LOAD_RANGES_SUCCESS:
				handled = true;
				storeData.loadingModels = false;

				// check that the response is the one we are expecting - stops overlapping requests generating an invalid state
				if (storeData.selectedManufacturer !== null && storeData.selectedManufacturer.key === action.data.key) {
					storeData.ranges = action.data.ranges;

					if (storeData.selectedRange !== null) {
						// if range selected by user before load ranges returns, reset selected range to get range years
						setSelectedRange(storeData.selectedRange.key);

						 if (storeData.initialModelKey) {
							// pre-select the model
							if (setSelectedModel(storeData.initialModelKey)) {
								storeData.initialModelKey = null;
							}
						}
					}
				}

				break;
			case ActionTypes.COMPANY_CAR_TAX_LOAD_RANGES_ERROR:
				handled = true;
				storeData.loadingModels = false;
				storeData.rangeYears = [];

				break;
			case ActionTypes.COMPANY_CAR_TAX_LOAD_YEAR_PLATES:
				handled = true;
				storeData.yearPlates = [];
				storeData.selectedDerivative = null;
				storeData.loadingYearPlates = true;

				break;
			case ActionTypes.COMPANY_CAR_TAX_LOAD_YEAR_PLATES_SUCCESS:
				handled = true;
				storeData.loadingYearPlates = false;

				// check that the response is the one we are expecting - stops overlapping requests generating an invalid state
				var modelKey = storeData.selectedModel ? storeData.selectedModel.key : storeData.initialModelKey;
				if (modelKey === action.data.modelKey) {
					storeData.yearPlates = action.data.yearPlates;
				}

				if (storeData.initialYearPlateKey) {
					if (setSelectedYearPlate(storeData.initialYearPlateKey)) {
						storeData.initialYearPlateKey = null;

						if (storeData.initialDerivativeKey) {
							if (setSelectedDerivative(storeData.initialDerivativeKey)) {
								storeData.initialDerivativeKey = null;
							}
						}
					}
				}

				break;
			case ActionTypes.COMPANY_CAR_TAX_LOAD_YEAR_PLATES_ERROR:
				handled = true;
				storeData.loadingYearPlates = false;
				storeData.yearPlates = [];

				break;
		}

		if (handled) {
			logger.debug('Company Car Tax Quick Find', {
				message: 'Handle server action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

function handleViewAction(action) {
	if (action) {
		var handled = false;

		switch (action.type) {
			case ActionTypes.COMPANY_CAR_TAX_INIT_STORE_DATA:
				handled = true;
				assign(storeData, action.data);

				break;
			case ActionTypes.COMPANY_CAR_TAX_SELECT_MANUFACTURER:
				handled = true;

				storeData.initialManufacturerKey = null;
				storeData.initialRangeKey = null;
				storeData.initialModelKey = null;
				storeData.ranges = [];
				storeData.rangeYears = [];
				storeData.selectedRange = null;
				storeData.selectedModel = null;
				storeData.selectedYearPlate = null;
				storeData.selectedDerivative = null;
				storeData.validationErrors = null;

				setSelectedManufacturer(action.data.manufacturerKey);

				break;				
			case ActionTypes.COMPANY_CAR_TAX_SELECT_RANGE:
				handled = true;

				storeData.initialRangeKey = null;
				storeData.initialModelKey = null;
				storeData.rangeYears = [];
				storeData.selectedModel = null;
				storeData.selectedYearPlate = null;
				storeData.selectedDerivative = null;
				storeData.validationErrors = null;

				setSelectedRange(action.data.rangeKey);

				break;
			case ActionTypes.COMPANY_CAR_TAX_SELECT_MODEL:
				handled = true;

				storeData.initialModelKey = null;
				storeData.yearPlates = [];
				storeData.selectedYearPlate = null;
				storeData.selectedDerivative = null;
				storeData.validationErrors = null;

				setSelectedModel(action.data.modelKey);
				
				break;
			case ActionTypes.COMPANY_CAR_TAX_SELECT_YEAR_PLATE:
				handled = true;

				storeData.selectedDerivative = null;
				storeData.validationErrors = null;

				setSelectedYearPlate(action.data.yearPlateKey);
				
				break;
			case ActionTypes.COMPANY_CAR_TAX_SELECT_DERIVATIVE:
				handled = true;

				setSelectedDerivative(action.data.derivativeKey);

				break;
			case ActionTypes.COMPANY_CAR_TAX_SELECT_TAX_YEAR:
				handled = true;

				storeData.selectedTaxRate = null;

				setSelectedTaxYear(action.data.taxYearKey);

				break;
			case ActionTypes.COMPANY_CAR_TAX_SELECT_TAX_RATE:
				handled = true;

				setSelectedTaxRate(action.data.taxRateKey);
				break;
			case ActionTypes.COMPANY_CAR_TAX_VALIDATION_ERROR:
				handled = true;

				storeData.validationErrors = {};

				if (!storeData.selectedManufacturer) {
					storeData.validationErrors.manufacturer = 'Required';
				} 

				if (!storeData.selectedRange) {
					storeData.validationErrors.range = 'Required';
				} 

				if (!storeData.selectedModel) {
					storeData.validationErrors.model = 'Required';
				} 

				if (!storeData.selectedYearPlate) {
					storeData.validationErrors.yearPlate = 'Required';
				} 

				if (!storeData.selectedYearPlate) {
					storeData.validationErrors.yearPlate = 'Required';
				} 

				if (!storeData.selectedDerivative) {
					storeData.validationErrors.derivative = 'Required';
				} 

				if (!storeData.selectedTaxYear) {
					storeData.validationErrors.taxYear = 'Required';
				} 

				if (!storeData.selectedTaxRate) {
					storeData.validationErrors.taxRate = 'Required';
				}

				break;
		}

		if (handled) {
			logger.debug('Company Car Tax Quick Find', {
				message: 'Handle view action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

Store.dispatchToken = FluxDispatcher.register(function (payload) {
	if (payload && payload.source) {
		if (payload.source === PayloadSources.SERVER_ACTION) {
			handleServerAction(payload.action);
		} else if (payload.source === PayloadSources.VIEW_ACTION) {
			handleViewAction(payload.action);
		}
	}
});

module.exports = Store;