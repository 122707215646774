'use strict';

module.exports = {
	getWordCount: function (text) {
		var words = text.split(' ');
		words = words.filter(function (words) { 
			return words.length > 0;
		}).length;

		return words;
	},

	formatNameUrlPath: function(urlPath, includeTrailingSlash) {

		// invalid characters are: & + / , ' \ ? .
		// space replaced with -
		if (urlPath) {
			urlPath = urlPath.replace(/[\/\&\+\/\,\'\?\.']/g, '').replace(/\s+/g, "-");

			return includeTrailingSlash ? urlPath.concat("/") : urlPath;
		}

		return null;
	}
};