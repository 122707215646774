/** @jsx React.DOM */'use strict'

var React = require('react');

var name = 'selectedTerm';

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		filterState: React.PropTypes.object.isRequired,
		termOptions: React.PropTypes.array.isRequired,
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		onValueSet: React.PropTypes.func.isRequired
	},
	/* #endregion */

	/* #region Render methods */
	render: function() {
		var desktopLeaseTerms = [];
		var mobileLeaseTerms = [];
		var selectedTerm = this.props.filterState.selectedTerm
		var disabled = false;

		if (this.props.isLoading || this.props.isDisabled) {
			disabled = true;
		}
		
		var isNoTermSelected = !selectedTerm;
		mobileLeaseTerms.push(this.getMobileLeaseTermItem('', 'All', isNoTermSelected, disabled), false);

		for (var index = 0; index < this.props.termOptions.length; index++)
		{
			var termOption = this.props.termOptions[index];
			var isSelected = selectedTerm === termOption;

			desktopLeaseTerms.push(this.getDesktopLeaseTermItem(termOption), false);
			mobileLeaseTerms.push(this.getMobileLeaseTermItem(termOption, termOption, isSelected, disabled), false);
		}

		return (
			<div className="search-filters__lease-term">
				<div className="search-filters__leasing-filters__label">
					<span className="search-filters__leasing-filters__label__title">Term in Months</span>
					<span className="search-filters__leasing-filters__label__description">How long would you like the lease?</span>
				</div>
				<div className="hide-for-large-up">
					<ul className="search-filters__lease-term__list" disabled={disabled}>
						{mobileLeaseTerms}
					</ul>
				</div>
				<div className="show-for-large-up">
					<select disabled={disabled} onChange={this.onDropdownChange} value={selectedTerm}>
						<option value="">All</option>
						{desktopLeaseTerms}
					</select>
				</div>
			</div>
		)
	},

	getDesktopLeaseTermItem: function(value) {
		return (
			<option value={value}>Up to {value} months</option>
		);
	},

	getMobileLeaseTermItem: function(value, displayText, isSelected, disabled) {
		var linkClassName = 'search-filters__lease-term__list__item__link';
		linkClassName += isSelected ? ' search-filters__lease-term__list__item__link--selected' : '';

		return (
			<li className="search-filters__lease-term__list__item">
				<button type="button" className={linkClassName} value={value} onClick={this.onLeaseTermLinkClicked} disabled={disabled}>
					{displayText}
				</button>
			</li>
		);
	},

	onDropdownChange: function(e) {
		var value = e.target.value;
		this.props.onValueSet(name, value);
	},

	onLeaseTermLinkClicked: function(e) {
		e.preventDefault();
		
		var value = e.target.value ? parseInt(e.target.value) : '';
		var selectedTerm = this.props.filterState.selectedTerm ? this.props.filterState.selectedTerm : '';
		
		if (value !== selectedTerm) {
			this.props.onValueSet(name, value);
		}
	}
	/* #endregion */
})