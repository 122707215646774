/** @jsx React.DOM */'use strict';

var React = require('react');

var FacetDropdown 	= require('./shared/facet-dropdown-view.jsx');
var FacetFlyoutList = require('./shared/facet-flyout-list-view.jsx');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		filterState: React.PropTypes.object.isRequired,
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		onItemRemove: React.PropTypes.func.isRequired,
		onItemSet: React.PropTypes.func.isRequired
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		return (
			<div>
				<div className="search-filters__facet hide-for-large-up">
					<FacetDropdown
						name="engineSizeMin"
						defaultText="Engine size from"
						isLoading={this.props.isLoading}
						isDisabled={this.props.isDisabled}
						items={this.props.filterState.minEngineSizeItems}
						selectedItem={this.props.filterState.selectedEngineSizeMin}
						onRemove={this.props.onItemRemove}
						onSet={this.props.onItemSet}
					/>
					<FacetDropdown
						name="engineSizeMax"
						defaultText="Engine size to"
						isLoading={this.props.isLoading}
						isDisabled={this.props.isDisabled}
						items={this.props.filterState.maxEngineSizeItems}
						selectedItem={this.props.filterState.selectedEngineSizeMax}
						onRemove={this.props.onItemRemove}
						onSet={this.props.onItemSet}
					/>
				</div>
				<div className="search-filters__facet show-for-large-up">
					<FacetFlyoutList
						label="Engine size from"
						name="engineSizeMin"
						title="What is the lowest engine size?"
						isLoading={this.props.isLoading}
						isDisabled={this.props.isDisabled}
						items={this.props.filterState.minEngineSizeItems}
						selectedItem={this.props.filterState.selectedEngineSizeMin}
						selectedItemPrefix="Engine size from"
						onRemove={this.props.onItemRemove}
						onSet={this.props.onItemSet}
					/>
				</div>
				<div className="search-filters__facet show-for-large-up">
					<FacetFlyoutList
						label="Engine size to"
						name="engineSizeMax"
						title="What is the highest engine size?"
						isLoading={this.props.isLoading}
						isDisabled={this.props.isDisabled}
						items={this.props.filterState.maxEngineSizeItems}
						selectedItem={this.props.filterState.selectedEngineSizeMax}
						selectedItemPrefix="Engine size to"
						onRemove={this.props.onItemRemove}
						onSet={this.props.onItemSet}
					/>
				</div>
			</div>
		);
	}
	/* #endregion */
});