'use strict';

var baseApi = require('./base-api.js');
var logger 	= require('../utils/logger.js');

function getApiUrl(vehicleType, filterName, value1, value2, value3) {
	if (!vehicleType) {
		logger.error('Taxonomy API - a vehicle type ("cars" or "vans") must be specified.');
		return;
	}

	var url = vehicleType + '/taxonomy';

	if (value1) {
		url += '/' + value1;
	}

	if (value2) {
		url += '/' + value2;
	}

	if (value3) {
		url += '/' + value3;
	}

	if (filterName) {
		url += '?filter=' + filterName;
	}

	return url;
}

module.exports = {
	getManufacturers: function (vehicleType, filterName, options) {
		var url = getApiUrl(vehicleType, filterName);
		baseApi.get(url, options);
	},

	getRangesForManufacturer: function (vehicleType, filterName, manufacturerKey, options) {
		var url = getApiUrl(vehicleType, filterName, manufacturerKey);
		baseApi.get(url, options);
	},

	getModelsForRange: function (vehicleType, filterName, manufacturerKey, rangeKey, options) {
		var url = getApiUrl(vehicleType, filterName, manufacturerKey, rangeKey);
		baseApi.get(url, options);
	},

	getDerivativeInfo : function(vehicleType, derivativeId, options) {
		var url = getApiUrl(vehicleType, null, 'derivative', derivativeId);
		baseApi.get(url, options);
	},

	getDataForVrm: function(vehicleType, derivativeKey, options) {
		var url = getApiUrl(vehicleType, null, 'vrm', 'derivative', derivativeKey);
		baseApi.get(url, options);
	}
};