'use strict';

var baseApi = require('./base-api.js');
var logger 	= require('../utils/logger.js');

function getApiUrl(vehicleType, filterName, value1, value2) {
	if (!vehicleType) {
		logger.error('Quick Find API - a vehicle type ("cars" or "vans") must be specified.');
		return;
	}

	var url = vehicleType + '/quick-find';

	if (filterName) {
		url += '/' + filterName;
	}

	if (value1) {
		url += '/' + value1;
	}

	if (value2) {
		url += '/' + value2;
	}

	return url;
}

module.exports = {
	getManufacturers: function (vehicleType, filterName, options) {
		var url = getApiUrl(vehicleType, filterName);
		baseApi.get(url, options);
	},

	getRangesForManufacturer: function (vehicleType, filterName, manufacturerKey, onlyLatestModels, options) {
		var url = getApiUrl(vehicleType, filterName, manufacturerKey, onlyLatestModels);
		baseApi.get(url, options);
	},	

	getYearsForModel: function (vehicleType, filterName, modelKey, options) {
		var url = getApiUrl(vehicleType, filterName, 'years', modelKey);
		baseApi.get(url, options);
	}
};