/** @jsx React.DOM */'use strict';

var React = require('react');

module.exports = React.createClass({

	propTypes: {
		isDisabled: React.PropTypes.bool,
		isLoading: React.PropTypes.bool,
		selectedItem: React.PropTypes.object,
		items: React.PropTypes.array,
		onItemSelect: React.PropTypes.func.isRequired,
		promptText: React.PropTypes.string,
		selectClassName: React.PropTypes.string,
		validationError: React.PropTypes.string,
		isChunky: React.PropTypes.bool
	},

	getDefaultProps: function () {
		return {
			promptText: 'Select an item',
			isChunky: false
		}
	},

	render: function () {
		var defaultText = this.props.isLoading ? 'Loading...' : this.props.promptText;
		var selectedValue = this.props.selectedItem !== null ? this.props.selectedItem.key : '';
		var containerClassName = 'form__entry';

		if (this.props.selectClassName) {
			containerClassName += ' ' + this.props.selectClassName;
		}

		var validationError;
		if (this.props.validationError) {
			validationError = <span className="error">{this.props.validationError}</span>;
		}

		var listItems;
		if (this.props.items) {
			listItems = this.props.items.map(function (item) {
				return <option key={item.key} value={item.key}>{item.name}</option>;
			});
		}

		var classNameToUse = this.props.isChunky ? 'form__list form__list--chunky' : 'form__list';
  
		return (
			<div className={containerClassName}>
				<select disabled={this.props.isDisabled} value={selectedValue} className={classNameToUse}
				onChange={this.select}> <option value="">{defaultText}</option>
				{listItems}
				</select>
				{validationError}
			</div>
		);
	},

	select: function(event) {
		var key = event.target.value;
		var name = event.target.options[event.target.selectedIndex].text;

		if (key) {
			this.props.onItemSelect(key, name);
		}
	}
});