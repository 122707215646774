'use strict';

var cookies = require('js-cookie');

var config = require('../utils/config.js');

function writeCookie (cookieName, cookieData, options) {

	if (typeof cookieData === "object") {
		cookieData = JSON.stringify(cookieData);
	}

	cookies.set(cookieName, cookieData, options);
}

module.exports = {
	deleteCookie: function (cookieName) {
		cookies.remove(cookieName, {
			domain: config.cookieDomain
		});
	},

	getCookie: function (cookieName) {
		var cookieData = cookies.get(cookieName);

		if (cookieData) {
			try {
				return JSON.parse(cookieData);
			}
			catch (e) {
				console.log("Cannot parse cookie as JSON " + e);
				return cookieData;
			}
		}

		return undefined;
	},

	setCookie: function (cookieName, cookieData, options) {
		writeCookie(cookieName, cookieData, options);
	},

	setCookieDefaultDomain: function (cookieName, cookieData) {
		var options = {
			domain: config.cookieDomain
		};

		writeCookie(cookieName, cookieData, options);
	},
};