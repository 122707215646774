/** @jsx React.DOM */'use strict';

var $ = require('jquery');
var React = require('react');

var analytics = require('../../utils/analytics.js');
var config = require('../../utils/config.js');
var logger = require('../../utils/logger.js');

var Actions = require('../actions/car-chooser-actions.js');
var Store = require('../stores/car-chooser-filters-store.js');
var Constants = require('../constants/car-chooser-constants.js');

var Classifications = require('./car-chooser/classifications.jsx');
var Lifestyles = require('./car-chooser/lifestyles.jsx');
var Prices = require('./car-chooser/prices.jsx');
var SearchTypeTabs = require('./car-chooser/search-type-tabs.jsx');

module.exports = React.createClass({

	propTypes: {
		filterRequest: React.PropTypes.object,
		expandClassifications: React.PropTypes.bool,
		expandLifestyles: React.PropTypes.bool
	},

	/* #region React functions */
	getInitialState: function () {
		return Store.getState();
	},

	getDefaultProps: function() {
		return {
			expandClassifications: false,
			expandLifestyles: false,
			expandPrices: false
		};
	},

	componentWillMount: function () {
		Store.addChangeListener(this.onStoreChange);
		Actions.initStoreData({
			filterRequest: this.props.filterRequest,
			expandClassifications: this.props.expandClassifications,
			expandLifestyles: this.props.expandLifestyles,
			expandPrices: this.props.expandPrices
		});
	},

	componentWillUnmount: function () {
		Store.removeChangeListener(this.onStoreChange);
	},

	onStoreChange: function () {
		this.setState(Store.getState());
	},

	componentDidUpdate: function () {
		if (this.state.updatePending) {
			setTimeout(function () {
				var filterRequest = Store.getFilterRequest();
				Actions.updateFilterState(filterRequest, true);
			}, 1);
		}
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		logger.debug('Car Chooser', {
			message: 'Render',
			state: this.state
		});

		return (
			<div>
				<div className="car-chooser-filters panel">
					<div className="car-chooser-filters__top-row">
						<Prices onPriceSet={this.onPriceSet}
							onMonthlyPriceSet={this.onMonthlyPriceSet}
							onPriceTypeSwitch={this.onPriceTypeToggle}
							isMonthlySelected={this.state.useMonthlyPrice}
							selectedMinPrice={this.state.selectedMinimumPrice} 
							selectedMaxPrice={this.state.selectedMaximumPrice} 
							selectedMinMonthlyPrice={this.state.selectedMinimumMonthlyPrice} 
							selectedMaxMonthlyPrice={this.state.selectedMaximumMonthlyPrice} 
							onDisplayToggle={this.onPricesDisplayToggle}
							expand={this.state.displayPricesContent} />
					</div>

					<div className="row">
						<div className="column large-6 car-chooser-filters__left-column">
							<Lifestyles onLifestyleToggle={this.onLifestyleToggle}
								selectedLifestyles={this.state.selectedLifestyles}
								onDisplayToggle={this.onLifestyleDisplayToggle}
								expand={this.state.displayLifestylesContent} />
						</div>

						<div className="column large-6 car-chooser-filters__right-column">
							<Classifications onClassificationToggle={this.onClassificationToggle}
								selectedClassifications={this.state.selectedClassifications}
								onDisplayToggle={this.onClassificationDisplayToggle}
								expand={this.state.displayClassificationsContent} />
						</div>
					</div>
				</div>

				<SearchTypeTabs currentSearchType={this.state.searchType} onTabClick={this.onSearchTypeClick} />
			</div>
		);
	},
	/* #endregion */

	/* #region Event Handlers */
	onPricesDisplayToggle: function (isVisible) {
		Actions.togglePricesDisplay(isVisible);
	},

	onClassificationToggle: function (classificationId) {
		Actions.toggleClassification(classificationId);
	},

	onClassificationDisplayToggle: function (isVisible) {
		Actions.toggleClassificationDisplay(isVisible);
	},

	onLifestyleToggle: function (lifestyle) {
		Actions.toggleLifestyle(lifestyle);
	},

	onLifestyleDisplayToggle: function (isVisible) {
		Actions.toggleLifestyleDisplay(isVisible);
	},

	onPriceSet: function (prices) {
		Actions.setPriceRange(prices);
	},

	onMonthlyPriceSet: function (prices) {
		Actions.setMonthlyPriceRange(prices);
	},

	onSearchTypeClick: function (searchType) {
		Actions.switchSearchType(searchType);
	},

	onPriceTypeToggle: function (useMonthlyPrice) {
		Actions.switchPriceType(useMonthlyPrice);
		Actions.switchSearchType(useMonthlyPrice ? 'Used' : 'New');

		analytics.trackEvent('Price Type Toggle', 'Clicked', useMonthlyPrice ? 'Monthly' : 'Purchase');
	}
	/* #endregion */
});