'use strict';

module.exports = {
	findArrayItemByField: function (array, fieldName, value) {
		var index, item = null, tempItem;
		for (index = 0; index < array.length; index++) {
			tempItem = array[index];
			if (tempItem[fieldName] === value) {
				item = tempItem;	
			}
		}

		return item;
	},

	isPopulated: function(array) {
		return array && array.length > 0;
	},

	shuffle: function(array) {
		var currentIndex = array.length;
		var temporaryValue;
		var randomIndex;

		while (currentIndex !== 0) {

			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}
		
		return array;
	}
};