'use strict';

var Q = require('kew');

var logger 			= require('../../utils/logger.js');
var quickFindApi 	= require('../../api/quick-find-api.js');

var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/quick-find-model-constants.js');

var ActionTypes = Constants.ActionTypes;

var loadManufacturerPromises = {};

function log(source, actionType, data) {
	logger.debug('Quick Find Model', {
		message: 'Create ' + source + ' action',
		action: actionType,
		data: data
	});
}

function createServerAction(actionType, data) {
	log('server', actionType, data);
	FluxDispatcher.dispatchServerAction({
		type: actionType,
		data: data
	});
}

function createViewAction(actionType, data) {
	log('view', actionType, data);
	FluxDispatcher.dispatchViewAction({
		type: actionType,
		data: data
	});
}

var Actions = {
	initStoreData: function (storeData) {
		createServerAction(ActionTypes.QUICK_FIND_INIT_STORE_DATA, storeData);
	},

	loadManufacturers: function (vehicleType, filterName) {
		createServerAction(ActionTypes.QUICK_FIND_LOAD_MANUFACTURERS, filterName);
	
		loadManufacturerPromises[filterName] = Q.defer();

		quickFindApi.getManufacturers(vehicleType, filterName, {
			successCallback: function (data) {
				if (data.manufacturers) {
					data.filterName = filterName;
					createServerAction(ActionTypes.QUICK_FIND_LOAD_MANUFACTURERS_SUCCESS, data);
				} else {
					createServerAction(ActionTypes.QUICK_FIND_LOAD_MANUFACTURERS_ERROR, filterName);
				}
				
				if (loadManufacturerPromises[filterName]) {
					loadManufacturerPromises[filterName].resolve();
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.QUICK_FIND_LOAD_MANUFACTURERS_ERROR, filterName);
			}
		});
	},

	loadRanges: function (vehicleType, filterName, manufacturerKey, onlyLatestModels) {
		createServerAction(ActionTypes.QUICK_FIND_LOAD_RANGES, filterName);
		quickFindApi.getRangesForManufacturer(vehicleType, filterName, manufacturerKey, onlyLatestModels, {
			successCallback: function (data) {
				if (data.ranges) {
					data.filterName = filterName;
					
					if (loadManufacturerPromises[filterName]) {
						loadManufacturerPromises[filterName].then(function () {
							createServerAction(ActionTypes.QUICK_FIND_LOAD_RANGES_SUCCESS, data);
						});
					}

				} else {
					createServerAction(ActionTypes.QUICK_FIND_LOAD_RANGES_ERROR, filterName);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.QUICK_FIND_LOAD_RANGES_ERROR, filterName);
			}
		});
	},

	selectManufacturer: function (manufacturerKey, filterName) {
		createViewAction(ActionTypes.QUICK_FIND_SELECT_MANUFACTURER, {
			manufacturerKey: manufacturerKey,
			filterName: filterName
		});
	},

	selectRange: function (rangeKey, filterName) {
		createViewAction(ActionTypes.QUICK_FIND_SELECT_RANGE, {
			rangeKey: rangeKey,
			filterName: filterName
		});
	},

	selectModel: function (modelKey, filterName) {
		createViewAction(ActionTypes.QUICK_FIND_SELECT_MODEL, {
			modelKey: modelKey,
			filterName: filterName
		});
	},

	submitForm: function (selectedItem) {
		createViewAction(ActionTypes.QUICK_FIND_SUBMIT_FORM, {
			selectedItem: selectedItem
		});
		window.location = selectedItem.url;
	},

	validationError: function (errorMessages, filterName) {
		createViewAction(ActionTypes.QUICK_FIND_VALIDATION_ERROR, {
			errorMessages: errorMessages,
			filterName: filterName
		});
	}
};

module.exports = Actions;