/** @jsx React.DOM */'use strict';

var $ = require('jquery');
var React = require('react');
var DisplayToggle = require('./display-toggle.jsx');
var PriceRange = require('./price-range.jsx');
var MonthlyPriceRange = require('./monthly-price-range.jsx');
var PriceTypeSwitch = require('./price-type-switch.jsx');

var classNames = {
	carChooser: 'car-chooser',
	contentPanel: 'prices__content'
};


module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		onDisplayToggle: React.PropTypes.func,
		expand: React.PropTypes.bool,
		onPriceSet: React.PropTypes.func.isRequired, 
		onMonthlyPriceSet: React.PropTypes.func.isRequired,
		onPriceTypeSwitch: React.PropTypes.func.isRequired,
		isMonthlySelected: React.PropTypes.bool,
		selectedMinPrice: React.PropTypes.number.isRequired,
		selectedMaxPrice: React.PropTypes.number.isRequired,
		selectedMinMonthlyPrice: React.PropTypes.number.isRequired,
		selectedMaxMonthlyPrice: React.PropTypes.number.isRequired
	},

	getDefaultProps: function() {
		return {
			expand: true,
			isMonthlySelected: false
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var sectionDisplayClass = 'car-chooser__prices__content';

		if (this.props.expand) {
			sectionDisplayClass +=' car-chooser__prices__content--expanded';
		}

		return (
			<div>
				<h4>What is your price range?</h4>
				<div className={sectionDisplayClass}>
					<div className="row">
						<div className="column">
							<PriceTypeSwitch onSwitch={this.props.onPriceTypeSwitch} isMonthlySelected={this.props.isMonthlySelected} />
						</div>
					</div>

					<div className="row">
						<div className="column large-6">
							<PriceRange onPriceSet={this.props.onPriceSet}
								selectedMinPrice={this.props.selectedMinimumPrice}
								selectedMaxPrice={this.props.selectedMaximumPrice} 
								isDisabled={this.props.isMonthlySelected} />
						</div>

						<div className="column large-6">
							<MonthlyPriceRange onPriceSet={this.props.onMonthlyPriceSet}
								selectedMinPrice={this.props.selectedMinimumMonthlyPrice}
								selectedMaxPrice={this.props.selectedMaximumMonthlyPrice}
								isDisabled={!this.props.isMonthlySelected} />
						</div>
					</div>
				</div>
				<DisplayToggle onDisplayToggle={this.props.onDisplayToggle} expand={this.props.expand} contentPanelClassName={classNames.contentPanel} />
			</div>			
		);
	},
	/* #endregion */
});
