/** @jsx React.DOM */'use strict';

var $ = require('jquery');
var React = require('react');
var logger = require('../../utils/logger.js');

var SelectItem = require('./quick-find-filters/select-item-view.jsx');
var SubmitButton = require('./quick-find-filters/submit-button-view.jsx');

var Actions = require('../actions/company-car-tax-quick-find-actions.js');
var Store = require('../stores/company-car-tax-quick-find-store.js');
var Constants = require('../constants/company-car-tax-quick-find-constants.js');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		manufacturerKey: React.PropTypes.string,
		rangeKey: React.PropTypes.string,
		modelKey: React.PropTypes.number,
		yearPlateKey: React.PropTypes.number,
		derivativeKey: React.PropTypes.number,
		taxYearKey: React.PropTypes.number,
		taxRateKey: React.PropTypes.number,
		buttonText: React.PropTypes.string
	},

	getInitialState: function () {
		return Store.getState();
	},

	componentWillMount: function () {
		Store.addChangeListener(this.onStoreChange);
		Actions.initStoreData({
			initialManufacturerKey: this.props.manufacturerKey ? String(this.props.manufacturerKey) : null,
			initialRangeKey: this.props.rangeKey ? String(this.props.rangeKey) : null,
			initialModelKey: this.props.modelKey ? String(this.props.modelKey) : null,
			initialYearPlateKey: this.props.yearPlateKey ? String(this.props.yearPlateKey) : null,
			initialDerivativeKey: this.props.derivativeKey ? String(this.props.derivativeKey) : null,
			initialTaxYearKey: this.props.taxYearKey ? String(this.props.taxYearKey) : null,
			initialTaxRateKey: this.props.taxRateKey ? String(this.props.taxRateKey) : null
		});
	},

	componentDidMount: function () {
		$.findByBem('company-car-tax-quick-find', 'placeholders').addClass('hide');
		Actions.loadInitialState();

		if (this.state.initialManufacturerKey) {
			Actions.loadRanges(this.state.initialManufacturerKey);
		}

		if (this.state.initialModelKey) {
			Actions.loadYearPlates(this.state.initialModelKey);
		}
	},

	componentWillUnmount: function () {
		Store.removeChangeListener(this.onStoreChange);
	},

	onStoreChange: function () {
		this.setState(Store.getState());
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		logger.debug('Company Car Tax Quick Find', {
			message: 'Render',
			state: this.state
		});

		var manufacturerListDisabled = this.state.isLoading && this.state.manufacturers.length === 0;
		var rangeListDisabled = manufacturerListDisabled || !this.state.selectedManufacturer || (this.state.selectedManufacturer && this.state.ranges.length === 0);
		var modelListDisabled = rangeListDisabled || !this.state.selectedRange || (this.state.selectedRange && this.state.rangeYears.length === 0);
		var yearPlateDisabled = modelListDisabled || !this.state.selectedModel || (this.state.selectedModel && this.state.yearPlates.length === 0);
		var derivativeListDisabled = yearPlateDisabled || !this.state.selectedYearPlate;
		var taxYearDisabled = derivativeListDisabled || !this.state.selectedDerivative || this.state.taxYears.length === 0;
		var taxRateDisabled = taxYearDisabled || !this.state.selectedTaxYear || (this.state.selectedTaxYear && this.state.taxRates.length === 0);
		
		var modelList = this.getModelList(modelListDisabled);
		var derivativeList = this.getDerivativeList(derivativeListDisabled);

		var buttonDisabled = !this.state.selectedDerivative || !this.state.selectedTaxRate;
		var submitButton = this.getSubmitButton(buttonDisabled);

		return (
			<div className="company-car-tax-quick-find__lists">
				<form className="form" onSubmit={this.submitForm}>
					<div className="company-car-tax-quick-find__list">
						<SelectItem
							selectedItem = {this.state.selectedManufacturer}
							items = {this.state.manufacturers}
							onItemSelect = {this.selectManufacturer}
							promptText = {this.state.isLoading ? 'Loading...' : 'Select a make'}
							isDisabled = {manufacturerListDisabled}
							validationError = {this.state.validationErrors ? this.state.validationErrors.manufacturer : null} />
					</div>

					<div className="company-car-tax-quick-find__list">
						<SelectItem
							selectedItem = {this.state.selectedRange}
							items = {this.state.ranges}
							onItemSelect = {this.selectRange}
							promptText = {this.state.isLoading ? 'Loading...' : 'Select a range'}
							isDisabled = {rangeListDisabled}
							validationError = {this.state.validationErrors ? this.state.validationErrors.range : null} />
					</div>

					<div className="company-car-tax-quick-find__list">
						{modelList}
					</div>

					<div className="company-car-tax-quick-find__list">
						<SelectItem
							selectedItem = {this.state.selectedYearPlate}
							items = {this.state.yearPlates}
							onItemSelect = {this.selectYearPlate}
							promptText = {this.state.isLoading || (this.state.selectedModel && this.state.loadingYearPlates) ? ' Loading...' : 'Select year and plate'}
							isDisabled = {yearPlateDisabled}
							validationError = {this.state.validationErrors ? this.state.validationErrors.yearPlate : null} />
					</div>

					<div className="company-car-tax-quick-find__list">
						{derivativeList}
					</div>

					<div className="company-car-tax-quick-find__list">
						<SelectItem
							selectedItem = {this.state.selectedTaxYear}
							items = {this.state.taxYears}
							onItemSelect = {this.selectTaxYear}
							promptText = {this.state.isLoading ? 'Loading...' : 'Select a tax year'}
							isDisabled = {taxYearDisabled}
							validationError = {this.state.validationErrors ? this.state.validationErrors.taxYear : null} />
					</div>

					<div className="company-car-tax-quick-find__list">
						<SelectItem
							selectedItem = {this.state.selectedTaxRate}
							items = {this.state.taxRates}
							onItemSelect = {this.selectTaxRate}
							promptText = {this.state.isLoading ? 'Loading...' : 'Select a tax rate'}
							isDisabled = {taxRateDisabled}
							validationError = {this.state.validationErrors ? this.state.validationErrors.taxRate : null} />
					</div>
					<div>
						{submitButton}
					</div>
				</form>
			</div>
		);
	},

	getModelList: function (isDisabled) {
		var selectedValue = this.state.selectedModel !== null ? this.state.selectedModel.key : null;

		var listItems;
		if (this.state.rangeYears) {
			listItems = this.state.rangeYears.map(function (rangeYear) {
				return <optgroup key={rangeYear.name} label={rangeYear.name}>{rangeYear.models.map(function (model) {
					return <option key={model.key} value={model.key}>{model.name}</option>;
				})}</optgroup>;
			});
		}

		var validationError;
		if (this.state.validationErrors && this.state.validationErrors.model) {
			validationError = <span className="error">{this.state.validationErrors.model}</span>;
		}

		return (
			<div className="form__entry">
				<select disabled={isDisabled} value={selectedValue} className="form__list" onChange={this.selectModel}>
					<option value="">{this.state.isLoading || (this.state.selectedRange && this.state.loadingModels) ? ' Loading...' : 'Select a model'}</option>
					{listItems}
				</select>
				{validationError}
			</div>
		);
	},

	getDerivativeList: function (isDisabled) {
		var selectVersionText = this.state.selectedYearPlate ? 'Select from versions available in ' + this.state.selectedYearPlate.year : 'Select version';
		var selectedValue = this.state.selectedDerivative !== null ? this.state.selectedDerivative.key : null;

		var validationError;
		if (this.state.validationErrors && this.state.validationErrors.derivative) {
			validationError = <span className="error">{this.state.validationErrors.derivative}</span>;
		}

		var listItems;

		if (this.state.selectedYearPlate) {
			listItems = this.state.selectedYearPlate.trims.map(function (trim) {
				var trimName = trim.name ? trim.name : 'Standard Trim';
				return <optgroup key={trimName} label={trimName}>{trim.derivatives.map(function (derivative) {
					return <option key={derivative.key} value={derivative.key}>{derivative.name}</option>;
				})}</optgroup>;
			});
		}

		return (
			<div className="form__entry">
				<select disabled={isDisabled} value={selectedValue} className="form__list" onChange={this.selectDerivative}>
					<option value="">{this.state.isLoading ? 'Loading...' : selectVersionText}</option>
					{listItems}
				</select>
				{validationError}
			</div>
		);
	},

	getSubmitButton: function(isDisabled) {
		var buttonText = <span>{isDisabled ? 'Choose your options' : this.props.buttonText}</span>
		return <SubmitButton promptText={buttonText} isDisabled={isDisabled} />
	},

	/* #endregion */

	/* #region Event handlers */

	selectManufacturer: function (manufacturerKey, manufacturerName) {
		if (manufacturerKey) {
			Actions.selectManufacturer(manufacturerKey);
			Actions.loadRanges(manufacturerKey);
		}
	},

	selectRange: function (rangeKey, rangeName) {
		if (rangeKey) {
			Actions.selectRange(rangeKey);
		}
	},

	selectModel: function (e) {
		var modelKey = e.target.value;
		if (modelKey) {
			Actions.selectModel(modelKey);
			Actions.loadYearPlates(modelKey);
		}
	},

	selectYearPlate: function (yearPlateKey, yearPlateName) {
		if (yearPlateKey) {
			Actions.selectYearPlate(yearPlateKey);
		}
	},

	selectDerivative: function (e) {
		var derivativeKey = e.target.value;

		if (derivativeKey) {
			Actions.selectDerivative(derivativeKey);
		}
	},

	selectTaxYear: function (taxYearKey, taxYearName) {
		if (taxYearKey) {
			Actions.selectTaxYear(taxYearKey);
		}
	},

	selectTaxRate: function (taxRateKey, taxRateName) {
		if (taxRateKey) {
			Actions.selectTaxRate(taxRateKey);
		}
	},

	submitForm: function (e) {
		 e.preventDefault();

		 var derivativeUrl = this.state.selectedDerivative ? this.state.selectedDerivative.url : null;
		 var taxYearKey = this.state.selectedTaxYear ? this.state.selectedTaxYear.key : null;
		 var taxRateKey = this.state.selectedTaxRate ? this.state.selectedTaxRate.key : null;

		 Actions.submitForm(derivativeUrl, taxYearKey, taxRateKey);
	}
	/* #endregion */
});