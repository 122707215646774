'use strict';

var $ = require('jquery');
var user = require('./user.js');

var callbacks = $.Callbacks("cfsShortlistUpdates");

module.exports = {
	maxItems: 30,

	get: function () {
		return user.getCfsShortlist();
	},

	count: function () {
		return user.getCfsShortlist().length;
	},

	add: function (id) {
		user.addToCfsShortlist(id);
		callbacks.fire('added');
	},

	remove: function (id) {
		user.removeFromCfsShortlist(id);
		callbacks.fire('removed');
	},

	subscribe: function (callback) {
		callbacks.add(callback);
	}
};