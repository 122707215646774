'use strict';

var keyMirror = require('keymirror');

module.exports = {
	ActionTypes: keyMirror({
		TAXONOMY_SELECTOR_LOAD_MANUFACTURERS: null,
		TAXONOMY_SELECTOR_LOAD_MANUFACTURERS_SUCCESS: null,
		TAXONOMY_SELECTOR_LOAD_MANUFACTURERS_ERROR: null,
		TAXONOMY_SELECTOR_LOAD_RANGES: null,
		TAXONOMY_SELECTOR_LOAD_RANGES_SUCCESS: null,
		TAXONOMY_SELECTOR_LOAD_RANGES_ERROR: null,
		TAXONOMY_SELECTOR_LOAD_MODELS: null,
		TAXONOMY_SELECTOR_LOAD_MODELS_SUCCESS: null,
		TAXONOMY_SELECTOR_LOAD_MODELS_ERROR: null,	
		TAXONOMY_SELECTOR_SELECT_MANUFACTURER: null,
		TAXONOMY_SELECTOR_SELECT_RANGE: null,
		TAXONOMY_SELECTOR_SELECT_MODEL: null,
		TAXONOMY_SELECTOR_SELECT_DERIVATIVE: null,
		TAXONOMY_VRM_LOOKUP: null,
		TAXONOMY_VRM_LOOKUP_SUCCESS: null,
		TAXONOMY_VRM_LOOKUP_ERROR: null,
		TAXONOMY_SELECTOR_SELECT_VEHICLE: null,
		LOOKUP_VRM: null,
		LOOKUP_VRM_SUCCESS: null,
		LOOKUP_VRM_ERROR: null
	})
};