/** @jsx React.DOM */'use strict';

var $		= require('jquery');
var numeral	= require('numeral');
var React 	= require('react');

var filterScroller = require('../../../utils/filter-scroll.js');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		filterState: React.PropTypes.object.isRequired,
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		monthlyPriceOptions: React.PropTypes.array.isRequired,
		label: React.PropTypes.string,
		validationErrors: React.PropTypes.array.isRequired,
		onValueSet: React.PropTypes.func.isRequired,
		onValidationError: React.PropTypes.func.isRequired
	},

	getDefaultProps: function() {
		return {
			isDisabled: false,
			onValidationError: function () {}
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var label = this.props.label || 'Monthly repayment';
		var disabled = this.props.isLoading || this.props.isDisabled;

		var monthlyMinimumPrice = this.props.filterState.monthlyMinimumPrice !== null ? this.props.filterState.monthlyMinimumPrice : '';
		var monthlyMaximumPrice = this.props.filterState.monthlyMaximumPrice !== null ? this.props.filterState.monthlyMaximumPrice : '';

		var monthlyMinimumPriceError = this.getValidationErrorMessage('monthlyMinimumPrice');
		var monthlyMaximumPriceError = this.getValidationErrorMessage('monthlyMaximumPrice');

		var minOptions = [];
		var maxOptions = [];

		for (var index = 0; index < this.props.monthlyPriceOptions.length - 1; index++)
		{
			minOptions.push(this.getOption(this.props.monthlyPriceOptions[index]));
		}

		for (var index = 1; index < this.props.monthlyPriceOptions.length; index++)
		{
			maxOptions.push(this.getOption(this.props.monthlyPriceOptions[index], index === this.props.monthlyPriceOptions.length - 1 ? '+' : ''));
		}

		return (
			<section className="search-filters__monthly-price">
				<div className="search-filters__item">
					<label htmlFor="ddlMonthlyMinimumPrice" className="search-filters__item__label">{label} from:</label>
					<select id="ddlMonthlyMinimumPrice" name="monthlyMinimumPrice" disabled={disabled} value={monthlyMinimumPrice} aria-invalid={monthlyMinimumPriceError && true}
						className="search-filters__monthly-price-range__min-list search-filters__item__list"
						onChange={this.onDropdownChange} onBlur={this.onBlur}>
						{ this.getOption('') }
						{minOptions}
					</select>
					{monthlyMinimumPriceError}
				</div>
				<div className="search-filters__item">
					<label htmlFor="ddlMonthlyMaximumPrice" className="search-filters__item__label">{label} to:</label>
					<select id="ddlMonthlyMaximumPrice" name="monthlyMaximumPrice" disabled={disabled} value={monthlyMaximumPrice} aria-invalid={monthlyMaximumPriceError && true}
						className="search-filters__monthly-amount-range__max-list search-filters__item__list"
						onChange={this.onDropdownChange} onBlur={this.onBlur}>
						{ this.getOption('') }
						{maxOptions}
					</select>
					{monthlyMaximumPriceError}
				</div>
			</section>
		);
	},

	getOption: function (value, suffix) {
		suffix = suffix || '';
		var text = value !== '' ? '£' + numeral(value).format('0,0') + suffix : '-';

		return <option key={value} value={value}>{text}</option>;
	},

	getValidationErrorMessage: function (name) {
		if (this.props.validationErrors[name]) {
			return (
				<div className="search-filters__item__validation-error">
					<span className="icon icon--tiny icon-gap-right fi-alert"></span>
					{this.props.validationErrors[name]}
				</div>
			);
		}

		return null;
	},
	/* #endregion */

	/* #region Event handlers */
	onBlur: function(e){
		filterScroller.scrollToOriginalPosition(e.target);
	},

	onDropdownChange: function (e) {
		if (!this.props.isLoading) {
			var name = e.target.name;
			var value = e.target.value;
			if (name === 'monthlyMinimumPrice') {
				if (this.props.filterState.monthlyMaximumPrice && value !== '' && value > this.props.filterState.monthlyMaximumPrice) {
					this.props.onValidationError(name, 'Must be less than To amount');
				} else {
					this.props.onValueSet(name, value);
				}
			} else if (name === 'monthlyMaximumPrice') {
				if (this.props.filterState.monthlyMinimumPrice && value !== '' && value < this.props.filterState.monthlyMinimumPrice) {
					this.props.onValidationError(name, 'Must be more than From amount');
				} else {
					this.props.onValueSet(name, value);
				}
			}
		}
	}

	/* #endregion */
});