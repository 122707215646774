'use strict';

var $ 		= require('jquery');
var assign 	= require('object-assign');

var cookie = require('../storage/cookie.js');

var classNames = {
	sortDropdown: 'sort-dropdown'
};

module.exports = {
	init: function (context) {
		$.findByBem(classNames.sortDropdown, null, null, context).off('change.sortDropdown').on('change.sortDropdown', function () {
			var select = $(this);
			var cookieName = select.attr('data-cookie-name');
			var firstPageUrl = select.attr('data-first-page');

			if (select.val().indexOf(':') > -1 && cookieName && firstPageUrl) {
				var sort = select.val().split(':');
				var cookieData = assign({}, cookie.getCookie(cookieName), {
					sortBy: sort[0],
					sortOrder: sort[1]
				});

				cookie.setCookie(cookieName, cookieData);
				window.location = firstPageUrl;
			}
		});
	}
};