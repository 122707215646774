/** @jsx React.DOM */'use strict'

var React = require('react');
var $ = require('jquery');

var Store = require('../stores/cfs-search-results-store.js');

var sortDropDown = require('../../components/sort-dropdown.js');
var urlDropDown = require('../../components/url-dropdown.js');
var rppDropDown = require('../../components/rpp-dropdown.js');
var panels = require('../../components/panels.js');
var advert = require('../../adverts/advert.js');
var history = require('../../ui/history.js');
var images = require('../../ui/images.js');
var layout = require('../../ui/layout.js');
var analytics = require('../../utils/analytics.js');
var tagManager = require('../../utils/tagManager.js');
var cfsShortlistControls = require('../../components/cfs-shortlist-controls.js');
var resultsFilterButton = require('../../components/results-filter-button.js');
var tooltip = require('../../components/tooltip.js');

function renderResultsPage(resultsHtml) {
	return <div dangerouslySetInnerHTML={{ __html: resultsHtml }} />;
};

function getLoadingMessage() {
	return <div className="results-loading-panel"><div className="results-loading-panel__spinner"></div>Updating results, please wait</div>;
};

function getErrorMessage() {
	return <div>An error has occurred retrieving the results</div>;
}

function greyOutResults() {
	$.findByBem('search-results').addBemClass("cfs-search-results", "container", "loading");
}

function removePreviousResults() {
	$.findByBem('search-results').remove();
}

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		isDisabled: React.PropTypes.bool
	},

	componentWillMount: function () {
		Store.addChangeListener(this.onStoreChange);
	},

	componentWillUnmount: function () {
		Store.removeChangeListener(this.onStoreChange);
	},

	componentDidUpdate: function () {
		if (!this.state.isLoading) {
			// re-initialise the dropdowns on the injected results
			sortDropDown.init();
			urlDropDown.init();
			rppDropDown.init();
			this.loadAds();
			this.setValuationModal();
			this.reinitialiseJsComponents();
			this.setHistory();
			this.updatePageStats();
			this.setReadMorePropositionClick();
			images.lazyLoad();
			analytics.initClickTracking();
		}
	},

	setHistory: function () {
		var pageTitle = this.state.pageTitle ? this.state.pageTitle : 'Search results';
		history.setUrl(this.state.searchResultsUrl, false, pageTitle, { cfsSearch: true });
	},

	updatePageStats: function () {
		tagManager.pushToDataLayer({ 'vehicle': this.state.vehicle });
		var url = this.state.searchResultsUrl;
		var path = url.match(/\/(\w+(-\w+)*\/)+/g)[0];
		analytics.trackPageview(path);
		analytics.trackIpsosIrisPageView();
	},

	getInitialState: function () {
		return Store.getState();
	},

	loadAds: function () {
		advert.resetTopAd();
		advert.loadAds(null);
	},

	setValuationModal: function () {
		var $context = $.findByBem('search-results');

		var $elements = $context.findByBem('for-sale-result-item', 'price__star-price');
		$elements.off('click.cfsResults').on('click.cfsResults', function (e) {
			e.preventDefault();
			e.stopPropagation();
			$('#valuationInfoModal').foundation('reveal', 'open');
		});
	},

	setReadMorePropositionClick: function () {
		var $context = $.findByBem('search-results');
		var $link = $context.findByBem('seo-text__more');

		if ($link) {
			$link.on('click', function (e) {
				e.preventDefault();
				$context.findByBem('seo-text__collapse').removeBemClass('seo-text__fadeout');
				$link.hide();
			});
		}
	},

	reinitialiseJsComponents: function () {
		var $context = $.findByBem('search-results');
		panels.init($context);
		cfsShortlistControls.load($context);
		tooltip.init($context);
		resultsFilterButton.init();
	},

	render: function () {
		if (layout.isMobile()) {
			$('.off-canvas-wrap').foundation('offcanvas', 'hide', 'move-right');
		}

		if (this.state.isLoading && this.state.updateResults) {
			greyOutResults();
			var results = renderResultsPage(this.state.searchResults);
			var loadingMessage = getLoadingMessage();

			return (<div>{loadingMessage}<div className="cfs-search-results__container--loading"> {results}</div></div>);
		}

		if (this.state.isError) {
			return getErrorMessage();
		}

		if (this.state.searchResults) {
			advert.updateTargeting($(this.state.searchResults));

			if (this.state.firstLoad) {
				removePreviousResults();
			}

			return renderResultsPage(this.state.searchResults);
		}

		return null;
	},

	onStoreChange: function () {
		var state = Store.getState();
		this.setState(state);
	},
});