'use strict';

var $ = require('jquery');

var classNames = {
	searchFilters: 'search-filters',
	seoCrawlPaths: 'seo-crawl-paths'
};

var $searchFilters;

function hideFlyouts() {
	$searchFilters.findByBem(classNames.searchFilters, 'flyout__panel:visible').hide();
}

function initFlyoutPanels() {
	$searchFilters.findByBem(classNames.searchFilters, 'flyout__open-link').off('click.searchFilters').on('click.searchFilters', function (e) {
		e.preventDefault();
		e.stopPropagation();

		if (!$(this).hasBemClass(classNames.searchFilters, 'flyout__open-link', 'disabled')) {
			var $flyoutPanel = $(this).siblingsByBem(classNames.searchFilters, 'flyout__panel');

			if (!$flyoutPanel.is(':visible')) {
				hideFlyouts();
				$flyoutPanel.fadeIn(200);
			} else {
				hideFlyouts();
			}
		}
	});

	$searchFilters.findByBem(classNames.searchFilters, 'flyout__panel__close-link').off('click.searchFilters').on('click.searchFilters', function (e) {
		e.preventDefault();
		e.stopPropagation();
		hideFlyouts();
	});

	$('body').off('click.searchFilters').on('click.searchFilters', function (e) {
		var $target = $(e.target);
		if ($target.closestByBem(classNames.searchFilters, 'flyout').length === 0 && !$target.hasBemClass(classNames.searchFilters, 'update-button')) {
			hideFlyouts();
		}
	});
}

module.exports = {
	init: function () {
		$searchFilters = $.findByBem(classNames.searchFilters);

		if ($searchFilters.length > 0) {
			initFlyoutPanels();
		}
	},

	hideSeoCrawlPaths: function() {
		var seoCrawlPaths = $.findByBem(classNames.seoCrawlPaths);

		if (seoCrawlPaths.length > 0) {
			seoCrawlPaths.hide();
		}
	},

	showSeoCrawlPaths: function () {
		var seoCrawlPaths = $.findByBem(classNames.seoCrawlPaths, null, 'hidden');

		if (seoCrawlPaths.length > 0) {
			seoCrawlPaths.attr('class', classNames.seoCrawlPaths);
		}
	}
};