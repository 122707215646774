'use strict';

var config = require('./config.js');
var tagManager = require('./tagManager.js');
var $ = require('jquery');

function trackGAEvent(category, action, label, value, nonInteraction) {
	if (category && action) {
		var gaEvent = {
			event: 'GA_Event',
			gaEventCategory: category,
			gaEventAction: action,
			gaEventLabel: label || config.dataLayer.page.templateName,
			gaEventValue: value,
			gaEventNonInteraction: nonInteraction || false
		};
		tagManager.pushToDataLayer(gaEvent);
	}
}

function setTrackingEvent($element, trackingData) {
	$element.off('click.analytics').on('click.analytics', function () {
		var label = trackingData.label;
		if (trackingData.position) {
			label = trackingData.position + " - " + trackingData.label;
		}

		trackGAEvent(trackingData.category, trackingData.action, label, trackingData.value);
	});
}

module.exports = {
	trackEvent: function (category, action, label, value, nonInteraction) {
		trackGAEvent(category, action, label, value, nonInteraction);
	},

	trackPageview: function (url, title) {
		var pageView = {
			event: 'GA_VirtualPageview',
			gaVirtualPageUrl: url,
			gaVirtualPageTitle: title
		};
		tagManager.pushToDataLayer(pageView);
	},

	trackIpsosIrisPageView: function () {
		// window.dm is defined in the Ipsos Iris script, see _IpsosIris partial view
		var ii = window.dm;
		if (ii) {
			ii.AjaxEvent('pageview', null, 'parkers');
		}
	},

	initClickTracking: function () {
		$('[data-ga-track]').each(function (index, element) {
			var $element = $(element);
			var trackingDataJson = $element.attr('data-ga-track');

			if (trackingDataJson) {
				var trackingData = null;
				try {
					trackingData = JSON.parse(trackingDataJson);
				} catch (e) {
					trackingData = null;
				}

				if (trackingData && trackingData.category && trackingData.action) {
					setTrackingEvent($element, trackingData);
				}
			}
		});
	}
};
