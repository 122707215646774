'use strict';

var config = require('../utils/config.js');
var screenSizeWatcher = require('../watchers/screen-size-watcher.js');
var layout = require('../ui/layout.js');

function formatSize(size) {
	return '_' + size + '_';
}

function updateScreenSizeHint() {
	var title = document.title.replace(/_(S|M|L|XL|XXL)_/i , formatSize(layout.getScreenSize()));
	document.title = title;
}

module.exports = {
	init: function() {
		if (!config.environment || config.environment === 'prd') {
			return;
		}

		screenSizeWatcher.onChange(function(){
			updateScreenSizeHint();
		});

		document.title = formatSize(layout.getScreenSize()) + ' | ' + document.title;
	}
};