'use strict';

var self = module.exports = {
	hyphenToCamelCase: function (value)
	{
		return value.replace(/-([a-z])/gi, function (s, g) { return g.toUpperCase(); } );
	},

	hyphenToPascalCase: function (value)
	{
		var camelCase = self.hyphenToCamelCase(value);
		var pascalCase = camelCase.substring(0, 1).toUpperCase() + camelCase.substring(1);
		
		return pascalCase;
	}
};