/** @jsx React.DOM */'use strict';

var React 	= require('react');
var logger 	= require('../../utils/logger.js');

var SelectItem		= require('./quick-find-filters/select-item-view.jsx');
var SubmitButton	= require('./quick-find-filters/submit-button-view.jsx');

var Actions		= require('../actions/quick-find-year-derivative-actions.js');
var Store		= require('../stores/quick-find-year-derivative-store.js');
var Constants	= require('../constants/quick-find-year-derivative-constants.js');

var ValuationPurpose = Constants.ValuationPurpose;

function showPageLoading() {
	$.findByBem('valuation-details-page__display').addBemClass("valuation-details-page", "display", "loading");
}

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		vehicleType: React.PropTypes.string.isRequired,
		filterName: React.PropTypes.string.isRequired,
		modelKey: React.PropTypes.number.isRequired,
		color: React.PropTypes.string,
		buttonText: React.PropTypes.string,
		taxonomyOnly: React.PropTypes.bool,
		columnClass: React.PropTypes.string,
		selectedYearPlate: React.PropTypes.string,
		selectedVersion: React.PropTypes.string
	},

	getInitialState: function () {
		return Store.getState();
	},

	componentWillMount: function () {
		Store.addChangeListener(this.onStoreChange);
	},

	componentDidMount: function () {
		Actions.loadYears(this.props.vehicleType, this.props.filterName, this.props.modelKey, this.props.selectedYearPlate, this.props.selectedVersion);
		Actions.initialiseValuationPurposeOptions();
	},

	componentWillUnmount: function () {
		Store.removeChangeListener(this.onStoreChange);
	},

	onStoreChange: function () {
		this.setState(Store.getState());
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		logger.debug('Quick Find Year & Derivative', {
			message: 'Render',
			state: this.state
		});

		var selectVersionText = this.state.selectedYearPlate ? 'Select from versions available in ' + this.state.selectedYearPlate.year : 'Select version';
		var selectYearPlateText = 'Select year and plate';

		var derivativeListDisabled = this.state.isLoading || !this.state.selectedYearPlate;
		var derivativeList = this.getDerivativeList(derivativeListDisabled, selectVersionText);

		var valuationPurposeOptions = !this.props.taxonomyOnly ? this.getValuationPurposeOptions() : null;

		var submitButton = this.getSubmitButton();

		var columnClass = this.props.columnClass ? 'column ' + this.props.columnClass : '';

		if (this.state.derivativeIsLoading){
			showPageLoading();
		}

		return (
			<div className="quick-find-year-derivative__lists">
				<form className="form" onSubmit={this.submitForm}>
				<div className={columnClass}>
				<div className="quick-find-year-derivative__list">
					<h5>{selectYearPlateText}</h5>
					<SelectItem
						selectedItem = {this.state.selectedYearPlate}
						items = {this.state.yearPlates}
						onItemSelect = {this.selectYearPlate}
						promptText = {selectYearPlateText}
						isDisabled = {this.state.isLoading}
						validationError = {this.state.validationErrors ? this.state.validationErrors.yearPlate : null} />
				</div>
				</div>
				<div className={columnClass}>
				<div className="quick-find-year-derivative__list">
					<h5>{selectVersionText}</h5>
					{derivativeList}
				</div>
				</div>
				{valuationPurposeOptions}
				<div>
					{submitButton}
				</div>
				</form>
			</div>
		);
	},

	getSubmitButton : function(){
		var buttonText = <span>{this.props.buttonText}</span>
		if (!this.props.taxonomyOnly){
			return <SubmitButton promptText = {buttonText} />
		}
	},

	getDerivativeList: function (isDisabled, promptText) {
		var defaultText = this.state.isLoading ? 'Loading...' : promptText;
		var selectedValue = this.state.selectedDerivativeUrl !== null ? this.state.selectedDerivativeUrl : '';

		var validationError;
		if (this.state.validationErrors.derivative) {
			validationError = <span className="error">{this.state.validationErrors.derivative}</span>;
		}

		var listItems;

		if (this.state.selectedYearPlate) {
			if (this.state.selectedYearPlate.trims.length > 1) {
				listItems = this.state.selectedYearPlate.trims.map(function (trim) {
					var trimName = trim.name ? trim.name : 'Standard Trim';
					return <optgroup key={trimName} label={trimName}>{trim.derivatives.map(function (derivative) {
						return <option key={derivative.key} value={derivative.url}>{derivative.name}</option>;
					})}</optgroup>;
				});
			} else {
				var trim = this.state.selectedYearPlate.trims[0];
				listItems = trim.derivatives.map(function (derivative) {
					return <option key={derivative.key} value={derivative.url}>{derivative.name}</option>;
				});
			}
		}

		return (
			<div className="form__entry">
				<select disabled={isDisabled} value={selectedValue} className="form__list" onChange={this.selectDerivative}>
					<option value="">{defaultText}</option>
					{listItems}
				</select>
				{validationError}
			</div>
		);
	},

	getValuationPurposeOptions: function () {
		var validationError;
		if (this.state.validationErrors.valuationPurpose) {
			validationError = <span className="error">{this.state.validationErrors.valuationPurpose}</span>;
		}

		var columnClass = this.props.columnClass ? 'column ' + this.props.columnClass : '';
		var vehicleType = this.props.vehicleType == 'cars' ? 'car' : 'van';

		var options = [];

		for (var i = 0; i < this.state.valuationPurposeOptions.length; i++) {
			var option = this.getPurposeOption(this.state.valuationPurposeOptions[i], vehicleType);
			options.push(option);
		};

		return (
			<div className={columnClass}>
			<div className="quick-find-year-derivative__list">
				<h5>What is the purpose of your valuation?</h5>
				<div className="form__entry">
					{options}
					<div><input id={ValuationPurpose.JUST_CURIOUS.NAME} type="radio" name="valuationPurpose" value={ValuationPurpose.JUST_CURIOUS.NAME} onChange={this.selectValuationPurposeOption} /><label htmlFor="curious"><span className="quick-find-year-derivative__label">{ValuationPurpose.JUST_CURIOUS.LABEL}</span></label></div>
				</div>
				{validationError}
			</div>
			</div>
		);
	},

	getPurposeOption: function(option, vehicleType) {
		var name = option[0];
		var label = option[1];
		var displayLabel = label.replace('|vehicleType|', vehicleType);
		return (
			<div><input id={name} type="radio" name="valuationPurpose" value={name} onChange={this.selectValuationPurposeOption} /><label htmlFor={name}><span className="quick-find-year-derivative__label">{displayLabel}</span></label></div>
		);
	},

	/* #endregion */

	/* #region Event handlers */
	selectYearPlate: function (yearPlateKey, yearPlateName) {
		Actions.selectYearPlate(yearPlateKey);
	},

	selectDerivative: function (e) {
		var derivativeUrl = e.target.value;

		if (derivativeUrl) {
			Actions.selectDerivative(derivativeUrl, this.props.taxonomyOnly);
		}
	},

	selectValuationPurposeOption: function (e) {
		var valuationPurpose = e.target.value;

		if (valuationPurpose) {
			Actions.selectValuationPurposeOption(valuationPurpose);
		}
	},

	submitForm: function (e) {
		e.preventDefault();
		Actions.submitForm(this.state.selectedDerivativeUrl, this.state.valuationPurpose, this.props.vehicleType);
	}
	/* #endregion */
});