'use strict';

var $		= require('jquery');
var React 	= require('react');

var CarChooserFilters = require('./views/car-chooser-filters-view.jsx');
var CarChooserResults = require('./views/car-chooser-results-view.jsx');
var CfsSearchFilters = require('./views/cfs-search-filters-view.jsx');
var CfsSearchResults = require('./views/cfs-search-results-view.jsx');
var VfsSearchFilters = require('./views/vfs-search-filters-view.jsx');
var VfsSearchResults = require('./views/vfs-search-results-view.jsx');
var CompanyCarTaxQuickFind = require('./views/company-car-tax-quick-find-view.jsx');
var ForSaleOrLeaseQuickFind = require('./views/for-sale-or-lease-quick-find-view.jsx');
var LeasingSearchFilters = require('./views/leasing-search-filters-view.jsx');
var LeasingSearchResults = require('./views/leasing-search-results-view.jsx');
var QuickFindModel = require('./views/quick-find-model-view.jsx');
var QuickFindYearDerivative = require('./views/quick-find-year-derivative-view.jsx');
var TaxonomySelector = require('./views/taxonomy-selector-view.jsx');
var VrmLookup = require('./views/vrm-lookup-view.jsx');

var componentNames = {
	carChooserFilters: 'car-chooser-filters',
	carChooserResults: 'car-chooser-results',
	cfsSearchFilters: 'cfs-search-filters',
	cfsSearchResults: 'cfs-search-results',
	vfsSearchFilters: 'vfs-search-filters',
	vfsSearchResults: 'vfs-search-results',
	companyCarTaxQuickFind: 'company-car-tax-quick-find',
	forSaleOrLeaseQuickFind: 'for-sale-or-lease-quick-find',
	leasingSearchFilters: 'leasing-search-filters',
	leasingSearchResults: 'leasing-search-results',
	quickFindModel: 'quick-find-model',
	quickFindYearDerivative: 'quick-find-year-derivative',
	taxonomySelector: 'taxonomy-selector',
	vrmLookup: 'vrm-lookup'
};

function renderView(component, componentName, context) {
	var containers = $('[data-jsx="' + componentName + '"]', context);

	if (containers.length > 0 && typeof React !== 'undefined') {
		$.each(containers, function (key, item) {
			var data = $(item).data();
			var element = React.createElement(component, data);
			React.render(element, item);
		});
	}
}

module.exports = {
	render: function (context) {
		renderView(CarChooserFilters, componentNames.carChooserFilters, context);
		renderView(CarChooserResults, componentNames.carChooserResults, context);
		renderView(CfsSearchFilters, componentNames.cfsSearchFilters, context);
		renderView(CfsSearchResults, componentNames.cfsSearchResults, context);
		renderView(VfsSearchFilters, componentNames.vfsSearchFilters, context);
		renderView(VfsSearchResults, componentNames.vfsSearchResults, context);
		renderView(CompanyCarTaxQuickFind, componentNames.companyCarTaxQuickFind, context);
		renderView(ForSaleOrLeaseQuickFind, componentNames.forSaleOrLeaseQuickFind, context);
		renderView(LeasingSearchFilters, componentNames.leasingSearchFilters, context);
		renderView(LeasingSearchResults, componentNames.leasingSearchResults, context);
		renderView(QuickFindModel, componentNames.quickFindModel, context);
		renderView(QuickFindYearDerivative, componentNames.quickFindYearDerivative, context);
		renderView(TaxonomySelector, componentNames.taxonomySelector, context);
		renderView(VrmLookup, componentNames.vrmLookup, context);
	}
};