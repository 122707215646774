'use strict';

var config = require('./config.js');

module.exports = {
	debug: function (message, debugObject) {
		if (!window.console) {
			return;
		} else if (!console.debug) {
			this.log(message, debugObject);
		}

		if (config.debugMode) {
			if (debugObject) {
				console.debug([ message, debugObject ]);
			} else {
				console.debug(message);
			}
		}
	},

	error: function (message, debugObject) {
		if (!window.console) {
			return;
		} else if (!console.error) {
			this.log(message, debugObject);
		}

		if (config.debugMode && debugObject) {
			console.error([ message, debugObject ]);
		} else {
			console.error(message);
		}
	},

	info: function (message, debugObject) {
		if (!window.console) {
			return;
		} else if (!console.info) {
			this.log(message, debugObject);
		}
 
		if (config.debugMode && debugObject) {
			console.info([ message, debugObject ]);
		} else {
			console.info(message);
		}
	},

	log: function (message, debugObject) {
		if (!window.console) {
			return;
		}

		if (config.debugMode && debugObject) {
			console.log([ message, debugObject ]);
		} else {
			console.log(message);
		}
	},

	warn: function (message, debugObject) {
		if (!window.console) {
			return;
		} else if (!console.warn) {
			this.log(message, debugObject);
		}

		if (config.debugMode && debugObject) {
			console.warn([ message, debugObject ]);
		} else {
			console.warn(message);
		}
	},

	secondsSincePageStart: function() {
		return (window.pageStart ? (Date.now() - window.pageStart) / 1000 : 0) + 's';
	}
};