/** @jsx React.DOM */'use strict';

var $ = require('jquery');
var React = require('react');
var analytics = require('../../utils/analytics.js');
var logger = require('../../utils/logger.js');

var Actions = require('../actions/vrm-lookup-actions.js');
var Store = require('../stores/vrm-lookup-store.js');

let recaptchaInstance;

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		vehicleType: React.PropTypes.string.isRequired,
		promptText: React.PropTypes.string,
		vrmPathSegment: React.PropTypes.string,
		inlineLayout: React.PropTypes.bool,
		gaLabel: React.PropTypes.string,
		recaptchaSiteKeyV2: React.PropTypes.string.isRequired, // v2 key
		recaptchaSiteKeyV3: React.PropTypes.string.isRequired, // v3 key
	},

	getDefaultProps: function () {
		return {
			promptText: 'Go',
		};
	},

	getInitialState: function () {
		const initialState = Store.getState();
		logger.debug('[VRM-LOOKUP] Initial State', { initialState });
		return {
			...initialState,
			isRecaptchaV2Rendered: false, // Flag to track if v2 has been rendered
		};
	},

	componentDidUpdate: function () {
		logger.debug('[VRM-LOOKUP] Component Updated', { currentState: this.state });

		$(document).foundation('equalizer', 'reflow');

		if (!this.state.isLoading && this.state.confirmationUrl) {
			var gaLabel = this.props.gaLabel || 'Valuation Landing';
			analytics.trackEvent('VRM Lookup', 'Successful VRM Lookup', gaLabel);
			var vrmData = {
				'vrm': this.state.vrmData.vrm,
				'derivativeId': this.state.vrmData.derivativeId,
				'fuelType': this.state.vrmData.fuelType,
				'firstRegistered': this.state.vrmData.firstRegistered,
				'plateAndYear': this.state.vrmData.plateAndYear,
				'vrmCookieName': this.state.vrmData.vrmCookieName,
			};
			logger.debug('[VRM-LOOKUP] Storing VRM Data', { vrmData, confirmationUrl: this.state.confirmationUrl });
			Actions.storeVrm(vrmData, this.state.confirmationUrl);
		}
	},

	componentWillMount: function () {
		logger.debug('[VRM-LOOKUP] Component Will Mount');
		Store.addChangeListener(this.onStoreChange);
	},

	componentWillUnmount: function () {
		logger.debug('[VRM-LOOKUP] Component Will Unmount');
		Store.removeChangeListener(this.onStoreChange);
	},

	onStoreChange: function () {
		const newState = Store.getState();
		logger.debug('[VRM-LOOKUP] Store Changed', { newState });
		this.setState(newState);
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		logger.debug('[VRM-LOOKUP] Rendering normal view layout');
		
		var errorMessage;
		var loadingItem;
		var layout;

		if (this.state.isLoading || this.state.confirmationUrl) {
			loadingItem = <span className="vrm-lookup__button__spinner"></span>;
		}

		if (this.state.errorMessage) {
			errorMessage = <span className="error">{this.state.errorMessage}</span>;
			logger.debug('[VRM-LOOKUP] Error Message Displayed', { errorMessage: this.state.errorMessage });
		}

		layout = this.props.inlineLayout
			? this.getInlineLayout(loadingItem, errorMessage)
			: this.getStackedLayout(loadingItem, errorMessage);

		return (
			<form className="vrm-lookup" onSubmit={this.onSubmit}>
				{layout}
			</form>
		);
	},

	getStackedLayout: function (loadingItem, errorMessage) {
		return (
			<span>
                <input type="text" className="vrm-lookup__input" placeholder="your reg" />
				{errorMessage}
				<div className="vrm-lookup__submit-panel">
                    <button className="vrm-lookup__button button" disabled={this.state.isLoading} type="submit">
                        {this.props.promptText}
						{loadingItem}
                    </button>
                </div>

                <div className="vrm-lookup__recaptcha-panel" id="recaptcha-v2"></div> {/* Container for v2 */}
            </span>
		);
	},

	getInlineLayout: function (loadingItem, errorMessage) {
		return (
			<span>
                <div className="vrm-lookup__inline-layout">
                    <input type="text" className="vrm-lookup__input" placeholder="your reg" />
                    <div className="vrm-lookup__submit-panel">
                        <button className="vrm-lookup__button button" disabled={this.state.isLoading} type="submit">
                            {this.state.isLoading ? loadingItem : this.props.promptText}
                        </button>
                    </div>

                    <div className="vrm-lookup__recaptcha-panel" id="recaptcha-v2"></div> {/* Container for v2 */}
                </div>
				{errorMessage}
            </span>
		);
	},

	/* #endregion */

	/* #region Event handlers */
	onSubmit: function (e) {
		e.preventDefault();
		var vrm = $('.vrm-lookup__input').val();

		logger.debug('[VRM-LOOKUP] Form Submitted', { submittedVrm: vrm });

		if (vrm) {
			// Execute reCAPTCHA v3 first
			window.grecaptcha.execute(this.props.recaptchaSiteKeyV3, { action: 'submit' })
				.then(this.onRecaptchaVerifyV3)
				.catch(() => {
					logger.debug('[VRM-LOOKUP] reCAPTCHA v3 Execution Failed. Falling back to v2.');
					this.renderRecaptchaV2(); // Render v2 if v3 fails

					var $recaptchaPanel = $.findByBem('vrm-lookup', 'recaptcha-panel');
					$recaptchaPanel.show();
				});
		} else {
			logger.debug('[VRM-LOOKUP] VRM is empty on form submission');
		}
	},

	onRecaptchaVerifyV3: function (recaptchaResponse) {
		var vrm = $('.vrm-lookup__input').val();

		logger.debug('[VRM-LOOKUP] reCAPTCHA v3 Verified', { recaptchaResponse });

		if (vrm) {
			vrm = vrm.replace(/\s+/g, '');
			logger.debug('[VRM-LOOKUP] Looking up VRM', { vehicleType: this.props.vehicleType, vrm });
			Actions.lookupVrm(this.props.vehicleType, vrm, recaptchaResponse, this.props.vrmPathSegment, true);
		} else {
			logger.debug('[VRM-LOOKUP] No VRM to lookup after v3 verification');
		}
	},

	onRecaptchaVerifyV2: function (recaptchaResponse) {
		var vrm = $('.vrm-lookup__input').val();

		logger.debug('[VRM-LOOKUP] reCAPTCHA v2 Verified', { recaptchaResponse });

		if (vrm) {
			vrm = vrm.replace(/\s+/g, '');
			logger.debug('[VRM-LOOKUP] Looking up VRM', { vehicleType: this.props.vehicleType, vrm });
			Actions.lookupVrm(this.props.vehicleType, vrm, recaptchaResponse, this.props.vrmPathSegment, false);
		} else {
			logger.debug('[VRM-LOOKUP] No VRM to lookup after v2 verification');
		}
	},

	renderRecaptchaV2: function () {
		// Check if recaptchaInstance exists and reset it if necessary
		if (recaptchaInstance !== null && typeof recaptchaInstance !== 'undefined') {
			window.grecaptcha.reset(recaptchaInstance);
			
			logger.debug('[VRM-LOOKUP] Existing reCAPTCHA instance reset');
		}
		
		if (!this.state.isRecaptchaV2Rendered && typeof window.grecaptcha !== 'undefined') {
			// Render reCAPTCHA v2
			recaptchaInstance = window.grecaptcha.render('recaptcha-v2', {
				'sitekey': this.props.recaptchaSiteKeyV2,
				'callback': this.onRecaptchaVerifyV2,
			});
			this.setState({ isRecaptchaV2Rendered: true });
			logger.debug('[VRM-LOOKUP] reCAPTCHA v2 rendered');
		} else {
			logger.debug('[VRM-LOOKUP] reCAPTCHA v2 not available');
		}
	}
	/* #endregion */
});
