/** @jsx React.DOM */'use strict';

var React = require('react');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		onFiltersReset: React.PropTypes.func.isRequired,
		filterState: React.PropTypes.object.isRequired
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var link;

		if (this.props.filterState.hasSelectedFilters) {
			link = (
				<a href="#" className="search-filters__reset__link" onClick={this.onResetClick}>Reset filters</a>
			);
		} else {
			link = (
				<span className="search-filters__reset__link search-filters__reset__link--disabled">Reset filters</span>
			);
		}
		
		return (			
			<div className="search-filters__reset search-filters__reset--display-inline">					
				{link}
			</div>
		);
	},
	/* #endregion */

	/* #region Event handlers */
	onResetClick: function (e) {
		e.preventDefault();
		if (!this.props.isLoading && this.props.filterState.hasSelectedFilters) {
			this.props.onFiltersReset();
		}
	}
	/* #endregion */
});