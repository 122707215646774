/** @jsx React.DOM */'use strict';

var React 	= require('react');
var logger 	= require('../../utils/logger.js');
var analytics = require('../../utils/analytics.js');

var Actions = require('../actions/taxonomy-selector-actions.js');
var Store 	= require('../stores/taxonomy-selector-store.js');

var VrmLookup = require('./vrm-layout-inline-view.jsx');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		vehicleType: React.PropTypes.string.isRequired,
		filterName: React.PropTypes.string,
		includeVrmLookup: React.PropTypes.bool,
		gaLabel: React.PropTypes.string,
		vrmPathSegment: React.PropTypes.string,
		recaptchaSiteKeyV2: React.PropTypes.string.isRequired, // v2 key
		recaptchaSiteKeyV3: React.PropTypes.string.isRequired, // v3 key
	},

	componentDidUpdate: function() {
		if (this.state.vrmLookupSuccessful) {
			analytics.trackEvent('VRM Lookup', 'Successful VRM Lookup', this.props.gaLabel);
			this.showTaxonomySelector();
		}
	},

	getInitialState: function () {
		this.setState({
			vrmValue: '',
			vrmLookupSuccessful: false
		});

		return Store.getState();
	},

	componentWillMount: function () {
		Store.addChangeListener(this.onStoreChange);
		Actions.loadManufacturers(this.props.vehicleType, this.props.filterName);
	},

	componentWillUnmount: function () {
		Store.removeChangeListener(this.onStoreChange);
	},

	onStoreChange: function () {
		this.setState(Store.getState());
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		logger.debug('Taxonomy Selector', {
			message: 'Render',
			state: this.state
		});

		var manufacturerListDisabled = this.state.isLoading && this.state.manufacturers.length === 0;
		var rangeListDisabled = manufacturerListDisabled || (this.state.selectedManufacturer !== null && this.state.ranges.length === 0);
		var modelListDisabled = rangeListDisabled || (this.state.selectedRange !== null && this.state.rangeYears.length === 0);
		var derivativeListDisabled = modelListDisabled || (this.state.selectedModel !== null && this.state.trims.length === 0);

		var manufacturerDefaultText = manufacturerListDisabled ? 'Loading...' : 'Select a make';
		var rangeDefaultText = rangeListDisabled && this.state.isLoading ? 'Loading...' : 'Select a range';
		var modelDefaultText = modelListDisabled && this.state.isLoading ? 'Loading...' : 'Select a model';
		var derivativeDefaultText = derivativeListDisabled && this.state.isLoading ? 'Loading...' : 'Select a version';

		var selectedManufacturerKey = this.state.selectedManufacturer !== null ? this.state.selectedManufacturer.key : null;
		var selectedRangeKey = this.state.selectedRange !== null ? this.state.selectedRange.key : null;
		var selectedModelKey = this.state.selectedModel !== null ? this.state.selectedModel.key : null;
		var selectedDerivativeKey = this.state.selectedDerivative !== null ? this.state.selectedDerivative.key : null;

		var manufacturerListItems, rangeListItems, modelListItems;

		if (this.state.manufacturers) {
			manufacturerListItems = this.state.manufacturers.map(function (manufacturer) {
				return <option key={manufacturer.key} value={manufacturer.key}>{manufacturer.name}</option>;
			});
		}

		if (this.state.ranges) {
			rangeListItems = this.state.ranges.map(function (range) {
				return <option key={range.key} value={range.key}>{range.name}</option>;
			});
		}

		if (this.state.rangeYears) {
			modelListItems = this.state.rangeYears.map(function (rangeYear) {
				return <optgroup key={rangeYear.name} label={rangeYear.name}>{rangeYear.models.map(function (model) {
					return <option key={model.key} value={model.key}>{model.name}</option>;
				})}</optgroup>;
			});
		}

		var derivativeList;
		if (this.props.showDerivatives && this.state.trims) {
			var derivativeListItems = this.state.trims.map(function (trim){
				return <optgroup key={trim.name} label={trim.name}>{trim.derivatives.map(function (derivative) {
					return <option key={derivative.key} value={derivative.key}>{derivative.name}</option>;
				})}</optgroup>;
			});
			
			derivativeList =
				<div className="form__entry">
					<select name="derivativeId" disabled={derivativeListDisabled} id="derivative" value={selectedDerivativeKey} className="form__list" onChange={this.selectDerivative}>
						<option value="">{derivativeDefaultText}</option>
						{derivativeListItems}
					</select>
				</div>;
		}

		return (

			<div>
				{this.props.includeVrmLookup && 
					<span>
						<VrmLookup
							vrmPath={this.props.vrmPathSegment}
							vehicleType={this.props.vehicleType} 
							isLoading={this.state.isLoading} 
							mobilePromptText="Look up reg"
							desktopPromptText="Look up registration"
							errorMessage={this.state.errorMessage}
							recaptchaSiteKeyV2={this.props.recaptchaSiteKeyV2}
							recaptchaSiteKeyV3={this.props.recaptchaSiteKeyV3}/>

						<div className="owner-review-form__manual-selection-link" onClick={this.showTaxonomySelector}>
							If you don't know the registration then search by make &amp; model
						</div>
					</span>
				}
				<div className={this.props.includeVrmLookup ? "taxonomy-selector__lists taxonomy-selector__lists--hidden" : "taxonomy-selector__lists"}>
					<div className="form__entry">
						<select name="manufacturerId" disabled={manufacturerListDisabled} value={selectedManufacturerKey} className="form__list" onChange={this.selectManufacturer}>
							<option value="">{manufacturerDefaultText}</option>
							{manufacturerListItems}
						</select>
					</div>
					<div className="form__entry">
						<select name="rangeId" disabled={rangeListDisabled} value={selectedRangeKey} className="form__list" onChange={this.selectRange}>
							<option value="">{rangeDefaultText}</option>
							{rangeListItems}
						</select>
					</div>
					<div className="form__entry">
						<select name="modelId" disabled={modelListDisabled} value={selectedModelKey} className="form__list" onChange={this.selectModel}>
							<option value="">{modelDefaultText}</option>
							{modelListItems}
						</select>
					</div>
					{derivativeList}
				</div>
			</div>
		);
	},
	/* #endregion */

	/* #region Event handlers */
	selectManufacturer: function (e) {
		var manufacturerKey = e.target.value;
		Actions.selectManufacturer(manufacturerKey);

		if (manufacturerKey !== '') {
			Actions.loadRanges(this.props.vehicleType, this.props.filterName, manufacturerKey);
		}
	},

	selectRange: function (e) {
		var rangeKey = e.target.value;
		Actions.selectRange(rangeKey);

		if (rangeKey !== '') {
			Actions.loadModels(this.props.vehicleType, this.props.filterName, this.state.selectedManufacturer.key, rangeKey);
		}
	},

	selectModel: function (e) {
		var modelKey = e.target.value;
		Actions.selectModel(modelKey);
	},

	selectDerivative: function (e) {
		var derivativeKey = e.target.value;
		Actions.selectDerivative(derivativeKey);
	},

	showTaxonomySelector: function() {
		$.findByBem('taxonomy-selector', 'lists').show(1000);
	}
	/* #endregion */
});