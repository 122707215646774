'use strict';

var assign 	= require('object-assign');
var FluxChangeStore = require('./flux-change-store.js');
var FluxDispatcher = require('../dispatchers/flux-dispatcher.js');
var FluxConstants = require('../constants/flux-constants.js');
var FilterConstants = require('../constants/vfs-search-filters-constants.js');

var PayloadSources = FluxConstants.PayloadSources;
var FilterActionTypes = FilterConstants.ActionTypes;

var storeState = {
	searchResults: null,
	isLoading: false,
	isError: false,
	searchResultsUrl: null,
	firstLoad: true,
	updateResults: true,
	vehicle: null,
	pageTitle: null
};

var firstLoad = true;

var Store = assign(new FluxChangeStore(), {
	getState : function() {
		return storeState;
	}
});

function handleServerAction(action) {
	var handled = false;

	if (action) {
		switch(action.type) {
			case FilterActionTypes.VFS_SEARCH_FILTERS_UPDATE_STATE:
			case FilterActionTypes.VFS_SEARCH_FILTERS_RESET:
				handled = true;
				storeState.isLoading = true;
				storeState.isError = false;

				break;

			case FilterActionTypes.VFS_SEARCH_FILTERS_UPDATE_STATE_SUCCESS:
				handled = true;
				storeState.isLoading = false;

				if (action.data && action.data.searchResults) {
					storeState.searchResults = action.data.searchResults;
					storeState.searchResultsUrl = action.data.searchResultsUrl;
					storeState.pageTitle = action.data.pageTitle;
					storeState.firstLoad = firstLoad;
					firstLoad = false;
					var nullValue = 'NotSet';

					storeState.vehicle = {
						makeName: action.data.selectedManufacturer ? action.data.selectedManufacturer.text : nullValue,
						rangeName: action.data.selectedRange ? action.data.selectedRange.text : nullValue,
						modelName: action.data.selectedModel ? action.data.selectedModel.text : nullValue,
						condition: action.data.condition || nullValue
					};

				} else {
					storeState.isError = true;
				}

				break;

			case FilterActionTypes.VFS_SEARCH_FILTERS_UPDATE_STATE_ERROR:
				handled = true;
				storeState.isLoading = false;
				storeState.isError = true;

				break;
		}
	}

	if (handled) {
		Store.emitChange();
	}
}

function handleViewAction(action) {
	var handled = false;
	
	if (action) {

		switch(action.type){
			case FilterActionTypes.VFS_SEARCH_FILTERS_UPDATE_RESULTS:
				handled = true;
				storeState.updateResults = action.data.updateResults;
				storeState.isLoading = true;

				break;
		}
	}

	if (handled) {
		Store.emitChange();
	}
}

Store.dispatchToken = FluxDispatcher.register(function (payload) {
	if (payload && payload.source) {
		if (payload.source === PayloadSources.SERVER_ACTION) {
			handleServerAction(payload.action);
		} else if (payload.source === PayloadSources.VIEW_ACTION) {
			handleViewAction(payload.action);
		}
	}
});

module.exports = Store;
