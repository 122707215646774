/** @jsx React.DOM */'use strict';

var React = require('react');

var FacetDropdown 	= require('./shared/facet-dropdown-view.jsx');
var FacetFlyoutList = require('./shared/facet-flyout-list-view.jsx');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		filterState: React.PropTypes.object.isRequired,
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		onItemRemove: React.PropTypes.func.isRequired,
		onItemSet: React.PropTypes.func.isRequired,
		vehicleType: React.PropTypes.string
	},

	getDefaultProps: function() {
		return {
			vehicleType: 'car'
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var title = 'How many seats should the ' + this.props.vehicleType + ' have?';
		return (
			<div className="search-filters__facet">
				<div className="hide-for-large-up">
					<FacetDropdown
						name="numberOfSeats"
						defaultText="Number of seats"
						isLoading={this.props.isLoading}
						isDisabled={this.props.isDisabled}
						items={this.props.filterState.numberOfSeatsItems}
						selectedItem={this.props.filterState.selectedNumberOfSeats}
						onRemove={this.props.onItemRemove}
						onSet={this.props.onItemSet}
					/>
				</div>
				<div className="show-for-large-up">
					<FacetFlyoutList
						label="Number of seats"
						name="numberOfSeats"
						title={title}
						isLoading={this.props.isLoading}
						isDisabled={this.props.isDisabled}
						items={this.props.filterState.numberOfSeatsItems}
						selectedItem={this.props.filterState.selectedNumberOfSeats}
						onRemove={this.props.onItemRemove}
						onSet={this.props.onItemSet}
					/>
				</div>
			</div>
		);
	}
	/* #endregion */
});