'use strict';

var assign 	= require('object-assign');

var cfsShortlistCookie = require('../storage/cfs-shortlist-cookie.js');
var userCookie = require('../storage/user-cookie.js');
var sessionCookie = require('../storage/session-cookie.js');
var cookie = require('../storage/cookie.js');
var sticky = require('../ui/sticky.js');

module.exports = {
	getPostcode: function () {
		var cookieData = userCookie.getCookie();

		if (cookieData) {
			return cookieData.postcode;
		}

		return null;
	},

	savePostcode: function (postcode) {
		var cookieData = userCookie.getCookie() || {};

		assign(cookieData, {
			postcode: postcode
		});

		userCookie.setCookie(cookieData);
	},

	isAuthenticated: function () {
		var cookieData = cookie.getCookie('parkers-auth');
		if (cookieData) {
			return true;
		}

		return false;
	},

	getOwnedVehicle: function (vehicleType) {
		var cookieData = sessionCookie.getCookie();

		if (cookieData) {
			if (vehicleType === 'van') {
				return cookieData.ownedVan;
			}

			return cookieData.ownedCar;
		}

		return null;
	},

	saveOwnedVehicle: function (vehicle, vehicleType) {
		var cookieData = sessionCookie.getCookie() || {};

		if (vehicleType === 'van') {
			assign(cookieData,
				{
					ownedVan: vehicle
				});
		} else {
			assign(cookieData,
				{
					ownedCar: vehicle
				});
		}

		sessionCookie.setCookie(cookieData);
	},

	saveValuationPurpose: function(valuationPurpose) {
		var cookieData = sessionCookie.getCookie() || {};

		assign(cookieData,
			{
				valuationPurpose: valuationPurpose
			});

		sessionCookie.setCookie(cookieData);
	},

	getCfsAdvancedFilterState: function () {
		var cookieData = sessionCookie.getCookie();

		if (cookieData) {
			return cookieData.showCfsAdvancedFilter;
		}

		return null;
	},
	
	saveCfsAdvancedFilterState: function(state) {
		if (state) {
			var cookieData = sessionCookie.getCookie() || {};

			assign(cookieData,
			{
				showCfsAdvancedFilter: state
			});

			sessionCookie.setCookie(cookieData);
		}
	},

	getLeasingAdvancedFilterState: function () {
		var cookieData = sessionCookie.getCookie();

		if (cookieData) {
			return cookieData.showLeasingAdvancedFilter;
		}

		return null;
	},
	
	saveLeasingAdvancedFilterState: function(state) {
		if (state) {
			var cookieData = sessionCookie.getCookie() || {};

			assign(cookieData,
			{
				showLeasingAdvancedFilter: state
			});

			sessionCookie.setCookie(cookieData);
		}
	},

	getVfsAdvancedFilterState: function () {
		var cookieData = sessionCookie.getCookie();

		if (cookieData) {
			return cookieData.showVfsAdvancedFilter;
		}

		return null;
	},
	
	saveVfsAdvancedFilterState: function(state) {
		if (state) {
			var cookieData = sessionCookie.getCookie() || {};

			assign(cookieData,
			{
				showVfsAdvancedFilter: state
			});

			sessionCookie.setCookie(cookieData);
		}
	},

	getCfsShortlist: function () {
		var cookieData = cfsShortlistCookie.getCookie();

		if (cookieData && cookieData.shortlist) {
			return cookieData.shortlist;
		}

		return [];
	},

	addToCfsShortlist: function(id) {
		if (!id) {
			return;
		}

		sticky.showSiteHeader();

		var cookieData = cfsShortlistCookie.getCookie() || {};

		var shortlist = this.getCfsShortlist();
		shortlist.push(id);

		assign(cookieData,
			{
				shortlist: shortlist
			});

		cfsShortlistCookie.setCookie(cookieData);
	},

	removeFromCfsShortlist: function(id) {
		if (!id) {
			return;
		}

		sticky.showSiteHeader();

		var cookieData = cfsShortlistCookie.getCookie() || {};

		var shortlist = this.getCfsShortlist();
		var index = shortlist.indexOf(id);

		if (index !== -1) {
			shortlist.splice(index, 1);

			assign(cookieData,
				{
					shortlist: shortlist
				});

			cfsShortlistCookie.setCookie(cookieData);
		}
	}
};