'use strict';

var assign 	= require('object-assign');

var logger	= require('../../utils/logger.js');

var FluxChangeStore	= require('./flux-change-store.js');
var FluxConstants 	= require('../constants/flux-constants.js');
var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/vrm-lookup-constants.js');

var ActionTypes 	= Constants.ActionTypes;
var PayloadSources 	= FluxConstants.PayloadSources;

var storeData = {
	confirmationUrl: null,
	errorMessage: null,
	isLoading: false
};

var Store = assign(new FluxChangeStore(), {
	getState: function () {
		return storeData;
	}
});

function handleServerAction(action) {
	if (action) {
		var handled = false;

		switch(action.type) {	
			case ActionTypes.LOOKUP_VRM:
				handled = true;

				storeData.isLoading = true;
				storeData.confirmationUrl = null;
				storeData.errorMessage = null;

				break;

			case ActionTypes.LOOKUP_VRM_SUCCESS:
				handled = true;

				storeData.isLoading = false;
				if (action.data.isSuccess) {
					storeData.confirmationUrl = action.data.confirmPageUrl;
					storeData.vrmData = action.data;
				} else {
					storeData.errorMessage = action.data.errorMessage;
				}

				break;

			case ActionTypes.LOOKUP_VRM_ERROR:
				handled = true;

				storeData.isLoading = false;
				storeData.errorMessage = 'An error occured. Please try later.';

				break;
		}

		if (handled) {
			logger.debug('VRM lookup', {
				message: 'Handle server action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

function handleViewAction(action) {
	if (action) {
		var handled = false;

		switch(action.type) {
		}

		if (handled) {
			logger.debug('VRM lookup', {
				message: 'Handle view action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

Store.dispatchToken = FluxDispatcher.register(function (payload) {
	if (payload && payload.source) {
		if (payload.source === PayloadSources.SERVER_ACTION) {
			handleServerAction(payload.action);
		} else if (payload.source === PayloadSources.VIEW_ACTION) {
			handleViewAction(payload.action);
		}
	}
});

module.exports = Store;