'use strict';

var baseApi = require('./base-api.js');
var $ = require('jquery');

function getApiUrl(action) {
    var url = 'cars/leasing/' + action;
    return url;
}

function getSearchResults(resultsUrl, options) {
    return $.ajax({
        url: resultsUrl,
        method: 'GET',
        contentType: 'text/html',
        cache: false,
        timeout: 20000,
        success: function (data) {
            if (options && options.successCallback) {

                var $pageHtml = $($.parseHTML(data));
                var resultsHtml = $pageHtml.filterByBem('search-results').first().prop('outerHTML');

                options.successCallback(resultsHtml);
            }
        },
        error: function (xhr, status, err) {
            if (options && options.errorCallback) {
                options.errorCallback(xhr, status, err);
            }
        }
    });
}

module.exports = {
    search: function (filterRequest, options) {
        var url = getApiUrl('search');

        if (options.includeResults) {
            var originalCallback = options.successCallback;

            options.successCallback = function(data) {
                getSearchResults(data.searchResultsUrl, {
                    successCallback: function(resultsHtml) {
                        data.searchResults = resultsHtml;
                        data.pageTitle = $(resultsHtml).attr('data-title');

                        originalCallback(data);
                    },
                    errorCallback: options.errorCallback
                });
            };
        }

        baseApi.post(url, filterRequest, options, 'json');
    }
};