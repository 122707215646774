/** @jsx React.DOM */'use strict';

var $		= require('jquery');
var numeral = require('numeral');
var React 	= require('react');
var filterScroller = require('../../../utils/filter-scroll.js');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		filterState: React.PropTypes.object.isRequired,
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		priceOptions: React.PropTypes.array.isRequired,
		label: React.PropTypes.string,
		validationErrors: React.PropTypes.array.isRequired,
		onValueSet: React.PropTypes.func.isRequired,
		onValidationError: React.PropTypes.func.isRequired
	},

	getDefaultProps: function() {
		return {
			isDisabled: false,
			onValidationError: function () {}
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var disabled = this.props.isLoading || this.props.isDisabled;

		var label = this.props.label || 'Price';

		var priceMin = this.props.filterState.priceMin !== null ? this.props.filterState.priceMin : '';
		var priceMax = this.props.filterState.priceMax !== null ? this.props.filterState.priceMax : '';

		var priceMinError = this.getValidationErrorMessage('priceMin');
		var priceMaxError = this.getValidationErrorMessage('priceMax');

		var minOptions = [];
		var maxOptions = [];

		for (var index = 0; index < this.props.priceOptions.length - 1; index++)
		{
			minOptions.push(this.getOption(this.props.priceOptions[index]));
		}

		for (var index = 1; index < this.props.priceOptions.length; index++)
		{
			maxOptions.push(this.getOption(this.props.priceOptions[index], index === this.props.priceOptions.length - 1 ? '+' : ''));
		}

		return (
			<section className="search-filters__price-range">
				<div className="search-filters__item">
					<label htmlFor="ddlPriceMin" className="search-filters__item__label">{label} from:</label>
					<select id="ddlPriceMin" name="priceMin" disabled={disabled} value={priceMin} aria-invalid={priceMinError && true}
						className={"search-filters__price-range__min-list search-filters__item__list"}
						onChange={this.onDropdownChange} onBlur={this.onBlur}>
						{ this.getOption('') }
						{minOptions}
					</select>
					{priceMinError}
				</div>
				<div className="search-filters__item">
					<label htmlFor="ddlPriceMax" className="search-filters__item__label">{label} to:</label>
					<select id="ddlPriceMax" name="priceMax" disabled={disabled} value={priceMax} aria-invalid={priceMaxError && true}
						className={"search-filters__price-range__max-list search-filters__item__list "}
						onChange={this.onDropdownChange} onBlur={this.onBlur}>
						{ this.getOption('') }
						{maxOptions}
					</select>
					{priceMaxError}
				</div>
			</section>
		);
	},

	getOption: function (value, suffix) {
		suffix = suffix || '';
		var text = value !== '' ? '£' + numeral(value).format('0,0') + suffix : ' ';

		return <option key={value} value={value}>{text}</option>;
	},

	getValidationErrorMessage: function (name) {
		if (this.props.validationErrors[name]) {
			return (
				<div className="search-filters__item__validation-error">
					<span className="icon icon--tiny icon-gap-right fi-alert"></span>
					{this.props.validationErrors[name]}
				</div>
			);
		}

		return null;
	},
	/* #endregion */

	/* #region Event handlers */
	onBlur: function(e){
		filterScroller.scrollToOriginalPosition(e.target);
	},

	onDropdownChange: function (e) {
		if (!this.props.isLoading) {
			var name = e.target.name;
			var value = e.target.value;
			if (name === 'priceMin') {
				if (this.props.filterState.priceMax && value !== '' && value > this.props.filterState.priceMax) {
					this.props.onValidationError(name, 'Must be less than To price');
				} else {
					this.props.onValueSet(name, value);
				}
			} else if (name === 'priceMax') {
				if (this.props.filterState.priceMin && value !== '' && value < this.props.filterState.priceMin) {
					this.props.onValidationError(name, 'Must be more than From price');
				} else {
					this.props.onValueSet(name, value);
				}
			}
		}
	}
	/* #endregion */
});