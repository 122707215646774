'use strict';

var $ = require('jquery');
var scroll = require('../ui/scroll.js');
var analytics = require('../utils/analytics.js');
var layout = require('../ui/layout.js');

var ScrollMagic = require('scrollmagic');

var classNames = {
	resultsFilterButton: 'results-filter-button',
	hide: 'hide'
};

var modifierNames = {
	sticky: 'sticky',
	inline: 'inline'
};

function initStickyFilterButton() {
	if (typeof ScrollMagic === 'undefined') {
		return;
	}

	var $stickyButton = $.findByBem(classNames.resultsFilterButton, null, modifierNames.sticky);

	var scrollMagicController = scroll.getScrollMagicController();

	new ScrollMagic.Scene({
		triggerElement: '.results-filter-button--inline',
		triggerHook: 'onLeave'
	}).on('enter', function (event) {
		var scrollDirection = event.target.controller().info('scrollDirection');

		if (scrollDirection === 'FORWARD') {
			$stickyButton.removeClass(classNames.hide);
		}
	}).on('leave', function (event) {
		var scrollDirection = event.target.controller().info('scrollDirection');

		if (scrollDirection === 'REVERSE') {
			$stickyButton.addClass(classNames.hide);
		}
	}).addTo(scrollMagicController);

	$stickyButton.on('click.result-filters', function () {
		$(window).scrollTop(0);
		analytics.trackEvent('Results filtering', 'Sticky Tab Click', layout.getScreenSize());
	});

	var $inlineButton = $.findByBem(classNames.resultsFilterButton, null, modifierNames.inline);

	$inlineButton.on('click.result-filters', function () {
		$(window).scrollTop(0);
		analytics.trackEvent('Results filtering', 'Button Click', layout.getScreenSize());
	});
}

module.exports = {
	init: function () {
		initStickyFilterButton();
	}
};