'use strict';

var $ = require('jquery');

var analytics = require('../utils/analytics.js');
var layout = require('../ui/layout.js');
var logger = require('../utils/logger.js');
var scroll = require('../ui/scroll.js');
var timer = require('../utils/timer.js');
var config = require('../utils/config.js');
var postcodeHelper = require('../utils/postcode-helper');
var adSlotSettings = require('./slotSettings.js');
var definedAdSlots = require('./definedSlots.js');
var ScrollMagic = require('scrollmagic');

var classNames = {
	siteRoot: 'site-root',
	topAd: 'top-ad',
	mpuAd: 'mpu-ad',
	inlineLeaderboardAd: 'inline-leaderboard-ad',
	stickyWrapperInner: 'sticky-wrapper__inner',
	moveableMpuContainer: 'moveable-mpu-container',
	mpuInject: 'mpu-inject',
	resizeableMpu: 'resizeable-mpu',
	mpu: 'mpu',
	hasHeroSkin: 'has-hero-skin',
	adContainer: 'ad-container'
};

var modifierNames = {
	stuck: 'stuck',
	unstuck: 'unstuck',
	loaded: 'loaded',
	viewed: 'viewed',
	doubleMpu: 'double',
	fullWidth: 'full-width',
	right: 'right',
	left: 'left',
	noFloat: 'no-float'
};

var queryStringKeys = {
	adTest: 'adtest',
	itmSource: 'itm_source',
	utmMedium: 'utm_medium',
	utmSource: 'utm_source'
};

var itmSourceValues = {
	bibblio: 'bibblio'
};

var targetingKeys = {
	adTest: 'adtest',
	autoRefresh: 'auto-refresh',
	cmsTemplateName: 'cms_template_name',
	googleThirdPartyCookieDeprecation: 'google_tpc',
	isBibblio: 'is_bibblio',
	onboarding: 'onboarding',
	pageviewId: 'pageview_id',
	paidCampaign: 'paidcampaign',
	paidTraffic: 'paidtraffic',
	permutive: 'permutive',
	position: 'position',
	postcode: 'postcode',
	referrer: 'referrer',
	skinAllow: 'skin-allow',
	viewport: 'viewport'
};

var googletag;
var providers = [];
var providerEventDetails = {};

var adConfig;
var adUnitPath;

var scrollMagicController;
var stickyTimerTimeout = 3000;

var slotSettings = adSlotSettings.slotSettings;
var flexibleSlotSizes = adSlotSettings.flexibleSlotSizes;
var definedSlotIds = definedAdSlots.definedAdSlotIds;
var adSlots = definedAdSlots.googleAdSlots;

var adsHaveLazyLoaded = false;

var injectedMpus = {
	enabled: false
};

var stickyMpus = {
	enabled: false,
	scenes: {}
};

var lazyLoadedAds = {
	slotsToLoad: []
};

var galleryAds = {
	automaticReloadEnabled: false,
	pendingAds: {
		mpu: false,
		leaderboard: false
	},
	reloadTimeoutTimer: null,
	reloadTimeoutDuration: null,
	viewedRefreshDuration: null,
	viewedRefreshTimer: null
};

var events = {
	init: 'onInit',
	defineSlot: 'onDefineSlot',
	requestAds: 'onRequestAds',
	slotTargeting: 'onSlotTargeting'
};

var gumgumIds = '[id^="ad_is_"]';

function stringIsNullishOrWhiteSpace(stringValue) {
	return stringValue === null || stringValue === undefined || typeof (stringValue) !== 'string' || stringValue.trim() === '';
}

function isSlotVisible(slotId) {
	return $('#' + slotId).is(':visible');
}

function logAdvertEvent(actionName, slotIds) {
	if (!slotIds || !config.enableAdRequestLogging) {
		return;
	}

	if (typeof slotIds === 'string') {
		slotIds = [slotIds];
	}

	slotIds.forEach(function (slotId) {
		analytics.trackEvent('Ad Requests', actionName, slotId, null, true);
	});
}

// Call this within a googletag.cmd.push function
function requestAds(slots) {
	googletag.pubads().refresh(slots);

	var slotIds = slots.map(function (slot) {
		return slot.getSlotElementId();
	});

	logAdvertEvent('Requested', slotIds);
}

var callbacks = {
	requestInitialAdsCallback: function () {
		googletag.cmd.push(function () {
			logger.debug('DFP: Load initial ads - after ' + logger.secondsSincePageStart());

			for (var i = 0; i < definedSlotIds.length; i++) {
				googletag.display(definedSlotIds[i]);
			}

			// Leaderboard and mpu-1 must never be lazy loaded
			var adsToRefresh = [adSlots[slotSettings.leaderboard1.id], adSlots[slotSettings.mpu1.id]];

			adsToRefresh.push(adSlots[slotSettings.overlay.id]);

			if (isSlotVisible(slotSettings.gumGum.id)) {
				adsToRefresh.push(adSlots[slotSettings.gumGum.id]);
			}

			if (isSlotVisible(slotSettings.seedTag.id)) {
				adsToRefresh.push(adSlots[slotSettings.seedTag.id]);
			}

			requestAds(adsToRefresh);
		});

		logger.debug('DFP: Defined initial slots - after ' + logger.secondsSincePageStart());
	},

	loadAdsCallback: function () {
		googletag.cmd.push(function () {
			logger.debug('DFP: Reload ads');

			var adsToRefresh = [adSlots[slotSettings.leaderboard1.id]];

			if (!injectedMpus.enabled) {
				adsToRefresh.push(adSlots[slotSettings.mpu1.id]);
			}

			adsToRefresh.push(adSlots[slotSettings.overlay.id]);

			requestAds(adsToRefresh);
		});
	},

	loadMobileGalleryAdsCallback: function (loadOnlyLeaderboardGallery) {
		googletag.cmd.push(function () {
			logger.debug('DFP: Reload mobile gallery ad');
			var slotsToLoad = [adSlots[slotSettings.leaderboardGallery.id]];

			if (!loadOnlyLeaderboardGallery) {
				slotsToLoad.push(adSlots[slotSettings.mpuGallery.id]);
			}

			requestAds(slotsToLoad);
		});
	},

	loadDesktopGalleryAdsCallback: function () {
		googletag.cmd.push(function () {
			logger.debug('DFP: Reload desktop gallery ad');
			var slotsToLoad = [adSlots[slotSettings.mpuGallery.id], adSlots[slotSettings.leaderboardGallery.id]];
			galleryAds.pendingAds.mpu = true;
			galleryAds.pendingAds.leaderboard = true;

			requestAds(slotsToLoad);
		});
	}
};

function providerComplete(eventId, providerId) {
	/*jshint validthis: true */

	var eventDetails = providerEventDetails[eventId];

	if (!eventDetails) {
		// Must have timed out and removed the event already
		return false;
	}

	timer.pause(eventDetails.timeout);

	var index = -1;

	eventDetails.providers.some(function (element, i) {
		if (element.id === providerId) {
			index = i;
			return true;
		}
	});

	if (index >= 0) {
		eventDetails.providers[index].isComplete = true;
	}

	var allComplete = eventDetails.providers.every(function (item) {
		return item.isComplete === true;
	});

	if (allComplete) {
		delete providerEventDetails[eventId];

		if (eventDetails.callback && (typeof eventDetails.callback === 'function')) {
			timer.cancel(eventDetails.timeout);
			logger.debug('DFP: All providers complete');
			eventDetails.callback.apply(this, eventDetails.arguments);
		}
	} else {
		timer.resume(eventDetails.timeout);
	}
}

function providerTimeout(eventId) {
	/*jshint validthis: true */
	var eventDetails = providerEventDetails[eventId];

	delete providerEventDetails[eventId];

	if (eventDetails.callback && (typeof eventDetails.callback === 'function')) {
		logger.debug('DFP: Provider timeout');
		eventDetails.callback.apply(this, eventDetails.arguments);
	}
}

function fireProviderEvent(event, eventData, callback, args) {
	var eventId = event + '_' + Date.now();
	var eventProviders = providers.filter(function (provider) {
		return provider[event];
	});

	if (callback) {
		var eventDetails = {
			eventId: eventId,
			callback: callback,
			arguments: args,
			providers: eventProviders.map(function (provider) {
				var result = {
					id: provider.getId(),
					isComplete: false
				};

				return result;
			})
		};

		providerEventDetails[eventId] = eventDetails;

		providerEventDetails[eventId].timeout = providerTimeout(eventId);
	}

	var data = {
		eventId: eventId,
		eventData: eventData,
		onCompleteCallback: providerComplete
	};

	eventProviders.forEach(function (provider) {
		provider[event](data);
	});
}

function parseDfpUpdateData(dfpDataContent) {
	var newAdConfig = {};
	try {
		newAdConfig = JSON.parse(dfpDataContent);
	} catch (e) {
		logger.warn('DFP: Invalid dfp data json', e);
	}

	return newAdConfig;
}

function setVisibility($context) {
	// add or remove ID attribute based on whether ad is visible or not so there are no <div> tags with duplicate IDs
	$('.advert[data-id]', $context).each(function () {
		var $adContainer = $(this);
		if ($adContainer.is(':visible')) {
			var dataVariable = 'data-id';

			$adContainer.attr('id', $adContainer.attr(dataVariable));
		} else {
			$adContainer.removeAttr('id');
		}
	});
}

function setCollectiveAdTargeting() {
	var windowOver1800 = $(window).width() > 1800;
	googletag.pubads().setTargeting(targetingKeys.skinAllow, windowOver1800.toString());
}

// Call this within a googletag.cmd.push function
function setTargeting() {
	googletag.pubads().setTargeting(targetingKeys.viewport, layout.getScreenSize());

	for (var key in adConfig.targetingKeyValues) {
		if (adConfig.targetingKeyValues.hasOwnProperty(key)) {
			var value = adConfig.targetingKeyValues[key];

			googletag.pubads().setTargeting(key, [value]);
		}
	}

	googletag.pubads().setTargeting(targetingKeys.autoRefresh, '00');

	setCollectiveAdTargeting();

	googletag.pubads().setTargeting(targetingKeys.onboarding, [(config.onboardingCandidate === true ? 'yes' : 'no')]);

	if (!stringIsNullishOrWhiteSpace(document.referrer)) {
		googletag.pubads().setTargeting(targetingKeys.referrer, [document.referrer]);
	}

	var searchParams = new window.URLSearchParams(window.location.search);

	if (searchParams.has(queryStringKeys.adTest)) {
		var adTestValue = searchParams.get(queryStringKeys.adTest);

		if (!stringIsNullishOrWhiteSpace(adTestValue)) {
			googletag.pubads().setTargeting(targetingKeys.adTest, [adTestValue]);
		}
	}

	var isBibblio = false;
	if (searchParams.has(queryStringKeys.itmSource)) {
		var itmSourceValue = searchParams.get(queryStringKeys.itmSource);

		isBibblio = !stringIsNullishOrWhiteSpace(itmSourceValue) && itmSourceValue.toLowerCase() === itmSourceValues.bibblio;
	}
	googletag.pubads().setTargeting(targetingKeys.isBibblio, [isBibblio.toString()]);

	var postcode = postcodeHelper.getPostcodeOutCode();
	googletag.pubads().setTargeting(targetingKeys.postcode, postcode);
	googletag.pubads().setTargeting(targetingKeys.pageviewId, config.pageViewId);

	var paidTrafficValue = 'no';
	
	if (config.ppcTrackingParams) {
		paidTrafficValue = 'yes';

		var params = config.ppcTrackingParams;
		var paidCampaignValue = `${params.utmMedium}|${params.utmSource}|${params.trackingIdName},${params.trackingId}`;
		googletag.pubads().setTargeting(targetingKeys.paidCampaign, paidCampaignValue);
	}
		
	googletag.pubads().setTargeting(targetingKeys.paidTraffic, paidTrafficValue);

	// Chrome third party cookie deprecation test
	if ('cookieDeprecationLabel' in window.navigator) {
		window.navigator.cookieDeprecationLabel.getValue().then(function(label) {
			googletag.pubads().setTargeting(targetingKeys.googleThirdPartyCookieDeprecation, label);
		});
	}

	logger.debug('DFP: Targetting set - after ' + logger.secondsSincePageStart());
}

// Call this within a googletag.cmd.push function
function defineAdSlot(adSlot, restrictDoubleMpu, enforceLeaderBoard1Size) {
	var slotSize;

	if (flexibleSlotSizes.enabled && adSlot.canBeFlexibleWidth) {
		slotSize = adSlotSettings.getFlexibleSlotSizes();

		if (enforceLeaderBoard1Size) {
			var leaderboard1Sizes = slotSettings.leaderboard1.sizes;
			slotSize = layout.isMobile() ? leaderboard1Sizes.mobile : leaderboard1Sizes.desktop;
		}

		// if a mobile view restrict the number of double sized MPUs
		if (restrictDoubleMpu) {
			slotSize = slotSize.slice(0, -1);
		}
	} else {
		slotSize = layout.isMobile() ? adSlot.sizes.mobile : adSlot.sizes.desktop;
	}

	logger.debug('DFP: Defining ' + adSlot.id);

	var slot = googletag.defineSlot(adUnitPath + '/' + adSlot.id + '/' + adConfig.templateName, slotSize, adSlot.id)
		.setTargeting(targetingKeys.position, [adSlot.positionValue]);

	fireProviderEvent(events.slotTargeting, slot);

	slot.addService(googletag.pubads());

	if (adSlot.sizes.sizeMapping) {
		slot.defineSizeMapping(adSlot.sizes.sizeMapping);
	}

	if (enforceLeaderBoard1Size) {
		var leaderboard1SizesMapping = slotSettings.leaderboard1.sizes.sizeMapping;
		slot.defineSizeMapping(leaderboard1SizesMapping);
	}

	definedSlotIds.push(adSlot.id);
	return slot;
}

function defineAdSlots() {
	fireProviderEvent(events.defineSlot, slotSettings.leaderboard1);

	if (!injectedMpus.enabled) {
		fireProviderEvent(events.defineSlot, slotSettings.mpu1);

		if (isSlotVisible(slotSettings.mpu2.id)) {
			fireProviderEvent(events.defineSlot, slotSettings.mpu2);
		}

		if (isSlotVisible(slotSettings.mpu3.id)) {
			fireProviderEvent(events.defineSlot, slotSettings.mpu3);
		}

		if (isSlotVisible(slotSettings.mpu4.id)) {
			fireProviderEvent(events.defineSlot, slotSettings.mpu4);
		}

		if (isSlotVisible(slotSettings.mpu5.id)) {
			fireProviderEvent(events.defineSlot, slotSettings.mpu5);
		}
	}

	if (isSlotVisible(slotSettings.native.id)) {
		fireProviderEvent(events.defineSlot, slotSettings.native);
	}

	if (isSlotVisible(slotSettings.native2.id)) {
		fireProviderEvent(events.defineSlot, slotSettings.native2);
	}

	if (isSlotVisible(slotSettings.inlineFooter.id)) {
		fireProviderEvent(events.defineSlot, slotSettings.inlineFooter);
	}

	if (isSlotVisible(slotSettings.gumGum.id)) {
		fireProviderEvent(events.defineSlot, slotSettings.gumGum);
	}

	if (isSlotVisible(slotSettings.seedTag.id)) {
		fireProviderEvent(events.defineSlot, slotSettings.seedTag);
	}

	googletag.cmd.push(function () {
		logger.debug('DFP: Starting initial slot definition - after ' + logger.secondsSincePageStart());

		adSlots[slotSettings.leaderboard1.id] = defineAdSlot(slotSettings.leaderboard1);

		if (!injectedMpus.enabled) {
			adSlots[slotSettings.mpu1.id] = defineAdSlot(slotSettings.mpu1);

			var restrictDoubleMpu = layout.isMobile() && adConfig.excludeMobileDoubleMpu;

			if (isSlotVisible(slotSettings.mpu2.id)) {
				adSlots[slotSettings.mpu2.id] = defineAdSlot(slotSettings.mpu2, restrictDoubleMpu);
				lazyLoadedAds.slotsToLoad.push(adSlots[slotSettings.mpu2.id]);
			}

			if (isSlotVisible(slotSettings.mpu3.id)) {
				adSlots[slotSettings.mpu3.id] = defineAdSlot(slotSettings.mpu3, false, true);
				lazyLoadedAds.slotsToLoad.push(adSlots[slotSettings.mpu3.id]);
			}

			if (isSlotVisible(slotSettings.mpu4.id)) {
				adSlots[slotSettings.mpu4.id] = defineAdSlot(slotSettings.mpu4, restrictDoubleMpu);
				lazyLoadedAds.slotsToLoad.push(adSlots[slotSettings.mpu4.id]);
			}

			if (isSlotVisible(slotSettings.mpu5.id)) {
				adSlots[slotSettings.mpu5.id] = defineAdSlot(slotSettings.mpu5, restrictDoubleMpu);
				lazyLoadedAds.slotsToLoad.push(adSlots[slotSettings.mpu5.id]);
			}
		}

		if (isSlotVisible(slotSettings.native.id)) {
			adSlots[slotSettings.native.id] = defineAdSlot(slotSettings.native);
			lazyLoadedAds.slotsToLoad.push(adSlots[slotSettings.native.id]);
		}

		if (isSlotVisible(slotSettings.native2.id)) {
			adSlots[slotSettings.native2.id] = defineAdSlot(slotSettings.native2);
			lazyLoadedAds.slotsToLoad.push(adSlots[slotSettings.native2.id]);
		}

		if (isSlotVisible(slotSettings.inlineFooter.id)) {
			adSlots[slotSettings.inlineFooter.id] = defineAdSlot(slotSettings.inlineFooter);
			lazyLoadedAds.slotsToLoad.push(adSlots[slotSettings.inlineFooter.id]);
		}

		if (isSlotVisible(slotSettings.gumGum.id)) {
			adSlots[slotSettings.gumGum.id] = defineAdSlot(slotSettings.gumGum);
		}

		if (isSlotVisible(slotSettings.seedTag.id)) {
			adSlots[slotSettings.seedTag.id] = defineAdSlot(slotSettings.seedTag);
		}

		adSlots[slotSettings.overlay.id] = defineAdSlot(slotSettings.overlay);

		setTargeting();

		googletag.pubads().enableSingleRequest();
		googletag.pubads().disableInitialLoad();

		googletag.cmd.push(function () {
			// _pdfps stands for Permutive DFP Storage
			if (googletag.pubads().getTargeting(targetingKeys.permutive).length === 0) {
				var keyValuePairs = window.localStorage.getItem('_pdfps');
				googletag.pubads().setTargeting(targetingKeys.permutive, keyValuePairs ? JSON.parse(keyValuePairs) : []);
			}
		});

		googletag.cmd.push(function () {
			if (googletag.pubads().getTargeting('covatic').length === 0) {
				var keyValuePairs = window.localStorage.getItem('_audienceProfiles');
				googletag.pubads().setTargeting('covatic', keyValuePairs || []);
			}
		});

		googletag.enableServices();

		fireProviderEvent(events.requestAds, null, callbacks.requestInitialAdsCallback);
	});
}

function injectAdSlot($element, slot, restrictDoubleMpu) {
	var adHtml = $('<div class="mpu-ad advert" id="' + slot.id + '"></div>');
	$element.append(adHtml);

	googletag.cmd.push(function () {
		if (definedSlotIds.indexOf(slot.id) === -1) {
			fireProviderEvent(events.defineSlot, slot);

			logger.debug('DFP: Defining injected mpu ad ' + slot.id);
			adSlots[slot.id] = defineAdSlot(slot, restrictDoubleMpu);
			googletag.display(slot.id);
		}
	});
}

function resizeFlexibleMpu(size, id) {
	if (!size || !id.startsWith('mpu')) {
		return;
	}

	var $advert = $('#' + id);

	$advert.css({
		'display': 'block',
		'height': size[1],
		'width': size[0]
	});

	if (size[0] > 300) {
		$advert.addBemClass(classNames.mpuAd, null, modifierNames.fullWidth);

		$advert.parentsByBem(classNames.moveableMpuContainer)
			.addBemClass(classNames.moveableMpuContainer, null, modifierNames.fullWidth);

		$advert.parentsByBem(classNames.resizeableMpu)
			.removeBemClass(classNames.resizeableMpu, null, modifierNames.right)
			.removeBemClass(classNames.resizeableMpu, null, modifierNames.left);
	} else {
		// remove full-width to float moveable ad left or right.
		$advert
			.parentsByBem(classNames.moveableMpuContainer)
			.removeBemClass(classNames.moveableMpuContainer, null, modifierNames.fullWidth);
	}
}

function initInjectedMpus(slotElementId) {
	// If we're not placing the leaderboard ad in the content, add an injection point at the start so desktop has MPU1 in first view.
	// Added via JS as no visibility checks for mpu-inject and dont want it populated but not shown on < XL.
	// The injectFirstMpu config will be false for pages where certain conditions affect the position of MPU1 and auto-injecting isn't suitable.
	if (layout.isDesktop() && adConfig.injectFirstMpu && !slotElementId) {
		$.findByBem(classNames.moveableMpuContainer).prepend('<div class="mpu-inject show-for-xlarge-up"></div>');
	}

	var $mpuInjectionPoints = $('.mpu-inject').filter(':visible');

	// Add an adslot to each injection point
	$mpuInjectionPoints.each(function (index, element) {
		var adIndex = index + 1;
		var $element = $(element);
		var maxAdInjections = 20;

		if (adIndex > maxAdInjections) {
			$element.hide();
			return;
		}

		var slotIndexName = 'mpu' + adIndex;
		var slot = slotSettings[slotIndexName];
		var maxDoubleAdInjections = 2;
		var restrictDoubleMpu = false;

		// if mobile view restrict the number of double sized MPUs including MPU-3 and onwards
		if ((adIndex > maxDoubleAdInjections && (layout.isMobile()))) {
			restrictDoubleMpu = true;
		}

		if (!slotElementId || slotElementId === 'mpu-' + adIndex) {
			injectAdSlot($element, slot, restrictDoubleMpu);
		}

		// MPU-2+ can be lazy-loaded, but MPU-1 must always be rendered
		if (!slotElementId && adIndex > 1) {
			lazyLoadedAds.slotsToLoad.push(adSlots[slot.id]);
		}
	});
}

function resizeStickyMpuPoint(size, id) {
	if (!size || !id.startsWith('mpu')) {
		return;
	}

	var $advert = $('#' + id);

	// Set sticky left position
	var leftOffset = $advert.attr('data-sticky-left');
	$advert.children().css('left', leftOffset);

	if (id !== slotSettings.mpu1.id && size[1] === 600) {
		// Offset sticky scene so it sticks in correct place for double mpu
		var enterSceneName = $advert.attr('data-sticky-enter-scene');

		if (enterSceneName) {
			var enterScene = stickyMpus.scenes[enterSceneName];
			enterScene.offset(300);
		}
	}
}

function manageStickyTimer(slotId, classNamePrefix, classNameElement) {
	var $adSlotElement = $('#' + slotId);

	if (!$adSlotElement.hasBemClass(classNamePrefix, classNameElement, modifierNames.stuck) ||
		!$adSlotElement.hasBemClass(classNamePrefix, classNameElement, modifierNames.loaded) ||
		$adSlotElement.hasBemClass(classNamePrefix, classNameElement, modifierNames.viewed)) {
		return;
	}

	window.setTimeout(function () {
		$adSlotElement
			.addBemClass(classNamePrefix, classNameElement, modifierNames.viewed)
			.removeBemClass(classNamePrefix, classNameElement, modifierNames.stuck);
	}, stickyTimerTimeout);
}

function onStickyMpuEnter(event) {
	var scrollDirection = event.target.controller().info('scrollDirection');
	if (scrollDirection === 'REVERSE') {
		return;
	}

	var $triggerElement = $(event.target.triggerElement());

	if (!$triggerElement.hasBemClass(classNames.mpuAd, null, modifierNames.viewed)) {
		$triggerElement
			.addBemClass(classNames.mpuAd, null, modifierNames.stuck);

		if (stickyMpus.enabled) {
			manageStickyTimer($triggerElement.attr('id'), classNames.mpuAd);
		}

		var leftOffset = $triggerElement.offset().left + 'px';
		$triggerElement.attr('data-sticky-left', leftOffset);
		$triggerElement.children().css('left', leftOffset);
	}
}

function initStickyMpus() {
	if (typeof ScrollMagic !== 'undefined' && !scrollMagicController) {
		scrollMagicController = scroll.getScrollMagicController();
	}

	logger.debug('DFP: Add sticky mpu scene');

	var $newAds = $('.' + classNames.mpuAd + ':visible').not('[data-sticky-enter-scene]');
	$.each($newAds, function () {
		var enterSceneName = 'sticky-' + this.id.replace('-', '');
		var existingScene = stickyMpus.scenes[enterSceneName];
		if (existingScene) {
			existingScene.destroy();
			delete stickyMpus.scenes[enterSceneName];
		} else {
			enterSceneName = 'sticky-mpu' + Object.keys(stickyMpus.scenes).length;
		}

		var enterScene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 'onLeave'
		});

		stickyMpus.scenes[enterSceneName] = enterScene;
		$(this).attr('data-sticky-enter-scene', enterSceneName);

		enterScene.on('enter', onStickyMpuEnter).addTo(scrollMagicController);
	});
}

function resizeStickyInlineLeaderboard(size) {
	if (!size) {
		return;
	}

	var $advert = $.findByBem(classNames.inlineLeaderboardAd);
	$advert.css('height', size[1]);
	$advert.css('width', size[0]);

	// Set sticky left position
	var leftOffset = $advert.offset().left;
	$advert.children().css('left', leftOffset);
}

function onStickyInlineLeaderboardEnter(event) {
	var scrollDirection = event.target.controller().info('scrollDirection');
	if (scrollDirection === 'REVERSE') {
		return;
	}

	var $triggerElement = $(event.target.triggerElement());

	if (!$triggerElement.hasBemClass(classNames.inlineLeaderboardAd, null, modifierNames.viewed)) {
		$triggerElement.addBemClass(classNames.inlineLeaderboardAd, null, modifierNames.stuck);

		manageStickyTimer($triggerElement.attr('id'), classNames.inlineLeaderboardAd);

		var leftOffset = $triggerElement.offset().left + 'px';
		$triggerElement.children().css('left', leftOffset);
	}
}

function initStickyInlineLeaderboard() {
	if (typeof ScrollMagic !== 'undefined' && !scrollMagicController) {
		scrollMagicController = scroll.getScrollMagicController();
	}

	logger.debug('DFP: Add sticky inline leaderboard scene');

	new ScrollMagic.Scene({
		triggerElement: '.' + classNames.inlineLeaderboardAd,
		triggerHook: 'onLeave'
	}).on('enter', onStickyInlineLeaderboardEnter).addTo(scrollMagicController);
}

function onStickyLeaderboardEnter() {
	if ($('body').hasClass(classNames.hasHeroSkin)) {
		return;
	}

	var $topAd = $.findByBem(classNames.topAd, classNames.stickyWrapperInner);

	if (!$topAd.hasBemClass(classNames.topAd, classNames.stickyWrapperInner, modifierNames.viewed)) {
		$topAd.addBemClass(classNames.topAd, classNames.stickyWrapperInner, modifierNames.stuck);

		logger.log('DFP: Leaderboard stuck');
		manageStickyTimer(slotSettings.leaderboard1.id, classNames.topAd, classNames.stickyWrapperInner);
	}
}

function initStickyLeaderboard() {
	if (typeof ScrollMagic !== 'undefined' && !scrollMagicController) {
		scrollMagicController = scroll.getScrollMagicController();
	}

	logger.debug('DFP: Add sticky leaderboard scene');

	new ScrollMagic.Scene({
		triggerElement: '#' + slotSettings.leaderboard1.id,
		triggerHook: 'onLeave',
		offset: -16	// 1rem - top margin of the advert container
	}).on('enter', onStickyLeaderboardEnter).addTo(scrollMagicController);

	// check the timer now in case it's aleady sticking.
	manageStickyTimer(slotSettings.leaderboard1.id, classNames.inlineLeaderboardAd);
}

function initLazyLoadedAds() {
	logger.debug('DFP: Enabling lazy loaded ads - after ' + logger.secondsSincePageStart());

	googletag.pubads().enableLazyLoad({
		fetchMarginPercent: 25,
		renderMarginPercent: 5,
		mobileScaling: 2.0
	});

	requestAds(lazyLoadedAds.slotsToLoad);
}

function showMobileGalleryAds(loadOnlyLeaderboardGallery) {
	googletag.cmd.push(function () {
		if (!loadOnlyLeaderboardGallery && definedSlotIds.indexOf(slotSettings.mpuGallery.id) === -1) {
			fireProviderEvent(events.defineSlot, slotSettings.mpuGallery);

			logger.debug('DFP: Defining mpu-gallery ad');
			adSlots[slotSettings.mpuGallery.id] = defineAdSlot(slotSettings.mpuGallery);

			googletag.display(slotSettings.mpuGallery.id);
		}

		if (definedSlotIds.indexOf(slotSettings.leaderboardGallery.id) === -1) {
			fireProviderEvent(events.defineSlot, slotSettings.leaderboardGallery);

			logger.debug('DFP: Defining leaderboard-gallery ad');
			adSlots[slotSettings.leaderboardGallery.id] = defineAdSlot(slotSettings.leaderboardGallery);

			googletag.display(slotSettings.leaderboardGallery.id);
		}

		fireProviderEvent(events.requestAds, null, callbacks.loadMobileGalleryAdsCallback, [loadOnlyLeaderboardGallery]);
	});
}

function showDesktopGalleryAds() {
	googletag.cmd.push(function () {
		if (definedSlotIds.indexOf(slotSettings.mpuGallery.id) === -1) {
			fireProviderEvent(events.defineSlot, slotSettings.mpuGallery);

			logger.debug('DFP: Defining mpu-gallery ad');
			adSlots[slotSettings.mpuGallery.id] = defineAdSlot(slotSettings.mpuGallery);

			googletag.display(slotSettings.mpuGallery.id);
		}

		if (definedSlotIds.indexOf(slotSettings.leaderboardGallery.id) === -1) {
			fireProviderEvent(events.defineSlot, slotSettings.leaderboardGallery);

			logger.debug('DFP: Defining leaderboard-gallery ad');
			adSlots[slotSettings.leaderboardGallery.id] = defineAdSlot(slotSettings.leaderboardGallery);

			googletag.display(slotSettings.leaderboardGallery.id);
		}

		fireProviderEvent(events.requestAds, null, callbacks.loadDesktopGalleryAdsCallback);

		if (galleryAds.reloadTimeoutDuration) {
			galleryAds.reloadTimeoutTimer = timer.begin(function () {
				logger.debug('DFP: Gallery ads not loaded, reloading - after ' + logger.secondsSincePageStart());
				showDesktopGalleryAds();
			}, galleryAds.reloadTimeoutDuration);
		}
	});
}

function extendStickyDuration($mpu, extraStickyTime) {
	window.setTimeout(function () {
		$mpu.addBemClass(classNames.mpuAd, null, modifierNames.viewed).removeBemClass(classNames.mpuAd, null, modifierNames.stuck);
	}, extraStickyTime);
}

function startGalleryAdViewedTimer() {
	if (galleryAds.reloadTimeoutTimer) {
		timer.cancel(galleryAds.reloadTimeoutTimer);
	}

	if (galleryAds.viewedRefreshDuration) {
		galleryAds.viewedRefreshTimer = timer.begin(function () {
			logger.debug('DFP: Reloading viewed gallery ads - after ' + logger.secondsSincePageStart());
			showDesktopGalleryAds();
		}, galleryAds.viewedRefreshDuration);
	}
}

var dfpEventHandlers = {
	onSlotRenderEnded: {
		'leaderboard-1': function (event) {
			resizeStickyInlineLeaderboard(event.size);
		},
		'mpu-1': function () {
			// We must always render MPU-1 before we turn on lazy loading, as MPU-1 must not be lazy loaded
			// Checking for lazy loading prevents a multiple ad reload quirk when MPU-1 is refreshed.
			if (lazyLoadedAds.slotsToLoad.length > 0 && !adsHaveLazyLoaded) {
				initLazyLoadedAds();
				adsHaveLazyLoaded = true;
			}
		}
	},

	onSlotOnLoad: {
		'leaderboard-1': function () {
			$('#' + slotSettings.leaderboard1.id).addBemClass(classNames.inlineLeaderboardAd, null, modifierNames.loaded);
			initStickyLeaderboard();
		}
	},

	onImpressionViewable: {
		'mpu-gallery': function () {
			galleryAds.pendingAds.mpu = false;

			if (galleryAds.automaticReloadEnabled && !galleryAds.pendingAds.leaderboard) {
				startGalleryAdViewedTimer();
			}
		},
		'leaderboard-gallery': function () {
			galleryAds.pendingAds.leaderboard = false;

			if (galleryAds.automaticReloadEnabled && !galleryAds.pendingAds.mpu) {
				startGalleryAdViewedTimer();
			}
		},
		'leaderboard-1': function () {
			logger.log('DFP: Leaderboard viewed');

			$.findByBem(classNames.inlineLeaderboardAd)
				.addBemClass(classNames.inlineLeaderboardAd, null, modifierNames.viewed)
				.removeBemClass(classNames.inlineLeaderboardAd, null, modifierNames.stuck);

			$.findByBem(classNames.topAd, classNames.stickyWrapperInner)
				.addBemClass(classNames.topAd, classNames.stickyWrapperInner, modifierNames.viewed)
				.removeBemClass(classNames.topAd, classNames.stickyWrapperInner, modifierNames.stuck);
		}
	}
};

// resets the top sticky ads on mobile.
function resetTopAdSlot() {
	var $topAdStickyWrapperInner = $.findByBem(classNames.topAd, classNames.stickyWrapperInner);
	$topAdStickyWrapperInner.removeBemClass(classNames.topAd, classNames.stickyWrapperInner, modifierNames.viewed);
	$topAdStickyWrapperInner.removeBemClass(classNames.topAd, classNames.stickyWrapperInner, modifierNames.unstuck);
}

function initAdContainer($advert, isFullWidth, eventHeight) {
	var $adContainer = $advert.parentsByBem(classNames.adContainer);
	if ($adContainer.length === 0) {
		return;
	}

	// eventSize should always have the correct height of the advert
	var advertHeight = eventHeight;
	var advertHeightAdjustment = 0;

	var adContainerModifier = isFullWidth ? modifierNames.fullWidth : classNames.mpu;
	$adContainer.addBemClass(classNames.adContainer, null, adContainerModifier);

	// Need room to display the scroll message if:
	// an MPU that is full width on desktop OR any size on a non desktop view
	var displayScrollMessage = $advert.hasBemClass(classNames.mpuAd) && (
		layout.isMobile() || (
			layout.isDesktop() && $adContainer.hasBemClass(classNames.adContainer, null, modifierNames.fullWidth)
		));

	if (displayScrollMessage) {
		advertHeightAdjustment = 25;
	}

	// if the advert container has content message then extend the ad container
	$adContainer.css({
		'height': advertHeight + advertHeightAdjustment
	});

	// adverts need additional height when the parent advert container has been extended
	$advert.css({
		'height': advertHeight + advertHeightAdjustment
	});
}

function injectAdContainers() {
	var $visibleAds = $('.advert').filter(':visible');

	// don't wrap ads like overlay / leaderboard in an ad container.
	var $adsInContainer = $($visibleAds).filter(function () {
		var slotId = $(this).attr('id') ? $(this).attr('id') : $(this).data('id');
		if (adSlotSettings.shouldInjectAdContainer(slotId)) {
			return this;
		}
	});

	// An ad container was used in the ad refresh to retain the spacing when the refresh occurred.
	// Now, it's useful to help provide consistent ad layout across the site.
	$adsInContainer.each(function () {
		var $advert = $(this);
		if (!$advert.parent().hasBemClass(classNames.adContainer)) {
			$advert.wrap('<div class="ad-container"></div>');
		}
	});
}

module.exports = {
	init: function (config) {
		logger.debug('DFP: Initialising ad script - after ' + logger.secondsSincePageStart());
		adConfig = config;

		if (adConfig.suppressAllAds) {
			return;
		}

		// initialise googletag, if it doesnt already exist
		googletag = window.googletag = window.googletag || {};
		googletag.cmd = googletag.cmd || [];

		googletag.cmd.push(function () {
			googletag.pubads().disableInitialLoad();
		});

		// add render event listeners
		googletag.cmd.push(function () {
			googletag.pubads().addEventListener('slotRenderEnded', function (event) {
				var slotElementId = event.slot.getSlotElementId();
				var $adSlotElement = $('#' + slotElementId);
				$adSlotElement.attr('data-line-item', event.sourceAgnosticLineItemId);

				if (dfpEventHandlers.onSlotRenderEnded.hasOwnProperty(slotElementId)) {
					var eventHandler = dfpEventHandlers.onSlotRenderEnded[slotElementId];
					eventHandler(event);
				}

				// Decorate double mpus with identifying class
				if (slotElementId.startsWith('mpu') && event.size && event.size[1] === 600) {
					$adSlotElement.addBemClass(classNames.mpuAd, null, modifierNames.doubleMpu);
				}

				if (flexibleSlotSizes.enabled) {
					resizeFlexibleMpu(event.size, slotElementId);
				}

				if (stickyMpus.enabled) {
					resizeStickyMpuPoint(event.size, slotElementId);
				}

				if (event.size) {
					initAdContainer($adSlotElement, event.size[0] > 300, event.size[1]);
				}

				logger.debug('DFP: slotRenderEnded fired for slot ' + slotElementId + ' with no size information - after ' + logger.secondsSincePageStart());
			});

			googletag.pubads().addEventListener('slotOnload', function (event) {
				var slotElementId = event.slot.getSlotElementId();
				logger.debug('DFP: slotOnload fired for slot ' + slotElementId + ' - after ' + logger.secondsSincePageStart());
				logAdvertEvent('Loaded', slotElementId);

				if (dfpEventHandlers.onSlotOnLoad.hasOwnProperty(slotElementId)) {
					var eventHandler = dfpEventHandlers.onSlotOnLoad[slotElementId];
					eventHandler(event);
				}

				if (slotElementId.startsWith('mpu')) {
					var $adSlotElement = $('#' + slotElementId);
					$adSlotElement.addBemClass(classNames.mpuAd, null, modifierNames.loaded);
					if (stickyMpus.enabled) {
						manageStickyTimer(slotElementId, classNames.mpuAd);
					}
				}
			});

			googletag.pubads().addEventListener('impressionViewable', function (event) {
				var slotElementId = event.slot.getSlotElementId();
				logger.debug('DFP: impressionViewable fired for slot ' + slotElementId + ' - after ' + logger.secondsSincePageStart());

				if (dfpEventHandlers.onImpressionViewable.hasOwnProperty(slotElementId)) {
					var eventHandler = dfpEventHandlers.onImpressionViewable[slotElementId];
					eventHandler(event);
				}

				if (stickyMpus.enabled && slotElementId.startsWith('mpu')) {
					var $mpu = $('#' + slotElementId);
					if (adConfig.extendedMpuStickDuration > 0 && slotElementId === slotSettings.mpu1.id) {
						extendStickyDuration($mpu, adConfig.extendedMpuStickDuration);
					} else {
						$mpu.addBemClass(classNames.mpuAd, null, modifierNames.viewed).removeBemClass(classNames.mpuAd, null, modifierNames.stuck);
					}
				}
			});
		});

		adUnitPath = '/24156345/' + adConfig.site + '/' + adConfig.sectionName;
		flexibleSlotSizes.enabled = adConfig.useFlexibleWidthMpus;

		var providerInitData = {
			config: adConfig,
			adPath: adUnitPath
		};

		fireProviderEvent(events.init, providerInitData);

		if (adConfig.useInjectedMpus) {
			injectedMpus.enabled = true;
			initInjectedMpus();
		}

		if (adConfig.useStickyMpus) {
			stickyMpus.enabled = true;
			initStickyMpus();
		}

		if (!layout.isMobile()) {
			initStickyInlineLeaderboard();
		}

		setVisibility();
		defineAdSlots();

		if (window.location.search.indexOf('takeover=1') > 0) {
			$.findByBem(classNames.siteRoot).addBemClass(classNames.siteRoot, null, 'has-takeover');
		}

		$(window).on('blur', function () {
			if (galleryAds.automaticReloadEnabled) {
				if (galleryAds.reloadTimeoutTimer) {
					timer.pause(galleryAds.reloadTimeoutTimer);
				}

				if (galleryAds.viewedRefreshTimer) {
					timer.pause(galleryAds.viewedRefreshTimer);
				}

				logger.debug('DFP: Auto gallery timers paused');
			}
		});

		$(window).on('focus', function () {
			if (galleryAds.automaticReloadEnabled) {
				if (galleryAds.reloadTimeoutTimer) {
					timer.resume(galleryAds.reloadTimeoutTimer);
				}

				if (galleryAds.viewedRefreshTimer) {
					timer.resume(galleryAds.viewedRefreshTimer);
				}

				logger.debug('DFP: Auto gallery timers resumed');
			}
		});

		injectAdContainers();
	},

	loadAds: function ($context) {
		if (adConfig.suppressAllAds) {
			return;
		}

		setVisibility($context);
		injectAdContainers();

		fireProviderEvent(events.requestAds, null, callbacks.loadAdsCallback);
	},

	loadMobileGalleryAds: function ($context, loadOnlyLeaderboardGallery) {
		if (adConfig.suppressAllAds) {
			return;
		}

		if ($context) {
			setVisibility($context);
		}

		showMobileGalleryAds(loadOnlyLeaderboardGallery);
	},

	loadDesktopGalleryAds: function ($context, viewedRefreshDuration, reloadTimeoutDuration) {
		if (adConfig.suppressAllAds) {
			return;
		}

		if (galleryAds.reloadTimeoutTimer) {
			timer.cancel(galleryAds.reloadTimeoutTimer);
		}

		if (galleryAds.viewedRefreshTimer) {
			timer.cancel(galleryAds.viewedRefreshTimer);
		}

		if (viewedRefreshDuration) {
			galleryAds.viewedRefreshDuration = viewedRefreshDuration;
		}

		if (reloadTimeoutDuration) {
			galleryAds.reloadTimeoutDuration = reloadTimeoutDuration;
		}

		if ($context) {
			setVisibility($context);
		}

		galleryAds.automaticReloadEnabled = true;

		showDesktopGalleryAds();
	},

	disableGalleryAdsAutoRefresh: function () {
		galleryAds.automaticReloadEnabled = false;

		if (galleryAds.reloadTimeoutTimer) {
			timer.cancel(galleryAds.reloadTimeoutTimer);
		}

		if (galleryAds.viewedRefreshTimer) {
			timer.cancel(galleryAds.viewedRefreshTimer);
		}

		logger.debug('DFP: Automatic gallery reload cancelled');
	},

	updateTargeting: function ($context) {
		googletag.pubads().clearTargeting();
		
		if (adConfig.suppressAllAds) {
			return;
		}

		var dfpDataContent = $context.findByBem('dfp-update-data').attr('data-value');

		if (dfpDataContent) {
			googletag.cmd.push(function () {
				adConfig = parseDfpUpdateData(dfpDataContent);
				setTargeting();
			});
		}
	},

	overridePageType: function (templateName) {
		if (adConfig.suppressAllAds) {
			return;
		}
		googletag.cmd.push(function () {
			googletag.pubads().setTargeting(targetingKeys.cmsTemplateName, [templateName]);
		});
	},

	resetTargeting: function () {
		googletag.cmd.push(function () {
			setTargeting();
		});
	},

	setupSkin: function (backgroundImageDesktop, backgroundImageTablet, backgroundImageMobile, clickThroughUrl) {
		$('body').addClass(classNames.hasHeroSkin);

		if (backgroundImageDesktop && backgroundImageTablet && backgroundImageMobile && clickThroughUrl) {
			$(window.parent.document).find("body").removeClass("has-skin");

			var $overlay = $(window.parent.document).find('#overlay');
			var windowWidth = $(window.parent.document).width();
			var backgroundImage = backgroundImageDesktop;

			if (windowWidth < 500) {
				backgroundImage = backgroundImageMobile;
			} else if (windowWidth <= 1200) {
				backgroundImage = backgroundImageTablet;
			}

			$overlay.css('background-image', 'url("' + backgroundImage + '")');
			$overlay.wrap('<a href="' + clickThroughUrl + '" target="_blank"></a>');
		}
	},

	disableStickyMpus: function () {
		stickyMpus.enabled = false;
		$.each(stickyMpus.scenes, function (index, value) {
			value = value.destroy(true);
		});

		$.findByBem(classNames.mpuAd, null, modifierNames.stuck).removeBemClass(classNames.mpuAd, null, modifierNames.stuck);
	},

	resetTopAd: function () {
		resetTopAdSlot();
	},

	hideGumGum: function () {
		if (layout.isMobile()) {
			var gumgumAd = $(gumgumIds);
			if (gumgumAd) {
				gumgumAd.hide();
			}
		}
	},

	showGumGum: function () {
		if (layout.isMobile()) {
			var gumgumAd = $(gumgumIds);
			if (gumgumAd) {
				gumgumAd.show();
			}
		}
	}
};