'use strict';

var assign 	= require('object-assign');

var logger	= require('../../utils/logger.js');

var FluxChangeStore	= require('./flux-change-store.js');
var FluxConstants 	= require('../constants/flux-constants.js');
var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/car-chooser-constants.js');

var ActionTypes 	= Constants.ActionTypes;
var PayloadSources 	= FluxConstants.PayloadSources;

var defaultStoreData = {
	selectedMinimumPrice: 0,
	selectedMaximumPrice: 240000,
	selectedMinimumMonthlyPrice: 0,
	selectedMaximumMonthlyPrice: 1500,
	selectedClassifications: [],
	selectedLifestyles: [],
	updatePending: false,
	isLoading: false,
	searchType: '',
	searchResultsUrl: '',
	displayClassificationsContent: false,
	displayLifestylesContent: false,
	displayPricesContent: false,
	useMonthlyPrice: false
};

var storeData = assign({}, defaultStoreData);

function isLifestyleSelected(lifestyle) {
	return storeData.selectedLifestyles.indexOf(lifestyle) !== -1;
}

function setInitialLifestyle(lifestyle, value) {
	if (value) {
		storeData.selectedLifestyles.push(lifestyle);
	}
}

var Store = assign(new FluxChangeStore(), {
	getFilterRequest: function () {
		var result = {
			classifications: storeData.selectedClassifications,
			searchType: storeData.searchType,
			minimumPrice: storeData.selectedMinimumPrice,
			maximumPrice: storeData.selectedMaximumPrice,
			minimumMonthlyPrice: storeData.selectedMinimumMonthlyPrice,
			maximumMonthlyPrice: storeData.selectedMaximumMonthlyPrice,
			useMonthlyPrice: storeData.useMonthlyPrice,
			suitableNewDrivers: isLifestyleSelected(Constants.Lifestyles.NEW_DRIVERS),
			suitableCompanyCar: isLifestyleSelected(Constants.Lifestyles.COMPANY_CAR),
			suitableLongJourney: isLifestyleSelected(Constants.Lifestyles.LONG_JOURNEY),
			suitableAllSeasons: isLifestyleSelected(Constants.Lifestyles.ALL_SEASONS),
			suitableBigBoot: isLifestyleSelected(Constants.Lifestyles.BIG_BOOT),
			suitableEasyAccess: isLifestyleSelected(Constants.Lifestyles.EASY_ACCESS),
			suitableCityDriving: isLifestyleSelected(Constants.Lifestyles.CITY_DRIVING),
			suitableStatement: isLifestyleSelected(Constants.Lifestyles.STATEMENT)
		};

		return result;
	},

	getState: function () {
		return storeData;
	}
});

function handleServerAction(action) {
	if (action) {
		var handled = false;

		switch(action.type) {

			case ActionTypes.CAR_CHOOSER_SEARCH_FILTERS_UPDATE_STATE:
				handled = true;
				storeData.isLoading = true;
				storeData.updatePending = false;

				break;

			case ActionTypes.CAR_CHOOSER_SEARCH_FILTERS_UPDATE_STATE_SUCCESS:
				handled = true;
				storeData.isLoading = false;

				if (action.data && action.data.searchResults) {
					storeData.searchResultsUrl = action.data.searchResultsUrl;
				}

				break;

			case ActionTypes.CAR_CHOOSER_SEARCH_FILTERS_UPDATE_STATE_ERROR:
				handled = true;
				storeData.isLoading = false;

				break;
		}

		if (handled) {
			logger.debug('Car Chooser', {
				message: 'Handle server action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

function handleViewAction(action) {
	if (action) {
		var handled = false;

		switch(action.type) {
			case ActionTypes.CAR_CHOOSER_INIT:
				handled = true;

				storeData.displayClassificationsContent = action.data.expandClassifications;
				storeData.displayLifestylesContent = action.data.expandLifestyles;
				storeData.displayPricesContent = action.data.expandPrices;

				var filterRequest = action.data.filterRequest;
				storeData.searchType = filterRequest.searchType;

				if (filterRequest.classifications) {
					storeData.selectedClassifications = filterRequest.classifications;
				}

				storeData.selectedMinimumPrice = filterRequest.minimumPrice;
				storeData.selectedMaximumPrice = filterRequest.maximumPrice;

				storeData.selectedMinimumMonthlyPrice = filterRequest.minimumMonthlyPrice;
				storeData.selectedMaximumMonthlyPrice = filterRequest.maximumMonthlyPrice;

				storeData.useMonthlyPrice = filterRequest.useMonthlyPrice;

				setInitialLifestyle(Constants.Lifestyles.NEW_DRIVERS, filterRequest.suitableNewDrivers);
				setInitialLifestyle(Constants.Lifestyles.COMPANY_CAR, filterRequest.suitableCompanyCar);
				setInitialLifestyle(Constants.Lifestyles.LONG_JOURNEY, filterRequest.suitableLongJourney);
				setInitialLifestyle(Constants.Lifestyles.ALL_SEASONS, filterRequest.suitableAllSeasons);
				setInitialLifestyle(Constants.Lifestyles.BIG_BOOT, filterRequest.suitableBigBoot);
				setInitialLifestyle(Constants.Lifestyles.EASY_ACCESS, filterRequest.suitableEasyAccess);
				setInitialLifestyle(Constants.Lifestyles.CITY_DRIVING, filterRequest.suitableCityDriving);
				setInitialLifestyle(Constants.Lifestyles.STATEMENT, filterRequest.suitableStatement);

				break;

			case ActionTypes.CAR_CHOOSER_SET_PRICE_RANGE:
				handled = true;

				if (action.data) {
					storeData.selectedMinimumPrice = action.data.min;
					storeData.selectedMaximumPrice = action.data.max;
				}

				storeData.updatePending = true;

				break;				

			case ActionTypes.CAR_CHOOSER_SET_MONTHLY_PRICE_RANGE:
				handled = true;

				if (action.data) {
					storeData.selectedMinimumMonthlyPrice = action.data.min;
					storeData.selectedMaximumMonthlyPrice = action.data.max;
				}

				storeData.updatePending = true;

				break;

			case ActionTypes.CAR_CHOOSER_SWITCH_SEARCH_TYPE:
				handled = true;

				storeData.searchType = action.data;

				storeData.updatePending = true;

				break;

			case ActionTypes.CAR_CHOOSER_TOGGLE_CLASSIFICATION:
				handled = true;

				var classification = action.data;
				var classificationIndex = storeData.selectedClassifications.indexOf(classification);

				if (classificationIndex !== -1) {
					storeData.selectedClassifications.splice(classificationIndex, 1);
				} else {
					storeData.selectedClassifications.push(classification);
				}

				storeData.updatePending = true;

				break;

			case ActionTypes.CAR_CHOOSER_TOGGLE_CLASSIFICATION_DISPLAY:
				handled = true;

				storeData.displayClassificationsContent = action.data;

				break;

			case ActionTypes.CAR_CHOOSER_TOGGLE_LIFESTYLE:
				handled = true;

				var lifestyle = action.data;
				var lifestyleIndex = storeData.selectedLifestyles.indexOf(lifestyle);

				if (lifestyleIndex !== -1) {
					storeData.selectedLifestyles.splice(lifestyleIndex, 1);
				} else {
					storeData.selectedLifestyles.push(lifestyle);
				}

				storeData.updatePending = true;

				break;

			case ActionTypes.CAR_CHOOSER_TOGGLE_LIFESTYLE_DISPLAY:
				handled = true;

				storeData.displayLifestylesContent = action.data;

				break;

			case ActionTypes.CAR_CHOOSER_SWITCH_PRICE_TYPE:
				handled = true;

				storeData.useMonthlyPrice = action.data;

				break;

			case ActionTypes.CAR_CHOOSER_TOGGLE_PRICES_DISPLAY:
				handled = true;

				storeData.displayPricesContent = action.data;

				break;
		}

		if (handled) {
			logger.debug('Car Chooser', {
				message: 'Handle view action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

Store.dispatchToken = FluxDispatcher.register(function (payload) {
	if (payload && payload.source) {
		if (payload.source === PayloadSources.SERVER_ACTION) {
			handleServerAction(payload.action);
		} else if (payload.source === PayloadSources.VIEW_ACTION) {
			handleViewAction(payload.action);
		}
	}
});

module.exports = Store;