'use strict';

var baseApi = require('./base-api.js');
var logger = require('../utils/logger.js');

function getApiUrl(vehicleType, filterName, searchType, manufacturerKey, rangeKey, modelKey, filters) {
	if (!vehicleType) {
		logger.error('For Sale or Lease Quick Find API - a vehicle type must be specified.');
		return;
	}

	var url = vehicleType + '/quick-find';

	if (filterName) {
		url += '/' + filterName;
	}

	if (searchType) {
		url += '/' + searchType;
	}

	if (manufacturerKey) {
		url += '/' + manufacturerKey;
	}

	if (rangeKey) {
		url += '/' + rangeKey;
	}

	if (modelKey) {
		url += '/' + modelKey;
	}

	if (filters) {
		url += '/with-filters';

		if (filters.fromPrice) {
			url += '/price-from-' + filters.fromPrice;
		}

		if (filters.toPrice) {
			url += '/price-to-' + filters.toPrice;
		}

		if (filters.postcode) {
			url += '/postcode-' + filters.postcode;
		}
	}

	return url + '/';
}

module.exports = {
	getManufacturers: function (vehicleType, filterName, searchType, filters, options) {
		var url = getApiUrl(vehicleType, filterName, searchType, null, null, null, filters);
		baseApi.get(url, options);
	},

	getRangesForManufacturer: function (vehicleType, filterName, searchType, manufacturerKey, filters, options) {
		var url = getApiUrl(vehicleType, filterName, searchType, manufacturerKey, null, null, filters);
		baseApi.get(url, options);
	},

	getModelsForRange: function (vehicleType, filterName, searchType, manufacturerKey, rangeKey, filters, options) {
		var url = getApiUrl(vehicleType, filterName, searchType, manufacturerKey, rangeKey, null, filters);
		baseApi.get(url, options);
	},

	getSearchUrl: function (vehicleType, filterName, searchType, manufacturerKey, rangeKey, modelKey, filters, options) {
		var url = getApiUrl(vehicleType, filterName, searchType, manufacturerKey, rangeKey, modelKey, filters);
		baseApi.get(url, options);
	},
};