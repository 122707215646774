'use strict';

var $ 	= require('jquery');
var td 	= require('throttle-debounce');

var layout 			= require('../ui/layout.js');
var scrollWatcher 	= require('../watchers/scroll-watcher.js');
var siteSearch 		= require('./site-search.js');

var classNames = {
	moreLinkDesktop: 	'primary-nav-desktop__menu__link--more-menu',
	moreMenuDesktop: 	'more-menu-desktop',
	primaryNavDesktop: 	'primary-nav-desktop',
	primaryNavMobile: 	'primary-nav-mobile',
	siteHeader: 		'site-header-desktop'
};

function initSubMenus () {
	$.findByBem(classNames.siteHeader).on('mouseenter.primaryNav', function () {
		$.findByBem(classNames.primaryNavDesktop).removeBemClass(classNames.primaryNavDesktop, null, 'hidden');
	});

	$.findByBem(classNames.primaryNavMobile, 'menu__link', 'has-sub-menu').on('click.primaryNav', function (e) {
		var link = $(this);
		var active = link.parent().hasBemClass(classNames.primaryNavMobile, 'menu__link', 'active');
		if (link.prop('href') === '#' || !active) {
			e.preventDefault();
			if (active) {
				link.parent().trigger('collapse');
			} else {
				link.parent().siblings().trigger('collapse');
				link.parent().trigger('expand');
			}
		}
	}).parent().on('expand.primaryNav', function () {
		$(this)
			.addBemClass(classNames.primaryNavMobile, 'menu__link', 'active')
			.childrenByBem(classNames.primaryNavMobile, 'sub-menu').slideDown();
	}).on('collapse.primaryNav', function () {
		$(this)
			.removeBemClass(classNames.primaryNavMobile, 'menu__link', 'active')
			.childrenByBem(classNames.primaryNavMobile, 'sub-menu').slideUp();
	});

	$.findByBem(classNames.primaryNavDesktop, 'menu__link', 'has-sub-menu').on('click.primaryNav', function (e) {
		var link = $(this);
		var hover = link.parent().hasBemClass(classNames.primaryNavDesktop, 'menu__link', 'hover');
		if (link.prop('href') === '#' || !hover) {
			e.preventDefault();
			link.parent().trigger(hover ? 'mouseleave' : 'mouseenter');
		}
	}).parent().on('mouseenter.primaryNav', function () {
		$.findByBem(classNames.moreMenuDesktop).hide();
		$(this)
			.addBemClass(classNames.primaryNavDesktop, 'menu__link', 'hover')
			.childrenByBem(classNames.primaryNavDesktop, 'sub-menu').show()
				.childrenByBem(classNames.primaryNavDesktop, 'sub-menu__item').css('min-width', $(this).width());
	}).on('mouseleave.primaryNav', function () {
		$(this)
			.removeBemClass(classNames.primaryNavDesktop, 'menu__link', 'hover')
			.childrenByBem(classNames.primaryNavDesktop, 'sub-menu').hide();
	});
	
	$.findByBem(classNames.primaryNavDesktop, 'menu__link', 'more-menu').on('click.primaryNav', function (e) {
		e.preventDefault();
		e.stopPropagation();
		var moreMenu = $.findByBem(classNames.moreMenuDesktop);
		if (moreMenu.is(':visible')) {
			moreMenu.slideUp();
		} else {
			siteSearch.hide();
			moreMenu.slideDown();
		}
	});

	$('body').on('click.primaryNav', function (e) {
		var moreMenu = $.findByBem(classNames.moreMenuDesktop);
		if (moreMenu.is(':visible') && moreMenu[0] !== e.target && !$.contains(moreMenu[0], e.target)) {
			moreMenu.slideUp();
		}
	});
}

function initMoreMenu () {
	if (layout.isTablet() || layout.isDesktop()) {
		var primaryNav = $.findByBem(classNames.primaryNavDesktop);
		var menuTop = primaryNav.findByBem(classNames.primaryNavDesktop, 'menu').position().top;
		var overflowDropdowns = [];
		var overflowSingles = [];
		var width = 0;
		var moreLink = $.findByBem(classNames.moreLinkDesktop).css('margin-right', 0);
		var moreMenu = $.findByBem(classNames.moreMenuDesktop);
		var moreMenuList = $.findByBem(classNames.moreMenuDesktop, 'menu');

		$.findByBem(classNames.primaryNavDesktop, 'menu__item:visible').each(function () {
			var item = $(this);
			if (item.position().top > menuTop) {
				// find items that have wrapped on to next line
				var newItem = item.clone();
				if (newItem.children('a').attr('data-text-expanded')) {
					newItem.children('a').html(newItem.children('a').attr('data-text-expanded'));
				}
				newItem.removeAttr('class id style')
					.children('a').removeAttr('class id style data-text-expanded');
				newItem.addBemClass(classNames.moreMenuDesktop, 'menu__item')
					.children('a').addBemClass(classNames.moreMenuDesktop, 'menu__link');

				if (newItem.children('ul').length > 0) {
					newItem.children('ul').removeAttr('class id style')
						.children('li').removeAttr('class id style')
							.children('a').removeAttr('class id style');
					newItem.children('ul').addBemClass(classNames.moreMenuDesktop, 'sub-menu')
						.children('li').addBemClass(classNames.moreMenuDesktop, 'sub-menu__item')
							.children('a').addBemClass(classNames.moreMenuDesktop, 'sub-menu__link');
					overflowDropdowns.push(newItem);
				} else {
					overflowSingles.push(newItem);
				}
			} else {
				width += item.outerWidth(false);
			}
		});

		if (overflowDropdowns.length > 0 || overflowSingles.length > 0) {
			if (overflowDropdowns.length > 0) {
				moreMenuList.html(overflowDropdowns);
				if (overflowSingles.length > 0) {
					if (overflowDropdowns.length > 1) {
						var item = $('<li />').addBemClass(classNames.moreMenuDesktop, 'menu__item').appendTo(moreMenuList);
						$(overflowSingles).each(function () {
							item.append($(this).children('a'));
						});
					} else {
						moreMenuList.append(overflowSingles);
					}
				}
			} else if (overflowSingles.length > 0) {
				moreMenuList.html(overflowSingles);
			}

			moreLink.show();
			if (width > 0) {
				var offset = primaryNav.width() - moreLink.outerWidth(false) - width - 2;
				moreLink.css('margin-right', offset);
			}
		} else {
			moreLink.hide();
			moreMenu.hide();
			moreMenuList.html('');
		}
	}
}

module.exports = {
	init: function () {
		initSubMenus();
		initMoreMenu();

		$(window).off('resize.primaryNav').on('resize.primaryNav', td.throttle(100, initMoreMenu));

		scrollWatcher.onScroll(function (e) {
			if (e.direction === 'up' && layout.isTablet()) {
				// always show primary nav when user scrolls up
				$.findByBem(classNames.primaryNavDesktop).removeBemClass(classNames.primaryNavDesktop, null, 'hidden');
			}
		});

		scrollWatcher.onScrollToHeading(function (e) {
			if (e.direction === 'down' && layout.isTablet()) {
				// hide primary nav when user hits main heading (H1)
				$.findByBem(classNames.moreMenuDesktop).hide();
				var nav = $.findByBem(classNames.primaryNavDesktop);
				var $leaderboard = $.findByBem('top-ad__sticky-wrapper__inner', null, 'stuck');

				if (!nav.hasBemClass(classNames.primaryNavDesktop, null, 'hidden') && $leaderboard.length === 0) {
					nav.addBemClass(classNames.primaryNavDesktop, null, 'hidden');
					$.findByBem(classNames.primaryNavDesktop, 'sub-menu').hide();
				}
			}
		});
	}
};