'use strict';

var $ 	= require('jquery');
var td 	= require('throttle-debounce');

var layout 	= require('../ui/layout.js');
var logger = require('../utils/logger.js');

var screenSizeWatcherCallbacks = [];

module.exports = {
	onChange: function (func) {
		if (typeof func === 'function') {
			screenSizeWatcherCallbacks.push(func);
		}
	},

	start: function () {
		var index, newSize, previousSize = layout.getScreenSize();

		$(window).off('resize.screenSizeWatcher').on('resize.screenSizeWatcher', td.debounce(50, function () {
			newSize = layout.getScreenSize();
			if (newSize !== previousSize) {
				previousSize = newSize;
				logger.debug('Screen size change');
				for (index = 0; index < screenSizeWatcherCallbacks.length; index++) {
					var callback = screenSizeWatcherCallbacks[index];
					callback();
				}
			}
		}));
	}
};