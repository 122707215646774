/** @jsx React.DOM */'use strict';

var $ 		= require('jquery');
var React 	= require('react');
var numeral = require('numeral');

var FacetDropdown = require('./shared/facet-dropdown-view.jsx');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		filterState: React.PropTypes.object.isRequired,
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		onItemRemove: React.PropTypes.func.isRequired,
		onItemSet: React.PropTypes.func.isRequired,
		vehicleType: React.PropTypes.string
	},

	getDefaultProps: function() {
		return {
			vehicleType: 'car'
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var desktopView;

		if (this.props.filterState.selectedColour) {
			desktopView = this.renderSelectedValue();
		} else if (this.props.filterState.colourItems && this.props.filterState.colourItems.length === 1) {
			desktopView = this.renderSingleValue();
		} else {
			desktopView = this.renderList();
		}

		return (
			<div className="search-filters__facet">
				<div className="hide-for-large-up">
					<FacetDropdown
						name="colour"
						defaultText="Colour"
						isLoading={this.props.isLoading}
						isDisabled={this.props.isDisabled}
						items={this.props.filterState.colourItems}
						selectedItem={this.props.filterState.selectedColour}
						onRemove={this.props.onItemRemove}
						onSet={this.props.onItemSet}
					/>
				</div>
				<div className="show-for-large-up">
					{desktopView}
				</div>
			</div>
		);
	},

	renderList: function () {
		var linkClass = 'search-filters__flyout__open-link';

		if (this.props.isLoading || this.props.isDisabled || !this.props.filterState.colourItems || this.props.filterState.colourItems.length === 0) {
			linkClass += ' search-filters__flyout__open-link--disabled';
		}

		return (
			<div className="search-filters__flyout">
				<div className={linkClass}>
					<span className="search-filters__flyout__open-link__icon icon-16x16-chevron"></span>
					<span className="search-filters__flyout__open-link__label">Colour</span>
					<span className="search-filters__flyout__open-link__value">Any</span>
				</div>
				<div className="search-filters__flyout__panel">
					<div className="search-filters__flyout__panel__header">
						<div className="search-filters__flyout__panel__title">
							Select a {this.props.vehicleType} colour
						</div>
						<a href="#" className="search-filters__flyout__panel__close-link">
							<span className="icon icon-gap-right fi-x"></span>
							Close
						</a>
					</div>
					<ul className="search-filters__colours inline">
						{this.getColour('Red')}
						{this.getColour('Green')}
						{this.getColour('Blue')}
						{this.getColour('Orange')}
						{this.getColour('Yellow')}
						{this.getColour('Silver')}
						{this.getColour('Black')}
						{this.getColour('White')}
						{this.getColour('Grey')}
					</ul>
				</div>
			</div>
		);
	},

	renderSelectedValue: function () {
		return (
			<div className="search-filters__flyout">
				<span className="search-filters__flyout__selected">
					{this.props.filterState.selectedColour.text}
					<a href="#" className="search-filters__flyout__selected__remove-link" title="Remove" onClick={this.onRemoveClick}>
						Remove
					</a>
				</span>
			</div>
		);
	},

	renderSingleValue: function () {
		var onlyFacetItem = this.props.filterState.colourItems[0];

		return (
			<div className="search-filters__flyout">
				<span className="search-filters__flyout__selected">
					{onlyFacetItem.text}
				</span>
			</div>
		);
	},

	getAvailableColour: function (value) {
		if (this.props.filterState.colourItems) {
			var index, item;

			for (index = 0; index < this.props.filterState.colourItems.length; index++) {
				item = this.props.filterState.colourItems[index];
				if (item.value === value) {
					return item;
				}
			}
		}

		return null;
	},

	getColour: function (value) {
		var className = 'search-filters__colour';
		var swatchClassName = 'search-filters__colour-swatch search-filters__colour-swatch--' + value.toLowerCase();

		var count, disabled = false, item = this.getAvailableColour(value);
		if (item) {
			count = '(' + numeral(item.count).format('0,0') + ')';
		} else {
			count = '(none)';
			disabled = true;
			className += ' search-filters__colour--disabled';
		}

		return (
			<li className="search-filters__colours__item">
				<button name={value} value={value} disabled={disabled} className="search-filters__colour-button" onClick={this.onButtonClick}>
					<span className={swatchClassName}></span>
					<span className={className}>
						<span className="search-filters__colour__name">{value}</span>
						<span className="search-filters__colour__count">{count}</span>
					</span>
				</button>
			</li>
		);
	},
	/* #endregion */

	/* #region Event handlers */
	onButtonClick: function (e) {
		if (!this.props.isLoading) {
			var button = $(e.target).closest('button')[0];
			this.props.onItemSet('colour', button.value, button.name);
		}
	},

	onRemoveClick: function (e) {
		e.preventDefault();
		if (!this.props.isLoading) {
			this.props.onItemRemove('colour');
		}
	}
	/* #endregion */
});