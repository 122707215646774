'use strict';

var $ = require('jquery');

var config 	= require('../utils/config.js');
var logger 	= require('../utils/logger.js');

function callApi(method, requestUrl, data, options, dataType, contentType) {
	var url = requestUrl + (config.apiExtension || '');
	if (url.substring(0, 7) !== 'http://' && url.substring(0, 8) !== 'https://') {
		url = config.apiUrl + url;
	}

	return $.ajax({
		url: url,
		method: method,
		data: data,
		dataType: dataType,
		contentType: contentType,
		complete: function (xhr, status) {
			if (options && (typeof options.completeCallback === 'function')) {
				logger.info('Parkers API call complete');
				options.completeCallback(status, xhr);
			}
		},
		success: function (data, status, xhr) {
			if (options && (typeof options.successCallback === 'function')) {
				logger.info('Parkers API call successful');
				options.successCallback(data, status, xhr);
			}
		},
		error: function (xhr, status, err) {
			if (options && (typeof options.errorCallback === 'function')) {
				logger.error(
					'Parkers API call failed',
					{
						message: 'API error',
						status: status,
						error: err
					}
				);
				options.errorCallback(status, err, xhr);
			}
		}
	});
}

module.exports = {
	get: function(requestUrl, options, dataType) {
		if (requestUrl) {
			dataType = dataType || 'json';
			return callApi('GET', requestUrl, null, options, dataType);
		}
	},

	post: function(requestUrl, data, options, dataType, contentType) {
		if (requestUrl && data) {
			dataType = dataType || 'text';
			return callApi('POST', requestUrl, data, options, dataType, contentType);
		}
	}
};