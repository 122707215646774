'use strict';

var assign = require('object-assign');
var FluxChangeStore = require('./flux-change-store.js');
var FluxDispatcher = require('../dispatchers/flux-dispatcher.js');
var FluxConstants = require('../constants/flux-constants.js');
var FilterConstants = require('../constants/car-chooser-constants.js');

var PayloadSources = FluxConstants.PayloadSources;
var FilterActionTypes = FilterConstants.ActionTypes;

var storeState = {
	searchResults: null,
	isLoading: false,
	isError: false,
	searchResultsUrl: null,
	firstLoad: true,
	updateResults: true,
	pageTitle: null
};

var firstLoad = true;

var Store = assign(new FluxChangeStore(), {
	getState : function() {
		return storeState;
	}
});

function handleServerAction(action) {
	var handled = false;

	if (action) {
		switch(action.type) {
			case FilterActionTypes.CAR_CHOOSER_SEARCH_FILTERS_UPDATE_STATE:
				handled = true;
				storeState.isLoading = true;
				storeState.isError = false;

				break;

			case FilterActionTypes.CAR_CHOOSER_SEARCH_FILTERS_UPDATE_STATE_SUCCESS:
				handled = true;
				storeState.isLoading = false;

				if (action.data && action.data.searchResults) {
					storeState.searchResults = action.data.searchResults;
					storeState.searchResultsUrl = action.data.searchResultsUrl;
					storeState.pageTitle = action.data.pageTitle;
					storeState.firstLoad = firstLoad;
					firstLoad = false;
					var nullValue = 'NotSet';

					storeState.vehicle = {
						makeName: action.data.firstResultManufacturer ? action.data.firstResultManufacturer : nullValue,
						rangeName: action.data.firstResultRange ? action.data.firstResultRange : nullValue,
						modelName: action.data.firstResultModel ? action.data.firstResultModel : nullValue,
						condition: action.data.firstResultCondition ? action.data.firstResultCondition : nullValue
					};
				} else {
					storeState.isError = true;
				}

				break;

			case FilterActionTypes.CAR_CHOOSER_SEARCH_FILTERS_UPDATE_STATE_ERROR:
				handled = true;
				storeState.isLoading = false;
				storeState.isError = true;

				break;
		}
	}

	if (handled) {
		Store.emitChange();
	}
}

function handleViewAction(action) {
	var handled = false;

	if (action) {

		switch(action.type) {
		}
	}

	if (handled) {
		Store.emitChange();
	}
}

Store.dispatchToken = FluxDispatcher.register(function (payload) {
	if (payload && payload.source) {
		if (payload.source === PayloadSources.SERVER_ACTION) {
			handleServerAction(payload.action);
		} else if (payload.source === PayloadSources.VIEW_ACTION) {
			handleViewAction(payload.action);
		}
	}
});

module.exports = Store;
