/** @jsx React.DOM */'use strict'

var React = require('react');
var $ = require('jquery');

var Store = require('../stores/car-chooser-results-store.js');

var panels = require('../../components/panels.js');
var advert = require('../../adverts/advert.js');
var analytics = require('../../utils/analytics.js');
var tagManager = require('../../utils/tagManager.js');
var history = require('../../ui/history.js');
var layout = require('../../ui/layout.js');
var images = require('../../ui/images.js');
var timeout = null;
var chooserInfoClassName = 'car-chooser__info';

function renderResultsPage(resultsHtml) {
	return <div dangerouslySetInnerHTML={{ __html: resultsHtml }} />;
};

function getLoadingMessage() {
	return <div className="results-loading-panel results-loading-panel--full-width"><div className="results-loading-panel__inner"><div className="results-loading-panel__spinner"></div>Updating results, please wait</div></div>;
};

function getErrorMessage() {
	return <div>An error has occurred retrieving the results</div>;
}

function greyOutResults() {
	$.findByBem('search-results').addBemClass("car-chooser-results", "container", "loading");
}

function removePreviousResults() {
	$.findByBem('search-results').remove();
}

function renderUpdatedMessage() {
	$.findByBem(chooserInfoClassName, 'message', 'loading').hide();
	$.findByBem(chooserInfoClassName, 'message', 'updated').show();

	timeout = setTimeout(function () {
		$.findByBem(chooserInfoClassName).slideUp('slow');
	}, 4000)
}

function renderLoadingBanner() {
	clearTimeout(timeout);
	$.findByBem(chooserInfoClassName, 'message', 'updated').hide();
	$.findByBem(chooserInfoClassName, 'message', 'loading').show();
	$.findByBem(chooserInfoClassName).slideDown('fast');
}

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		isDisabled: React.PropTypes.bool
	},

	componentWillMount: function () {
		Store.addChangeListener(this.onStoreChange);
	},

	componentWillUnmount: function () {
		Store.removeChangeListener(this.onStoreChange);
	},

	componentDidUpdate: function () {
		if (!this.state.isLoading) {
			this.loadClickPanels();
			this.setHistory();
			this.loadAds();
			this.updatePageStats();
			images.lazyLoad();

			renderUpdatedMessage();
		}
	},

	setHistory: function () {
		var pageTitle = this.state.pageTitle ? this.state.pageTitle : 'Search results';
		history.setUrl(this.state.searchResultsUrl, false, pageTitle, { carChooserSearch: true });
	},

	getInitialState: function () {
		return Store.getState();
	},

	updatePageStats: function () {
		tagManager.pushToDataLayer({ 'vehicle': this.state.vehicle });
		var url = this.state.searchResultsUrl;
		analytics.trackPageview(url);
		analytics.trackIpsosIrisPageView();
	},

	loadAds: function () {
		advert.resetTopAd();
		advert.loadAds(null);
	},

	loadClickPanels: function () {
		var $context = $.findByBem('search-results');
		panels.init($context);
	},

	render: function () {
		if (layout.isMobile()) {
			$('.off-canvas-wrap').foundation('offcanvas', 'hide', 'move-right');
		}

		if (this.state.isLoading && this.state.updateResults) {
			greyOutResults();
			var results = renderResultsPage(this.state.searchResults);
			var loadingMessage = getLoadingMessage();
			renderLoadingBanner();
			return (<div>{loadingMessage}<div className="car-chooser-results__container--loading"> {results}</div></div>);
		}

		if (this.state.isError) {
			return getErrorMessage();
		}

		if (this.state.searchResults) {
			advert.updateTargeting($(this.state.searchResults));

			if (this.state.firstLoad) {
				removePreviousResults();
			}

			return renderResultsPage(this.state.searchResults);
		}

		return null;
	},

	onStoreChange: function () {
		var state = Store.getState();
		this.setState(state);
	}
});