'use strict';

var $ = require('jquery');

var classNames = {
	urlDropdown: 'url-dropdown'
};

function loadUrlDropdowns(context) {
	$.findByBem(classNames.urlDropdown, null, null, context).off('change.urlDropdown').on('change.urlDropdown', function () {
		if (this.value) {
			window.location = this.value;
		}
	});
}

module.exports = {
	init: function (context) {
		loadUrlDropdowns(context);
	}
};