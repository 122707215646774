'use strict';

var assign 	= require('object-assign');

var location		= require('../../utils/location.js');
var logger			= require('../../utils/logger.js');
var textConverter	= require('../../utils/text-converter.js');
var postcodeHelper	= require('../../utils/postcode-helper.js');
var user			= require('../../profile/user.js');

var FluxChangeStore	= require('./flux-change-store.js');
var FluxConstants 	= require('../constants/flux-constants.js');
var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/cfs-search-filters-constants.js');

var ActionTypes 	= Constants.ActionTypes;
var PayloadSources 	= FluxConstants.PayloadSources;

var storeData = {
	filterState: {},
	filterStateError: false,
	isLoading: true,
	searchType: null,
	validationErrors: [],
	waitingForLocation: false,
	updateResults: false
};

function getSelectedPropertyName(name) {
	return 'selected' + textConverter.hyphenToPascalCase(name);
}

function getSelectedValue(selectedItem) {
	return selectedItem !== null ? selectedItem.value : null;
}

function resetDependentItems(name) {
	switch(name) {
		case 'manufacturer':
			storeData.filterState.selectedRange = null;
			storeData.filterState.rangeItems = [];

			storeData.filterState.selectedModel = null;
			storeData.filterState.modelItems = [];

			storeData.filterState.selectedTrim = null;
			storeData.filterState.trimItems = [];

			storeData.filterState.selectedDerivative = null;
			storeData.filterState.derivativeItems = [];

			break;
		case 'range':
			storeData.filterState.selectedModel = null;
			storeData.filterState.modelItems = [];

			storeData.filterState.selectedTrim = null;
			storeData.filterState.trimItems = [];

			storeData.filterState.selectedDerivative = null;
			storeData.filterState.derivativeItems = [];

			break;
		case 'model':
			storeData.filterState.selectedTrim = null;
			storeData.filterState.trimItems = [];

			storeData.filterState.selectedDerivative = null;
			storeData.filterState.derivativeItems = [];

			break;
		case 'trim':
			storeData.filterState.selectedDerivative = null;
			storeData.filterState.derivativeItems = [];

			break;
	}
}

function requiresEmsUpdate(selectedFacet) {
	var result = false;
	if (selectedFacet === 'range' || selectedFacet === 'model' || selectedFacet === 'derivative')
	{
		result = true;
	}

	return result;
}

var Store = assign(new FluxChangeStore(), {
	getFilterRequest: function () {
		var result = {
			searchType: storeData.searchType,
			distance: storeData.filterState.distance,
			nationwideDistanceRadius: storeData.filterState.nationwideDistanceRadius,
			postcode: storeData.filterState.postcode,
			latLong: storeData.filterState.latLong,
			manufacturer: getSelectedValue(storeData.filterState.selectedManufacturer),
			range: getSelectedValue(storeData.filterState.selectedRange),
			model: getSelectedValue(storeData.filterState.selectedModel),
			trim: getSelectedValue(storeData.filterState.selectedTrim),
			derivative: getSelectedValue(storeData.filterState.selectedDerivative),
			priceMin: storeData.filterState.priceMin,
			priceMax: storeData.filterState.priceMax,
			monthlyMinimumPrice: storeData.filterState.monthlyMinimumPrice,
			monthlyMaximumPrice: storeData.filterState.monthlyMaximumPrice,
			yearMin: getSelectedValue(storeData.filterState.selectedYearMin),
			yearMax: getSelectedValue(storeData.filterState.selectedYearMax),
			engineSizeMin: getSelectedValue(storeData.filterState.selectedEngineSizeMin),
			engineSizeMax: getSelectedValue(storeData.filterState.selectedEngineSizeMax),
			keywords: storeData.filterState.keywords,
			carType: getSelectedValue(storeData.filterState.selectedCarType),
			mileage: getSelectedValue(storeData.filterState.selectedMileage),
			age: getSelectedValue(storeData.filterState.selectedAge),
			sellerType: getSelectedValue(storeData.filterState.selectedSellerType),
			gearbox: getSelectedValue(storeData.filterState.selectedGearbox),
			fuelType: getSelectedValue(storeData.filterState.selectedFuelType),
			numberOfSeats: getSelectedValue(storeData.filterState.selectedNumberOfSeats),
			numberOfDoors: getSelectedValue(storeData.filterState.selectedNumberOfDoors),
			colour: getSelectedValue(storeData.filterState.selectedColour),
			mpg: getSelectedValue(storeData.filterState.selectedMpg),
			insuranceGroup: getSelectedValue(storeData.filterState.selectedInsuranceGroup),
			roadTax: getSelectedValue(storeData.filterState.selectedRoadTax),
			co2Emissions: getSelectedValue(storeData.filterState.selectedCo2Emissions),
			updateEms: storeData.filterState.updateEms,
			useMonthlyPrice: storeData.filterState.useMonthlyPrice
		};

		return result;
	},

	getResetRequest: function() {
		var result = {
			searchType: storeData.searchType,
			postcode: storeData.filterState.postcode,
			enablePostcodeInfoBubble: storeData.filterState.enablePostcodeInfoBubble,
			latLong: storeData.filterState.latLong,
			nationwideDistanceRadius: storeData.filterState.nationwideDistanceRadius,
			useMonthlyPrice: storeData.filterState.useMonthlyPrice
		};

		return result;
	},

	getState: function () {
		return storeData;
	}
});

function handleServerAction(action) {
	if (action) {
		var handled = false;

		switch(action.type) {
			case ActionTypes.CFS_SEARCH_FILTERS_GET_STATE:
			case ActionTypes.CFS_SEARCH_FILTERS_UPDATE_STATE:
				handled = true;
				storeData.isLoading = true;

				if (postcodeHelper.isValid(action.data.postcode)) {
					// save postcode with each get state to extend its expiry
					user.savePostcode(action.data.postcode);
				}

				break;
			case ActionTypes.CFS_SEARCH_FILTERS_GET_STATE_SUCCESS:
			case ActionTypes.CFS_SEARCH_FILTERS_UPDATE_STATE_SUCCESS:
				handled = true;
				storeData.isLoading = false;
				storeData.filterState = action.data;
				storeData.filterStateError = false;

				break;
			case ActionTypes.CFS_SEARCH_FILTERS_GET_STATE_ERROR:
			case ActionTypes.CFS_SEARCH_FILTERS_UPDATE_STATE_ERROR:
				handled = true;
				storeData.isLoading = false;
				storeData.filterStateError = true;

				break;
			case ActionTypes.CFS_SEARCH_FILTERS_INIT_STORE_DATA:
				handled = true;
				assign(storeData, action.data);

				storeData.filterState.latLong = location.getSavedLocation();
				storeData.showCfsAdvancedFilters = user.getCfsAdvancedFilterState();

				if (!storeData.filterState.latLong || !storeData.filterState.latLong.isValid) {
					storeData.filterState.postcode = user.getPostcode();
				}

				break;
			case ActionTypes.CFS_SEARCH_FILTERS_REQUEST_LOCATION_SUCCESS:
				handled = true;
				storeData.isLoading = false;
				storeData.waitingForLocation = false;
				storeData.filterState.postcode = null;
				storeData.filterState.latLong = action.data.latLong;
				storeData.filterState.locationError = null;

				break;
			case ActionTypes.CFS_SEARCH_FILTERS_REQUEST_LOCATION_ERROR:
				handled = true;
				storeData.isLoading = false;
				storeData.waitingForLocation = false;
				storeData.filterState.latLong = null;
				storeData.filterState.locationError = action.data.errorMessage;

				break;

			case ActionTypes.CFS_SEARCH_FILTERS_RESET:
				handled = true;
				storeData.isLoading = true;

				break;
		}

		if (handled) {
			logger.debug('CFS Search Filters', {
				message: 'Handle server action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

function handleViewAction(action) {
	if (action) {
		var handled = false;
		storeData.updateResults = true;
		
		switch(action.type) {
			case ActionTypes.CFS_SEARCH_FILTERS_GET_LOCATION:
				handled = true;
				storeData.waitingForLocation = true;
				storeData.filterState.locationError = null;

				break;
			case ActionTypes.CFS_SEARCH_FILTERS_REMOVE_LOCATION:
				handled = true;
				storeData.filterState.latLong = null;
				storeData.filterState.postcode = user.getPostcode();
				location.clearLocation();

				break;
			case ActionTypes.CFS_SEARCH_FILTERS_REMOVE_ITEM:
				handled = true;
				storeData.filterState[getSelectedPropertyName(action.data.name)] = null;
				storeData.filterState.updateEms = false;
				resetDependentItems(action.data.name);

				break;
			case ActionTypes.CFS_SEARCH_FILTERS_SET_DISTANCE:
				handled = true;
				storeData.filterState.distance = action.data.distance;

				break;
			case ActionTypes.CFS_SEARCH_FILTERS_SET_KEYWORDS:
				handled = true;
				storeData.filterState.keywords = action.data.keywords;

				break;
			case ActionTypes.CFS_SEARCH_FILTERS_SET_POSTCODE:
				handled = true;
				storeData.filterState.latLong = null;
				storeData.filterState.postcode = action.data.postcode;

				if (action.data.saveToCookie && 
					(!storeData.filterState.postcode || postcodeHelper.isValid(storeData.filterState.postcode))) {
					user.savePostcode(storeData.filterState.postcode);
				}

				break;
			case ActionTypes.CFS_SEARCH_FILTERS_SET_ITEM:
				handled = true;
				storeData.filterState[getSelectedPropertyName(action.data.name)] = {
					text: action.data.text,
					value: action.data.value
				};
				resetDependentItems(action.data.name);

				if (user.isAuthenticated()) {
					storeData.filterState.updateEms = requiresEmsUpdate(action.data.name);
				}

				break;

			case ActionTypes.CFS_SEARCH_FILTERS_SET_VALUE:
				handled = true;
				storeData.filterState[action.data.name] = action.data.value;
				storeData.validationErrors[action.data.name] = null;

				break;
			case ActionTypes.CFS_SEARCH_FILTERS_VALIDATION_ERROR:
				handled = true;
				storeData.filterState[action.data.name] = null;
				storeData.validationErrors[action.data.name] = action.data.message;

				break;
			case ActionTypes.CFS_SEARCH_ADVANCED_FILTERS_EXPANDED:
				handled = true;
				user.saveCfsAdvancedFilterState(action.data.showCfsAdvancedFilters);
				storeData.showCfsAdvancedFilters = action.data.showCfsAdvancedFilters;

				break;

			case ActionTypes.CFS_SEARCH_FILTERS_SWITCH_PRICE_TYPE:
				handled = true;
				storeData.filterState.useMonthlyPrice = action.data;

				break;
		}

		if (handled) {
			logger.debug('CFS Search Filters', {
				message: 'Handle view action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

Store.dispatchToken = FluxDispatcher.register(function (payload) {
	if (payload && payload.source) {
		if (payload.source === PayloadSources.SERVER_ACTION) {
			handleServerAction(payload.action);
		} else if (payload.source === PayloadSources.VIEW_ACTION) {
			handleViewAction(payload.action);
		}
	}
});

module.exports = Store;