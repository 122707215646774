'use strict';

function defineStartsWith() {
	if (!String.prototype.startsWith) {
		String.prototype.startsWith = function(value, position) {
			position = position || 0;
			return this.substr(position, value.length) === value;
		};
	}
}

function definePolyfills() {
	defineStartsWith();
}

module.exports = {
	init: definePolyfills
};