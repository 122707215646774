'use strict';

var logger 			= require('../../utils/logger.js');
var carChooserApi 	= require('../../api/car-chooser-api.js');

var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/car-chooser-constants.js');

var ActionTypes = Constants.ActionTypes;

function log(source, actionType, data) {
	logger.debug('Car Chooser', {
		message: 'Create ' + source + ' action',
		action: actionType,
		data: data
	});
}

function createServerAction(actionType, data) {
	log('server', actionType, data);
	FluxDispatcher.dispatchServerAction({
		type: actionType,
		data: data
	});
}

function createViewAction(actionType, data) {
	log('view', actionType, data);
	FluxDispatcher.dispatchViewAction({
		type: actionType,
		data: data
	});
}

var Actions = {
	initStoreData: function (filterRequest) {
		createViewAction(ActionTypes.CAR_CHOOSER_INIT, filterRequest);
	},

	switchSearchType: function (searchType) {
		createViewAction(ActionTypes.CAR_CHOOSER_SWITCH_SEARCH_TYPE, searchType);
	},

	setPriceRange: function (prices) {
		createViewAction(ActionTypes.CAR_CHOOSER_SET_PRICE_RANGE, prices);
	},

	setMonthlyPriceRange: function (prices) {
		createViewAction(ActionTypes.CAR_CHOOSER_SET_MONTHLY_PRICE_RANGE, prices);
	},

	toggleClassification: function (classification) {
		createViewAction(ActionTypes.CAR_CHOOSER_TOGGLE_CLASSIFICATION, classification);
	},

	toggleClassificationDisplay: function (isVisible) {
		createViewAction(ActionTypes.CAR_CHOOSER_TOGGLE_CLASSIFICATION_DISPLAY, isVisible);
	},

	toggleLifestyle: function (lifestyle) {
		createViewAction(ActionTypes.CAR_CHOOSER_TOGGLE_LIFESTYLE, lifestyle);
	},

	toggleLifestyleDisplay: function (isVisible) {
		createViewAction(ActionTypes.CAR_CHOOSER_TOGGLE_LIFESTYLE_DISPLAY, isVisible);
	},

	switchPriceType: function (useMonthlyPrice) {
		createViewAction(ActionTypes.CAR_CHOOSER_SWITCH_PRICE_TYPE, useMonthlyPrice);		
	},

	togglePricesDisplay: function (isVisible) {
		createViewAction(ActionTypes.CAR_CHOOSER_TOGGLE_PRICES_DISPLAY, isVisible);
	},

	updateFilterState: function (filterRequest, dynamicallyUpdate) {
		createServerAction(ActionTypes.CAR_CHOOSER_SEARCH_FILTERS_UPDATE_STATE, filterRequest);

		carChooserApi.search(filterRequest, {
			includeResults: dynamicallyUpdate,
			successCallback: function(data) {
				createServerAction(ActionTypes.CAR_CHOOSER_SEARCH_FILTERS_UPDATE_STATE_SUCCESS, data);
			},
			errorCallback: function() {
				createServerAction(ActionTypes.CAR_CHOOSER_SEARCH_FILTERS_UPDATE_STATE_ERROR);
			}
		});
	}
};

module.exports = Actions;