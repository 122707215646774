'use strict';

var $  			= require('jquery');
var cookies 	= require('../storage/cookie.js');

var config = require('../utils/config.js');

var classNames = {
	betaMessage: 'beta-message'
};

var revealDelay = 1000;
var betaCookieName	= 'Parkers.UI.Beta';
var redirectURL = config.betaEscapeUrl;

var cookieDefaults = {
	showInfoPopup: true,
	useBetaSite: true
};

var cookieSettings = {
	expires: 30,
	domain: config.cookieDomain
};

function setBetaCookie(betaCookie) {
	cookies.setCookie(betaCookieName, betaCookie, cookieSettings);
}

function getBetaCookie() {
	var betaCookie = cookies.getCookie(betaCookieName);

	if(betaCookie) {
		return betaCookie;
	} else {
		setBetaCookie(cookieDefaults);
		return cookieDefaults;
	}
}

function closeInfoPopup() {
	$.findByBem(classNames.betaMessage, 'info-popup')
		.remove();

	var betaCookie = getBetaCookie();
	betaCookie.showInfoPopup = false;
	setBetaCookie(betaCookie);
}

function exitBeta() {
	var betaCookie = getBetaCookie();
	betaCookie.useBetaSite = false;
	setBetaCookie(betaCookie);
	window.location.href = redirectURL;
}

function initBetaMessage() {
	var betaCookie = getBetaCookie();

	$.findByBem(classNames.betaMessage, 'exit-link').on('click.exitBeta', function(){
		exitBeta();
	});

	if(!betaCookie.showInfoPopup)
	{
		$.findByBem(classNames.betaMessage, 'info-popup').remove();
		return;
	}

	window.setTimeout(function() {
		$.findByBem(classNames.betaMessage, 'info-popup')
		.addBemClass(classNames.betaMessage, 'info-popup', 'open');		 
	}, revealDelay);

	$.findByBem(classNames.betaMessage, 'info-popup__close').on('click.closeBetaInfo', function(){
		closeInfoPopup();
	});

	$.findByBem(classNames.betaMessage, 'link').on('click.showBetaMessage', function(){
		closeInfoPopup();
	});
}

module.exports = {
	init: function () {
		initBetaMessage();
	}
};