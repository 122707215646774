'use strict';

var $ 		= require('jquery');
var assign 	= require('object-assign');

var cookie = require('../storage/cookie.js');

var classNames = {
	rppDropdown: 'rpp-dropdown'
};

module.exports = {
	init: function (context) {
		$.findByBem(classNames.rppDropdown, null, null, context).off('change.rppDropdown').on('change.rppDropdown', function () {
			var select = $(this);
			var cookieName = select.attr('data-cookie-name');
			var firstPageUrl = select.attr('data-first-page');

			if (cookieName && firstPageUrl) {
				var cookieData = assign({}, cookie.getCookie(cookieName), {
					rpp: parseInt(select.val())
				});

				cookie.setCookie(cookieName, cookieData);
				window.location = firstPageUrl;
			}
		});
	}
};