'use strict';

module.exports = {
	begin: function (callback, delay) {
		var result = {
			start: new Date(),
			remaining: delay,
			onCompleteCallback: callback,
			timerId: window.setTimeout(callback, delay)
		};

		return result;
	},

	pause: function (timer) {
		window.clearTimeout(timer.timerId);
		timer.remaining -= new Date() - timer.start;
	},

	resume: function (timer) {
		timer.start = new Date();
		window.clearTimeout(timer.timerId);

		if (timer.onCompleteCallback) {
			timer.timerId = window.setTimeout(timer.onCompleteCallback, timer.remaining);
		}
	},

	cancel: function (timer) {
		window.clearTimeout(timer.timerId);
		timer.remaining = 0;
		timer.onCompleteCallback = null;
	},

	reset: function (timer) {
		window.clearTimeout(timer.timerId);
		timer.remaining = 0;
		timer.onCompleteCallback();
	},
};