/** @jsx React.DOM */'use strict';

var React = require('react');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		currentSearchType: React.PropTypes.string.isRequired,
		switchSearchTypeUrl: React.PropTypes.string.isRequired,
		vehicleType: React.PropTypes.string
	},

	getDefaultProps: function() {
		return {
			currentSearchType: 'New',
			switchSearchTypeUrl: '',
			vehicleType: 'car'
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var switches;

		if (this.props.currentSearchType === 'New') {
			switches = (
				<div className="toggle-switch__switches">
					<span className="toggle-switch__switch toggle-switch__switch--large toggle-switch__switch--active">New {this.props.vehicleType}s</span>
					<a href={this.props.switchSearchTypeUrl} className="toggle-switch__switch toggle-switch__switch--large">Used {this.props.vehicleType}s</a>
				</div>
			);
		} else if (this.props.currentSearchType === 'Used') {
			switches = (
				<div className="toggle-switch__switches">
					<a href={this.props.switchSearchTypeUrl} className="toggle-switch__switch toggle-switch__switch--large">New {this.props.vehicleType}s</a>
					<span className="toggle-switch__switch toggle-switch__switch--large toggle-switch__switch--active">Used {this.props.vehicleType}s</span>
				</div>
			);
		} else {
			return null;
		}

		return (
			<section className="search-filters__search-type toggle-switch">
				<h3 className="show-for-sr">New or Used</h3>
				<div className="search-filters__search-type__label">Search type:</div>
				{switches}
			</section>
		);
	}
	/* #endregion */
});