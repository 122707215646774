'use strict';

var keyMirror = require('keymirror');

module.exports = {
	ActionTypes: keyMirror({

		FOR_SALE_OR_LEASE_QUICK_FIND_INIT_STORE_DATA: null,

		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_TYPE: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_TYPE_SUCCESS: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_TYPE_ERROR: null,

		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MANUFACTURERS: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MANUFACTURERS_SUCCESS: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MANUFACTURERS_ERROR: null,

		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_RANGES: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_RANGES_SUCCESS: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_RANGES_ERROR: null,

		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MODELS: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MODELS_SUCCESS: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MODELS_ERROR: null,

		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_URL: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_URL_SUCCESS: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_URL_ERROR: null,

		FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_SEARCH_TYPE: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_MANUFACTURER: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_RANGE: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_MODEL: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_FROM_PRICE: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_TO_PRICE: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_SET_POSTCODE: null,

		FOR_SALE_OR_LEASE_QUICK_FIND_VALIDATION_ERROR: null,
		FOR_SALE_OR_LEASE_QUICK_FIND_POSTCODE_VALIDATION_ERROR: null
	})
};