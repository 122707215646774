/** @jsx React.DOM */'use strict';

var $ 		= require('jquery');
var React 	= require('react');
var numeral = require('numeral');

var FacetDropdown = require('./shared/facet-dropdown-view.jsx');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		filterState: React.PropTypes.object.isRequired,
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		onItemRemove: React.PropTypes.func.isRequired,
		onItemSet: React.PropTypes.func.isRequired
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var desktopView;

		if (this.props.filterState.selectedVanType) {
			desktopView = this.renderSelectedValue();
		} else if (this.props.filterState.vanTypeItems && this.props.filterState.vanTypeItems.length === 1) {
			desktopView = this.renderSingleValue();
		} else {
			desktopView = this.renderList();
		}

		return (
			<div className="search-filters__facet">
				<div className="hide-for-large-up">
					<FacetDropdown
						name="vanType"
						defaultText="Van type"
						isLoading={this.props.isLoading}
						isDisabled={this.props.isDisabled}
						items={this.props.filterState.vanTypeItems}
						selectedItem={this.props.filterState.selectedVanType}
						onRemove={this.props.onItemRemove}
						onSet={this.props.onItemSet}
					/>
				</div>
				<div className="show-for-large-up">
					{desktopView}
				</div>
			</div>
		);
	},

	renderList: function () {
		var linkClass = 'search-filters__flyout__open-link';

		if (this.props.isLoading || this.props.isDisabled || !this.props.filterState.vanTypeItems || this.props.filterState.vanTypeItems.length === 0) {
			linkClass += ' search-filters__flyout__open-link--disabled';
		}

		return (
			<div className="search-filters__flyout">
				<div className={linkClass}>
					<span className="search-filters__flyout__open-link__icon icon-16x16-chevron"></span>
					<span className="search-filters__flyout__open-link__label">Van type</span>
					<span className="search-filters__flyout__open-link__value">Any</span>
				</div>
				<div className="search-filters__flyout__panel">
					<div className="search-filters__flyout__panel__header">
						<div className="search-filters__flyout__panel__title">
							Select a van type / category
						</div>
						<a href="#" className="search-filters__flyout__panel__close-link">
							<span className="icon icon-gap-right fi-x"></span>
							Close
						</a>
					</div>
					<ul className="search-filters__vehicle-types body-styles inline">
						{this.getVanType('Small Van', 'small-van')}
						{this.getVanType('Medium Van', 'medium-van')}
						{this.getVanType('Large Van', 'large-van')}
						{this.getVanType('Pickup', 'pickup')}
						{this.getVanType('Car Van', 'car-van')}
						{this.getVanType('Commercial 4x4', 'commercial-4x4')}
						{this.getVanType('Conversion', 'conversion')}
						{this.getVanType('Light Truck', 'light-truck')}
						{this.getVanType('Minibus', 'minibus')}
					</ul>
				</div>
			</div>
		);
	},

	renderSelectedValue: function () {
		return (
			<div className="search-filters__flyout">
				<span className="search-filters__flyout__selected">
					{this.props.filterState.selectedVanType.text}
					<a href="#" className="search-filters__flyout__selected__remove-link" title="Remove" onClick={this.onRemoveClick}>
						Remove
					</a>
				</span>
			</div>
		);
	},

	renderSingleValue: function () {
		var onlyFacetItem = this.props.filterState.vanTypeItems[0];

		return (
			<div className="search-filters__flyout">
				<span className="search-filters__flyout__selected">
					{onlyFacetItem.text}
				</span>
			</div>
		);
	},

	getAvailableVanType: function (value) {
		if (this.props.filterState.vanTypeItems) {
			var index, item;

			for (index = 0; index < this.props.filterState.vanTypeItems.length; index++) {
				item = this.props.filterState.vanTypeItems[index];
				if (item.value === value) {
					return item;
				}
			}
		}

		return null;
	},

	getVanType: function (value, className) {
		var buttonClass = 'search-filters__vehicle-type body-styles__link';
		var iconClass = 'body-styles__link__icon body-styles__link__icon--' + className;

		var count, disabled = false, item = this.getAvailableVanType(value);
		if (item) {
			count = '(' + numeral(item.count).format('0,0') + ')';
		} else {
			count = '(none)';
			disabled = true;
			buttonClass = buttonClass + ' search-filters__vehicle-type--disabled body-styles__link--disabled';
		}

		return (
			<li className="body-styles__item">
				<button name={value} value={value} disabled={disabled} className={buttonClass} onClick={this.onButtonClick}>
					<span className={iconClass}></span>
					<span className="search-filters__vehicle-type__name">{value}</span>
					<span className="search-filters__vehicle-type__count">{count}</span>
				</button>
			</li>
		);
	},
	/* #endregion */

	/* #region Event handlers */
	onButtonClick: function (e) {
		if (!this.props.isLoading) {
			var button = $(e.target).closest('button')[0];
			this.props.onItemSet('vanType', button.value, button.name);
		}
	},

	onRemoveClick: function (e) {
		e.preventDefault();
		if (!this.props.isLoading) {
			this.props.onItemRemove('vanType');
		}
	}
	/* #endregion */
});