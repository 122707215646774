'use strict';

var $ 			= require('jquery');
var ScrollMagic = require('scrollmagic');

var config = require('../utils/config.js');
var layout = require('./layout.js');

// singleton ScrollMagic controller required per scroll container (i.e. window)
var scrollMagicController;

var warningLogLevel = 2, debugLogLevel = 3;

function scrollToOffset(offset, speed, scrollableElement) {
	if (scrollableElement && speed) {
		$(scrollableElement).animate({
			scrollTop: offset
		}, speed);
	}

	if (speed) {
		$('html, body').animate({
			scrollTop: offset
		}, speed);
	} else {
		window.scrollTo(0, offset);
	}
}

var self = module.exports = {
	getScrollMagicController: function () {
		if (!scrollMagicController) {
			scrollMagicController = new ScrollMagic.Controller({
				loglevel: config.isDebugMode ? debugLogLevel : warningLogLevel
			});
		}

		return scrollMagicController;
	},

	getScrollOffset: function ($element) {
		var offset = $element.offset().top - layout.getContentOffset(true);
		if (offset < 0) {
			offset = 0;
		}

		return offset;
	},

	scrollIntoView: function ($element, speed) {
		if ($element.length > 0) {
			var offset = self.getScrollOffset($element);
			var screenTop = $(window).scrollTop();
			var screenBottom = $(window).scrollTop() + (window.innerHeight || document.documentElement.clientHeight);

			if (offset < screenTop || offset > screenBottom) {
				// only scroll into view if outside of viewport
				scrollToOffset(offset, speed);
			}
		}
	},

	scrollToHorizontal: function ($scrollableElement, $specificElement) {
		if ($scrollableElement.length > 0 &&  $specificElement.length > 0) {
			var scrollOffSet = $specificElement.position().left + $specificElement.width() - $scrollableElement.width();
			
			// When scrollOffset is negative - a specific element is still in view. 
			if (scrollOffSet > 0) {
				$scrollableElement.scrollLeft(scrollOffSet);
			}
		}
	},

	scrollTo: function ($element, speed, scrollableElement) {		
		if (scrollableElement) {
			scrollToOffset(0, speed, scrollableElement);
		} else if ($element.length > 0) {
					var offset = self.getScrollOffset($element);
					scrollToOffset(offset, speed);
			}
	}
};