/** @jsx React.DOM */'use strict';

var React = require('react');

var filterScroller = require('../../../utils/filter-scroll.js');

var previousKeywords;

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		filterState: React.PropTypes.object.isRequired,
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		onKeywordsSet: React.PropTypes.func.isRequired
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var disabled = this.props.isLoading || this.props.isDisabled;
		return (
			<section className="search-filters__keywords search-filters__item">
				<label htmlFor="tbKeywords" className="search-filters__item__label">Keywords:</label>
				<input name="tbKeywords" type="text" maxLength="50" placeholder="e.g. cruise control" value={this.props.filterState.keywords} disabled={disabled}
					className="search-filters__location__keywords__input search-filters__item__input"
					onBlur={this.onKeywordsBlur} onChange={this.onKeywordsChange} onFocus={this.onKeywordsFocus} onKeyPress={this.onKeywordsKeyPress} />
			</section>
		);
	},
	/* #endregion */

	/* #region Event handlers */
	onKeywordsBlur: function (e) {
		if (!this.props.isLoading && e.target.value !== previousKeywords) {
			this.props.onKeywordsSet(e.target.value, true);
		}

		filterScroller.scrollToOriginalPosition(e.target);
	},

	onKeywordsChange: function (e) {
		// React input controls with a value require an onChange event, to update the store data and refresh the view
		// this gets triggered on every key press, we only want to do the actual search onBlur
		if (!this.props.isLoading) {
			this.props.onKeywordsSet(e.target.value, false);
		}
	},

	onKeywordsFocus: function (e) {
		previousKeywords = e.target.value;
	},

	onKeywordsKeyPress: function (e) {
		if (!this.props.isLoading && (e.charCode === 13 || e.key === 'Enter')) {
			// enter key pressed
			e.preventDefault();
			e.stopPropagation();
			this.props.onKeywordsSet(e.target.value, true);
		}
	}
	/* #endregion */
});