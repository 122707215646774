'use strict';

var assign = require('object-assign');

var logger = require('../../utils/logger.js');
var textConverter = require('../../utils/text-converter.js');

var FluxChangeStore	= require('./flux-change-store.js');
var FluxConstants = require('../constants/flux-constants.js');
var FluxDispatcher = require('../dispatchers/flux-dispatcher.js');
var Constants = require('../constants/leasing-search-filters-constants.js');
var user = require('../../profile/user.js');

var ActionTypes 	= Constants.ActionTypes;
var PayloadSources 	= FluxConstants.PayloadSources;

var storeData = {
	filterState: {},
	filterStateError: false,
	isLoading: true,
	searchType: 'personal',
	updateResults: false,
	validationErrors: []
};

function getSelectedPropertyName(name) {
	return 'selected' + textConverter.hyphenToPascalCase(name);
}

function getSelectedValue(selectedItem) {
	return selectedItem !== null ? selectedItem.value : null;
}

function resetDependentItems(name) {
	switch(name) {
		case 'manufacturer':
			storeData.filterState.selectedRange = null;
			storeData.filterState.rangeItems = [];

			storeData.filterState.selectedModel = null;
			storeData.filterState.modelItems = [];

			storeData.filterState.selectedTrim = null;
			storeData.filterState.trimItems = [];

			storeData.filterState.selectedDerivative = null;
			storeData.filterState.derivativeItems = [];

			break;
		case 'range':
			storeData.filterState.selectedModel = null;
			storeData.filterState.modelItems = [];

			storeData.filterState.selectedTrim = null;
			storeData.filterState.trimItems = [];

			storeData.filterState.selectedDerivative = null;
			storeData.filterState.derivativeItems = [];

			break;
		case 'model':
			storeData.filterState.selectedTrim = null;
			storeData.filterState.trimItems = [];

			storeData.filterState.selectedDerivative = null;
			storeData.filterState.derivativeItems = [];

			break;
		case 'trim':
			storeData.filterState.selectedDerivative = null;
			storeData.filterState.derivativeItems = [];

			break;
	}
}

var Store = assign(new FluxChangeStore(), {
	getFilterRequest: function () {
	 	var result = {
	 		searchType: storeData.searchType,
			manufacturer: getSelectedValue(storeData.filterState.selectedManufacturer),
			range: getSelectedValue(storeData.filterState.selectedRange),
			model: getSelectedValue(storeData.filterState.selectedModel),
			trim: getSelectedValue(storeData.filterState.selectedTrim),
			derivative: getSelectedValue(storeData.filterState.selectedDerivative),
			priceMin: storeData.filterState.priceMin,
			priceMax: storeData.filterState.priceMax,
			term: storeData.filterState.selectedTerm,
			deposit: storeData.filterState.selectedDeposit,
			mileageAllowance: storeData.filterState.selectedMileageAllowance,
			fuelType: getSelectedValue(storeData.filterState.selectedFuelType),
			gearbox: getSelectedValue(storeData.filterState.selectedGearbox),
			mpg: getSelectedValue(storeData.filterState.selectedMpg),
			carType: getSelectedValue(storeData.filterState.selectedCarType)
	 	};

		return result;
	},

	getResetRequest: function() {
		var result = {
	 		searchType: storeData.searchType
		};

		return result;
	},

	getState: function () {
		return storeData;
	}
});

function handleServerAction(action) {
	if (action) {
		var handled = false;

		switch(action.type) {
			case ActionTypes.LEASING_SEARCH_FILTERS_GET_STATE:
			case ActionTypes.LEASING_SEARCH_FILTERS_UPDATE_STATE:
				handled = true;
				storeData.isLoading = true;

				break;
			case ActionTypes.LEASING_SEARCH_FILTERS_GET_STATE_SUCCESS:
			case ActionTypes.LEASING_SEARCH_FILTERS_UPDATE_STATE_SUCCESS:
				handled = true;
				storeData.isLoading = false;
				storeData.filterState = action.data;
				storeData.filterStateError = false;

				break;
			case ActionTypes.LEASING_SEARCH_FILTERS_GET_STATE_ERROR:
			case ActionTypes.LEASING_SEARCH_FILTERS_UPDATE_STATE_ERROR:
				handled = true;
				storeData.isLoading = false;
				storeData.filterStateError = true;

				break;
			case ActionTypes.LEASING_SEARCH_FILTERS_INIT_STORE_DATA:
				handled = true;
				assign(storeData, action.data);

				storeData.showLeasingAdvancedFilters = user.getLeasingAdvancedFilterState();

				break;
			case ActionTypes.LEASING_SEARCH_FILTERS_RESET:
				handled = true;
				storeData.isLoading = true;

				break;
		}

		if (handled) {
			logger.debug('Leasing Search Filters', {
				message: 'Handle server action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

function handleViewAction(action) {
	if (action) {
		var handled = false;
		storeData.updateResults = true;
		
		switch(action.type) {
			case ActionTypes.LEASING_SEARCH_FILTERS_REMOVE_ITEM:
				handled = true;
				storeData.filterState[getSelectedPropertyName(action.data.name)] = null;
				resetDependentItems(action.data.name);

				break;
			case ActionTypes.LEASING_SEARCH_FILTERS_SET_ITEM:
				handled = true;
				storeData.filterState[getSelectedPropertyName(action.data.name)] = {
					text: action.data.text,
					value: action.data.value
				};
				resetDependentItems(action.data.name);

				break;
			case ActionTypes.LEASING_SEARCH_FILTERS_SET_VALUE:
				handled = true;
				storeData.filterState[action.data.name] = action.data.value;
				storeData.validationErrors[action.data.name] = null;

				break;
			case ActionTypes.LEASING_SEARCH_FILTERS_VALIDATION_ERROR:
				handled = true;
				storeData.filterState[action.data.name] = null;
				storeData.validationErrors[action.data.name] = action.data.message;

				break;
			case ActionTypes.LEASING_SEARCH_ADVANCED_FILTERS_EXPANDED:
				handled = true;
				user.saveLeasingAdvancedFilterState(action.data.showLeasingAdvancedFilters);
				storeData.showLeasingAdvancedFilters = action.data.showLeasingAdvancedFilters;

				break;
		}

		if (handled) {
			logger.debug('Leasing Search Filters', {
				message: 'Handle view action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

Store.dispatchToken = FluxDispatcher.register(function (payload) {
	if (payload && payload.source) {
		if (payload.source === PayloadSources.SERVER_ACTION) {
			handleServerAction(payload.action);
		} else if (payload.source === PayloadSources.VIEW_ACTION) {
			handleViewAction(payload.action);
		}
	}
});

module.exports = Store;