'use strict';

var keyMirror = require('keymirror');

module.exports = {
	ActionTypes: keyMirror({
		COMPANY_CAR_TAX_INIT_STORE_DATA: null,
		COMPANY_CAR_TAX_LOAD_INITIAL_STATE: null,
		COMPANY_CAR_TAX_LOAD_INITIAL_STATE_SUCCESS: null,
		COMPANY_CAR_TAX_LOAD_INITIAL_STATE_ERROR: null,
		COMPANY_CAR_TAX_SELECT_MANUFACTURER: null,
		COMPANY_CAR_TAX_SELECT_RANGE: null,
		COMPANY_CAR_TAX_SELECT_MODEL: null,
		COMPANY_CAR_TAX_SELECT_YEAR_PLATE: null,
		COMPANY_CAR_TAX_SELECT_DERIVATIVE: null,
		COMPANY_CAR_TAX_LOAD_RANGES: null,
		COMPANY_CAR_TAX_LOAD_RANGES_SUCCESS: null,
		COMPANY_CAR_TAX_LOAD_RANGES_ERROR: null,
		COMPANY_CAR_TAX_LOAD_YEAR_PLATES: null,
		COMPANY_CAR_TAX_LOAD_YEAR_PLATES_SUCCESS: null,
		COMPANY_CAR_TAX_LOAD_YEAR_PLATES_ERROR: null,
		COMPANY_CAR_TAX_SELECT_TAX_YEAR: null,
		COMPANY_CAR_TAX_SELECT_TAX_RATE: null,
		COMPANY_CAR_TAX_VALIDATION_ERROR: null
	})
};