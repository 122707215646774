'use strict';

var $ = require('jquery');

function wrapRevealModals() {
    $('a[data-delegate-reveal-id]').on('click', function() {
        $('#' + $(this).data('delegate-reveal-id')).foundation('reveal', 'open');
    });
}

module.exports = {
	init: wrapRevealModals
};
