/** @jsx React.DOM */'use strict';

var React = require('react');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		currentSearchType: React.PropTypes.string.isRequired,
		onTabClick: React.PropTypes.func.isRequired
	},

	getDefaultProps: function() {
		return {
			currentSearchType: 'New'
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var switches;

		if (this.props.currentSearchType === 'New') {
			switches = (
				<ul className="tabs">
					<li className="tabs__item active">
						<a className="tabs__link">New cars</a>
					</li>
					<li className="tabs__item">
						<a onClick={this.onTabClick} className="tabs__link" href="#">Used cars</a>
					</li>
				</ul>
			);
		} else if (this.props.currentSearchType === 'Used') {
			switches = (
				<ul className="tabs">
					<li className="tabs__item">
						<a onClick={this.onTabClick} className="tabs__link" href="#">New cars</a>
					</li>
					<li className="tabs__item active">
						<a className="tabs__link">Used cars</a>
					</li>
				</ul>
			);
		}

		return switches;
	},
	/* #endregion */

	/* #region Event handlers */
	onTabClick: function (e) {
		e.preventDefault();
		var searchType = this.props.currentSearchType === 'New' ? 'Used' : 'New';
		this.props.onTabClick(searchType);
	}
	/* #endregion */
});