'use strict';

var keyMirror = require('keymirror');

module.exports = {
	ActionTypes: keyMirror({
		CFS_SEARCH_FILTERS_INIT_STORE_DATA: null,
		CFS_SEARCH_FILTERS_GET_LOCATION: null,

		CFS_SEARCH_FILTERS_GET_STATE: null,
		CFS_SEARCH_FILTERS_GET_STATE_SUCCESS: null,
		CFS_SEARCH_FILTERS_GET_STATE_ERROR: null,

		CFS_SEARCH_FILTERS_UPDATE_STATE: null,
		CFS_SEARCH_FILTERS_UPDATE_STATE_SUCCESS: null,
		CFS_SEARCH_FILTERS_UPDATE_STATE_ERROR: null,

		CFS_SEARCH_FILTERS_REMOVE_LOCATION: null,
		CFS_SEARCH_FILTERS_REMOVE_ITEM: null,
		CFS_SEARCH_FILTERS_REQUEST_LOCATION: null,
		CFS_SEARCH_FILTERS_REQUEST_LOCATION_SUCCESS: null,
		CFS_SEARCH_FILTERS_REQUEST_LOCATION_ERROR: null,
		CFS_SEARCH_FILTERS_SET_ITEM: null,
		CFS_SEARCH_FILTERS_SET_DISTANCE: null,
		CFS_SEARCH_FILTERS_SET_POSTCODE: null,
		CFS_SEARCH_FILTERS_SET_VALUE: null,
		CFS_SEARCH_FILTERS_SWITCH_PRICE_TYPE: null,
		CFS_SEARCH_FILTERS_UPDATE_RESULTS: null,
		CFS_SEARCH_FILTERS_VALIDATION_ERROR: null,
		CFS_SEARCH_FILTERS_RESET: null,
		CFS_SEARCH_ADVANCED_FILTERS_EXPANDED: null
	})
};