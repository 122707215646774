'use strict';

var $ = require('jquery');

var classNames = {
	screenSizeDetector:	'screen-size-detector',
	siteHeaderDesktop:	'site-header-desktop',
	mobileHeader:		'site-header-mobile',
	desktopHeader:		'site-header-desktop',
	siteFooter:			'site-footer',
	backToTopWrapper:	'back-to-top__wrapper',
	raisedModifier:		'raised'
};

var breakpoints = {
	medium: 	500,
	large: 		800,
	xlarge: 	1200,
	xxlarge: 	99999
};

var self = module.exports = {
	getBreakpoints: function () {
		return breakpoints;
	},

	getContentOffset: function (addTopMargin) {
		var result = 0;
		if (self.isTablet() || self.isDesktop()) {
			var siteHeader = $.findByBem(classNames.siteHeaderDesktop);
			if (siteHeader.length > 0) {
				result = siteHeader.outerHeight();
			}
		}

		if (addTopMargin) {
			result += 16;
		}

		return result;
	},

	getScreenSize: function () {
		var $screenSizeDetector = $.findByBem(classNames.screenSizeDetector);

		var detectorFontFamily = $screenSizeDetector.css('font-family');

		return detectorFontFamily.toUpperCase();
	},

	getHeaderHeight: function () {
		return self.isMobile() ? $.findByBem(classNames.mobileHeader).height() : $.findByBem(classNames.desktopHeader).height();
	},

	hasSkin: function () {
		return $('body').hasClass('has-skin');
	},

	isDesktop: function () {
		var screenSize = self.getScreenSize();
		return screenSize === 'XL' || screenSize === 'XXL';
	},

	isMobile: function () {
		var screenSize = self.getScreenSize();
		return screenSize === 'S' || screenSize === 'M';
	},

	isTablet: function () {
		var screenSize = self.getScreenSize();
		return screenSize === 'L';
	},

	isSmall: function () {
		var screenSize = self.getScreenSize();
		return screenSize === 'S';
	},

	raiseSiteFooter: function() {
		var $siteFooter = $.findByBem(classNames.siteFooter);
	
		if ($siteFooter) {
			$siteFooter.addBemClass(classNames.siteFooter, null, classNames.raisedModifier);
		}
	},

	toggleBackToTopVisibility: function(makeVisible) {
		var $backToTop = $.findByBem(classNames.backToTopWrapper);

		if (makeVisible) {
			$backToTop.addBemClass('show-for-xlarge-up').show();
			return;
		}

		$backToTop.removeBemClass('show-for-xlarge-up').hide();
	},

	moveBackToTopAboveFooter: function(){
		var $backToTopWrapper = $.findByBem(classNames.backToTopWrapper);

		if ($backToTopWrapper) {
			$backToTopWrapper.addBemClass(classNames.backToTopWrapper, null, classNames.raisedModifier);
		}
	}
};