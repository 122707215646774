'use strict';

var carsForSaleApi 	= require('../../api/cars-for-sale-api.js');
var location		= require('../../utils/location.js');
var logger 			= require('../../utils/logger.js');

var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/cfs-search-filters-constants.js');

var ActionTypes = Constants.ActionTypes;

function log(source, actionType, data) {
	logger.debug('CFS Search Filters', {
		message: 'Create ' + source + ' action',
		action: actionType,
		data: data
	});
}

function createServerAction(actionType, data) {
	log('server', actionType, data);
	FluxDispatcher.dispatchServerAction({
		type: actionType,
		data: data
	});
}

function createViewAction(actionType, data) {
	log('view', actionType, data);
	FluxDispatcher.dispatchViewAction({
		type: actionType,
		data: data
	});
}

function reloadResults(searchResultsUrl, updateResults, redirectImmediately) {
	if (redirectImmediately) {
		window.location = searchResultsUrl;
	}
	else {
		createViewAction(ActionTypes.CFS_SEARCH_FILTERS_UPDATE_RESULTS, {
			searchResultsUrl: searchResultsUrl,
			updateResults: updateResults
		});
	}
}

var Actions = {
	initStoreData: function (storeData) {
		createServerAction(ActionTypes.CFS_SEARCH_FILTERS_INIT_STORE_DATA, storeData);
	},

	getFilterState: function (filterRequest) {
		createServerAction(ActionTypes.CFS_SEARCH_FILTERS_GET_STATE, filterRequest);

		carsForSaleApi.search(filterRequest, {
			includeResults: false,
			successCallback: function(data) {
				createServerAction(ActionTypes.CFS_SEARCH_FILTERS_GET_STATE_SUCCESS, data);
			},
			errorCallback: function() {
				createServerAction(ActionTypes.CFS_SEARCH_FILTERS_GET_STATE_ERROR);
			}
		});
	},

	updateFilterState: function (filterRequest, dynamicallyUpdate) {
		if (dynamicallyUpdate) {
			createServerAction(ActionTypes.CFS_SEARCH_FILTERS_UPDATE_STATE, filterRequest);

			carsForSaleApi.search(filterRequest, {
				includeResults: true,
				successCallback: function(data) {
					createServerAction(ActionTypes.CFS_SEARCH_FILTERS_UPDATE_STATE_SUCCESS, data);
				},
				errorCallback: function() {
					createServerAction(ActionTypes.CFS_SEARCH_FILTERS_UPDATE_STATE_ERROR);
				}
			});
		} else {
			createServerAction(ActionTypes.CFS_SEARCH_FILTERS_GET_STATE, filterRequest);

			carsForSaleApi.search(filterRequest, {
				includeResults: false,
				successCallback: function(data) {
					createServerAction(ActionTypes.CFS_SEARCH_FILTERS_GET_STATE_SUCCESS, data);
				},
				errorCallback: function() {
					createServerAction(ActionTypes.CFS_SEARCH_FILTERS_GET_STATE_ERROR);
				}
			});
		}
	},

	resetFilters: function(resetRequest, redirectImmediately) {
		createServerAction(ActionTypes.CFS_SEARCH_FILTERS_RESET);

		carsForSaleApi.search(resetRequest, {
			includeResults: true,
			successCallback: function(data) {
				if (redirectImmediately){
					reloadResults(data.searchResultsUrl, null, redirectImmediately);
				}
				else {
					createServerAction(ActionTypes.CFS_SEARCH_FILTERS_UPDATE_STATE_SUCCESS, data);
				}
			},
			errorCallback: function() {
				createServerAction(ActionTypes.CFS_SEARCH_FILTERS_UPDATE_STATE_ERROR);
			}
		});
	},

	getLocation: function (callback) {
		createViewAction(ActionTypes.CFS_SEARCH_FILTERS_GET_LOCATION);
		location.getCurrentLocation(function (latLong) {
			createServerAction(ActionTypes.CFS_SEARCH_FILTERS_REQUEST_LOCATION_SUCCESS, {
				latLong: latLong
			});
			callback(latLong);
		}, function (errorMessage) {
			createServerAction(ActionTypes.CFS_SEARCH_FILTERS_REQUEST_LOCATION_ERROR, {
				errorMessage: errorMessage
			});
		});
	},

	removeLocation: function () {
		createViewAction(ActionTypes.CFS_SEARCH_FILTERS_REMOVE_LOCATION);
	},

	removeItem: function (name) {
		createViewAction(ActionTypes.CFS_SEARCH_FILTERS_REMOVE_ITEM, {
			name: name
		});
	},

	setAdvancedFilters: function (){
		createViewAction(ActionTypes.CFS_SEARCH_ADVANCED_FILTERS_EXPANDED, {
			showCfsAdvancedFilters: true
		});
	},

	setDistance: function (distance) {
		createViewAction(ActionTypes.CFS_SEARCH_FILTERS_SET_DISTANCE, {
			distance: distance
		});
	},

	setItem: function (name, value, text) {
		createViewAction(ActionTypes.CFS_SEARCH_FILTERS_SET_ITEM, {
			name: name,
			text: text,
			value: value
		});
	},

	setKeywords: function (keywords) {
		createViewAction(ActionTypes.CFS_SEARCH_FILTERS_SET_KEYWORDS, {
			keywords: keywords
		});
	},

	setPostcode: function (postcode, saveToCookie) {
		createViewAction(ActionTypes.CFS_SEARCH_FILTERS_SET_POSTCODE, {
			postcode: postcode,
			saveToCookie: saveToCookie
		});
	},

	switchPriceType: function(useMonthlyPrice) {
		createViewAction(ActionTypes.CFS_SEARCH_FILTERS_SWITCH_PRICE_TYPE, useMonthlyPrice);
	},

	setValue: function (name, value) {
		createViewAction(ActionTypes.CFS_SEARCH_FILTERS_SET_VALUE, {
			name: name,
			value: value
		});
	},

	updateResults: function (searchResultsUrl, updateResults) {
		reloadResults(searchResultsUrl, updateResults);
	},

	validationError: function (name, message) {
		createViewAction(ActionTypes.CFS_SEARCH_FILTERS_VALIDATION_ERROR, {
			name: name,
			message: message
		});
	},
};

module.exports = Actions;