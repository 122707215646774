/** @jsx React.DOM */'use strict'

var slider = require('../../../../components/slider.js');

var View = React.createClass({
	propTypes: {
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		sliderOptions: React.PropTypes.object,
		name: React.PropTypes.string,
		sliderType: React.PropTypes.object,
		inputFieldId:React.PropTypes.string,
		plusButtonId: React.PropTypes.string,
		minusButtonId: React.PropTypes.string,
		onValueSet: React.PropTypes.func.isRequired
	},

	componentDidMount: function () {
		this.initSlider();
	},

	componentDidUpdate: function () {
		var $slider = $('#' + this.props.name);
		$slider[0].noUiSlider.set(this.props.sliderOptions.start, false);
		
		if (this.props.isLoading || this.props.isDisabled) {
			$slider[0].setAttribute('disabled', true);
		} else {
			$slider[0].removeAttribute('disabled');
		}
	},

	render: function () {
		return (
			<div id={this.props.name} className="search-filters__slider"></div>
		);
	},

	initSlider: function () {
		var $slider = $('#' + this.props.name);

		var $inputField = $('#' + this.props.inputFieldId);
		var $plusButton = $('#' + this.props.plusButtonId);
		var $minusButton = $('#' + this.props.minusButtonId);
		
		var sliderInstance;
		switch (this.props.sliderType) {
			case slider.sliderTypes.miles:
				sliderInstance = slider.initMilesSlider($slider, this.props.sliderOptions, $inputField, null, $minusButton, $plusButton);
				break;
				
			case slider.sliderTypes.mpg:
				sliderInstance = slider.initMpgSlider($slider, this.props.sliderOptions, $inputField, null, $minusButton, $plusButton);
				break;
				
			case slider.sliderTypes.price:
				sliderInstance = slider.initPriceSlider($slider, this.props.sliderOptions, $inputField, null, $minusButton, $plusButton);
				break;
			
			case slider.sliderTypes.default:
			default:
				sliderInstance = slider.initSlider($slider, this.props.sliderOptions, $inputField, null, null, $minusButton, $plusButton);
		}

		sliderInstance.on('set', this.onSliderChange);
	},

	onSliderChange: function(value, handle) {
		var sliderValue = parseInt(value[handle])

		if (sliderValue !== this.props.sliderOptions.start) {
			this.props.onValueSet(sliderValue);
		}
	}
});

module.exports = View;