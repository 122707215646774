var logger = require('../utils/logger.js');
var Promise = require('promise');

var tcfVersion = 2;
var BauerVendorName = 'Bauer Media Group';

var eventNames = {
    uiShown: 'cmpuishown',
    userActionComplete: 'useractioncomplete',
    subsequentPageLoad: 'tcloaded'
};

// These values match those defined in the Vendor Management page on the Sourcepoint dashboard.
// Purposes have consistent preset names, while the IDs these names map to differ between vendor lists.
var purposeNames = {
	storeAndAccessInfo: 'Store and/or access information on a device',
	selectPersonalisedAds: 'Use profiles to select personalised advertising',
	createPersonalisedAdsProfile: 'Create profiles for personalised advertising'
};

function hasPurposeConsent(vendorConsents, purposeName) {
	if (!vendorConsents || !vendorConsents.consentedPurposes) {
		logger.warn('Vendor consent data is not set');
		return false;
	}

	return vendorConsents.consentedPurposes.some(function (purpose) {
			return purpose.name === purposeName;
		});
}

function hasVendorConsent(vendorConsents, vendorName) {
	if (!vendorConsents || !vendorConsents.consentedVendors) {
		logger.warn('Vendor consent data is not set');
		return false;
	}

	return vendorConsents.consentedVendors.some(function (vendor) {
		return vendor.name === vendorName;
	});
}

function addTcfApiHandler(eventStatus, callback) {
	window.__tcfapi('addEventListener', tcfVersion, function (tcData, success) {
		if (!success) {
			logger.warn('Failed to add tcf api handler');
			return;
		}

		if (tcData.eventStatus === eventNames.uiShown && eventStatus === eventNames.uiShown) {
			callback();
		}

		if (tcData.eventStatus === eventNames.userActionComplete && eventStatus === eventNames.userActionComplete) {
			callback();
		}

		if (tcData.eventStatus === eventNames.subsequentPageLoad && eventStatus === eventNames.subsequentPageLoad) {
			window.__tcfapi('removeEventListener', tcfVersion, function (success) {
				if (success) {
					callback();
				}
			}, tcData.listenerId);
		}
	});
}

function onRetrieveBauerConsent(successCallback, failureCallback) {
	window.__tcfapi('getCustomVendorConsents', tcfVersion, function (vendorConsents, success) {
		if (!success) {
			logger.warn('Failed to add bauer consent handler');
			return;
		}

		if (hasPurposeConsent(vendorConsents, purposeNames.storeAndAccessInfo) && hasVendorConsent(vendorConsents, BauerVendorName)) {
			successCallback();
		} else {
			failureCallback();
		}
	});
}

function onRetrieveVendorAndPurposeConsents(successCallback, failureCallback) {
	var hasBauerConsent = false;
	var hasStoreAndAccessInfoConsent = false;
	var hasSelectPersonalisedAds = false;
	var hasCreatePersonilisedAdsProfile = false;

	window.__tcfapi('getCustomVendorConsents', tcfVersion, function (vendorConsents, success) {
		if (!success) {
			logger.warn('Failed to add bauer consent handler');
			return;
		}

		if (hasVendorConsent(vendorConsents, BauerVendorName)) {
			hasBauerConsent = true;
		}
		
		vendorConsents.consentedPurposes.forEach(function (purpose) {
			if (purpose.name === purposeNames.storeAndAccessInfo) {
				hasStoreAndAccessInfoConsent = true;
			}

			if (purpose.name === purposeNames.selectPersonalisedAds) {
				hasSelectPersonalisedAds = true;
			}

			if (purpose.name === purposeNames.createPersonalisedAdsProfile) {
				hasCreatePersonilisedAdsProfile = true;
			}
		});

		if (hasBauerConsent && hasStoreAndAccessInfoConsent && hasSelectPersonalisedAds && hasCreatePersonilisedAdsProfile) {
			successCallback();
		} else {
			failureCallback();
		}
	});
}

function hasIABVendorConsent(vendorId) {
	return new Promise(function (resolve, reject) {
		try {
			//Ensure vendorId is not null or 0 and is a valid number
			vendorId = Number(vendorId);
			if (vendorId === null || vendorId === 0 || isNaN(vendorId)) {
				reject("Invalid vendor id");
			}

			// Fetch TC Data and check consent
			var tcDataPromise = new Promise(function (tcDataResolve, tcDataReject) {
				window.__tcfapi('getTCData', tcfVersion, function(data, success) {
					if (success) {
						tcDataResolve(data);
					} else {
						tcDataReject(new Error('Failed to get TC Data'));
					}
				});
			});
			

			tcDataPromise
				.then(
					function(tcData) {
						var result = false;

						if (tcData && tcData.vendor && tcData.vendor.consents) {
							var vendorConsents = tcData.vendor.consents;
							logger.info('Vendor consents: ' + vendorConsents);
				
							if (vendorConsents[vendorId]) {
								logger.info('Vendor ID: ' + vendorId + ' has consent.');
								result = true;
							} else {
								logger.info('Vendor ID: ' + vendorId + ' does not have consent');
							}
						} else {
							logger.error('TC Data is missing vendor or consent information');
						}
						return result;
					},
					function() {
						logger.error('Failed to get TCData');
						return false;
					})
				.then(function(success) {
					resolve(success);
				});
		} catch (err) {
			logger.error('An error occured: ', err);
			reject(err);
		}
	});
}

module.exports = {
    eventNames: eventNames,
	hasIABVendorConsent: hasIABVendorConsent,
	addTcfApiHandler: addTcfApiHandler,
	onRetrieveBauerConsent: onRetrieveBauerConsent,
	onRetrieveVendorAndPurposeConsents: onRetrieveVendorAndPurposeConsents
};