'use strict';

var $ = require('jquery');
var numeral = require('numeral');

function formatPrice(value) {
    if (isNaN(value)) {
        return value;
    }

    return '£' + numeral(value).format('0,0');
}

function adjustVanPrices(value) {
    var adjustedValuations = JSON.parse($('#adjustedValuations').val());
    var adjustmentIndex = value - 1;
    var privateSellerPrice, dealerPrice, partExPrice;

    if (adjustedValuations.length > adjustmentIndex) {
        privateSellerPrice = adjustedValuations[adjustmentIndex].privateSeller;
        dealerPrice = adjustedValuations[adjustmentIndex].dealerPrice;
        partExPrice = adjustedValuations[adjustmentIndex].partEx;
    }
    else {
        privateSellerPrice = '???';
        dealerPrice = '???';
        partExPrice = '???';
    }

    $('#privateSeller').text(formatPrice(privateSellerPrice));
    $('#dealerPrice').text(formatPrice(dealerPrice));
    $('#partEx').text(formatPrice(partExPrice));
}

module.exports = {
    update: function (value) {
        adjustVanPrices(value);
    }
};