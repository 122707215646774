'use strict';

var cookies = require('./cookie.js');

var config = require('../utils/config.js');

var cookieName = 'Parkers.Geolocation';

module.exports = {
	deleteCookie: function () {
		cookies.deleteCookie(cookieName);
	},

	getCookie: function () {
		return cookies.getCookie(cookieName);
	},

	setCookie: function (latLong) {
		cookies.setCookie(
			cookieName,
			{ latLong: latLong },
			{ domain: config.cookieDomain }
		);
	}
};