/** @jsx React.DOM */'use strict'

var slider = require('../../../components/slider.js');
var FacetSlider = require('./shared/facet-slider-view.jsx');

var name = 'selectedDeposit';
var maxDeposit = 35000;

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		filterRequest: React.PropTypes.object.isRequired,
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		onValueSet: React.PropTypes.func.isRequired
	},
	/* #endregion */

	/* #region Render methods */
	render: function() {
		var disabled = false;

		if (this.props.isLoading || this.props.isDisabled) {
			disabled = true;
		}

		var sliderInputFieldId = 'initialPaymentInput';
		var sliderPlusButtonId = 'initialPaymentPlusBtn';
		var sliderMinusButtonId = 'initialPaymentMinusBtn';

		return (
			<div className="search-filters__slider-facet">
				<div className="search-filters__slider-facet__input">
					<a id={sliderMinusButtonId} disabled={disabled} onClick={this.onSliderButtonClick} className="search-filters__slider-facet__input__button search-filters__slider-facet__input__button--decrement button"></a>
					<input id={sliderInputFieldId} type="number" disabled={disabled} className="search-filters__slider-facet__input__input-field"/>
					<a id={sliderPlusButtonId} disabled={disabled} onClick={this.onSliderButtonClick} className="search-filters__slider-facet__input__button search-filters__slider-facet__input__button--increment button"></a>
				</div>

				<div className="search-filters__leasing-filters__label">
					<span className="search-filters__leasing-filters__label__title">Max Initial Payment</span>
					<span className="search-filters__leasing-filters__label__description">How much do you want to pay up front?</span>
				</div>
				
				<div className="search-filters__slider-facet__slider">
					<FacetSlider
						isLoading={this.props.isLoading}
						isDisabled={this.props.isDisabled}
						sliderOptions={this.getSliderOptions()}
						name={name}
						sliderType={slider.sliderTypes.price}
						inputFieldId={sliderInputFieldId}
						plusButtonId={sliderPlusButtonId}
						minusButtonId={sliderMinusButtonId}
						onValueSet={this.onSliderSet} />
				</div>
			</div>
		);
	},

	onSliderButtonClick: function(e) {
		e.preventDefault();
	},

	onSliderSet: function(value) {
		if (value === maxDeposit) {
			value = null;
		}

		this.props.onValueSet(name, value);
	},

	getSliderOptions: function() {
		var selectedDeposit = this.props.filterState.selectedDeposit;
		
		var result = {
			connect: false,
			range: {
				'min': 0,
				'max': maxDeposit
			},
			step: 500,
			start: !selectedDeposit && selectedDeposit !== 0 ? maxDeposit : this.props.filterState.selectedDeposit
		};
		
		return result;
	}
	/* #endregion */
});