'use strict';

var logger = require('../utils/logger.js');

var popStateCallbacks = [];

window.onpopstate = function (e) {
	logger.debug('Popstate event', e.state);

	var index, callback;
	for (index = 0; index < popStateCallbacks.length; index++) {
		callback = popStateCallbacks[index];
		if (typeof callback === 'function') {
			callback(e.state);
		}
	}
};

module.exports = {
	onStateChange: function (func) {
		popStateCallbacks.push(func);
	},

	setState: function (data) {
		var result = false;

		if (window.history && window.history.replaceState) {
			result = true;
			window.history.replaceState(data, null);
			logger.debug('Set history state', data);
		}

		return result;
	},

	setUrl: function (url, replaceState, title, data) {
		var result = false;

		if ((url && url !== window.location.url) || title || data) {
			if (window.history && window.history.pushState) {
				result = true;

				if (replaceState) {
					window.history.replaceState(data, title, url);
				}
				else {
					window.history.pushState(data, title, url);
				}

				logger.debug('Set history url', {
					url: url,
					title: title,
					data: data
				});
			}

			if (title) {
				// some browsers don't support setting title via pushState
				document.title = title;
			}
		}

		return result;
	}
};