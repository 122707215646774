'use strict';

var config = require('../utils/config.js');
var api = require('../api/graph-api.js');
var isBot = require('isbot');

function getUser() {
    var user = window.localStorage ? window.localStorage.parkersUser : null;

    if (!user) {
        var date = new Date();
        user = "parkers-user_" + date.getTime() + "_" + Math.random();
        if (window.localStorage) {
            window.localStorage.parkersUser = user;
        }
    }

    return user;
}

module.exports = {
    enqueue: function() {
        var isbot = isBot(window.navigator.userAgent);

        if (config.graph && !isbot) {
            config.graph.userId = getUser();

            if (config.graph.userId) {
                api.enqueue(config.graph);
            }
        }
    }
};