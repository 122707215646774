'use strict';

var assign 	= require('object-assign');
var flux 	= require('flux');

var logger = require('../../utils/logger.js');

var FluxConstants = require('../constants/flux-constants.js');

var PayloadSources = FluxConstants.PayloadSources;

function log(source, action) {
	logger.debug('Flux Dispatcher', {
		message: 'Dispatch ' + source + ' action',
		action: action.type,
		data: action.data
	});
}

var FluxDispatcher = assign(new flux.Dispatcher(), {  
	dispatchServerAction: function (action) {
		var payload = {
			source: PayloadSources.SERVER_ACTION,
			action: action
		};

		log('server', action);
		this.dispatch(payload);
	},

	dispatchViewAction: function (action) {
		var payload = {
			source: PayloadSources.VIEW_ACTION,
			action: action
		};

		log('view', action);
		this.dispatch(payload);
	}
});

module.exports = FluxDispatcher;