'use strict';

var logger 			= require('../../utils/logger.js');
var quickFindApi 	= require('../../api/for-sale-or-lease-quick-find-api.js');

var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants		= require('../constants/for-sale-or-lease-quick-find-constants.js');

var ActionTypes = Constants.ActionTypes;

function log(source, actionType, data, instance) {
	logger.debug('For Sale or Lease Quick Find', {
		message: 'Create ' + source + ' action',
		action: actionType,
		instance: instance,
		data: data
	});
}

function createServerAction(actionType, data, instance) {
	log('server', actionType, data, instance);
	FluxDispatcher.dispatchServerAction({
		type: actionType,
		instance: instance,
		data: data
	});
}

function createViewAction(actionType, data, instance) {
	log('view', actionType, data, instance);
	FluxDispatcher.dispatchViewAction({
		type: actionType,
		instance: instance,
		data: data
	});
}

var Actions = {
	initStoreData: function (filterName, searchTypeKey, instance) {
		createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_INIT_STORE_DATA, {
			filterName: filterName,
			searchTypeKey: searchTypeKey
		}, instance);
	},

	loadManufacturers: function (vehicleType, filterName, searchType, filters, instance) {
		createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MANUFACTURERS, null, instance);
		quickFindApi.getManufacturers(vehicleType, filterName, searchType, filters, {
			successCallback: function (data) {
				if (data) {
					createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MANUFACTURERS_SUCCESS, data, instance);
				} else {
					createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MANUFACTURERS_ERROR, null, instance);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MANUFACTURERS_ERROR, null, instance);
			}
		});
	},

	loadRanges: function (vehicleType, filterName, searchType, manufacturerKey, filters, instance) {
		createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_RANGES, null, instance);
		quickFindApi.getRangesForManufacturer(vehicleType, filterName, searchType, manufacturerKey, filters, {
			successCallback: function (data) {
				if (data) {
					createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_RANGES_SUCCESS, data, instance);
				} else {
					createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_RANGES_ERROR, null, instance);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_RANGES_ERROR, null, instance);
			}
		});
	},

	loadModels: function (vehicleType, filterName, searchType, manufacturerKey, rangeKey, filters, instance) {
		createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MODELS, null, instance);
		quickFindApi.getModelsForRange(vehicleType, filterName, searchType, manufacturerKey, rangeKey, filters, {
			successCallback: function (data) {
				if (data) {
					createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MODELS_SUCCESS, data, instance);
				} else {
					createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MODELS_ERROR, null, instance);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MODELS_ERROR, null, instance);
			}
		});
	},

	loadSearchUrl: function(vehicleType, filterName, searchType, manufacturerKey, rangeKey, modelKey, filters, instance) {
		createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_URL, null, instance);
		quickFindApi.getSearchUrl(vehicleType, filterName, searchType, manufacturerKey, rangeKey, modelKey, filters, {
			successCallback: function (data) {
				if (data) {
					createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_URL_SUCCESS, data, instance);
				} else {
					createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_URL_ERROR, null, instance);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_URL_ERROR, null, instance);
			}
		});
	},

	selectSearchType: function(filterName, searchTypeKey, instance) {
		createViewAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_SEARCH_TYPE, {
			filterName: filterName,
			searchTypeKey: searchTypeKey
		}, instance);
	},

	selectManufacturer: function (manufacturerKey, instance) {
		createViewAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_MANUFACTURER, {
			manufacturerKey: manufacturerKey
		}, instance);
	},

	selectRange: function (rangeKey, instance) {
		createViewAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_RANGE, {
			rangeKey: rangeKey
		}, instance);
	},

	selectFromPrice: function(price, instance) {
		createViewAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_FROM_PRICE, {
			fromPrice: price
		}, instance);
	},

	selectToPrice: function(price, instance) {
		createViewAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_TO_PRICE, {
			toPrice: price
		}, instance);
	},

	selectModel: function (modelKey, instance) {
		createViewAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_MODEL, {
			modelKey: modelKey
		}, instance);
	},

	setPostcode: function(postcode, saveToCookie, instance) {
		createViewAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SET_POSTCODE, {
			postcode : postcode,
			saveToCookie: saveToCookie
		}, instance);
	},

	submitForm: function (searchUrl) {
		window.location = searchUrl;
	},

	validationError: function (error, instance) {
		createViewAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_VALIDATION_ERROR, error, instance);
	},

	// Obsolete as part of postcode test, leaving here to revert after test if required
	postcodeValidationError: function (postcode, instance) {
		createViewAction(ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_POSTCODE_VALIDATION_ERROR, postcode, instance);
	}
};

module.exports = Actions;