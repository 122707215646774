/** @jsx React.DOM */'use strict';

var $ = require('jquery');
var React = require('react');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		onSwitch: React.PropTypes.func.isRequired,
		isMonthlySelected: React.PropTypes.bool,
	},

	getDefaultProps: function() {
		return {
			isMonthlySelected: false,
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var monthlyCssClass = 'toggle-switch__switch search-filters__search-type__price-type';
		var priceCssClass = 'toggle-switch__switch search-filters__search-type__price-type';

		if (this.props.isMonthlySelected) {
			monthlyCssClass  += ' toggle-switch__switch--active';
		} else {
			priceCssClass += ' toggle-switch__switch--active';			
		}

		return (
			<section className="toggle-switch search-filters__search-type">
				<h3 className="show-for-sr">Price type:</h3>
				<div className="toggle-switch__switches">
					<span href="#" className={priceCssClass} onClick={this.onPriceClick}>Total Price</span>
					<span href="#" className={monthlyCssClass} onClick={this.onMonthlyClick}>Monthly Price</span>
				</div>
			</section>
		);
	},
	/* #endregion */

	/* #region Event handlers */
	onPriceClick: function () {
		this.props.onSwitch(false);
	},

	onMonthlyClick: function () {
		this.props.onSwitch(true);
	}
	/* #endregion */
});