/** @jsx React.DOM */'use strict';

var $ = require('jquery');
var React = require('react');

var numeral = require('numeral');
var slider = require('../../../components/slider.js');

var classNames = {
	carChooser: 'car-chooser'
};

var priceSliderInstance;

var priceOptions = [0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 12000, 14000, 16000, 18000, 20000,
		25000, 30000, 35000, 40000, 45000, 50000, 60000, 70000, 80000, 90000, 100000, 120000, 140000, 160000, 200000, 240000];

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		onPriceSet: React.PropTypes.func.isRequired,
		selectedMinPrice: React.PropTypes.number.isRequired,
		selectedMaxPrice: React.PropTypes.number.isRequired,
		isDisabled: React.PropTypes.bool
	},

	getDefaultProps: function() {
		return {
			selectedMinPrice: 0,
			selectedMaxPrice: 240000,
			isDisabled: false
		};
	},

	componentDidMount: function () {
		this.initSlider();
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var minPrice = parseInt(this.props.selectedMinPrice);
		var maxPrice = parseInt(this.props.selectedMaxPrice);

		var minPriceIndex = priceOptions.indexOf(minPrice);
		var maxPriceIndex = priceOptions.indexOf(maxPrice);

		var minOptions = [];
		var maxOptions = [];

		for (var index = 0; index <= maxPriceIndex; index++)
		{
			minOptions.push(this.getOption(priceOptions[index]));
		}

		for (var index = minPriceIndex; index < priceOptions.length; index++)
		{
			maxOptions.push(this.getOption(priceOptions[index], index === priceOptions.length - 1 ? '+' : ''));
		}

		return (
			<div className="car-chooser__price-range">
				<div className="car-chooser__price-range__slider-container show-for-medium-up">
					<div className="car-chooser__price-range__slider slider slider--split-tooltip" disabled={this.props.isDisabled}></div>
				</div>
				<div className="hide-for-medium-up">
					<div>
						<label htmlFor="ddlPriceMin">From</label>
						<select id="ddlPriceMin" name="priceMin" className="car-chooser__price-range__min-list" onChange={this.onMinPriceChange} disabled={this.props.isDisabled}>
							{minOptions}
						</select>
					</div>
					<div>
						<label htmlFor="ddlPriceMax">To</label>
						<select id="ddlPriceMax" name="priceMax" className="car-chooser__price-range__max-list" onChange={this.onMaxPriceChange} disabled={this.props.isDisabled}>
							{maxOptions}
						</select>
					</div>
				</div>
			</div>
		);
	},

	getOption: function (value, suffix) {
		suffix = suffix || '';
		var text = value !== '' ? '£' + numeral(value).format('0,0') + suffix : '-';

		return <option key={value} value={value}>{text}</option>;
	},
	/* #endregion */

	/* #region Event handlers */
	initSlider: function () {
		var $priceRangeSlider = $.findByBem(classNames.carChooser, 'price-range__slider');
		var $priceMinSelect = $.findByBem(classNames.carChooser, 'price-range__min-list');
		var $priceMaxSelect = $.findByBem(classNames.carChooser, 'price-range__max-list');

		if ($priceRangeSlider.length > 0 && $priceMinSelect.length > 0 && $priceMaxSelect.length > 0) {
			var min = this.props.selectedMinPrice > 0 ? this.props.selectedMinPrice : 0;
			var max = this.props.selectedMaxPrice < 240000 ? this.props.selectedMaxPrice : 0;

			var placeholderText;
			if (!min && !max) {
				placeholderText = [ '£0', '£240,000+' ];
			} else if (!min) {
				placeholderText = [ '£0', null ];
			} else if (!max) {
				placeholderText = [ null, '£240,000+' ];
			}

			var sliderOptions = {
				connect: true,
				placeholderText: placeholderText,
				range: {
					'min': [ 0 ],
					'25%': [ 10000, 2000 ],
					'40%': [ 20000, 5000 ],
					'60%': [ 50000, 10000 ],
					'80%': [ 100000, 20000 ],
					'90%': [ 160000, 40000 ],
					'max': [ 240000 ]
				},
				step: 1000,
				start: [ (min || 0), (max || 240000) ]
			};

			var tooltipFormatter =  {
				from: function (value) {
					return numeral().unformat(value);
				},
				to: function (value) {
					if (value === 240000) {
						return '£' + numeral(value).format('0,0') + '+';
					} else {
						return '£' + numeral(value).format('0,0');
					}
				}
			};

			priceSliderInstance = slider.init($priceRangeSlider, sliderOptions, $priceMinSelect, $priceMaxSelect, tooltipFormatter);

			var onPriceSetFunc = this.props.onPriceSet;

			priceSliderInstance.on('change', function (value, handle) {
				var prices  = {
					min: value[0],
					max: value[1]
				};

				onPriceSetFunc(prices);
			});
		}
	},

	onMaxPriceChange: function (e) {
		var prices  = {
			min: this.props.selectedMinPrice,
			max: e.target.value
		};

		this.props.onPriceSet(prices);
	},

	onMinPriceChange: function (e) {
		var prices  = {
			min: e.target.value,
			max: this.props.selectedMaxPrice
		};

		this.props.onPriceSet(prices);
	}
	/* #endregion */
});

