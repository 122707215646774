/** @jsx React.DOM */'use strict';

var $ 		= require('jquery');
var React 	= require('react');
var numeral = require('numeral');

var View = React.createClass({
	/* #region React functions */
	propTypes: {
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		items: React.PropTypes.array,
		label: React.PropTypes.string.isRequired,
		name: React.PropTypes.string.isRequired,
		selectedItem: React.PropTypes.object,
		selectedItemPrefix: React.PropTypes.string,
		title: React.PropTypes.string.isRequired,
		onRemove: React.PropTypes.func.isRequired,
		onSet: React.PropTypes.func.isRequired
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		if (this.props.selectedItem) {
			return this.renderSelectedValue();
		} else if (this.props.items && this.props.items.length === 1) {
			return this.renderSingleValue();
		} else {
			return this.renderList();
		}
	},

	renderList: function () {
		var linkClass = 'search-filters__flyout__open-link';
		var listClass = 'search-filters__flyout__list', listItems;

		if (this.props.isLoading || this.props.isDisabled || !this.props.items || this.props.items.length === 0) {
			linkClass += ' search-filters__flyout__open-link--disabled';
		} else {
			listItems = this.props.items.map(this.renderOption);
			if (this.props.items.length > 10) {
				listClass += ' search-filters__flyout__list--three-column'
			} else if (this.props.items.length > 5) {
				listClass += ' search-filters__flyout__list--two-column'
			}
		}

		return (
			<div className="search-filters__flyout">
				<div className={linkClass}>
					<span className="search-filters__flyout__open-link__icon icon-16x16-chevron"></span>
					<span className="search-filters__flyout__open-link__label">{this.props.label}</span>
					<span className="search-filters__flyout__open-link__value">Any</span>

				</div>
				<div className="search-filters__flyout__panel">
					<div className="search-filters__flyout__panel__header">
						<div className="search-filters__flyout__panel__title">
							{this.props.title}
						</div>
						<a href="#" className="search-filters__flyout__panel__close-link">
							<span className="icon icon-gap-right fi-x"></span>
							Close
						</a>
					</div>
					<ul className={listClass}>
						{listItems}
					</ul>
				</div>
			</div>
		);
	},

	renderOption: function (item) {
		var formattedCount = numeral(item.count).format('0,0');
		return (
			<li key={item.value} className="search-filters__flyout__list__option">
				<a href="#" data-value={item.value} onClick={this.onOptionClick}>
					<span className="search-filters__flyout__list__option__name">{item.text}</span>
					<span className="search-filters__flyout__list__option__count">({formattedCount})</span>
				</a>
			</li>
		);
	},

	renderSelectedValue: function () {
		var selectedItemText = this.props.selectedItemPrefix ?
			this.props.selectedItemPrefix + ' ' + this.props.selectedItem.text :
			this.props.selectedItem.text;

		var disabled = this.props.isLoading || this.props.isDisabled;

		return (
			<div className="search-filters__flyout">
				<span className="search-filters__flyout__selected">
					{selectedItemText}
					<a href="#" className="search-filters__flyout__selected__remove-link" disabled={disabled} title="Remove" onClick={this.onRemoveClick}>
						Remove
					</a>
				</span>
			</div>
		);
	},

	renderSingleValue: function () {
		var onlyFacetItem = this.props.items[0];

		return (
			<div className="search-filters__flyout">
				<span className="search-filters__flyout__selected">
					{onlyFacetItem.text}
				</span>
			</div>
		);
	},
	/* #endregion */

	/* #region Event handlers */
	onOptionClick: function (e) {
		e.preventDefault();
		if (!this.props.isLoading) {
			var $link = $(e.target).closest('a');
			var value = $link.attr('data-value');
			var text = $link.children('.search-filters__flyout__list__option__name').text();
			this.props.onSet(this.props.name, value, text);
		}
	},

	onRemoveClick: function (e) {
		e.preventDefault();
		if (!this.props.isLoading) {
			this.props.onRemove(this.props.name);
		}
	}
	/* #endregion */
});

module.exports = View;