'use strict';

var assign 	= require('object-assign');

var arrays	= require('../../utils/arrays.js');
var logger	= require('../../utils/logger.js');
var postcodeHelper	= require('../../utils/postcode-helper.js');
var user			= require('../../profile/user.js');

var FluxChangeStore	= require('./flux-change-store.js');
var FluxConstants 	= require('../constants/flux-constants.js');
var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/for-sale-or-lease-quick-find-constants.js');

var ActionTypes 	= Constants.ActionTypes;
var PayloadSources = FluxConstants.PayloadSources;

var storeData = {
	isLoading: [],
	manufacturers: [],
	ranges: [],
	rangeYears: [],
	models: [],
	toPrices: [],
	fromPrices: [],

	selectedFilterName: [],
	selectedSearchType: [],
	selectedManufacturer: [],
	selectedRange: [],
	selectedModel: [],
	selectedPostcode: null,
	selectedFromPrice: [],
	selectedToPrice: [],


	searchUrl: [],

	isPostcodeRequired: [],
	postcodeValidationError: null,

	validationErrors: []
};

var prices = {
	'for-sale': {
		price: [
			0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000,
			10000, 12000, 14000, 16000, 18000,
			20000, 25000, 30000, 35000, 40000, 45000,
			50000, 60000, 70000, 80000, 90000,
			100000, 120000, 140000, 160000, 180000,
			200000, 240000]
	},
	'leasing': {
		price: [0, 100, 200, 300, 400, 500, 700, 1000]
	}
};

function setDefaultPrices(instance) {
	storeData.toPrices[instance] = [];
	storeData.fromPrices[instance] = [];

	var filterPrices = prices[storeData.selectedFilterName[instance]].price;

	if (filterPrices && filterPrices.length > 0) {
		var lastPriceIndex = filterPrices.length - 1;

		for (var index = 0; index <= lastPriceIndex; index++) {
			var currentPrice = filterPrices[index];

			if (index === 0) {
				storeData.fromPrices[instance].push(currentPrice);
			} else if (index === lastPriceIndex) {
				storeData.toPrices[instance].push(currentPrice);
			} else {
				storeData.fromPrices[instance].push(currentPrice);
				storeData.toPrices[instance].push(currentPrice);
			}
		}
	}
}

function getPricesFrom(price, instance) {
	var result = [];

	var filterPrices = prices[storeData.selectedFilterName[instance]].price;

	if (!filterPrices || filterPrices.length === 0) {
		return;
	}

	for (var index = 0; index < filterPrices.length; index++) {
		var currentPrice = filterPrices[index];

		if (currentPrice > price) {
			result.push(currentPrice);
		}
	}

	return result;
}

function getPricesTo(price, instance) {
	var result = [];
	var filterPrices = prices[storeData.selectedFilterName[instance]].price;

	if (!filterPrices || filterPrices.length === 0) {
		return;
	}
	
	for (var index = 0; index < filterPrices.length; index++) {
		var currentPrice = filterPrices[index];

		if (currentPrice < price) {
			result.push(currentPrice);
		}
	}

	return result;
}

function setSelectedSearchType(filterName, searchTypeKey, instance) {
	storeData.selectedFilterName[instance] = filterName;
	storeData.selectedSearchType[instance] = searchTypeKey;
	return true;
}

function setSelectedManufacturer(manufacturerKey, instance) {
	var item = arrays.findArrayItemByField(storeData.manufacturers[instance], 'key', manufacturerKey);

	if (item !== null) {
		storeData.selectedManufacturer[instance] = item;
		storeData.ranges[instance] = item.ranges || [];
		storeData.models[instance] = item.models || [];
		return true;
	}

	return false;
}

function setSelectedRange(rangeKey, instance) {
	var item = arrays.findArrayItemByField(storeData.ranges[instance], 'key', rangeKey);

	if (item !== null) {
		storeData.selectedRange[instance] = item;
		storeData.models[instance] = item.models || [];
		return true;
	}

	return false;
}

function setSelectedModel(modelKey, instance) {
	var item = arrays.findArrayItemByField(storeData.models[instance], 'key', modelKey);

	if (item !== null) {
		storeData.selectedModel[instance] = item;
		return true;
	}

	return false;
}

function setSelectedFromPrice(price, instance) {
	storeData.selectedFromPrice[instance] = price;
	storeData.toPrices[instance] = getPricesFrom(price, instance);
}

function setSelectedToPrice(price, instance) {
	storeData.selectedToPrice[instance] = price;
	storeData.fromPrices[instance] = getPricesTo(price, instance);
}

function getPostcodeError(postcode, instance) {
	var errorText = null;
	var isMandatoryPostcodeMissing = storeData.isPostcodeRequired[instance] && !postcode;

	var isPostcodeInvalid = postcode && !postcodeHelper.isValid(postcode);

	if (isPostcodeInvalid) {
		errorText = "The postcode is invalid.";
	}
	else if (isMandatoryPostcodeMissing) {
		errorText = "A postcode is required.";
	}

	return errorText;
}

var Store = assign(new FluxChangeStore(), {
	getState: function () {
		return storeData;
	}
});

function handleServerAction(action) {
	if (action) {
		var handled = false;

		switch(action.type) {
			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_INIT_STORE_DATA:
				handled = true;

				setSelectedSearchType(action.data.filterName, action.data.searchTypeKey, action.instance);
				setDefaultPrices(action.instance);
				storeData.isLoading[action.instance] = true;
				storeData.selectedPostcode = user.getPostcode();

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MANUFACTURERS:
				handled = true;
				storeData.isLoading[action.instance] = true;
				storeData.manufacturers[action.instance] = [];
				storeData.ranges[action.instance] = [];
				storeData.rangeYears[action.instance] = [];

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MANUFACTURERS_SUCCESS:
				handled = true;
				storeData.isLoading[action.instance] = false;
				storeData.manufacturers[action.instance] = action.data.options;
				storeData.searchUrl[action.instance] = action.data.searchUrl;
				storeData.isPostcodeRequired[action.instance] = action.data.isPostcodeRequired;

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MANUFACTURERS_ERROR:
				handled = true;
				storeData.isLoading[action.instance] = false;
				storeData.manufacturers[action.instance] = [];

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_RANGES:
				handled = true;
				storeData.rangeYears[action.instance] = [];
				storeData.selectedModel[action.instance] = null;

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_RANGES_SUCCESS:
				handled = true;
				// check that the response is the one we are expecting - stops overlapping requests generating an invalid state
				if (storeData.selectedManufacturer[action.instance] !== null) {
					storeData.ranges[action.instance] = action.data.options;
					storeData.searchUrl[action.instance] = action.data.searchUrl;

					if (storeData.selectedRange[action.instance] !== null) {
						// if range selected by user before load ranges returns, reset selected range to get range years
						setSelectedRange(storeData.selectedRange[action.instance].key, action.instance);

						if (storeData.initialModelKey) {
							// pre-select the model
							if (setSelectedModel(storeData.initialModelKey, action.instance)) {
								storeData.initialModelKey = null;
							}
						} else if (storeData.rangeYears[action.instance].length === 1 && storeData.rangeYears[action.instance][0].models.length === 1) {
							// only one model, select it automatically
							setSelectedModel(storeData.rangeYears[action.instance][0].models[0].key, action.instance);
						}
					}
				}

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_MODELS_SUCCESS:
				handled = true;
				// check that the response is the one we are expecting - stops overlapping requests generating an invalid state
				if (storeData.selectedManufacturer[action.instance] !== null) {
					storeData.models[action.instance] = action.data.options;
					storeData.searchUrl[action.instance] = action.data.searchUrl;

					if (storeData.selectedModel[action.instance] !== null) {
						// if range selected by user before load ranges returns, reset selected range to get range years
						setSelectedModel(storeData.selectedModel[action.instance].key, action.instance);
					}
				}

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_RANGES_ERROR:
				handled = true;
				storeData.rangeYears[action.instance] = [];

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_URL:
				handled = true;
				storeData.searchUrl[action.instance] = null;

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_URL_SUCCESS:
				handled = true;

				// If make, range, model are selected along price range the search url will be in action.data
				// Otherwise the search Url will be in action.data.searchUrl
				storeData.searchUrl[action.instance] = action.data.searchUrl ? action.data.searchUrl : action.data;

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_LOAD_SEARCH_URL_ERROR:
				handled = true;
				storeData.searchUrl[action.instance] = null;

				break;
		}

		if (handled) {
			logger.debug('For Sale or lease Quick Find', {
				message: 'Handle server action',
				action: action.type,
				instance: action.instance,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

function handleViewAction(action) {
	if (action) {
		var handled = false;

		switch (action.type) {
			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_SEARCH_TYPE:
				handled = true;
				storeData.manufacturers[action.instance] = [];
				storeData.selectedManufacturer[action.instance] = null;
				storeData.selectedRange[action.instance] = null;
				storeData.ranges[action.instance] = [];
				storeData.rangeYears[action.instance] = [];
				storeData.selectedModel[action.instance] = null;
				storeData.models[action.instance] = [];
				storeData.selectedManufacturer[action.instance] = null;
				storeData.selectedRange[action.instance] = null;
				storeData.selectedModel[action.instance] = null;
				storeData.selectedPostcode= user.getPostcode();
				storeData.selectedToPrice[action.instance] = null;
				storeData.selectedFromPrice[action.instance] = null;
				storeData.validationErrors[action.instance] = {};

				setSelectedSearchType(action.data.filterName, action.data.searchTypeKey, action.instance);
				setDefaultPrices(action.instance);

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_MANUFACTURER:
				handled = true;
				storeData.selectedRange[action.instance] = null;
				storeData.ranges[action.instance] = [];
				storeData.rangeYears[action.instance] = [];
				storeData.selectedModel[action.instance] = null;
				storeData.models[action.instance] = [];
				setDefaultPrices(action.instance);
				storeData.selectedPostcode = user.getPostcode();
				storeData.selectedToPrice[action.instance] = null;
				storeData.selectedFromPrice[action.instance] = null;
				storeData.validationErrors[action.instance] = {};

				setSelectedManufacturer(action.data.manufacturerKey, action.instance);

				if (storeData.ranges.length === 1) {
					// only one range, select it automatically
					setSelectedRange(storeData.ranges[action.instance][0].key, action.instance);
				}

				break;
			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_RANGE:
				handled = true;
				storeData.selectedModel[action.instance] = null;
				storeData.models[action.instance] = [];
				setDefaultPrices(action.instance);
				storeData.selectedPostcode = user.getPostcode();
				storeData.selectedToPrice[action.instance] = null;
				storeData.selectedFromPrice[action.instance] = null;
				storeData.validationErrors[action.instance] = {};

				setSelectedRange(action.data.rangeKey, action.instance);

				if (storeData.rangeYears[action.instance].length === 1 && storeData.rangeYears[action.instance][0].models.length === 1) {
					// only one model, select it automatically
					setSelectedModel(storeData.rangeYears[action.instance][0].models[0].key, action.instance);
				}

				break;
			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_MODEL:
				handled = true;
				setDefaultPrices(action.instance);
				storeData.selectedPostcode = user.getPostcode();
				storeData.selectedToPrice[action.instance] = null;
				storeData.selectedFromPrice[action.instance] = null;
				storeData.validationErrors[action.instance] = {};

				setSelectedModel(action.data.modelKey, action.instance);

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SET_POSTCODE:
				handled = true;
				storeData.selectedPostcode = action.data.postcode;
				storeData.postcodeValidationError = null;

				if (action.data.saveToCookie) {
					storeData.postcodeValidationError = getPostcodeError(action.data.postcode, action.instance);

					if (!storeData.selectedPostcode || postcodeHelper.isValid(storeData.selectedPostcode)) {
						user.savePostcode(storeData.selectedPostcode);
					}
				}

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_FROM_PRICE:
				handled = true;
				setSelectedFromPrice(action.data.fromPrice, action.instance);
				storeData.validationErrors[action.instance] = {};

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_SELECT_TO_PRICE:
				handled = true;
				setSelectedToPrice(action.data.toPrice, action.instance);
				storeData.validationErrors[action.instance] = {};

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_VALIDATION_ERROR:
				handled = true;
				assign(storeData.validationErrors[action.instance], action.data);

				break;

			case ActionTypes.FOR_SALE_OR_LEASE_QUICK_FIND_POSTCODE_VALIDATION_ERROR:
				handled = true;
				storeData.postcodeValidationError = getPostcodeError(action.data, action.instance);

				break;
		}

		if (handled) {
			logger.debug('For Sale or Lease Quick Find', {
				message: 'Handle view action',
				action: action.type,
				instance: action.instance,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

Store.dispatchToken = FluxDispatcher.register(function (payload) {
	if (payload && payload.source) {
		if (payload.source === PayloadSources.SERVER_ACTION) {
			handleServerAction(payload.action);
		} else if (payload.source === PayloadSources.VIEW_ACTION) {
			handleViewAction(payload.action);
		}
	}
});

module.exports = Store;