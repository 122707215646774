var $ = require('jquery');

module.exports = {
	init: function() {
		var $clickableElements = $.findByBem('stop-propogation');
		if ($clickableElements.length > 0) {
			$($clickableElements).on('click', function(e) {
				e.stopPropagation();
			});
		}
	}
};