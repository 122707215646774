/** @jsx React.DOM */'use strict';

var $ = require('jquery');
var React = require('react');

var scroll = require('../../../ui/scroll.js');

var classNames = {
	carChooser: 'car-chooser',
};

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		onDisplayToggle: React.PropTypes.func,
		expand: React.PropTypes.bool,
		contentPanelClassName: React.PropTypes.string
	},

	getDefaultProps: function() {
		return {
			onDisplayToggle: React.PropTypes.func,
			expand: true
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var displayToggle;

		if (this.props.expand) {
			displayToggle = (
				<a className="display-toggle display-toggle--shrink hide-for-large-up" onClick={this.onShrinkClick} href="#"><span className="display-toggle__text"><span>Hide options</span></span></a>
			);
		} else {
			displayToggle = (
				<a className="display-toggle display-toggle--expand hide-for-large-up" onClick={this.onExpandClick} href="#"><span className="display-toggle__text"><span>See options</span></span></a>
			);
		}

		return displayToggle;
	},
	/* #endregion */

	/* #region Event handlers */
	onExpandClick: function (e) {
		e.preventDefault();
		var $panel = $.findByBem(classNames.carChooser, this.props.contentPanelClassName);
		var toggleFunction = this.props.onDisplayToggle;

		$panel.slideDown(500, function () {
			if (toggleFunction) {
				toggleFunction(true);
			}
		});
	},

	onShrinkClick: function (e) {
		e.preventDefault();
		var $panel = $.findByBem(classNames.carChooser, this.props.contentPanelClassName);
		var toggleFunction = this.props.onDisplayToggle;
		var $toggle = $(e.target);

		$panel.slideUp(500, function () {
			scroll.scrollIntoView($toggle, 'fast');

			if (toggleFunction) {
				toggleFunction(false);
			}
		});
	}
	/* #endregion */
});
