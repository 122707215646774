'use strict';

var geolocationCookie = require('../storage/geolocation-cookie.js');
var logger = require('./logger.js');

var geolocationRequestTimeout = 30000;

module.exports = {
	clearLocation: function () {
		geolocationCookie.deleteCookie();
	},

	getCurrentLocation: function (successCallback, errorCallback) {
		if (window.navigator && window.navigator.geolocation) {
			var requestTimeoutTimer;
			logger.debug('Geolocation requested');
			this.clearLocation();

			window.navigator.geolocation.getCurrentPosition(function (position) {
				logger.debug('Geolocation response', position);
				window.clearTimeout(requestTimeoutTimer);
				if (position.coords) {
					if (successCallback && (typeof successCallback === 'function')) {
						var latLong = {
							latitude: position.coords.latitude,
							longitude: position.coords.longitude,
							source: 'Browser',
							isValid: true
						};
						geolocationCookie.setCookie(latLong);
						successCallback(latLong);
					}
				} else {
					logger.debug('Geolocation co-ordinats not available');
					if (errorCallback && (typeof errorCallback === 'function')) {
						errorCallback('Location information unavailable');
					}
				}
			}, function (error) {
				logger.debug('Geolocation error', error.code);
				window.clearTimeout(requestTimeoutTimer);
				var errorMessage;
				switch (error.code) {
					case error.PERMISSION_DENIED:
						errorMessage = 'Access to location denied';
						break;
					case error.POSITION_UNAVAILABLE:
						errorMessage = 'Location information unavailable';
						break;
					case error.TIMEOUT:
						errorMessage = 'Location request timed out';
						break;
					case error.UNKNOWN_ERROR:
						errorMessage = 'An unknown error occurred';
						break;
				}
				if (errorCallback && (typeof errorCallback === 'function')) {
					errorCallback(errorMessage, error);
				}
			}, {
				timeout: geolocationRequestTimeout
			});

			// not all browsers support the build-in timeout, create our own timer
			requestTimeoutTimer = window.setTimeout(function () {
				logger.debug('Geolocation request timed out');
				if (errorCallback && (typeof errorCallback === 'function')) {
					errorCallback('Location request timed out');
				}
			}, geolocationRequestTimeout);
		} else {
			logger.debug('Geolocation not supported by browser');
		}
	},

	getSavedLocation: function () {
		var cookie = geolocationCookie.getCookie();

		if (cookie) {
			return cookie.latLong;
		}

		return null;
	}
};