'use strict';

var $ = require('jquery');
var analytics = require('../utils/analytics.js');
var images = require('../ui/images.js');
var royalSlider = require('../../royalslider.js');

var classNames = {
	forSaleFeaturedCars: 'cfs-featured-cars',
	leasingFeaturedCars: 'leasing-featured-cars',
	featuredCarousel: 'featured-carousel',
	carouselContainer: 'featured-carousel-container',
	featuredResultItem: 'featured-result-item',
	carouselLoaded: 'carousel-loaded',
	itemPanel: 'item__panel',
	itemImage: 'item__image'
};

function forSaleCarouselClick() {
	analytics.trackEvent('ForSaleLinks (Featured Promo)', 'Car', 'Used | www.motors.co.uk', -1);
}

function leasingCarouselClick() {
	analytics.trackEvent('Leasing featured promo', 'Carousel click');
}

function leasingSlideChange($featuredCarousel, changeEvent) {
	var href = changeEvent.target.currSlide.content.find('.featured-carousel__item__panel').attr('href');

	if (href) {
		$featuredCarousel.siblings('.featured-carousel__image').attr('href', href);
	}
}

function setClickEvents($featuredCarousel, clickEvent) {
	$featuredCarousel.findByBem(classNames.featuredCarousel, classNames.itemPanel).off('click.featuredCarousel').on('click.featuredCarousel', clickEvent);
	$featuredCarousel.findByBem(classNames.featuredCarousel, classNames.itemImage).off('click.featuredCarousel').on('click.featuredCarousel', clickEvent);
}

function loadFeaturedCarousel(containerClass, clickEvent, slideChangeEvent) {
	var $featuredCarousel = $.findByBem(containerClass).findByBem(classNames.featuredCarousel);

	if ($featuredCarousel.length > 0) {
		var $carouselContainer = $.findByBem(classNames.carouselContainer);
		var carouselLoaded = $carouselContainer.hasClass(carouselLoaded);
		var isVisibleNearby = $featuredCarousel.data('visible-nearby');

		if (!carouselLoaded && typeof royalSlider !== 'undefined') {
			$featuredCarousel.royalSlider({
				controlNavigation: 'bullets',
				addActiveClass: true,
				autoScaleSlider: false,
				autoHeight: true,
				loop: true,
				navigateByClick: false,
				imageScaleMode: 'none',
				numImagesToPreload: 2,
				arrowsNav: true,
				arrowsNavAutoHide: false,
				keyboardNavEnabled: true,
				fadeinLoadedSlide: true,
				controlsInside: false,
				autoPlay: {
					enabled: true,
					delay: 3000
				},
				visibleNearby: {
					enabled: isVisibleNearby,
					centerArea: 0.5,
					center: true,
					breakpoint: 400,
					breakpointCenterArea: 0.7,
					navigateByCenterClick: true
				}
			});

			$featuredCarousel.each(function() {
				$(this).data('royalSlider').ev.on('rsAfterSlideChange', function(event) {
					images.lazyLoad(event.target.slider);
					setClickEvents($featuredCarousel, clickEvent); // Do this each time due to lazy load

					if (typeof slideChangeEvent === 'function') {
						slideChangeEvent($featuredCarousel, event);
					}
				});
			});

			$carouselContainer.addClass(classNames.carouselLoaded);
		}

		setClickEvents($featuredCarousel, clickEvent);
	}
}

module.exports = {
	init: function () {
		loadFeaturedCarousel(classNames.forSaleFeaturedCars, forSaleCarouselClick);
		loadFeaturedCarousel(classNames.leasingFeaturedCars, leasingCarouselClick, leasingSlideChange);
	}
};
