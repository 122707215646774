'use strict';

var keyMirror = require('keymirror');

module.exports = {
	ActionTypes: keyMirror({
		LOOKUP_VRM: null,
		LOOKUP_VRM_SUCCESS: null,
		LOOKUP_VRM_ERROR: null
	})
};