'use strict';

var userCookie = require('../storage/user-cookie.js');
var logger = require('./logger.js');
var vendorConsents = require('./vendor-consents.js');

var covatic = window.covaticBrowserSDK;
var mediaAssetDuration = 3600;
var playHeadPosition = 300;
var audienceProfileStorageKey = '_audienceProfiles';

function onCovaticReady(mediaConsumptionData) {
    logger.info("Covatic SDK is ready.");
    
    covatic.notifySubscription("guestAccount");
    logger.info("Covatic subscription level notified");

    if (mediaConsumptionData) {
        covatic.notifyEvent(
            new covatic.MediaConsumption(
                '', // mediaId or assetId (not applicable)
                mediaConsumptionData.mediaAssetTitle,
                { 'cvc_description': mediaConsumptionData.cvcDescription },
                [], // media tags (not commercial categories on agnetha)
                mediaConsumptionData.mediaSubsection,
                mediaAssetDuration,
                playHeadPosition,
                covatic.MediaConsumption.Action.commenceMedia,
                covatic.MediaConsumption.Type.text));
        logger.info("Media consumption notified.");
    } else {
        logger.warn("No media consumption data available.");
    }

    // Check for user's postcode in cookies and notify Covatic if available
    var userCookieData = userCookie.getCookie();
    if (userCookieData && userCookieData.postcode) {
        covatic.notifyPostcode(userCookieData.postcode);
        logger.info('User postcode notified.');
    }

    // Fetch and handle audience profiles from Covatic
    var audienceProfiles = covatic.getLinkedAudiences();
    logger.info('AudienceProfiles: ', audienceProfiles);

    if (audienceProfiles && audienceProfiles.length > 0) {
        window.localStorage.setItem(audienceProfileStorageKey, audienceProfiles);
        logger.info('AudienceProfiles stored in localStorage.');
    }
}

function onConsentGranted() {
    var mediaConsumptionData = null;

	if (window.MediaConsumptionData) {
		logger.info("Media consumption data available.");
		mediaConsumptionData = window.MediaConsumptionData;
	}

	// Initialize the Covatic Browser SDK using the client config
    if (mediaConsumptionData) {
	    covatic.Client(mediaConsumptionData.clientConfig);
    }

	// Notify Covatic that user consent has been granted
	covatic.notifyConsent(true);
	logger.info('Covatic notified: consent granted.');

	// Wait until the Covatic SDK is ready before proceeding
    covatic.isReady().then(
        onCovaticReady(mediaConsumptionData),
        function() {
            logger.warn('Covatic SDK is not ready');
        });
}

function onConsentRefused() {
	logger.info('Covatic consent has not been granted - no data will be collected');

	// Remove the audience profiles if in local storage
	if (window.localStorage.getItem(audienceProfileStorageKey) !== null) {
		window.localStorage.removeItem(audienceProfileStorageKey);
		window.logger.info('Covatic audience profile removed from local storage');
	}

	covatic.notifyConsent(false);
}

function handleTcfEvent(eventName, vendorId) {
	vendorConsents.addTcfApiHandler(eventName, function () {
		var consentGrantedResultPromise = vendorConsents.hasIABVendorConsent(vendorId);

        consentGrantedResultPromise.then(
			onConsentGranted,
			onConsentRefused);
	});
}

module.exports = {
	init: function () {
		var CovaticVendorId = 1104;

		var eventsTohandle = [
			vendorConsents.eventNames.userActionComplete,
			vendorConsents.eventNames.subsequentPageLoad
		];

		eventsTohandle.forEach(function(eventName) {
            handleTcfEvent(eventName, CovaticVendorId);
        });
	}
};