'use strict';

var leasingApi 		= require('../../api/leasing-api.js');
var logger 			= require('../../utils/logger.js');
var tagManager = require('../../utils/tagManager.js');
var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/leasing-search-filters-constants.js');

var ActionTypes = Constants.ActionTypes;

function log(source, actionType, data) {
	logger.debug('Leasing Search Filters', {
		message: 'Create ' + source + ' action',
		action: actionType,
		data: data
	});
}

function createServerAction(actionType, data) {
	log('server', actionType, data);
	FluxDispatcher.dispatchServerAction({
		type: actionType,
		data: data
	});
}

function createViewAction(actionType, data) {
	log('view', actionType, data);
	FluxDispatcher.dispatchViewAction({
		type: actionType,
		data: data
	});
}

function reloadResults(searchResultsUrl, updateResults, redirectImmediately) {
	if (redirectImmediately) {
		window.location = searchResultsUrl;
	}
	else {
		createViewAction(ActionTypes.LEASING_SEARCH_FILTERS_UPDATE_RESULTS, {
			searchResultsUrl: searchResultsUrl,
			updateResults: updateResults
		});
	}
}

var Actions = {
	initStoreData: function (storeData) {
		createServerAction(ActionTypes.LEASING_SEARCH_FILTERS_INIT_STORE_DATA, storeData);
	},

	getFilterState: function (filterRequest) {
	 	createServerAction(ActionTypes.LEASING_SEARCH_FILTERS_GET_STATE, filterRequest);

		leasingApi.search(filterRequest, {
			includeResults: false,
			successCallback: function(data) {
				createServerAction(ActionTypes.LEASING_SEARCH_FILTERS_GET_STATE_SUCCESS, data);
			},
			errorCallback: function() {
				createServerAction(ActionTypes.LEASING_SEARCH_FILTERS_GET_STATE_ERROR);
			}
		});
	},

	updateFilterState: function (filterRequest, dynamicallyUpdate) {

		tagManager.pushToDataLayer({'monthlyBudgetFrom': filterRequest.priceMin});
		tagManager.pushToDataLayer({'monthlyBudgetTo': filterRequest.priceMax});
		tagManager.pushToDataLayer({'annualMileage': filterRequest.mileageAllowance});
		tagManager.pushToDataLayer({'leaseTerm': filterRequest.term});
		tagManager.pushToDataLayer({'deposit': filterRequest.deposit});
		
	 	var initialActionName = dynamicallyUpdate ? ActionTypes.LEASING_SEARCH_FILTERS_UPDATE_STATE : ActionTypes.LEASING_SEARCH_FILTERS_GET_STATE;
		var successActionName = dynamicallyUpdate ? ActionTypes.LEASING_SEARCH_FILTERS_UPDATE_STATE_SUCCESS : ActionTypes.LEASING_SEARCH_FILTERS_GET_STATE_SUCCESS;
		var errorActionName = dynamicallyUpdate ? ActionTypes.LEASING_SEARCH_FILTERS_UPDATE_STATE_ERROR : ActionTypes.LEASING_SEARCH_FILTERS_GET_STATE_ERROR;

		createServerAction(initialActionName, filterRequest);

		leasingApi.search(filterRequest, {
			includeResults: dynamicallyUpdate,
			successCallback: function(data) {
				createServerAction(successActionName, data);
			},
			errorCallback: function() {
				createServerAction(errorActionName);
			}
		});
	},

	resetFilters: function(resetRequest, redirectImmediately) {
		createServerAction(ActionTypes.LEASING_SEARCH_FILTERS_RESET);

		leasingApi.search(resetRequest, {
			includeResults: true,
			successCallback: function(data) {
				if (redirectImmediately){
					reloadResults(data.searchResultsUrl, null, redirectImmediately);
				}
				else {
					createServerAction(ActionTypes.LEASING_SEARCH_FILTERS_UPDATE_STATE_SUCCESS, data);
				}
			},
			errorCallback: function() {
				createServerAction(ActionTypes.LEASING_SEARCH_FILTERS_UPDATE_STATE_ERROR);
			}
		});
	},
	
	removeItem: function (name) {
		createViewAction(ActionTypes.LEASING_SEARCH_FILTERS_REMOVE_ITEM, {
			name: name
		});
	},

	setItem: function (name, value, text) {
		createViewAction(ActionTypes.LEASING_SEARCH_FILTERS_SET_ITEM, {
			name: name,
			text: text,
			value: value
		});
	},

	setValue: function (name, value) {
		createViewAction(ActionTypes.LEASING_SEARCH_FILTERS_SET_VALUE, {
			name: name,
			value: value
		});
	},

	updateResults: function (searchResultsUrl, updateResults) {
		reloadResults(searchResultsUrl, updateResults);
	},

	validationError: function (name, message) {
		createViewAction(ActionTypes.LEASING_SEARCH_FILTERS_VALIDATION_ERROR, {
			name: name,
			message: message
		});
	},

	setAdvancedFilters: function (){
		createViewAction(ActionTypes.LEASING_SEARCH_ADVANCED_FILTERS_EXPANDED, {
			showLeasingAdvancedFilters: true
		});
	},
};

module.exports = Actions;