'use strict';

var $ = require('jquery');
var ScrollMagic = require('scrollmagic');

var scroll = require('./scroll.js');

var logger = require('../utils/logger.js');
var heightWatcher = require('../watchers/height-watcher.js');
var layout = require('./layout.js');
var screenSizeWatcher = require('../watchers/screen-size-watcher.js');
var scrollWatcher = require('../watchers/scroll-watcher.js');

var classNames = {
	mpuAd: 'mpu-ad',
	siteFooter: 'site-footer',
	siteHeaderDesktop: 'site-header-desktop',
	siteHeaderMobile: 'site-header-mobile',
	siteContent: 'site-content',
	siteSidebarLeft: 'site-sidebar-left',
	topAd: 'top-ad',
	stickyWrapper: 'sticky-wrapper',
	stickyWrapperInner: 'sticky-wrapper__inner'
};

var modifierNames = {
	retracted: 'retracted',
	stuck: 'stuck',
	unstuck: 'unstuck',
	revealed: 'revealed',
	transition: 'transition',
	viewed: 'viewed'
};

var stickyInstances = [];
var stickyModuleInitialised = false;
var hasSiteHeaderRetracted = false;
var isSiteHeaderVisible = true;

function stickElement(stickyElementName, $triggerElement, $stickyElement, stickyOffset, duration) {
	var validParameters = stickyElementName && $triggerElement && $triggerElement.length && $stickyElement && $stickyElement.length;

	if (!validParameters) {
		return;
	}

	var stickyScene = stickyInstances[stickyElementName + '--sticky-element'];

	if (!stickyScene) {
		stickyScene = new ScrollMagic.Scene({
			triggerElement: $triggerElement[0],
			triggerHook: 0.01, //non-zero so that window resize works when duration is zero
			duration: duration,
			offset: stickyOffset * -1
		})
		.setPin($stickyElement[0])
		.addTo(scroll.getScrollMagicController());

		stickyInstances[stickyElementName + '--sticky-element'] = stickyScene;

		logger.debug('Sticky scene created:' + stickyElementName + ' off:' + stickyOffset + 'px dur:' + duration + 'px');
	}
	else {
		stickyScene.offset(stickyOffset * -1);
		stickyScene.duration(duration);
		logger.debug('Sticky scene updated:' + stickyElementName + ' off:' + stickyOffset + 'px dur:' + duration + 'px');
	}
}

function unstickElement(stickyElementName) {
	var stickyScene = stickyInstances[stickyElementName + '--sticky-element'];

	if (stickyScene) {
		stickyScene.remove();
		stickyScene.destroy(true);
		delete stickyInstances[stickyElementName + '--sticky-element'];
		logger.debug('Sticky scene removed:' + stickyElementName);
	}
}

function setStickySidebarLeft() {
	var $leftSidebarWrapper = $.findByBem(classNames.siteSidebarLeft, classNames.stickyWrapper);
	var $leftSidebarInner = $.findByBem(classNames.siteSidebarLeft, classNames.stickyWrapperInner);
	var stickyOffset = layout.getContentOffset();
	var sidebarInnerElementHeight = $leftSidebarInner.first().outerHeight(true);

	var sidebarLeftStickyWrapperClass = $.getBemClass(classNames.siteSidebarLeft, classNames.stickyWrapper);
	
	if ((sidebarInnerElementHeight + stickyOffset) > $(window).height()) {
		unstickElement(sidebarLeftStickyWrapperClass);
		return;
	}

	var stickyDuration = $.findByBem(classNames.siteSidebarLeft).first().outerHeight() - $leftSidebarInner.first().outerHeight() - 1;

	stickElement(sidebarLeftStickyWrapperClass, $leftSidebarWrapper, $leftSidebarInner, stickyOffset, stickyDuration);
}

function addRetractionBehaviour($siteHeaderMobile, $siteHeaderDesktop, $siteContent) {
	new ScrollMagic.Scene({
		triggerElement: '.retraction-marker',
	 	triggerHook: 'onLeave'
	})
	.on('enter', function() {
		$siteHeaderMobile.addBemClass(classNames.siteHeaderMobile, null, modifierNames.retracted);
		$siteHeaderDesktop.addBemClass(classNames.siteHeaderDesktop, null, modifierNames.retracted);
		$siteContent.addBemClass(classNames.siteContent, null, modifierNames.retracted);

		hasSiteHeaderRetracted = true;
		isSiteHeaderVisible = false;
	}).addTo(scroll.getScrollMagicController());
}

function removeRetractionBehaviour($siteHeaderMobile, $siteHeaderDesktop, $siteContent) {
	new ScrollMagic.Scene({
		triggerElement: '.reset-marker',
		triggerHook:'onLeave',
		offset: 1 //Single pixel offset in order to trigger the onLeave event as we are at the very top of the page.
	})
	.on('leave',function() {
		$siteHeaderMobile.removeBemClass(classNames.siteHeaderMobile, null, modifierNames.retracted);
		$siteHeaderDesktop.removeBemClass(classNames.siteHeaderDesktop, null, modifierNames.retracted);
	
		$siteHeaderMobile.removeBemClass(classNames.siteHeaderMobile, null, modifierNames.revealed);
		$siteHeaderDesktop.removeBemClass(classNames.siteHeaderDesktop, null,modifierNames.revealed);

		$siteHeaderMobile.removeBemClass(classNames.siteHeaderMobile, null, modifierNames.transition);
		$siteHeaderDesktop.removeBemClass(classNames.siteHeaderDesktop, null,modifierNames.transition);
		
		$siteContent.removeBemClass(classNames.siteContent, null, modifierNames.retracted);

		hasSiteHeaderRetracted = false;
		isSiteHeaderVisible = true;
	}).addTo(scroll.getScrollMagicController());
}

function setSiteHeaderRevealOnScroll($siteHeaderMobile, $siteHeaderDesktop) {
	//The scrollWatcher element will affect the behaviour of the site headers and topAd when we have scrolled away from 
	//the top part of the page
	scrollWatcher.onScroll(function (e) {
		if(!hasSiteHeaderRetracted) {
			return;
		}
		if (e.direction === 'down') {
			//hide site headers
			$siteHeaderMobile.removeBemClass(classNames.siteHeaderMobile, null, modifierNames.revealed);
			$siteHeaderDesktop.removeBemClass(classNames.siteHeaderDesktop, null,modifierNames.revealed);
			isSiteHeaderVisible = false;
		}

		if (e.direction === 'up') {
			//reveal the site headers
			$siteHeaderMobile.addBemClass(classNames.siteHeaderMobile, null, modifierNames.revealed);
			$siteHeaderDesktop.addBemClass(classNames.siteHeaderDesktop, null,modifierNames.revealed);
			$siteHeaderMobile.addBemClass(classNames.siteHeaderMobile, null, modifierNames.transition);
			$siteHeaderDesktop.addBemClass(classNames.siteHeaderDesktop, null,modifierNames.transition);

			isSiteHeaderVisible = true;
		}
	});
}

function initRetractionBehaviour() {
	var $siteHeaderMobile = $.findByBem(classNames.siteHeaderMobile);
	var $siteHeaderDesktop = $.findByBem(classNames.siteHeaderDesktop);
	var $siteContent = $.findByBem(classNames.siteContent);

	addRetractionBehaviour($siteHeaderMobile, $siteHeaderDesktop, $siteContent);

	removeRetractionBehaviour($siteHeaderMobile, $siteHeaderDesktop, $siteContent);

	setSiteHeaderRevealOnScroll($siteHeaderMobile, $siteHeaderDesktop);
}

module.exports = {
	init: function () {
		if (stickyModuleInitialised) {
			return;
		}

		stickyModuleInitialised = true;

		setStickySidebarLeft();

		initRetractionBehaviour();
		
		$(window).on('load', function () {
			if (typeof ScrollMagic !== 'undefined') {
				heightWatcher.onChange(function () {
					// update ScrollMagic to re-calculate offsets
					scroll.getScrollMagicController().update();
				});

				screenSizeWatcher.onChange(function () {
					logger.debug('Resetting sidebar');
					setStickySidebarLeft();
				});
			}
		});
	},

	showSiteHeader: function () {		
		var $siteHeaderMobile = $.findByBem(classNames.siteHeaderMobile);
		var $siteHeaderDesktop = $.findByBem(classNames.siteHeaderDesktop);
		
		if (hasSiteHeaderRetracted && !isSiteHeaderVisible) {
			$siteHeaderMobile.addBemClass(classNames.siteHeaderMobile, null, modifierNames.revealed);
			$siteHeaderDesktop.addBemClass(classNames.siteHeaderDesktop, null,modifierNames.revealed);
			$siteHeaderMobile.addBemClass(classNames.siteHeaderMobile, null, modifierNames.transition);
			$siteHeaderDesktop.addBemClass(classNames.siteHeaderDesktop, null,modifierNames.transition);

			setTimeout(function() {
				if (!isSiteHeaderVisible) {
					$siteHeaderMobile.removeBemClass(classNames.siteHeaderMobile, null, modifierNames.revealed);
					$siteHeaderDesktop.removeBemClass(classNames.siteHeaderDesktop, null,modifierNames.revealed);
				}
			}, 3000);
		} 
	},

	resetStickySidebar: function () {
		setStickySidebarLeft();
	}
};