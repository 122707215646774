'use strict';

var $ = require('jquery');

var scroll = require('./scroll.js');

module.exports = {
	init: function ($context) {
		$('a[href^="#"]:not(a[href="#"])', $context).off('click.anchor').on('click.anchor', function (e) {
			if (this.hash) {
				var $target = $(this.hash + ',a[name="' + this.hash.substring(1) + '"]');
				if ($target.length > 0) {
					e.preventDefault();
					scroll.scrollTo($target, 'fast');
				}
			}
		});
	}
};