/** @jsx React.DOM */'use strict';

var React = require('react');

module.exports = React.createClass({

	propTypes: {
		promptText: React.PropTypes.object,
		isDisabled: React.PropTypes.bool,
		isCentered: React.PropTypes.bool
	},

	getDefaultProps: function() {
		return {
			promptText: 'Go',
			isCentered: false
		}
	},

	render: function () {
		var formClasses = this.props.isCentered ? 'form__entry form__entry--center-container' : 'form__entry';

		return (
			<div className={formClasses}>
				<button type="submit" className="quick-find__button button" disabled={this.props.isDisabled}>
					{this.props.promptText}
				</button>
			</div>
		);
	}
});