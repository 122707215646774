'use strict';

var $ = require('jquery');

var advert 		= require('../adverts/advert.js');
var scroll 		= require('../ui/scroll.js');

var classNames = {
	accordion: 				'accordion',
	accordionContent: 		'accordion__content',
	accordionNavigation: 	'accordion__navigation'
};

function onCollapse($content, options) {
	if (options && (typeof options.onCollapse === 'function')) {
		options.onCollapse($content);
	}
}

function onExpand($content, options) {
	window.setTimeout(function () {
		// lazy load images that are now in view
		$(window).trigger('scroll');
	}, 100);
	
	advert.loadAds($content);

	if (options && (typeof options.onExpand === 'function')) {
		options.onExpand($content);
	}
}

function loadAccordions($context, options, reInitDataEqualizer) {
	$.findByBem(classNames.accordionNavigation, null, null, $context).off('click.accordion').on('click.accordion', function (e) {
		e.preventDefault();
		var $nav = $(this);
		if($nav.hasBemClass(classNames.accordionNavigation, null, 'disabled')){
			return false;
		}
		var $accordion = $nav.parentsByBem(classNames.accordion);
		var $content = $nav.siblingsByBem(classNames.accordionContent);

		var contentId = $nav.data('id');

		if (contentId) {
			$content = $content.filter('#' + contentId);
		}

		if ($nav.hasBemClass(classNames.accordionNavigation, null, 'expanded')) {
			$nav.removeBemClass(classNames.accordionNavigation, null, 'expanded');
			$content.slideUp('fast', function () {
				$content.removeBemClass(classNames.accordionContent, null, 'expanded');
				onCollapse($content, options);
			});
		} else {

			if (!$accordion.findByBem(classNames.accordionNavigation).hasBemClass(classNames.accordionNavigation,null,'keep-open')) {

				$accordion.findByBem(classNames.accordionNavigation).removeBemClass(classNames.accordionNavigation, null, 'expanded');
				$accordion.findByBem(classNames.accordionContent).removeBemClass(classNames.accordionContent, null, 'expanded').hide();
			}
			
			$nav.addBemClass(classNames.accordionNavigation, null, 'expanded');
			scroll.scrollIntoView($nav);
			$content.slideDown('fast', function () {
				$content.addBemClass(classNames.accordionContent, null, 'expanded');

				if (reInitDataEqualizer) {
					$(document).foundation().init('equalizer');
				}
				
				onExpand($content, options);
			});
		}
	});
}

module.exports = {
	init: function ($context, options, reInitDataEqualizer) {
		loadAccordions($context, options, reInitDataEqualizer);
	}
};