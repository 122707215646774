'use strict';

var $ = require('jquery');

function initTooltipButtons($context) {
	var $tooltipElements = $context.findByBem('tooltip');

	$tooltipElements.each(function() {
		$(this).off('click.tooltip').on('click.tooltip', function(e){
			e.preventDefault();
			e.stopImmediatePropagation();
			$(this).toggleClass('tooltip--displayed');
		});
	});
}

module.exports = {
	init: function ($context) {
		initTooltipButtons($context);
	}
};