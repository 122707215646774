/** @jsx React.DOM */'use strict';

var React = require('react');

var FacetDropdown 	= require('./shared/facet-dropdown-view.jsx');
var FacetFlyoutList = require('./shared/facet-flyout-list-view.jsx');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		filterState: React.PropTypes.object.isRequired,
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		onItemRemove: React.PropTypes.func.isRequired,
		onItemSet: React.PropTypes.func.isRequired
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		return (
			<div className="search-filters__facet">
				<div className="hide-for-large-up">
					<FacetDropdown
						name="range"
						defaultText="Range"
						isLoading={this.props.isLoading}
						isDisabled={this.props.isDisabled}
						items={this.props.filterState.rangeItems}
						selectedItem={this.props.filterState.selectedRange}
						onRemove={this.props.onItemRemove}
						onSet={this.props.onItemSet}
					/>
				</div>
				<div className="show-for-large-up">
					<FacetFlyoutList
						label="Range"
						name="range"
						title="Select a range"
						isLoading={this.props.isLoading}
						isDisabled={this.props.isDisabled}
						items={this.props.filterState.rangeItems}
						selectedItem={this.props.filterState.selectedRange}
						onRemove={this.props.onItemRemove}
						onSet={this.props.onItemSet}
					/>
				</div>
			</div>
		);
	}
	/* #endregion */
});