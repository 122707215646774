/** @jsx React.DOM */'use strict';

var React 	= require('react');
var numeral = require('numeral');

var textConverter = require('../../../../utils/text-converter.js');
var filterScroller = require('../../../../utils/filter-scroll.js');

var View = React.createClass({
	/* #region React functions */
	propTypes: {
		defaultText: React.PropTypes.string.isRequired,
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		items: React.PropTypes.array,
		label: React.PropTypes.string.isRequired,
		name: React.PropTypes.string.isRequired,
		selectedItem: React.PropTypes.object,
		onRemove: React.PropTypes.func.isRequired,
		onSet: React.PropTypes.func.isRequired
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		if (this.props.selectedItem) {
			return this.renderSelectedValue();
		} else if (this.props.items && this.props.items.length === 1) {
			return this.renderSingleValue();
		} else {
			return this.renderDropdown();
		}
	},

	renderDropdown: function () {
		var id = 'ddl' + textConverter.hyphenToPascalCase(this.props.name);
		var defaultText = this.props.defaultText;
		var disabled, listItems;

		if (this.props.isLoading) {
			disabled = true;
			defaultText = "Loading...";
		} else if (this.props.isDisabled) {
			disabled = true;
		} else if (this.props.items && this.props.items.length > 0) {
			disabled = false;
			listItems = this.props.items.map(function (item) {
				var formattedCount = numeral(item.count).format('0,0');
				return <option key={item.value} value={item.value}>{item.text}&nbsp;&nbsp;({formattedCount})</option>;
			});
		} else {
			disabled = true;
		}

		var itemClassName = 'search-filters__item search-filters--border-bottom-only';
		if (disabled) {
			itemClassName += ' search-filters--disabled';
		}

		return (
			<div className={itemClassName}>
				<select id={id} className="search-filters__item__list search-filters__item__list--chevron" 
						disabled={disabled} onChange={this.onDropdownChange} onBlur={this.onBlur}>
					<option value="">{defaultText}</option>
					{listItems}
				</select>
				<label className="search-filters__item__list-label">Any</label>
			</div>
		);
	},

	renderSelectedValue: function () {
		return (
			<div className="search-filters__item search-filters--border-bottom-only">
				<a href="#" className="search-filters__item__selected" title={this.props.selectedItem.text} 
					onClick={this.onRemoveClick}>
					{this.props.selectedItem.text}
					<span className="search-filters__item__selected__remove-link"></span>
				</a>
			</div>
		);
	},

	renderSingleValue: function () {
		var onlyFacetItem = this.props.items[0];
		return (
			<div className="search-filters__item search-filters--border-bottom-only">
				<span className="search-filters__item__selected" title={onlyFacetItem.text}>
					{onlyFacetItem.text}
					<span className="search-filters__item__selected__remove-link"></span>
				</span>
			</div>
		);
	},

	/* #endregion */

	/* #region Event handlers */
	onBlur: function(e){
		filterScroller.scrollToOriginalPosition(e.target);
	},

	onDropdownChange: function(e) {
		var localSelf = this;
		// Force the React event wrapper to persist the event otherwise it gets reset
		e.persist();
		// Queue up the real change handler. IOS10 Safari does not like it if you remove
		// an element from within an event handler on that element. (which we are doing
		// when a facet is selected in a dropdown).
		window.setTimeout(function() {
				localSelf.onDelayedDropdownChange(e);
		}, 50);
	},

	onDelayedDropdownChange: function (e) {
		if (!this.props.isLoading) {
			filterScroller.scrollToOriginalPosition(e.target);

			var name = this.props.name;
			var value = e.target.value;

			if (value) {
				var index;
				var text = e.target.options[e.target.selectedIndex].text;

				if ((index = text.indexOf(String.fromCharCode(160))) > -1) {
					// strip count which is separated by &nbsp; (char code 160)
					text = text.substring(0, index);
				}

				this.props.onSet(name, value, text);
			} else {
				this.props.onRemove(name);
			}
		}
	},

	onRemoveClick: function (e) {
		e.preventDefault();
		if (!this.props.isLoading) {
			this.props.onRemove(this.props.name);
		}
	}
	/* #endregion */
});

module.exports = View;