'use strict';
var cookie = require('../storage/cookie.js');

var PostcodeRegex = /^((((([A-PR-UWYZ][0-9][0-9A-HJKS-UW]?)|([A-PR-UWYZ][A-HK-Y][0-9][0-9ABEHMNPRV-Y]?))\s{0,1}[0-9]([ABD-HJLNP-UW-Z]{2}))|(GIR\s{0,2}0AA))|(((([a-pr-uwyz][0-9][0-9a-hjks-uw]?)|([a-pr-uwyz][a-hk-y][0-9][0-9abehmnprv-y]?))\s{0,1}[0-9]([abd-hjlnp-uw-z]{2}))|(gir\s{0,2}0aa)))$/;
var cookieName ='Parkers.User';

function getPostcode(){
	var cookieData = cookie.getCookie(cookieName);

	if(cookieData && cookieData.postcode){ 
		var SPACE_REGEX = /\s+/gi;
		return cookieData.postcode.replace(SPACE_REGEX, '').toUpperCase();
	}

	return null;
}

module.exports = {
	format: function (postcode) {
		if (postcode) {
			postcode = postcode.toUpperCase().replace(/ /g, '');
			if (this.isValid(postcode)) {
				var inwardIndex = postcode.length - 3;
				postcode = postcode.substring(0, inwardIndex) + ' ' + postcode.substring(inwardIndex);
			}
		}

		return postcode;
	},

	isValid: function(postcode) {
		if (postcode) {
			postcode = postcode.toUpperCase().replace(/ /g, '');
			return postcode.length >= 5 && postcode.match(PostcodeRegex);
		}

		return false;
	},
	getPostcodeOutCode: function () {
		var postcode = getPostcode(cookieName);

		if (postcode) {
			var INCODE_REGEX = /\d[a-z]{2}$/i;
			var outcode = postcode.replace(INCODE_REGEX, '');
			return outcode;
		}
	
		return null;
	}
};