'use strict';

// foundation
require('foundation-abide');
require('foundation-alert');
require('foundation-equalizer');
require('foundation-interchange');
require('foundation-offcanvas');
require('foundation-orbit');
require('foundation-reveal');
require('foundation-tab');
require('foundation-tooltip');