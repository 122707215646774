'use strict';

var $ = require('jquery');
var cfsShortlist = require('../profile/cfs-shortlist.js');
var analytics = require('../utils/analytics.js');

var classNames = {
	shortlistControls: 'for-sale-shortlist-controls',
	shortlistCta: 'cta',
	forSaleShortListControlContainer: 'for-sale-shortlist-controls-container'
};

var shortlistLimit;
var shortlist;
var trackingAction = '';

function setShortlistControls($controlContainers) {
	shortlist = cfsShortlist.get();

	if (!shortlist || $controlContainers.length === 0) {
		return;
	}

	$controlContainers.each(function() {
		var $container = $(this);
		var vehicleId = $container.data('vehicle-id');
		var stockId = $container.data('stock-id');

		var isShortlistFull = shortlist.length >= shortlistLimit;
		var isAlreadyShortlisted = shortlist.indexOf(vehicleId) !== -1;
		var $shortlistSaveCta = $container.findByBem(classNames.shortlistControls, classNames.shortlistCta, 'save');
		var $shortlistSavedCta = $container.findByBem(classNames.shortlistControls, classNames.shortlistCta, 'saved');
	
		if (isAlreadyShortlisted) {
			$container.addBemClass(classNames.shortlistControls, null, 'saved');
		}

		if (isShortlistFull) {
			$container.addBemClass(classNames.shortlistControls, null, 'disabled');
		} 

		$shortlistSaveCta.off('click.cfs-shortlist-save').on('click.cfs-shortlist-save', function(e) {
			e.preventDefault();
			e.stopPropagation();

			var $itemShortListButtons = $container.closestByBem(classNames.forSaleShortListControlContainer).findByBem(classNames.shortlistControls);
			$itemShortListButtons.addBemClass(classNames.shortlistControls, null, 'saved');

			// Save if theres room
			if (cfsShortlist.count() < shortlistLimit) {
				cfsShortlist.add(vehicleId);
				analytics.trackEvent('Shortlist Button - Add Vehicle', trackingAction, stockId);
			}

			// Check if limit is now hit after the save
			if (cfsShortlist.count() === shortlistLimit) {
				// Add disabled class to everything - saved class will take priority
				$controlContainers.addBemClass(classNames.shortlistControls, null, 'disabled');
			}
		});

		$shortlistSavedCta.off('click.cfs-shortlist-saved').on('click.cfs-shortlist-saved', function(e) {
			e.preventDefault();
			e.stopPropagation();

			var $itemShortListButtons = $container.closestByBem(classNames.forSaleShortListControlContainer).findByBem(classNames.shortlistControls);
			$itemShortListButtons.removeBemClass(classNames.shortlistControls, null, 'saved');

			cfsShortlist.remove(vehicleId);
			analytics.trackEvent('Shortlist Button - Remove Vehicle', trackingAction, stockId);

			// If this container is disabled then others are too, so undisable them now theres space
			if ($container.hasBemClass(classNames.shortlistControls, null, 'disabled')) {
				$controlContainers.removeBemClass(classNames.shortlistControls, null, 'disabled');
			}
		});
	});

	$controlContainers.removeBemClass(classNames.shortlistControls, null, 'hidden');
}

var self = module.exports = {
	init: function($context, gaAction) {
		shortlistLimit = cfsShortlist.maxItems;
		trackingAction = gaAction;

		self.load($context);
	},

	load: function($context) {
		if (!$context) {
			return;
		}

		shortlistLimit = cfsShortlist.maxItems;
		
		var $controlContainers = $context.findByBem(classNames.shortlistControls);
		setShortlistControls($controlContainers);
	}
};
