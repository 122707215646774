'use strict';

var $ = require('jquery');
var assign 	= require('object-assign');

var logger	= require('../../utils/logger.js');
var array = require('../../utils/arrays.js');

var FluxChangeStore	= require('./flux-change-store.js');
var FluxConstants 	= require('../constants/flux-constants.js');
var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/quick-find-year-derivative-constants.js');

var ActionTypes 	= Constants.ActionTypes;
var PayloadSources = FluxConstants.PayloadSources;
var ValuationPurpose	= Constants.ValuationPurpose;

var defaultStoreData = {
	isLoading: true,
	yearPlates: [],
	selectedYearPlate: null,
	selectedDerivativeUrl: null,
	valuationPurpose: null,
	valuationPurposeOptions: [],
	validationErrors: {}
};

var storeData = assign({}, defaultStoreData);

var Store = assign(new FluxChangeStore(), {
	getState: function () {
		return storeData;
	}
});

function getSelectedYearPlate(selectedYearPlate)
{
	var yearPlate = null;

	if(selectedYearPlate) {
		var selectedYearPlates = $.grep(storeData.yearPlates, function(element) {
			return element.key === selectedYearPlate.toString();
		});

		if (selectedYearPlates.length === 1)
		{
			yearPlate =  selectedYearPlates[0];
		}
	}

	return yearPlate;
}

function handleServerAction(action) {
	if (action) {
		var handled = false;

		switch(action.type) {
			case ActionTypes.YEAR_DERIVATIVE_LOAD_YEARS:
				handled = true;
				
				break;

			case ActionTypes.YEAR_DERIVATIVE_LOAD_YEARS_SUCCESS:
				handled = true;

				storeData.isLoading = false;
				storeData.yearPlates = action.data.yearPlates;

				storeData.selectedYearPlate = getSelectedYearPlate(action.data.selectedYearPlate);	
				storeData.selectedDerivativeUrl = action.data.selectedVersion;			

				break;

			case ActionTypes.YEAR_DERIVATIVE_LOAD_YEARS_ERROR:
				handled = true;

				storeData.isLoading = false;				
				storeData.yearPlates = [];

				break;
		}

		if (handled) {
			logger.debug('Quick Find Year & Derivative', {
				message: 'Handle server action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

function handleViewAction(action) {
	if (action) {
		var handled = false;

		switch (action.type) {
			case ActionTypes.YEAR_DERIVATIVE_INITIALISE_VALUATION_PURPOSE:
				handled = true;

				var purposeOptions = [[ValuationPurpose.BUYING_A_CAR.NAME, ValuationPurpose.BUYING_A_CAR.LABEL],[ValuationPurpose.SELLING_A_CAR.NAME, ValuationPurpose.SELLING_A_CAR.LABEL],[ValuationPurpose.INSURING_A_CAR.NAME, ValuationPurpose.INSURING_A_CAR.LABEL]];
				storeData.valuationPurposeOptions = array.shuffle(purposeOptions);

				break;

			case ActionTypes.YEAR_DERIVATIVE_SELECT_YEAR_PLATE:
				handled = true;

				storeData.validationErrors = {};
				storeData.selectedDerivativeUrl = null;

				storeData.selectedYearPlate = getSelectedYearPlate(action.data.yearPlateKey);
				storeData.derivativeIsLoading = true;

				break;
			
			case ActionTypes.YEAR_DERIVATIVE_SELECT_DERIVATIVE:
				handled = true;

				storeData.validationErrors = {};
				storeData.selectedDerivativeUrl = action.data.derivativeUrl;	

				break;

			case ActionTypes.YEAR_DERIVATIVE_SELECT_VALUATION_PURPOSE:
				handled = true;

				storeData.validationErrors = {};
				storeData.valuationPurpose = action.data.valuationPurpose;

				break;

			case ActionTypes.YEAR_DERIVATIVE_VALIDATION_ERROR:
				handled = true;

				storeData.validationErrors = {};

				if (!storeData.selectedYearPlate) {
					storeData.validationErrors.yearPlate = 'Required';
				} else if (!storeData.selectedDerivativeUrl) {
					storeData.validationErrors.derivative = 'Required';
				}

				if (storeData.valuationPurpose !== ValuationPurpose.BUYING_A_CAR.NAME &&
					storeData.valuationPurpose !== ValuationPurpose.SELLING_A_CAR.NAME &&
					storeData.valuationPurpose !== ValuationPurpose.INSURING_A_CAR.NAME &&
					storeData.valuationPurpose !== ValuationPurpose.JUST_CURIOUS.NAME) {
					storeData.validationErrors.valuationPurpose = 'Required.';
				}

				break;
			case ActionTypes.YEAR_DERIVATIVE_SELECT_DERIVATIVE_LOADING:
				handled = true;

				storeData.derivativeIsLoading = true;

				break;
		}

		if (handled) {
			logger.debug('Quick Find Year & Derivative', {
				message: 'Handle view action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

Store.dispatchToken = FluxDispatcher.register(function (payload) {
	if (payload && payload.source) {
		if (payload.source === PayloadSources.SERVER_ACTION) {
			handleServerAction(payload.action);
		} else if (payload.source === PayloadSources.VIEW_ACTION) {
			handleViewAction(payload.action);
		}
	}
});

module.exports = Store;