/** @jsx React.DOM */'use strict';

var React = require('react');

var postcodeHelper = require('../../../utils/postcode-helper.js');
var filterScroller = require('../../../utils/filter-scroll.js');

var previousPostcode;

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		filterState: React.PropTypes.object.isRequired,
		isLoading: React.PropTypes.bool,
		searchType: React.PropTypes.string.isRequired,
		waitingForLocation: React.PropTypes.bool.isRequired,
		onDistanceSet: React.PropTypes.func.isRequired,
		onLocationToggle: React.PropTypes.func.isRequired,
		onPostcodeSet: React.PropTypes.func.isRequired,
		postcodeError: React.PropTypes.string
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		if (this.props.searchType === 'Used' || this.props.searchType === 'NearlyNew') {
			var distanceList = this.getDistanceList();
			var postcodeBox = this.getPostcodeBox();

			return (
				<section className="search-filters__location">
					{distanceList}
					{postcodeBox}
				</section>
			);
		}

		return null;
	},

	getDistanceList: function () {
		var nationwideDistance = this.props.filterState.nationwideDistanceRadius || 0;
		var disabled = this.props.isLoading;
		var distance = this.props.filterState.distance || nationwideDistance;

		return (
			<div className="search-filters__location__distance search-filters__item">
				<label htmlFor="ddlDistance" className="search-filters__item__label">Where:</label>
				<select name="ddlDistance" value={distance} className="search-filters__item__list" disabled={disabled} onChange={this.onDistanceChange} onBlur={this.onDistanceBlur}>
					<option value={nationwideDistance}>Any distance</option>
					<option value="1">Within 1 mile</option>
					<option value="5">Within 5 miles</option>
					<option value="10">Within 10 miles</option>
					<option value="20">Within 20 miles</option>
					<option value="30">Within 30 miles</option>
					<option value="40">Within 40 miles</option>
					<option value="60">Within 60 miles</option>
					<option value="100">Within 100 miles</option>
					<option value="200">Within 200 miles</option>
				</select>
			</div>
		);
	},

	getPostcodeBox: function () {
		var postcode = postcodeHelper.format(this.props.filterState.postcode);
		var latLong = this.props.filterState.latLong;
		var hasMyLocation = latLong && latLong.isValid && latLong.source === 'Browser';
		var disabled = this.props.isLoading || hasMyLocation;

		var useMyLocationClass = 'search-filters__location__use-my-location';

		if (hasMyLocation) {
			useMyLocationClass += ' search-filters__location__use-my-location--active';
		} else if (this.props.waitingForLocation) {
			useMyLocationClass += ' search-filters__location__use-my-location--loading';
		}

		var locationError;
		if (this.props.filterState.locationError) {
			locationError = (
				<div className="search-filters__validation-error">
					<span className="icon icon--tiny icon-gap-right fi-alert"></span>
					{this.props.filterState.locationError}
				</div>
			);
		}

		var postcodeError = this.getPostcodeError();
		var postcodeClass = "search-filters__location__postcode__input search-filters__item__input";

		return (
			<div className="search-filters__location__postcode search-filters__item">
				<div className="search-filters__item--no-wrapping">
					<input name="tbPostcode" type="text" maxLength="8" placeholder="Enter Postcode..." value={postcode} disabled={disabled}
						className={postcodeClass} aria-invalid={locationError && true}
						onBlur={this.onPostcodeBlur} onChange={this.onPostcodeChange} onFocus={this.onPostcodeFocus} onKeyPress={this.onPostcodeKeyPress} />

					<div className="alert-box hide">
						<span className="icon fi-alert"></span><span> Postcode is required</span>
					</div>
				</div>
				{postcodeError}
				<button className={useMyLocationClass} onClick={this.onLocationClick}>
					<span className="icon icon--tiny icon-gap-right fi-target-two"></span>
					<span className="icon icon--tiny icon-gap-right fi-check"></span>
					<span className="search-filters__location__use-my-location__loading-icon"></span>
					Use my location
				</button>
				{locationError}
			</div>
		);
	},

	getPostcodeError: function () {
		if (this.props.postcodeError && this.props.filterState.postcode) {
			return (
				<div className="search-filters__item__validation-error">
					<span className="icon icon--tiny icon-gap-right fi-alert"></span>
					{this.props.postcodeError}
				</div>
			);
		}
	},

	getPostcodeRequiredView: function() {
		if (this.props.postcodeError && !this.props.filterState.postcode){
			return (
				<div className="search-filters__location__postcode__required alert-box alert">
					<span>Required</span>
				</div>
			);
		}
	},
	/* #endregion */

	/* #region Event handlers */
	onDistanceBlur: function (e) {
		filterScroller.scrollToOriginalPosition(e.target);
	},

	onDistanceChange: function (e) {
		if (!this.props.isLoading) {
			this.props.onDistanceSet(e.target.value);
		}
	},

	onLocationClick: function (e) {
		e.preventDefault();
		this.props.onLocationToggle();
	},

	onPostcodeBlur: function (e) {
		if (!this.props.isLoading && e.target.value !== previousPostcode) {
			this.props.onPostcodeSet(e.target.value, previousPostcode, true);
		}

		filterScroller.scrollToOriginalPosition(e.target);
	},

	onPostcodeChange: function (e) {
		// React input controls with a value require an onChange event, to update the store data and refresh the view
		// this gets triggered on every key press, we only want to do the actual search onBlur
		if (!this.props.isLoading) {
			this.props.onPostcodeSet(e.target.value, previousPostcode, false);
		}
	},

	onPostcodeFocus: function (e) {
		previousPostcode = e.target.value;
	},

	onPostcodeKeyPress: function (e) {
		if (!this.props.isLoading && (e.charCode === 13 || e.key === 'Enter')) {
			// enter key pressed
			e.preventDefault();
			e.stopPropagation();
			this.props.onPostcodeSet(e.target.value, previousPostcode, true);
		}
	}
	/* #endregion */
});