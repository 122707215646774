'use strict';

var $ = require('jquery');
var layout = require('../ui/layout.js');
var cfsShortlist = require('../profile/cfs-shortlist.js');

var classNames = {
	menuIcon: 'fi-list',
	siteHeaderDesktop: 'site-header-desktop',
	siteHeaderMobile: 'site-header-mobile',
	siteNavLink: 'site-nav__link',
	offCanvasMenu: 'off-canvas-menu-mobile',
	offCanvasTabLink: 'off-canvas-menu-mobile__tab__link'
};

var desktopAnimationTimeout;
var mobileAnimationTimeout;

function updateSavedCarsDesktopHeaderBadge($badge, action) {
	if ($badge.length) {
		$badge.html(cfsShortlist.count());

		if (action) {
			$badge.removeClass();
			$badge.addBemClass(classNames.siteNavLink, 'badge');
			$badge.addBemClass(classNames.siteNavLink, 'badge', action);

			clearTimeout(desktopAnimationTimeout);

			desktopAnimationTimeout = setTimeout(function () {
				$badge.removeBemClass(classNames.siteNavLink, 'badge', action);
			}, 4000);
		}
	}
}

function updateSavedCarsMobileHeaderBadge($badge, action) {
	if (!layout.isMobile()) {
		return;
	}

	if ($badge.length) {
		var $menuLink = $.findByBem(classNames.siteHeaderMobile, 'icon', 'menu');
		var $menuLinkText = $menuLink.findByBem(classNames.siteHeaderMobile, 'icon__text');
		$menuLinkText.hide();

		$menuLink.removeClass(classNames.menuIcon);
		$menuLink.addBemClass(classNames.siteHeaderMobile, 'icon', 'saved-cars');

		$badge.removeClass();
		$badge.addBemClass(classNames.siteHeaderMobile, 'badge');
		$badge.addBemClass(classNames.siteHeaderMobile, 'badge', action);
		$badge.html(cfsShortlist.count());

		clearTimeout(mobileAnimationTimeout);

		mobileAnimationTimeout = setTimeout(function () {
			$badge.removeBemClass(classNames.siteHeaderMobile, 'badge', action);
			$menuLink.addClass(classNames.menuIcon);
			$menuLink.removeBemClass(classNames.siteHeaderMobile, 'icon', 'saved-cars');
			$menuLinkText.fadeIn();
		}, 4000);
	}
}

function updateSavedCarsOffCanvasMenuBadge($badge) {
	if ($badge.length) {
		$badge.html(cfsShortlist.count());
	}
}

function initSavedCarsDesktopHeader() {
	var $context = $.findByBem(classNames.siteHeaderDesktop);
	var $link = $context.findByBem(classNames.siteNavLink, null, 'saved-cars');
	var $badge = $link.findByBem(classNames.siteNavLink, 'badge');

	updateSavedCarsDesktopHeaderBadge($badge);

	var updateSavedCarsDesktopHeaderBadgeCallback = function(action) {
		updateSavedCarsDesktopHeaderBadge($badge, action);
	};

	cfsShortlist.subscribe(updateSavedCarsDesktopHeaderBadgeCallback);
}

function initSavedCarsMobileHeader() {
	var $badge = $.findByBem(classNames.siteHeaderMobile, 'badge');

	var updateSavedCarsMobileHeaderBadgeCallback = function(action) {
		updateSavedCarsMobileHeaderBadge($badge, action);
	};

	cfsShortlist.subscribe(updateSavedCarsMobileHeaderBadgeCallback);
}

function initSavedCarsOffCanvasMenu() {
	var $context = $.findByBem(classNames.offCanvasMenu);
	var $link = $context.findByBem(classNames.offCanvasTabLink, null, 'saved-cars');
	var $badge = $link.findByBem(classNames.offCanvasTabLink, 'badge');

	updateSavedCarsOffCanvasMenuBadge($badge);

	var updateSavedCarsOffCanvasMenuBadgeCallback = function() {
		updateSavedCarsOffCanvasMenuBadge($badge);
	};

	cfsShortlist.subscribe(updateSavedCarsOffCanvasMenuBadgeCallback);
}

function initOffCanvasMenuLinkScroll() {
	var $menuLink = $.findByBem(classNames.siteHeaderMobile, 'icon', 'menu');
	$menuLink.on('click', function() {
		$(window).scrollTop(0);
	});
}

module.exports = {
	init: function () {
		initSavedCarsDesktopHeader();
		initSavedCarsMobileHeader();
		initSavedCarsOffCanvasMenu();		
		initOffCanvasMenuLinkScroll();
	}
};
