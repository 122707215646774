'use strict';

var logger 			= require('../../utils/logger.js');
var taxonomyApi 	= require('../../api/taxonomy-api.js');
var vrmApi 			= require('../../api/vrm-api.js');

var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/taxonomy-selector-constants.js');

var ActionTypes = Constants.ActionTypes;

function log(source, actionType, data) {
	logger.debug('Taxonomy Selector', {
		message: 'Create ' + source + ' action',
		action: actionType,
		data: data
	});
}

function createServerAction(actionType, data) {
	log('server', actionType, data);
	FluxDispatcher.dispatchServerAction({
		type: actionType,
		data: data
	});
}

function createViewAction(actionType, data) {
	log('view', actionType, data);
	FluxDispatcher.dispatchViewAction({
		type: actionType,
		data: data
	});
}

function populateTaxonomySelector (vehicleType, manufacturerKey, rangeKey, modelKey, derivativeKey) {
	createServerAction(ActionTypes.TAXONOMY_VRM_LOOKUP);
	taxonomyApi.getDataForVrm(vehicleType, derivativeKey, {
		successCallback: function (data) {
			if (data.manufacturers && data.manufacturerWithModels && data.rangeWithDerivatives) {
				createServerAction(ActionTypes.TAXONOMY_VRM_LOOKUP_SUCCESS, data);

				createViewAction(ActionTypes.TAXONOMY_SELECTOR_SELECT_VEHICLE, {
					manufacturerKey: manufacturerKey,
					rangeKey: rangeKey,
					modelKey: modelKey,
					derivativeKey: derivativeKey
				});
			} else {
				createServerAction(ActionTypes.TAXONOMY_VRM_LOOKUP_ERROR);
			}
		},
		errorCallback: function () {
			createServerAction(ActionTypes.TAXONOMY_VRM_LOOKUP_ERROR);
		}
	});
}

var Actions = {

	lookupVrm: function (vehicleType, vrm, recaptchaResponse, vrmPathSegment, isVersion3) {
		createServerAction(ActionTypes.LOOKUP_VRM);
		vrmApi.getVrmInfo(vehicleType, vrm, recaptchaResponse, vrmPathSegment, isVersion3, {
			successCallback: function (data) {
				var vrmApiResult = JSON.parse(data);
				if (vrmApiResult && vrmApiResult.isSuccess) {
					createServerAction(ActionTypes.LOOKUP_VRM_SUCCESS, vrmApiResult);
						populateTaxonomySelector(vehicleType, vrmApiResult.manufacturerKey, vrmApiResult.rangeKey, vrmApiResult.modelKey, vrmApiResult.derivativeKey);
				} else {
					createServerAction(ActionTypes.LOOKUP_VRM_ERROR, vrmApiResult);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.LOOKUP_VRM_ERROR);
			}
		});
	},

	loadManufacturers: function (vehicleType, filterName) {
		createServerAction(ActionTypes.TAXONOMY_SELECTOR_LOAD_MANUFACTURERS);
		taxonomyApi.getManufacturers(vehicleType, filterName, {
			successCallback: function (data) {
				if (data.manufacturers) {
					createServerAction(ActionTypes.TAXONOMY_SELECTOR_LOAD_MANUFACTURERS_SUCCESS, data);
				} else {
					createServerAction(ActionTypes.TAXONOMY_SELECTOR_LOAD_MANUFACTURERS_ERROR);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.TAXONOMY_SELECTOR_LOAD_MANUFACTURERS_ERROR);
			}
		});
	},

	loadRanges: function (vehicleType, filterName, manufacturerKey) {
		createServerAction(ActionTypes.TAXONOMY_SELECTOR_LOAD_RANGES);
		taxonomyApi.getRangesForManufacturer(vehicleType, filterName, manufacturerKey, {
			successCallback: function (data) {
				if (data.ranges) {
					createServerAction(ActionTypes.TAXONOMY_SELECTOR_LOAD_RANGES_SUCCESS, data);
				} else {
					createServerAction(ActionTypes.TAXONOMY_SELECTOR_LOAD_RANGES_ERROR);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.TAXONOMY_SELECTOR_LOAD_RANGES_ERROR);
			}
		});
	},

	loadModels: function (vehicleType, filterName, manufacturerKey, rangeKey) {
		createServerAction(ActionTypes.TAXONOMY_SELECTOR_LOAD_MODELS);
		taxonomyApi.getModelsForRange(vehicleType, filterName, manufacturerKey, rangeKey, {
			successCallback: function (data) {
				if (data.rangeYears) {
					createServerAction(ActionTypes.TAXONOMY_SELECTOR_LOAD_MODELS_SUCCESS, data);
				} else {
					createServerAction(ActionTypes.TAXONOMY_SELECTOR_LOAD_MODELS_ERROR);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.TAXONOMY_SELECTOR_LOAD_MODELS_ERROR);
			}
		});
	},

	selectManufacturer: function (manufacturerKey) {
		createViewAction(ActionTypes.TAXONOMY_SELECTOR_SELECT_MANUFACTURER, {
			manufacturerKey: manufacturerKey
		});
	},

	selectRange: function (rangeKey) {
		createViewAction(ActionTypes.TAXONOMY_SELECTOR_SELECT_RANGE, {
			rangeKey: rangeKey
		});
	},

	selectModel: function (modelKey) {
		createViewAction(ActionTypes.TAXONOMY_SELECTOR_SELECT_MODEL, {
			modelKey: modelKey
		});
	},

	selectDerivative: function (derivativeKey) {
		createViewAction(ActionTypes.TAXONOMY_SELECTOR_SELECT_DERIVATIVE, {
			derivativeKey: derivativeKey
		});
	}
};

module.exports = Actions;