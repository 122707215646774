'use strict';

var logger			= require('../../utils/logger.js');
var quickFindApi	= require('../../api/quick-find-api.js');
var analytics = require('../../utils/analytics.js');
var user = require('../../profile/user.js');

var FluxDispatcher	= require('../dispatchers/flux-dispatcher.js');
var Constants		= require('../constants/quick-find-year-derivative-constants.js');

var ActionTypes			= Constants.ActionTypes;
var ValuationPurpose	= Constants.ValuationPurpose;
var EventCategories = Constants.EventCategory;

function log(source, actionType, data) {
	logger.debug('Quick Find Year & Derivative', {
		message: 'Create ' + source + ' action',
		action: actionType,
		data: data
	});
}

function createServerAction(actionType, data) {
	log('server', actionType, data);
	FluxDispatcher.dispatchServerAction({
		type: actionType,
		data: data
	});
}

function createViewAction(actionType, data) {
	log('view', actionType, data);
	FluxDispatcher.dispatchViewAction({
		type: actionType,
		data: data
	});
}

var Actions = {
	loadYears: function(vehicleType, filterName, modelKey, selectedYearPlate, selectedVersion) {
		createServerAction(ActionTypes.YEAR_DERIVATIVE_LOAD_YEARS);
		quickFindApi.getYearsForModel(vehicleType, filterName, modelKey, {
			successCallback: function (data) {
				if (data.yearPlates) {
					data.selectedYearPlate = selectedYearPlate;
					data.selectedVersion = selectedVersion;
					createServerAction(ActionTypes.YEAR_DERIVATIVE_LOAD_YEARS_SUCCESS, data);
				} else {
					createServerAction(ActionTypes.YEAR_DERIVATIVE_LOAD_YEARS_ERROR);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.YEAR_DERIVATIVE_LOAD_YEARS_ERROR);
			}
		});
	},

	initialiseValuationPurposeOptions: function() {
		createViewAction(ActionTypes.YEAR_DERIVATIVE_INITIALISE_VALUATION_PURPOSE);
	},

	selectYearPlate: function(yearPlateKey) {
		createViewAction(ActionTypes.YEAR_DERIVATIVE_SELECT_YEAR_PLATE, {
			yearPlateKey: yearPlateKey
		});
	},

	selectDerivative: function(derivativeUrl, redirectImmediately) {
		if (redirectImmediately){
			createViewAction(ActionTypes.YEAR_DERIVATIVE_SELECT_DERIVATIVE_LOADING);
			window.location = derivativeUrl;
		}

		createViewAction(ActionTypes.YEAR_DERIVATIVE_SELECT_DERIVATIVE, {
			derivativeUrl: derivativeUrl
		});
	},

	selectValuationPurposeOption: function (valuationPurpose) {
		createViewAction(ActionTypes.YEAR_DERIVATIVE_SELECT_VALUATION_PURPOSE, {
			valuationPurpose: valuationPurpose
		});
	},

	submitForm: function (derivativeUrl, valuationPurpose, vehicleType) {
		if (derivativeUrl) {
			if (valuationPurpose !== ValuationPurpose.BUYING_A_CAR.NAME &&
				valuationPurpose !== ValuationPurpose.SELLING_A_CAR.NAME &&
				valuationPurpose !== ValuationPurpose.INSURING_A_CAR.NAME &&
				valuationPurpose !== ValuationPurpose.JUST_CURIOUS.NAME) {
				createViewAction(ActionTypes.YEAR_DERIVATIVE_VALIDATION_ERROR);
			} else {
				user.saveValuationPurpose(valuationPurpose);
				if (vehicleType === 'cars') {
					analytics.trackEvent(EventCategories.CAR, valuationPurpose);
				} else {
					analytics.trackEvent(EventCategories.VAN, valuationPurpose);
				}
				window.location = derivativeUrl;
			}
		} else {
			createViewAction(ActionTypes.YEAR_DERIVATIVE_VALIDATION_ERROR);
		}
	}
};

module.exports = Actions;