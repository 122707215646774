/** @jsx React.DOM */'use strict';

var $ = require('jquery');
var React = require('react');
var Constants = require('../../constants/car-chooser-constants.js');

var DisplayToggle = require('./display-toggle.jsx');

var classNames = {
	carChooser: 'car-chooser',
	contentPanel: 'lifestyles__content'
};

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		onLifestyleToggle: React.PropTypes.func.isRequired,
		selectedLifestyles: React.PropTypes.array.isRequired,
		onDisplayToggle: React.PropTypes.func,
		expand: React.PropTypes.bool
	},

	getDefaultProps: function() {
		return {
			selectedLifestyles: [],
			expand: true
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var sectionDisplayClass = 'car-chooser__lifestyles__content';

		if (this.props.expand) {
			sectionDisplayClass +=' car-chooser__lifestyles__content--expanded';
		}

		return (
			<div className="car-chooser__lifestyles">
				<h4>What do you want to do with your car?</h4>
				<div className={sectionDisplayClass}>
					<ul className="block-grid small-block-grid-2 medium-block-grid-4">
						{this.getLifestyleButton(Constants.Lifestyles.NEW_DRIVERS, 'New drivers', 'new-drivers')}
						{this.getLifestyleButton(Constants.Lifestyles.COMPANY_CAR, 'Company car', 'company-car')}
						{this.getLifestyleButton(Constants.Lifestyles.LONG_JOURNEY, 'Long journey', 'long-journey')}
						{this.getLifestyleButton(Constants.Lifestyles.ALL_SEASONS, 'All seasons', 'all-seasons')}
						{this.getLifestyleButton(Constants.Lifestyles.BIG_BOOT, 'Big boot', 'big-boot')}
						{this.getLifestyleButton(Constants.Lifestyles.EASY_ACCESS, 'Easy access', 'easy-access')}
						{this.getLifestyleButton(Constants.Lifestyles.CITY_DRIVING, 'City driving', 'city-driving')}
						{this.getLifestyleButton(Constants.Lifestyles.STATEMENT, 'Make a statement', 'statement')}
					</ul>
				</div>
				<DisplayToggle onDisplayToggle={this.props.onDisplayToggle} expand={this.props.expand} contentPanelClassName={classNames.contentPanel} />
			</div>
		);
	},

	getLifestyleButton: function(key, text, className) {
		var buttonClass = 'lifestyles__link';
		var iconClass = 'lifestyles__link__icon lifestyles__link__icon--' + className;


		if (this.props.selectedLifestyles.indexOf(key) !== -1) {
			buttonClass += ' lifestyles__link--selected';
		}

		return (
			<li>
				<button value={key} className={buttonClass} onClick={this.onLifestyleClick}>
					<span className={iconClass}></span>
					{text}
				</button>
			</li>
		);
	},
	/* #endregion */

	/* #region Event handlers */
	onLifestyleClick: function (e) {
		var lifestyle = $(e.target).closest('button').val();

		if (lifestyle) {
			this.props.onLifestyleToggle(lifestyle);
		}
	}
	/* #endregion */
});
