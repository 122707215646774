'use strict';

var keyMirror = require('keymirror');

module.exports = {
	ActionTypes: keyMirror({
		QUICK_FIND_INIT_STORE_DATA: null,
		QUICK_FIND_LOAD_MANUFACTURERS: null,
		QUICK_FIND_LOAD_MANUFACTURERS_SUCCESS: null,
		QUICK_FIND_LOAD_MANUFACTURERS_ERROR: null,
		QUICK_FIND_LOAD_RANGES: null,
		QUICK_FIND_LOAD_RANGES_SUCCESS: null,
		QUICK_FIND_LOAD_RANGES_ERROR: null,
		QUICK_FIND_SELECT_MANUFACTURER: null,
		QUICK_FIND_SELECT_RANGE: null,
		QUICK_FIND_SELECT_MODEL: null,
		QUICK_FIND_SUBMIT_FORM: null,
		QUICK_FIND_VALIDATION_ERROR: null
	})
};