/** @jsx React.DOM */'use strict';

var $ = require('jquery');
var React = require('react');
var DisplayToggle = require('./display-toggle.jsx');

var classNames = {
	carChooser: 'car-chooser',
	contentPanel: 'classifications__content'
};


module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		onClassificationToggle: React.PropTypes.func.isRequired,
		selectedClassifications: React.PropTypes.array.isRequired,
		onDisplayToggle: React.PropTypes.func,
		expand: React.PropTypes.bool
	},

	getDefaultProps: function() {
		return {
			selectedClassifications: [],
			expand: true
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var sectionDisplayClass = 'car-chooser__classifications__content';

		if (this.props.expand) {
			sectionDisplayClass +=' car-chooser__classifications__content--expanded';
		}

		return (
			<div className="car-chooser__classifications">
				<h4>What type of car are you looking for?</h4>
				<div className={sectionDisplayClass}>
				<ul className="block-grid small-block-grid-2 medium-block-grid-4 large-block-grid-3">
					{this.getClassificationButton('Convertible / Roadster', 'Convertible', 'convertible-roadster')}
					{this.getClassificationButton('Coupe', 'Coupé', 'coupe')}
					{this.getClassificationButton('Electric / Hybrid', 'Electric/Hybrid', 'electric-hybrid')}
					{this.getClassificationButton('Estate', 'Estate', 'estate')}
					{this.getClassificationButton('Family', 'Family', 'family')}
					{this.getClassificationButton('Fast / Sports', 'Sports Car', 'fast-sports')}
					{this.getClassificationButton('Hatchback', 'Hatchback', 'hatchback')}
					{this.getClassificationButton('MPV / People Carrier', 'People Carrier', 'mpv-people-carrier')}
					{this.getClassificationButton('Saloon', 'Saloon', 'saloon')}
					{this.getClassificationButton('Small / City', 'Small/City Car', 'small-city')}
					{this.getClassificationButton('SUV', 'SUV', 'suv')}
					{this.getClassificationButton('4x4', '4x4', '4x4')}
				</ul>
				</div>
				<DisplayToggle onDisplayToggle={this.props.onDisplayToggle} expand={this.props.expand} contentPanelClassName={classNames.contentPanel} />
			</div>
		);
	},

	getClassificationButton: function(key, text, className) {
		var buttonClass = 'body-styles__link';
		var iconClass = 'body-styles__link__icon body-styles__link__icon--' + className;


		if (this.props.selectedClassifications.indexOf(key) !== -1) {
			buttonClass += ' body-styles__link--selected';
		}

		return (
			<li>
				<button value={key} className={buttonClass} onClick={this.onClassificationClick}>
					<span className={iconClass}></span>
					{text}
				</button>
			</li>
		);
	},
	/* #endregion */

	/* #region Event handlers */
	onClassificationClick: function (e) {
		var classificationId = $(e.target).closest('button').val();

		if (classificationId) {
			this.props.onClassificationToggle(classificationId);
		}
	}
	/* #endregion */
});
