/** @jsx React.DOM */'use strict';

var React = require('react');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		showAdvancedFilters: React.PropTypes.bool,
		onButtonClick: React.PropTypes.func.isRequired
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var className = "search-filters__advanced-filters__expand-link";
		if (this.props.showAdvancedFilters){
			className = className + " search-filters__advanced-filters__expand-link--hidden"
		}

		return (
			<button className={className} onClick={this.props.onButtonClick}>
					<span className="icon icon-gap-right fi-plus"></span>
					More filters
			</button>
		);
	}
	/* #endregion */
});