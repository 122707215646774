'use strict';

var $ = require('jquery');
var Siema = require('siema');

var analytics = require('../utils/analytics.js');
var layout = require('../ui/layout.js');
var logger = require('../utils/logger.js');
var screenSizeWatcher	= require('../watchers/screen-size-watcher.js');

var classNames = {
	carouselLoaded: 'carousel-loaded',
	modelCarousel: 'model-carousel',
	modelSwitcher: 'model-switcher',
	nav: 'nav',
	item: 'item'
};

var modifierNames = {
	next: 'next',
	prev: 'prev',
	disabled: 'disabled'
};

var swiped = false;

function setNavState($modelSwitcher) {
	var $modelCarousel = $modelSwitcher.findByBem(classNames.modelCarousel);
	var $items = $modelSwitcher.findByBem(classNames.modelCarousel, classNames.item);

	var $navPrev = $modelSwitcher.findByBem(classNames.modelSwitcher, classNames.nav, modifierNames.prev);
	var $navNext = $modelSwitcher.findByBem(classNames.modelSwitcher, classNames.nav, modifierNames.next);

	var $firstItem = $items.first();
	var firstInView = $firstItem.offset().left >= $modelCarousel.offset().left;

	var $lastItem = $items.last();
	var lastInView = $lastItem.offset().left + $lastItem.width() <= $modelCarousel.offset().left + $modelCarousel.width();

	if (firstInView) {
		$navPrev.addBemClass(classNames.modelSwitcher, classNames.nav, modifierNames.disabled);
	} else {
		$navPrev.removeBemClass(classNames.modelSwitcher, classNames.nav, modifierNames.disabled);
	}
	
	if (lastInView) {
		$navNext.addBemClass(classNames.modelSwitcher, classNames.nav, modifierNames.disabled);
	} else {
		$navNext.removeBemClass(classNames.modelSwitcher, classNames.nav, modifierNames.disabled);
	}
}

function loadModelCarousel() {
	var $modelSwitcher = $.findByBem(classNames.modelSwitcher);
	var $modelCarousel = $modelSwitcher.findByBem(classNames.modelCarousel);

	if ($modelCarousel.length > 0) {
		var carouselLoaded = $modelCarousel.hasClass(classNames.carouselLoaded);
		
		if (!carouselLoaded) {
			var carousel = new Siema({
				selector: '.' + classNames.modelCarousel,
				// Default 3 slides, increase to 5 at 800px width
				perPage: {
					0: 3,
					800: 5
				},
				onChange: function () {
					if (!swiped && $modelSwitcher.hasClass(classNames.carouselLoaded)) {
						analytics.trackEvent('Model Switcher', 'Swipe');
						swiped = true;
					}
					window.setTimeout(function () {
						setNavState($modelSwitcher);
					}, 300);
				}
			});
					
			$modelSwitcher.findByBem(classNames.modelSwitcher, classNames.nav, modifierNames.prev).on('click.slider', function(e) {
				e.preventDefault();
				carousel.prev();
			});

			$modelSwitcher.findByBem(classNames.modelSwitcher, classNames.nav, modifierNames.next).on('click.slider', function(e) {
				e.preventDefault();
				carousel.next();
			});

			carousel.goTo(parseInt($modelCarousel.attr('data-selected-index') || 0));
			setNavState($modelSwitcher);
			$modelSwitcher.addClass(classNames.carouselLoaded);

			screenSizeWatcher.onChange(function () {
				setNavState($modelSwitcher);
			});
		}
	}
}

function setTrackingEvents() {
	var screenSize = layout.getScreenSize();

	$.findByBem(classNames.modelSwitcher, 'item__link').each(function (index, element) {
		$(element).off('click.model-switcher')
			.on('click.model-switcher', function () {
				logger.debug('Model Carousel Click');
				analytics.trackEvent('Model Carousel Interaction', screenSize, ' ');
			});
	});
	$.findByBem(classNames.modelSwitcher, 'dropdown').off('change.modelSwitcherDropdown').on('change.modelSwitcherDropdown', function() {
		logger.debug('Model Dropdown Click');
		analytics.trackEvent('Model Carousel Interaction', screenSize, ' ');
		// label set to a single space in both trackEvent calls and above to prevent the page template name being used as the label
	});
}

module.exports = {
	init: function () {
		loadModelCarousel();
		setTrackingEvents();
	}
};