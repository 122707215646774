'use strict';

var $ = require('jquery');

var classNames = {
	backToTop: 'back-to-top'
};

module.exports = {
	init: function () {
		$.findByBem(classNames.backToTop).off('click.backToTop').on('click.backToTop', function (e) {
			e.preventDefault();
			$(window).scrollTop(0);
		});
	}
};