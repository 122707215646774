/** @jsx React.DOM */'use strict';

var assign = require('object-assign');
var numeral = require('numeral');
var React = require('react');
var $ = require('jquery');

var logger = require('../../utils/logger.js');
var postcodeHelper = require('../../utils/postcode-helper.js');
var analytics = require('../../utils/analytics.js');
var searchFilters = require('../../components/search-filters.js');
var layout = require('../../ui/layout.js');
var sticky = require('../../ui/sticky.js');
var scroll = require('../../ui/scroll.js');

var Actions = require('../actions/cfs-search-filters-actions.js');
var Store = require('../stores/cfs-search-filters-store.js');

var CarType = require('./search-filters/car-type-view.jsx');
var Colour = require('./search-filters/colour-view.jsx');
var Derivative = require('./search-filters/derivative-view.jsx');
var Emissions = require('./search-filters/emissions-view.jsx');
var EngineSize = require('./search-filters/engine-size-view.jsx');
var FuelType = require('./search-filters/fuel-type-view.jsx');
var Gearbox = require('./search-filters/gearbox-view.jsx');
var InsuranceGroup = require('./search-filters/insurance-group-view.jsx');
var Keywords = require('./search-filters/keywords-view.jsx');
var Location = require('./search-filters/location-view.jsx');
var Make = require('./search-filters/make-view.jsx');
var Mileage = require('./search-filters/mileage-view.jsx');
var Model = require('./search-filters/model-view.jsx');
var MonthlyRange = require('./search-filters/monthly-repayment-range-view.jsx');
var Mpg = require('./search-filters/mpg-view.jsx');
var PriceRange = require('./search-filters/price-range-view.jsx');
var PriceType = require('./search-filters/price-type-view.jsx');
var Range = require('./search-filters/range-view.jsx');
var RoadTax = require('./search-filters/road-tax-view.jsx');
var SearchType = require('./search-filters/search-type-view.jsx');
var Seats = require('./search-filters/seats-view.jsx');
var Doors = require('./search-filters/doors-view.jsx');
var SellerType = require('./search-filters/seller-type-view.jsx');
var Trim = require('./search-filters/trim-view.jsx');
var Year = require('./search-filters/year-view.jsx');
var ResetFilters = require('./search-filters/reset-filters-view.jsx');
var AdvancedButton = require('./search-filters/advanced-filters-button-view.jsx');

var isEditingPostcode = false;
var enablePostcodeInfoBubble = false;
var postcodeIsValid = false;
var isInfoBubbleShowing = false;
var isPostcodeRequired = false;
var filtersDisabled = false;

var tracking = {
	category: {
		added: 'CFS Filters',
		removed: 'CFS Filters Removed'
	},
	labels: {
		keywords: 'Keywords',
		distance: 'Distance',
		postcode: 'Postcode'
	}
};

function updateFilterState(changedItem, newValue) {

	var filterRequest = Store.getFilterRequest();

	if (!isPostcodeRequired || postcodeIsValid) {
		if (!layout.isMobile()) {
			var $searchresults = $.findByBem('reset-marker');
			scroll.scrollTo($searchresults, 'slow');
		}

		isInfoBubbleShowing = false;
		filtersDisabled = false;

		$.findByBem('alert-box').addBemClass('hide').removeBemClass('alert-box', null, 'info-bubble');
		$.findByBem('search-filters__location__postcode').removeBemClass('search-filters__item', null, 'required');

		searchFilters.hideSeoCrawlPaths();
		filterRequest[changedItem] = newValue;

		var dynamicallyUpdate = layout.isDesktop() || layout.isTablet();
		Actions.updateFilterState(filterRequest, dynamicallyUpdate);
	} else if (isPostcodeRequired && enablePostcodeInfoBubble && !filterRequest['postcode']) {
		scroll.scrollTo(null, 'slow', '.site-sidebar-left__search-filters__container');

		if (!isInfoBubbleShowing) {
			$.findByBem('alert-box').removeBemClass('hide').addBemClass('alert-box', null, 'info-bubble');
			$.findByBem('search-filters__location__postcode').addBemClass('search-filters__item', null, 'required');
		}

		isInfoBubbleShowing = true;
		filtersDisabled = true;
	}
}

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		searchType: React.PropTypes.string.isRequired,
		filterRequest: React.PropTypes.object
	},

	getInitialState: function () {
		return Store.getState();
	},

	componentWillMount: function () {
		Store.addChangeListener(this.onStoreChange);
		Actions.initStoreData({
			searchType: this.props.searchType
		});
	},

	componentDidMount: function () {
		var filterRequest = this.props.filterRequest;

		if (!filterRequest.latLong) {
			// copy saved lat long
			filterRequest.latLong = this.state.filterState.latLong;
		}

		if (!filterRequest.postcode) {
			// copy saved postcode
			filterRequest.postcode = this.state.filterState.postcode;
		}

		Actions.getFilterState(filterRequest);

		searchFilters.showSeoCrawlPaths();
	},

	componentDidUpdate: function () {
		searchFilters.init();
		sticky.resetStickySidebar();
	},

	componentWillUnmount: function () {
		Store.removeChangeListener(this.onStoreChange);
	},

	onStoreChange: function () {
		this.setState(Store.getState());
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		logger.debug('CFS Search Filters', {
			message: 'Render',
			state: this.state
		});

		var cssClass = 'cfs-search-filters search-filters';

		if (this.state.isLoading) {
			cssClass += ' search-filters--loading';
		}

		if (this.state.filterStateError) {
			cssClass += ' search-filters--error';
		}

		enablePostcodeInfoBubble = this.state.filterState.enablePostcodeInfoBubble;
		var errorMessage = this.getErrorMessage();
		var updateButton = this.getUpdateButton();
		var resultMessage = this.getResultMessage();

		isPostcodeRequired = this.state.filterState.isPostcodeRequired;
		postcodeIsValid = (this.state.filterState.postcode && postcodeHelper.isValid(this.state.filterState.postcode)) ||
			(this.state.filterState.latLong && this.state.filterState.latLong.isValid);

		var advancedFilters;
		var advancedFiltersClassName = $.getBemClass('search-filters', 'advanced-filters__content');
		if (!this.state.showCfsAdvancedFilters) {
			advancedFiltersClassName = advancedFiltersClassName + ' hide';
		}


		if (this.isUsedOrNearlyNew()) {
			advancedFilters = (
				<div className={advancedFiltersClassName}>
					<Mileage
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet}
						searchType={this.state.searchType} />
					<Year
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<Colour
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<Gearbox
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<FuelType
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<EngineSize
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<Seats
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<Doors
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<InsuranceGroup
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<RoadTax
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<SellerType
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
				</div>
			);
		} else {
			advancedFilters = (
				<div className={advancedFiltersClassName}>
					<Gearbox
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<FuelType
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<EngineSize
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<Seats
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<Doors
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<Mpg
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<InsuranceGroup
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<RoadTax
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
					<Emissions
						filterState={this.state.filterState}
						isLoading={this.state.isLoading}
						isDisabled={filtersDisabled}
						onItemRemove={this.onItemRemove}
						onItemSet={this.onItemSet} />
				</div>
			);
		}

		var priceOptions = [0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 12000, 14000, 16000, 18000, 20000,
			25000, 30000, 35000, 40000, 45000, 50000, 60000, 70000, 80000, 90000, 100000, 120000, 140000, 160000, 200000, 240000];

		var monthlyPriceOptions = [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300];

		return (
			<div>
				<form action={this.state.filterState.searchResultsUrl} className={cssClass} onSubmit={this.onFormSubmit} data-update={this.state.updateResults}>
					
					<div className="search-filters__heading hide-for-large-up">
						<span>Filter vehicle results</span>
						{this.getCloseButton()}
						<label className="search-filters__heading__label">save &amp; close</label>
					</div>

					{errorMessage}

					<div className="site-sidebar-left__search-filters__container">
						{resultMessage}

						<div className="hide-for-large-up search-filters__result-container">
							<ResetFilters
								onFiltersReset={this.onFiltersReset}
								filterState={this.state.filterState} />
						</div>

						<SearchType
							currentSearchType={this.props.searchType}
							switchSearchTypeUrl={this.state.filterState.switchSearchTypeUrl} />

						<Location
							filterState={this.state.filterState}
							isLoading={this.state.isLoading}
							searchType={this.props.searchType}
							waitingForLocation={this.state.waitingForLocation}
							onDistanceSet={this.onDistanceSet}
							onLocationToggle={this.onLocationToggle}
							onPostcodeSet={this.onPostcodeSet}
							postcodeError={this.getPostcodeError()} />
						<section className="search-filters__group">
							<Make
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={filtersDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
							<Range
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={filtersDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
							<Model
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={filtersDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
							<Trim
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={filtersDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
							<Derivative
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={filtersDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
							<CarType
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={filtersDisabled}
								onItemRemove={this.onItemRemove}
								onItemSet={this.onItemSet} />
						</section>
						{this.isUsedOrNearlyNew() ?
							<PriceType
								onSwitch={this.onPriceTypeSet}
								isMonthlySelected={this.state.filterState.useMonthlyPrice} /> : null}
						{!this.state.filterState.useMonthlyPrice ?
							<PriceRange
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={filtersDisabled}
								priceOptions={priceOptions}
								validationErrors={this.state.validationErrors}
								onValidationError={this.onValidationError}
								onValueSet={this.onValueSet} /> : null}
						{this.state.filterState.useMonthlyPrice ?
							<MonthlyRange
								filterState={this.state.filterState}
								isLoading={this.state.isLoading}
								isDisabled={filtersDisabled}
								monthlyPriceOptions={monthlyPriceOptions}
								validationErrors={this.state.validationErrors}
								onValidationError={this.onValidationError}
								onValueSet={this.onValueSet} /> : null}
						<section className="search-filters__advanced-filters search-filters__group">
							<AdvancedButton
								onButtonClick={this.onAdvancedButtonClick}
								showAdvancedFilters={this.state.showCfsAdvancedFilters} />
							{advancedFilters}
						</section>

						<Keywords
							filterState={this.state.filterState}
							isLoading={this.state.isLoading}
							isDisabled={filtersDisabled}
							onKeywordsSet={this.onKeywordsSet} />

					</div>
					{updateButton}
				</form>
			</div>
		);
	},

	getCloseButton: function () {
		return (
			<button type="button" className="search-filters__heading__button left-off-canvas-toggle hide-for-large-up"></button>
	   );
	},

	getErrorMessage: function () {
		if (this.state.filterStateError) {
			return (
				<div className="search-filters__result-count search-filters__result-count--error">Something went wrong! Please refresh your page.</div>
			);
		}
	},

	getUpdateButton: function () {
		var disabled = this.state.isLoading;
		var cssClass = 'sticky-off-canvas-bottom__button';
		
		if (disabled) {
			cssClass = 'sticky-off-canvas-bottom__button--disabled ' + cssClass;

			return (
				<div className="sticky-off-canvas-bottom">
					<button type="submit" className={cssClass} disabled={disabled}>
						<div className="sticky-off-canvas-bottom__spinner"></div>
						<span className="sticky-off-canvas-bottom__result">Loading results...</span>
					</button>
				</div>
			);
		}
		else {
			var results = this.getFormattedResultCount();
			var buttonContent = (
				<span>View {results}</span>
			);

			return (
				<div className="sticky-off-canvas-bottom">
					<button type="submit" className={cssClass} disabled={disabled}>{buttonContent}</button>
				</div>
			);
		}
	},

	getResultMessage: function () {
		var message;

		if (this.state.isLoading) {
			message = 'Loading...'
		}
		else {
			var count = this.getFormattedResultCount();

			message = (
				<span>{count} found</span>
			);

		}

		return (
			<div className='search-filters__result-count'><span>{message}</span></div>
		);

	},

	getFormattedResultCount: function () {
		var count = this.state.filterState.totalResults || 0;
		var suffix = (count === 1) ? 'result' : 'results';
		var formattedCount = numeral(count).format('0,0');

		return (
			<span><span className='sticky-off-canvas-bottom__count'>{formattedCount}</span> {suffix}</span>
		);
	},

	getPostcodeError: function () {
		var errorText = null;
		var postcode = this.state.filterState.postcode;
		var postcodeInvalid = postcode && !postcodeHelper.isValid(postcode);

		if (!isEditingPostcode && postcodeInvalid) {
			errorText = "The postcode is invalid."
		}

		return errorText;
	},
	/* #endregion */

	/* #region Event handlers */
	onDistanceSet: function (distance) {
		Actions.setDistance(distance);

		if (this.state.filterState.postcode || (this.state.filterState.latLong && this.state.filterState.latLong.isValid)) {
			updateFilterState('distance', distance);
			analytics.trackEvent(tracking.category.added, tracking.labels.distance, distance);
		}
	},

	onFormSubmit: function (e) {
		e.preventDefault();
		if (!this.state.isLoading) {
			Actions.updateResults(e.target.action, this.state.updateResults);
		}
	},

	onItemRemove: function (name) {
		Actions.removeItem(name);
		updateFilterState(name, null);
		analytics.trackEvent(tracking.category.removed, name, '-');
	},

	onItemSet: function (name, value, text) {
		if (isPostcodeRequired && (!this.state.filterState.postcode || !postcodeIsValid)) {
			filtersDisabled = true;
		}

		Actions.setItem(name, value, text);
		updateFilterState(name, value);
		analytics.trackEvent(tracking.category.added, name, text);
	},

	onKeywordsSet: function (keywords, updateCount) {
		Actions.setKeywords(keywords);
		if (updateCount) {
			updateFilterState('keywords', keywords);

			// Only track on Enter keypress or blur event.
			analytics.trackEvent(tracking.category.added, tracking.labels.keywords, keywords);
		}
	},

	onLocationToggle: function () {
		var latLong = this.state.filterState.latLong;
		var distance = this.state.filterState.distance;

		if (latLong && latLong.isValid && latLong.source === 'Browser') {
			Actions.removeLocation();
			if (distance) {
				updateFilterState('latLong', null);
			}
		} else {
			Actions.getLocation(function (latLong) {
				if (distance && latLong && latLong.isValid) {
					updateFilterState('latLong', latLong);
				}
			});
		}
	},

	onPostcodeSet: function (postcode, previousPostcode, updateCount) {
		if (!updateCount) {
			isEditingPostcode = true;
		}

		Actions.setPostcode(postcode, updateCount);

		if (updateCount && this.state.filterState.distance) {
			isEditingPostcode = false;

			var postcodeProvided = postcodeHelper.isValid(postcode);
			var postcodeRemoved = !postcode;

			if (postcodeProvided || postcodeRemoved) {
				updateFilterState('postcode', postcode);
				analytics.trackEvent(tracking.category.added, tracking.labels.postcode, 'Postcode entered');
			}
		}
	},

	onPriceTypeSet: function (useMonthlyPrice) {
		Actions.switchPriceType(useMonthlyPrice);
		updateFilterState('useMonthlyPrice', useMonthlyPrice);
	},

	onValueSet: function (name, value) {
		Actions.setValue(name, value);
		updateFilterState(name, value);
		analytics.trackEvent(tracking.category.added, name, value !== '' ? value : '-');
	},

	onFiltersReset: function () {
		var data = Store.getResetRequest();
		Actions.resetFilters(data, !layout.isDesktop());
	},

	onAdvancedButtonClick: function (e) {
		var $searchFilters = $.findByBem('search-filters', 'advanced-filters__expand-link');
		e.preventDefault();
		$searchFilters.hide().siblingsByBem('search-filters', 'advanced-filters__content').slideDown('fast',
			function () {
				Actions.setAdvancedFilters();
			});
	},

	isUsedOrNearlyNew: function () {
		return this.state.searchType === 'Used' || this.state.searchType === 'NearlyNew';
	},

	getPostcodePrompt: function () {
		return "Postcode";
	}

	/* #endregion */
});
