'use strict';

var cookies = require('./cookie.js');

var config = require('../utils/config.js');

var cookieName = 'Parkers.CFS.Shortlist';
var cookieExpires = 365;

module.exports = {
	deleteCookie: function () {
		cookies.deleteCookie(cookieName);
	},

	getCookie: function () {
		return cookies.getCookie(cookieName);
	},

	setCookie: function (cookieData) {
		cookies.setCookie(
			cookieName,
			cookieData,
			{
				expires: cookieExpires,
				domain: config.cookieDomain
			}
		);
	}
};