'use strict';

var Q = require('kew');

var logger = require('../../utils/logger.js');
var quickFindApi = require('../../api/quick-find-api.js');

var FluxDispatcher = require('../dispatchers/flux-dispatcher.js');
var Constants = require('../constants/company-car-tax-quick-find-constants.js');

var ActionTypes	= Constants.ActionTypes;

var loadManufacturerPromise;

function log(source, actionType, data) {
	logger.debug('Company Car Tax Quick Find', {
		message: 'Create ' + source + ' action',
		action: actionType,
		data: data
	});
}

function createServerAction(actionType, data) {
	log('server', actionType, data);
	FluxDispatcher.dispatchServerAction({
		type: actionType,
		data: data
	});
}

function createViewAction(actionType, data) {
	log('view', actionType, data);
	FluxDispatcher.dispatchViewAction({
		type: actionType,
		data: data
	});
}

var Actions = {
	initStoreData: function (storeData) {
		createViewAction(ActionTypes.COMPANY_CAR_TAX_INIT_STORE_DATA, storeData);
	},

	loadInitialState: function() {
		createServerAction(ActionTypes.COMPANY_CAR_TAX_LOAD_INITIAL_STATE);

		loadManufacturerPromise = Q.defer();

		quickFindApi.getManufacturers('cars', 'company-car-tax', {
			successCallback: function (data) {
				if (data.manufacturers) {
					createServerAction(ActionTypes.COMPANY_CAR_TAX_LOAD_INITIAL_STATE_SUCCESS, data);
				} else {
					createServerAction(ActionTypes.COMPANY_CAR_TAX_LOAD_INITIAL_STATE_ERROR);
				}

				if (loadManufacturerPromise) {
					loadManufacturerPromise.resolve();
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.COMPANY_CAR_TAX_LOAD_INITIAL_STATE_ERROR);
			}
		});
	},

	loadRanges: function (manufacturerKey) {
		createServerAction(ActionTypes.COMPANY_CAR_TAX_LOAD_RANGES);
		quickFindApi.getRangesForManufacturer('cars', 'company-car-tax', manufacturerKey, false, {
			successCallback: function (data) {
				if (data.ranges) {
					
					if (loadManufacturerPromise) {
						loadManufacturerPromise.then(function () {
							createServerAction(ActionTypes.COMPANY_CAR_TAX_LOAD_RANGES_SUCCESS, data);
						});
					}

				} else {
					createServerAction(ActionTypes.COMPANY_CAR_TAX_LOAD_RANGES_ERROR);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.COMPANY_CAR_TAX_LOAD_RANGES_ERROR);
			}
		});
	},

	loadYearPlates: function (modelKey) {
		createServerAction(ActionTypes.COMPANY_CAR_TAX_LOAD_YEAR_PLATES);
		quickFindApi.getYearsForModel('cars', 'company-car-tax', modelKey, {
			successCallback: function (data) {
				if (data.yearPlates) {
					createServerAction(ActionTypes.COMPANY_CAR_TAX_LOAD_YEAR_PLATES_SUCCESS, data);
				} else {
					createServerAction(ActionTypes.COMPANY_CAR_TAX_LOAD_YEAR_PLATES_ERROR);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.COMPANY_CAR_TAX_LOAD_YEAR_PLATES_ERROR);
			}
		});
	},

	selectManufacturer: function (manufacturerKey) {
		createViewAction(ActionTypes.COMPANY_CAR_TAX_SELECT_MANUFACTURER, {
			manufacturerKey: manufacturerKey
		});
	},

	selectRange: function (rangeKey) {
		createViewAction(ActionTypes.COMPANY_CAR_TAX_SELECT_RANGE, {
			rangeKey: rangeKey
		});
	},

	selectModel: function (modelKey) {
		createViewAction(ActionTypes.COMPANY_CAR_TAX_SELECT_MODEL, {
			modelKey: modelKey
		});
	},

	selectYearPlate: function(yearPlateKey) {
		createViewAction(ActionTypes.COMPANY_CAR_TAX_SELECT_YEAR_PLATE, {
			yearPlateKey: yearPlateKey
		});
	},

	selectDerivative: function(derivativeKey) {
		createViewAction(ActionTypes.COMPANY_CAR_TAX_SELECT_DERIVATIVE, {
			derivativeKey: derivativeKey
		});
	},

	selectTaxYear: function(taxYearKey) {
		createViewAction(ActionTypes.COMPANY_CAR_TAX_SELECT_TAX_YEAR, {
			taxYearKey: taxYearKey
		});
	},

	selectTaxRate: function(taxRateKey) {
		createViewAction(ActionTypes.COMPANY_CAR_TAX_SELECT_TAX_RATE, {
			taxRateKey: taxRateKey
		});
	},

	submitForm: function (derivativeUrl, taxYearKey, taxRateKey) {
		if (derivativeUrl && taxYearKey && taxRateKey) {
			var url = derivativeUrl + '&taxyear=' + taxYearKey + '&taxrate=' + taxRateKey;
			window.location = url;
		} else {
			createViewAction(ActionTypes.COMPANY_CAR_TAX_VALIDATION_ERROR);
		}
	}
};

module.exports = Actions;