'use strict';

var $ = require('jquery');

// MPU double width restriction on mobile viewports may remove the last sizing, currently [300, 600]
// check behaviour in advert.js 'function defineAdSlot' before changing
var flexibleWidthMpus = {
	enabled: false,
	sizes: {
		widthUnder488: [[320, 50], [320, 100], [300, 250], [300, 600], [320, 480]],
		widthUnder640: [[320, 50], [320, 100], [300, 250], [300, 600], [320, 480]],
		widthUnder748: [[640, 480], [640, 380], [320, 50], [320, 100], [300, 250], [300, 600], [320, 480]],
		widthUnder1150: [[640, 480], [640, 380], [728, 90], [300, 250], [300, 600], [320, 480]],
		widthOver1150HeightUnder750: [[640, 480], [640, 380], [728, 90], [300, 250], [300, 600], [320, 480]],
		widthOver1150HeightOver750: [[640, 480], [640, 380],[728, 90], [970, 250], [300, 250], [300, 600], [320, 480]]
	}
};

function getMpuSizeMappings(includeMobileDmpu) {
	var defaultSizeMapping = [[300, 250]];
	var widthOver340SizeMappings = [[320, 480], [300, 250]];

	if (includeMobileDmpu) {
		var dmpuSizeMapping = [[300, 600]];

		defaultSizeMapping = defaultSizeMapping.concat(dmpuSizeMapping);
		widthOver340SizeMappings = widthOver340SizeMappings.concat(dmpuSizeMapping);
	}

	var result = [
		[
			[501, 0], [[300, 250], [300, 600]]
		],
		[
			[340, 0], widthOver340SizeMappings
		],
		[
			[0, 0], defaultSizeMapping
		]
	];

	return result;
}

var adSlots = {
	mpu1:{
		id: 'mpu-1',
		positionValue: 'inline-02',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250], [300, 600]],
			sizeMapping: getMpuSizeMappings(true)
		},
		canBeFlexibleWidth: true
	},
	mpu2: {
		id: 'mpu-2',
		positionValue: 'inline-03',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250], [300, 600]],
			sizeMapping: getMpuSizeMappings(true)
		},
		canBeFlexibleWidth: true,
		isVideoCandidate: true
	},
	mpu3: {
		id: 'mpu-3',
		positionValue: 'inline-04',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu4: {
		id: 'mpu-4',
		positionValue: 'inline-05',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu5: {
		id: 'mpu-5',
		positionValue: 'inline-06',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu6: {
		id: 'mpu-6',
		positionValue: 'inline-07',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu7: {
		id: 'mpu-7',
		positionValue: 'inline-08',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu8: {
		id: 'mpu-8',
		positionValue: 'inline-09',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu9: {
		id: 'mpu-9',
		positionValue: 'inline-10',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu10: {
		id: 'mpu-10',
		positionValue: 'inline-11',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu11: {
		id: 'mpu-11',
		positionValue: 'inline-12',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu12: {
		id: 'mpu-12',
		positionValue: 'inline-13',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu13: {
		id: 'mpu-13',
		positionValue: 'inline-14',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu14: {
		id: 'mpu-14',
		positionValue: 'inline-15',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu15: {
		id: 'mpu-15',
		positionValue: 'inline-16',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu16: {
		id: 'mpu-16',
		positionValue: 'inline-17',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu17: {
		id: 'mpu-17',
		positionValue: 'inline-18',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu18: {
		id: 'mpu-18',
		positionValue: 'inline-19',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu19: {
		id: 'mpu-19',
		positionValue: 'inline-20',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpu20: {
		id: 'mpu-20',
		positionValue: 'inline-21',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]],
			sizeMapping: getMpuSizeMappings(false)
		},
		canBeFlexibleWidth: true
	},
	mpuGallery: {
		id: 'mpu-gallery',
		positionValue: 'mpu-gallery',
		sizes: {
			desktop: [[300, 250], [300, 600]],
			mobile: [[300, 250]]
		}
	},
	native: {
		id: 'native-01',
		positionValue: 'native-01',
		sizes: {
			desktop: 'fluid',
			mobile: 'fluid'
		},
		excludeAdContainer: true
	},
	native2: {
		id: 'native-02',
		positionValue: 'native-02',
		sizes: {
			desktop: 'fluid',
			mobile: 'fluid'
		},
		excludeAdContainer: true
	},
	leaderboard1: {
		id: 'leaderboard-1',
		positionValue: 'inline-01',
		sizes: {
			desktop: [[728, 90], [970, 250]],
			mobile: [[320, 50],[320, 100]],
			sizeMapping: [
				[
					[1050, 0], [[970, 250], [728, 90]]
				],
				[
					[767, 0], [[728, 90]]
				],
				[
					[0, 0], [[320, 100], [320, 50]]
				]
			]
		},
		excludeAdContainer: true
	},
	leaderboardGallery: {
		id: 'leaderboard-gallery',
		positionValue: 'leaderboard-gallery',
		sizes: {
			desktop: [[728, 90]],
			mobile: [[320, 50],[320, 100]],
			sizeMapping: [
				[
					[1050, 0], [[728, 90]]
				],
				[
					[767, 0], [[728, 90]]
				],
				[
					[640, 0], [[320, 100], [320, 50]]
				],
				[
					[0, 0], [[320, 100], [320, 50]]
				]
			]
		},
		excludeAdContainer: true
	},
	overlay: {
		id: 'overlay',
		positionValue: 'overlay',
		sizes: {
			desktop: [[1, 1]],
			mobile: [[1, 1]]
		},
		excludeAdContainer: true
	},
	inlineFooter: {
		id: 'inline-footer',
		positionValue: 'inline-footer',
		sizes: {
			desktop: [[728, 90], [970, 250], [300, 250], [300, 600]],
			mobile: [[320, 50], [320, 100], [300, 250], [300, 600]],
			sizeMapping: [
				[
					[1050, 0], [[970, 250], [728, 90], [640, 480], [640, 380], [320, 480], [300, 250], [300, 600]]
				],
				[
					[767, 0], [[728, 90], [640, 480], [640, 380], [320, 480], [300, 250], [300, 600]]
				],
				[
					[640, 0], [[640, 480], [640, 380], [320, 480], [320, 100], [320, 50], [300, 250], [300, 600]]
				],
				[
					[340, 0], [[320, 480], [320, 100], [320, 50], [300, 250], [300, 600]]
				],
				[
					[0, 0], [[320, 100], [320, 50], [300, 250], [300, 600]]
				]
			]
		},
		excludeAdContainer: true
	},
	seedTag: {
		id: 'in_image',
		positionValue: 'in_image',
		sizes: {
			desktop: [[1, 3]],
			mobile: [[1, 3]]
		}
	},
	gumGum: {
		id: 'sticky_ad_bottom',
		positionValue: 'sticky_ad_bottom',
		sizes: {
			desktop: [[1, 2]],
			mobile: [[1, 2]]
		}
	},
};

module.exports = {
	slotSettings: adSlots,
	flexibleSlotSizes: flexibleWidthMpus,

	getFlexibleSlotSizes: function() { 
		var slotSize;
		var height = $(window).height();
		var width = $(window).width();
	
		if (width < 488) {
			slotSize = flexibleWidthMpus.sizes.widthUnder488;
		} else if( width < 640) {
			slotSize = flexibleWidthMpus.sizes.widthUnder640;
		} else if (width < 748) {
			slotSize = flexibleWidthMpus.sizes.widthUnder748;
		} else if (width < 1150) {
			slotSize = flexibleWidthMpus.sizes.widthUnder1150;
		} else {
			slotSize = (height < 750) ? flexibleWidthMpus.sizes.widthOver1150HeightUnder750 : flexibleWidthMpus.sizes.widthOver1150HeightOver750;
		}
	
		return slotSize;
	},

	shouldInjectAdContainer: function(slotId) {
		for (var itemKey in adSlots) {
			if (adSlots[itemKey].id === slotId) {
				return !adSlots[itemKey].excludeAdContainer;
			}
		}
	}
};
	