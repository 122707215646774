'use strict';

var assign 	= require('object-assign');

var arrays	= require('../../utils/arrays.js');
var logger	= require('../../utils/logger.js');

var FluxChangeStore	= require('./flux-change-store.js');
var FluxConstants 	= require('../constants/flux-constants.js');
var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/quick-find-model-constants.js');

var ActionTypes 	= Constants.ActionTypes;
var PayloadSources 	= FluxConstants.PayloadSources;

var storeData = {
	isLoading: true,
	initialManufacturerKey: null,
	initialRangeKey: null,
	initialModelKey: null,
	manufacturers: {},
	ranges: {},
	rangeYears: {},
	selectedManufacturer: {},
	selectedRange: {},
	selectedModel: {},
	validationErrors: {}
};

function setSelectedManufacturer(manufacturerKey, filterName) {
	var item = arrays.findArrayItemByField(storeData.manufacturers[filterName], 'key', manufacturerKey);

	if (item !== null) {
		storeData.selectedManufacturer[filterName] = item;
		storeData.ranges[filterName] = item.ranges || [];
		return true;
	}

	return false;
}

function setSelectedRange(rangeKey, filterName) {
	var item = arrays.findArrayItemByField(storeData.ranges[filterName], 'key', rangeKey);

	if (item !== null) {
		storeData.selectedRange[filterName]  = item;
		storeData.rangeYears[filterName] = item.rangeYears || [];
		return true;
	}

	return false;
}

function setSelectedModel(modelKey, filterName) {
	var rangeYears = storeData.rangeYears[filterName];
	if (rangeYears) {
		var index, item = null, rangeYear;

		for (index = 0; index < rangeYears.length; index++) {
			rangeYear = rangeYears[index];
			item = arrays.findArrayItemByField(rangeYear.models, 'key', modelKey);

			if (item !== null) {
				break;
			}
		}

		if (item !== null) {
			storeData.selectedModel[filterName]  = item;
			return true;
		}
	}

	return false;
}

var Store = assign(new FluxChangeStore(), {
	getState: function () {
		return storeData;
	}
});

function handleServerAction(action) {
	if (action) {
		var handled = false;
		var filterName = '';

		switch(action.type) {
			case ActionTypes.QUICK_FIND_INIT_STORE_DATA:
				handled = true;
				assign(storeData, action.data);

				break;
			case ActionTypes.QUICK_FIND_LOAD_MANUFACTURERS:
				handled = true;
				filterName = action.data;
				storeData.isLoading = true;
				storeData.manufacturers[filterName] = [];
				storeData.ranges[filterName] = [];
				storeData.rangeYears[filterName] = [];
				storeData.selectedManufacturer[filterName] = null;
				storeData.selectedRange[filterName] = null;
				storeData.selectedModel[filterName] = null;

				break;
			case ActionTypes.QUICK_FIND_LOAD_MANUFACTURERS_SUCCESS:
				handled = true;
				storeData.isLoading = false;
				storeData.manufacturers[action.data.filterName] = action.data.manufacturers;

				if (storeData.initialManufacturerKey) {
					// pre-select the manufacturer and set the ranges
					if (setSelectedManufacturer(storeData.initialManufacturerKey, action.data.filterName)) {
						storeData.initialManufacturerKey = null;

						if (storeData.initialRangeKey) {
							// pre-select the range and set the range years
							if (setSelectedRange(storeData.initialRangeKey, action.data.filterName)) {
								storeData.initialRangeKey = null;
							}
						}
					}
				}

				break;
			case ActionTypes.QUICK_FIND_LOAD_MANUFACTURERS_ERROR:
				handled = true;
				filterName = action.data;
				storeData.isLoading = false;
				storeData.manufacturers[filterName] = [];

				break;
			case ActionTypes.QUICK_FIND_LOAD_RANGES:
				handled = true;
				filterName = action.data;
				storeData.rangeYears[filterName] = [];
				storeData.selectedModel[filterName] = null;

				break;
			case ActionTypes.QUICK_FIND_LOAD_RANGES_SUCCESS:
				handled = true;
				filterName = action.data.filterName;

				// check that the response is the one we are expecting - stops overlapping requests generating an invalid state
				if (storeData.selectedManufacturer[filterName] !== null && storeData.selectedManufacturer[filterName].key === action.data.key) {
					storeData.ranges[filterName] = action.data.ranges;

					if (storeData.selectedRange[filterName] !== null) {
						// if range selected by user before load ranges returns, reset selected range to get range years
						setSelectedRange(storeData.selectedRange[filterName].key, filterName);
						var rangeYears = storeData.rangeYears[filterName];

						if (storeData.initialModelKey) {
							// pre-select the model
							if (setSelectedModel(storeData.initialModelKey, filterName)) {
								storeData.initialModelKey = null;
							}
						} else if (rangeYears.length === 1 && rangeYears[0].models.length === 1) {
							// only one model, select it automatically
							setSelectedModel(rangeYears[0].models[0].key, filterName);
						}
					}
				}

				break;
			case ActionTypes.QUICK_FIND_LOAD_RANGES_ERROR:
				handled = true;
				filterName = action.data;
				storeData.rangeYears[filterName] = [];

				break;
		}

		if (handled) {
			logger.debug('Quick Find Model', {
				message: 'Handle server action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

function handleViewAction(action) {
	if (action) {
		var handled = false;
		var filterName = '';

		switch(action.type) {
			case ActionTypes.QUICK_FIND_SELECT_MANUFACTURER:
				handled = true;
				filterName = action.data.filterName;

				storeData.initialManufacturerKey = null;
				storeData.initialRangeKey = null;
				storeData.initialModelKey = null;
				storeData.ranges[filterName] = [];
				storeData.rangeYears[filterName] = [];
				storeData.selectedRange[filterName] = null;
				storeData.selectedModel[filterName] = null;
				storeData.validationErrors[filterName] = null;

				setSelectedManufacturer(action.data.manufacturerKey, filterName);
				var ranges = storeData.ranges[filterName];

				if (ranges.length === 1) {
					// only one range, select it automatically
					setSelectedRange(ranges[0].key, filterName);
				}

				break;
			case ActionTypes.QUICK_FIND_SELECT_RANGE:
				handled = true;
				filterName = action.data.filterName;

				storeData.initialRangeKey = null;
				storeData.initialModelKey = null;
				storeData.rangeYears[filterName] = [];
				storeData.selectedModel[filterName] = null;
				storeData.validationErrors[filterName] = null;

				setSelectedRange(action.data.rangeKey, filterName);
				var rangeYears = storeData.rangeYears[filterName];

				if (rangeYears.length === 1 && rangeYears[0].models.length === 1) {
					// only one model, select it automatically
					setSelectedModel(rangeYears[0].models[0].key, filterName);
				}

				break;
			case ActionTypes.QUICK_FIND_SELECT_MODEL:
				handled = true;
				filterName = action.data.filterName;

				storeData.initialModelKey = null;
				storeData.validationErrors[filterName] = null;

				setSelectedModel(action.data.modelKey, filterName);

				break;
			case ActionTypes.QUICK_FIND_VALIDATION_ERROR:
				handled = true;

				storeData.validationErrors[action.data.filterName] = action.data.errorMessages;

				break;
		}

		if (handled) {
			logger.debug('Quick Find Model', {
				message: 'Handle view action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

Store.dispatchToken = FluxDispatcher.register(function (payload) {
	if (payload && payload.source) {
		if (payload.source === PayloadSources.SERVER_ACTION) {
			handleServerAction(payload.action);
		} else if (payload.source === PayloadSources.VIEW_ACTION) {
			handleViewAction(payload.action);
		}
	}
});

module.exports = Store;