/** @jsx React.DOM */'use strict';

var $ = require('jquery');
var React = require('react');

var classNames = {
	carChooser: 'car-chooser'
};

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		onSwitch: React.PropTypes.func.isRequired,
		isMonthlySelected: React.PropTypes.bool
	},

	getDefaultProps: function() {
		return {
			isMonthlySelected: false
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var purchaseCssClass = 'car-chooser__price-type__switch';
		var monthlyCssClass = 'car-chooser__price-type__switch';

		if (this.props.isMonthlySelected) {
			monthlyCssClass += ' car-chooser__price-type__switch--active';
		} else {
			purchaseCssClass += ' car-chooser__price-type__switch--active';			
		}

		return (
			<div className="car-chooser__price-type">
				<h5 className="car-chooser__price-type__heading">Price type</h5>				
				<div className="car-chooser__price-type__switches">
					<span href="#" className={purchaseCssClass} onClick={this.onPurchaseClick}>Purchase price</span>
					<span href="#" className={monthlyCssClass} onClick={this.onMonthlyClick}>Monthly price</span>
				</div>
			</div>
		);
	},
	/* #endregion */

	/* #region Event handlers */
	onPurchaseClick: function () {
		this.props.onSwitch(false);
	},

	onMonthlyClick: function () {
		this.props.onSwitch(true);
	}
	/* #endregion */
});

