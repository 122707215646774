'use strict';

require('jquery-validation');

var $ = require('jquery');
var analytics = require('../utils/analytics.js');
var cookie = require('../storage/cookie.js');
var logger = require('../utils/logger.js');
var vendorConsents = require('../utils/vendor-consents.js');

var cookieNames = {
    newsletterSignup: 'newsletter-signup',
	permutiveUserEmailHash: 'permutive-user-email-hash'
};

var classNames = {
    newsletterSignup: 'newsletter-signup',
    newsletterSignupInner: 'newsletter-signup__inner',
    newsletterSignupInnerSubmit: 'newsletter-signup__inner__submit',
    completeButton: 'complete-button',
    errorLabel: 'error-label',
    input: 'input',
    button: 'button'
};

var modifierNames = {
    submitting: 'submitting'
};

var trackingCategory = 'NTG newsletter';

function setPermutiveCookie(newsletterSubscribeResult)
{	
	var permutiveUserEmailHashCookie = cookie.getCookie(cookieNames.permutiveUserEmailHash);

	if(!permutiveUserEmailHashCookie)
	{
		permutiveUserEmailHashCookie = {
			permutiveUserEmailHashData: {
				userEmailHash: newsletterSubscribeResult.userEmailHash,
				userSaltedEmailHash: newsletterSubscribeResult.userSaltedEmailHash
			},
			userKnown: true
		};

		cookie.setCookieDefaultDomain(cookieNames.permutiveUserEmailHash, permutiveUserEmailHashCookie);
	}
} 

function setCookieInteracted(userEmail, newsletterSubscribeResult) {
    var newsletterSignupCookie = cookie.getCookie(cookieNames.newsletterSignup);
    if (newsletterSignupCookie) {
        newsletterSignupCookie.interacted = true;

        if (userEmail) {
            newsletterSignupCookie.userEmail = userEmail;
        }

		cookie.setCookieDefaultDomain(cookieNames.newsletterSignup, newsletterSignupCookie);

		if (window.permutive && newsletterSubscribeResult) {

			window.permutive.identify([
				{
					id: newsletterSubscribeResult.userSaltedEmailHash,
					tag: 'bauer_id',
					priority: 0
				},
				{
					id: newsletterSubscribeResult.userEmailHash,
					tag: 'email_sha256',
					priority: 1
				}
			]);

			setPermutiveCookie(newsletterSubscribeResult);
		}
    }
}

function initVisibility() {
    var newsletterSignupCookie = cookie.getCookie(cookieNames.newsletterSignup);
    if (!newsletterSignupCookie) {
        newsletterSignupCookie = {
            pageViewCount: 1,
            interacted: false
        };
    }
    else {
        newsletterSignupCookie.pageViewCount++;
    }

    cookie.setCookieDefaultDomain(cookieNames.newsletterSignup, newsletterSignupCookie);

    var pageViewThreshold = $('#newsletterSignup').data('page-view-threshold');
    if (newsletterSignupCookie.pageViewCount >= pageViewThreshold && !newsletterSignupCookie.interacted) {
        $.findByBem(classNames.newsletterSignup).show();
        analytics.trackEvent(trackingCategory, 'Load', window.location.href);
    }
}

function initCloseButtons() {
    $.findByBem(classNames.newsletterSignupInner, 'close').on('click', function () {
        $.findByBem(classNames.newsletterSignup).hide();
        setCookieInteracted();
    });

    $.findByBem(classNames.newsletterSignupInner, classNames.completeButton).on('click', function () {
        $.findByBem(classNames.newsletterSignup).hide();
    });
}

function initSubmitValidation() {
    var $signupForm = $.findByBem(classNames.newsletterSignupInnerSubmit);

    $signupForm.validate({
        ignore: [],
        rules: {
            email: { required: true, email: true }
        },
        errorPlacement: function (error, element) {
            var placement = $(element).data('error');
            if (placement) {
                $(placement).append(error);
            }
            else {
                error.insertAfter(element);
            }
        },
        errorElement: 'span',
        errorClass: $.getBemClass(classNames.newsletterSignupInnerSubmit, 'error') + ' error',
        submitHandler: function () {
            analytics.trackEvent(trackingCategory, 'newsletter signup', 'success');
            $.findByBem(classNames.newsletterSignupInnerSubmit, classNames.errorLabel).hide();
            $.findByBem(classNames.newsletterSignupInnerSubmit, classNames.input).attr('disabled', true);
            $.findByBem(classNames.newsletterSignupInnerSubmit, classNames.button)
                .attr('disabled', true)
                .html('Submitting&hellip;')
                .addBemClass(classNames.newsletterSignupInnerSubmit, classNames.button, modifierNames.submitting);
            var userEmail = $('#emailInput').val();
            $.ajax({
                method: 'GET',
                url: '/api/newsletter-signup/subscribe/' + userEmail + '/',
                success: function (resultData) {
                    $.findByBem(classNames.newsletterSignupInnerSubmit, classNames.button).hide();
                    $.findByBem(classNames.newsletterSignupInnerSubmit, 'prompt-label').hide();
                    $.findByBem(classNames.newsletterSignupInner, classNames.completeButton).css('display', 'block');
                    $.findByBem(classNames.newsletterSignupInnerSubmit, 'success-label').show();
                    $.findByBem(classNames.newsletterSignupInnerSubmit, classNames.input).attr('disabled', 'true');
                    setCookieInteracted(userEmail, resultData);
                },
                error: function (xhr, status, err) {
                    $.findByBem(classNames.newsletterSignupInnerSubmit, classNames.errorLabel).css('display', 'block').insertAfter(err);
                    $.findByBem(classNames.newsletterSignupInnerSubmit, classNames.input).attr('disabled', false);
                    $.findByBem(classNames.newsletterSignupInnerSubmit, classNames.button)
                        .attr('disabled', false)
                        .html('Submit')
                        .removeBemClass(classNames.newsletterSignupInnerSubmit, classNames.button, modifierNames.submitting);
                }
            });
        }
    });
}

function initForm() {
    vendorConsents.addTcfApiHandler(vendorConsents.eventNames.userActionComplete, function () {
        vendorConsents.onRetrieveBauerConsent(function () {
            initVisibility();
            initCloseButtons();
            initSubmitValidation();
        }, function () {
            cookie.deleteCookie('newsletter-signup');
            logger.info('Did not initialise newsletter on completed user action: Insufficient consent');
        });
    });

    vendorConsents.addTcfApiHandler(vendorConsents.eventNames.subsequentPageLoad, function () {
        vendorConsents.onRetrieveBauerConsent(function () {
            initVisibility();
            initCloseButtons();
            initSubmitValidation();
        }, function () {
            cookie.deleteCookie('newsletter-signup');
            logger.info('Did not initialise newsletter on subsequent page load: Insufficient consent');
        });
    });
}

module.exports = {
    init: function () {
        initForm();
    }
};