/** @jsx React.DOM */'use strict';

var React	= require('react');
var numeral = require('numeral');

module.exports = React.createClass({

	propTypes: {
		isDisabled: React.PropTypes.bool,
		isLoading: React.PropTypes.bool,
		selectedPrice: React.PropTypes.number,
		prices: React.PropTypes.array,
		promptText: React.PropTypes.string,
		selectClassName: React.PropTypes.string,
		onItemSelect: React.PropTypes.func.isRequired,
		validationError: React.PropTypes.string,
		isChunky: React.PropTypes.bool
	},

	getDefaultProps: function() {
		return {
			promptText: 'Select a price',
			isChunky: false
		}
	},

	render: function () {
		var defaultText = this.props.isLoading ? 'Loading...' : this.props.promptText;
		var selectedValue = this.props.selectedPrice !== null ? this.props.selectedPrice : '';
		var containerClassName = 'form__entry';

		if (this.props.selectClassName) {
			containerClassName += ' ' + this.props.selectClassName;
		}

		var validationError;
		if (this.props.validationError) {
			validationError = <span className="error">{this.props.validationError}</span>;
		}

		var priceOptions;

		if (this.props.prices) {
			priceOptions = this.props.prices.map(function (price) {
				return <option key={price} value={price}>&pound; {numeral(price).format('0,0')}</option>
			});
		}
		
		var classNameToUse = this.props.isChunky ? 'form__list form__list--chunky' : 'form__list';
		
		return (
			<div className={containerClassName}>
				<select disabled={this.props.isDisabled} value={selectedValue} className={classNameToUse} 
				onChange={this.select}> <option value="">{defaultText}</option>
				{priceOptions}
				</select>
				{validationError}
			</div>
		);
	},

	select: function (event) {
		if (!this.props.isDisabled) {
			var value = event.target.value;

			if (value) {
				var price = parseInt(value, 10);
				this.props.onItemSelect(isNaN(price) ? null : price);
			}
		}
	}
});