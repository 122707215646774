/** @jsx React.DOM */'use strict';

var React = require('react');

var postcodeHelper = require('../../../utils/postcode-helper.js');

var previousValue;

// this module relies on the caller to maintain the postcode value
// this means it has to set the selected postcode property in its onValueSet function
module.exports = React.createClass({

	propTypes: {
		isDisabled: React.PropTypes.bool,
		isLoading: React.PropTypes.bool,
		selectedPostCode: React.PropTypes.string,
		onValueSet: React.PropTypes.func.isRequired,
		promptText: React.PropTypes.string,
		postcodeError: React.PropTypes.string
	},

	getDefaultProps: function () {
		return {
			promptText: 'Enter Postcode'
		}
	},

	render: function () {
		var className = 'quick-find__postcode';
		var selectedPostcode = postcodeHelper.format(this.props.selectedPostcode);

		var postcodeError = this.getPostcodeError();

		return (
			<div className="form__entry">
				<input 
						className={this.props.postcodeError ? className.concat(" quick-find__postcode--error") : className }
						type="text"
						name="postcode"
						maxLength="8"
						placeholder={this.props.promptText}
						value={selectedPostcode}
						disabled={this.props.isDisabled}
						onBlur={this.onPostcodeBlur}
						onChange={this.onPostcodeChange}
						onFocus={this.onPostcodeFocus}
						onKeyPress={this.onPostcodeKeyPress} />
				
				{postcodeError}
			</div>
		);
	},

	getPostcodeError: function () {
		if (this.props.postcodeError) {
			return (
				<span className="quick-find__postcode__error error">{this.props.postcodeError}</span>
			);
		}
	},

	onPostcodeBlur: function (event) {
		var value = event.target.value;

		if (value != previousValue) {
			this.setPostcode(value, true);
		}
	},

	onPostcodeChange: function (event) {
		this.setPostcode(event.target.value, false);
	},

	onPostcodeFocus: function (event) {
		previousValue = event.target.value;
	},

	onPostcodeKeyPress: function (event) {
		if (event.charCode === 13 || event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			this.setPostcode(event.target.value, true);
		}
	},

	setPostcode : function(postcode, update) {
		if (!this.props.isLoading && !this.props.isDisabled) {
			this.props.onValueSet(postcode, update);
		}
	}
});