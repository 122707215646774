'use strict';

var $ 	= require('jquery');
var td 	= require('throttle-debounce');

var layout = require('../ui/layout.js');

var classNames = {
	hasScrolled: 'has-scrolled',
	mainHeading: 'main-heading',
	scrollMarker: 'scroll-marker'
};

var scrollCallbacks = [];
var scrollToHeadingCallbacks = [];

module.exports = {
	onScroll: function (func) {
		if (typeof func === 'function') {
			scrollCallbacks.push(func);
		}
	},

	onScrollToHeading: function (func) {
		if (typeof func === 'function') {
			scrollToHeadingCallbacks.push(func);
		}
	},

	start: function () {
		var body = $('body');
		var heading = $.findByBem(classNames.mainHeading);

		$(window).off('scroll.scrollWatcher').on('scroll.scrollWatcher', td.throttle(100, function (e) {
			var index, callback, event = {},
				target = e.currentTarget,
				self = $(target),
				lastScrollTop = self.data('lastScrollTop') || 0,
				scrollHeight = target.scrollHeight || document.body.scrollHeight;

			event.scrollTop = window.pageYOffset || target.scrollTop || 0;
			event.bottom = scrollHeight - event.scrollTop === self.innerHeight();

			if (event.scrollTop > 0 && !body.hasBemClass(classNames.hasScrolled)) {
				body.addBemClass(classNames.hasScrolled);
			}

			if (event.scrollTop > lastScrollTop) {
				event.direction = 'down';
			} else if (event.scrollTop < lastScrollTop) {
				event.direction = 'up';
			}

			self.data('lastScrollTop', event.scrollTop);

			for (index = 0; index < scrollCallbacks.length; index++) {
				callback = scrollCallbacks[index];
				callback(event);
			}

			if (heading.length > 0) {
				var hitHeading, headingTop = heading.offset().top - layout.getContentOffset();

				if (headingTop > lastScrollTop && headingTop <= event.scrollTop) {
					hitHeading = true;
					body.addBemClass(classNames.scrollMarker, null, classNames.mainHeading);
				} else if (headingTop <= lastScrollTop && headingTop > event.scrollTop) {
					hitHeading = true;
					body.removeBemClass(classNames.scrollMarker, null, classNames.mainHeading);
				}

				if (hitHeading) {						
					for (index = 0; index < scrollToHeadingCallbacks.length; index++) {
						callback = scrollToHeadingCallbacks[index];
						callback(event);
					}
				}
			}
		}));
	}
};