'use strict';

var keyMirror = require('keymirror');

module.exports = {
	ActionTypes: keyMirror({
		LEASING_SEARCH_FILTERS_INIT_STORE_DATA: null,

		LEASING_SEARCH_FILTERS_GET_STATE: null,
		LEASING_SEARCH_FILTERS_GET_STATE_SUCCESS: null,
		LEASING_SEARCH_FILTERS_GET_STATE_ERROR: null,
		LEASING_SEARCH_FILTERS_UPDATE_STATE: null,
		LEASING_SEARCH_FILTERS_UPDATE_STATE_SUCCESS: null,
		LEASING_SEARCH_FILTERS_UPDATE_STATE_ERROR: null,
		LEASING_SEARCH_FILTERS_SET_ITEM: null,
		LEASING_SEARCH_FILTERS_REMOVE_ITEM: null,
		LEASING_SEARCH_FILTERS_SET_VALUE: null,
		LEASING_SEARCH_FILTERS_RESET: null,
		LEASING_SEARCH_FILTERS_RESET_SUCCESS: null,
		LEASING_SEARCH_FILTERS_RESET_ERROR: null,
		LEASING_SEARCH_FILTERS_UPDATE_RESULTS: null,
		LEASING_SEARCH_FILTERS_VALIDATION_ERROR: null,
		LEASING_SEARCH_ADVANCED_FILTERS_EXPANDED: null
	})
};