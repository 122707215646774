/** @jsx React.DOM */'use strict';

var React = require('react');

var analytics 		= require('../../utils/analytics.js');
var logger 			= require('../../utils/logger.js');
var postcodeHelper 	= require('../../utils/postcode-helper.js');

var Actions			= require('../actions/for-sale-or-lease-quick-find-actions.js');
var Store			= require('../stores/for-sale-or-lease-quick-find-store.js');

var Postcode		= require('./quick-find-filters/postcode-view.jsx');
var Price			= require('./quick-find-filters/price-view.jsx');
var SelectItem		= require('./quick-find-filters/select-item-view.jsx');
var SubmitButton	= require('./quick-find-filters/submit-button-view.jsx');

// the values are case-sensitive to be recognised by the search api
var SearchTypes = {
	NEW: 'New',
	USED: 'Used',
	NEARLYNEW: 'NearlyNew',
	PERSONAL: 'Personal'
}

var FilterNames = {
	FOR_SALE: 'for-sale',
	LEASING: 'leasing'
}

var leasingButtonText = 'View deals';

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		instance: React.PropTypes.string.isRequired,
		vehicleType: React.PropTypes.string.isRequired,
		filterName: React.PropTypes.string.isRequired,
		color: React.PropTypes.string,
		priceSelectionClass: React.PropTypes.string,
		searchType: React.PropTypes.string,
		showForSaleUsedSearchType: React.PropTypes.bool,
		showForSaleNewSearchType: React.PropTypes.bool,
		showForSaleNearlyNewSearchType: React.PropTypes.bool,
		showLeasingSearchType: React.PropTypes.bool,
		hideAllSearchTypes: React.PropTypes.bool,
		stackInputs: React.PropTypes.bool,
		countNew: React.PropTypes.string,
		countUsed: React.PropTypes.string,
		countNearlynew: React.PropTypes.string,
		countLease: React.PropTypes.string,
		newLogoUrl: React.PropTypes.string,
		newLogoAltText: React.PropTypes.string,
		usedLogoUrl: React.PropTypes.string,
		usedLogoAltText: React.PropTypes.string,
		leaseLogoUrl: React.PropTypes.string,
		leaseLogoAltText: React.PropTypes.string,
		isLeasingLandingPage: React.PropTypes.bool
	},

	selectedFilterName: null,
	selectedSearchType: null,
	selectedManufacturer: null,
	selectedRange: null,
	selectedModel: null,
	selectedFromPrice: null,
	selectedToPrice: null,

	searchUrl: null,

	manufacturers: [],
	ranges: [],
	rangeYears: [],
	models: [],
	toPrices: [],
	fromPrices: [],

	validationErrors: null,

	getDefaultProps: function() {
		return {
			color: 'red',
			filterName: FilterNames.FOR_SALE,
			searchType: SearchTypes.USED,
			priceSelectionClass: 'small-6',
			showForSaleUsedSearchType: true,
			showForSaleNewSearchType: true,
			showForSaleNearlyNewSearchType: true,
			showLeasingSearchType: true,
			hideAllSearchTypes: false,
			stackInputs: false,
			isLeasingLandingPage:false
		}
	},

	
	getInitialState: function () {
		return Store.getState();
	},

	componentWillMount: function () {
		Store.addChangeListener(this.onStoreChange);
		Actions.initStoreData(this.props.filterName, this.props.searchType, this.props.instance);
	},

	componentDidMount: function () {
		this.selectSearchType(this.props.filterName, this.props.searchType);
	},

	componentWillUnmount: function () {
		Store.removeChangeListener(this.onStoreChange);
	},

	onStoreChange: function () {
		this.setState(Store.getState());
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		logger.debug('For Sale or Lease Quick Find', {
			message: 'Render',
			state: this.state
		});

		this.searchUrl = this.state.searchUrl[this.props.instance] || null;

		this.selectedSearchType = this.state.selectedSearchType[this.props.instance] || null;
		this.selectedManufacturer = this.state.selectedManufacturer[this.props.instance] || null;
		this.selectedRange = this.state.selectedRange[this.props.instance] || null;
		this.selectedModel = this.state.selectedModel[this.props.instance] || null;
		this.selectedFromPrice = isNaN(this.state.selectedFromPrice[this.props.instance]) ? null : this.state.selectedFromPrice[this.props.instance];
		this.selectedToPrice = isNaN(this.state.selectedToPrice[this.props.instance]) ? null : this.state.selectedToPrice[this.props.instance];
		
		this.manufacturers = this.state.manufacturers[this.props.instance] || [];
		this.ranges = this.state.ranges[this.props.instance] || [];
		this.rangeYears = this.state.rangeYears[this.props.instance] || [];
		this.models = this.state.models[this.props.instance] || [];
		this.toPrices = this.state.toPrices[this.props.instance] || [];
		this.fromPrices = this.state.fromPrices[this.props.instance] || [];

		this.validationErrors = this.state.validationErrors[this.props.instance] || [];

		var manufacturerListDisabled = (this.state.isLoading[this.props.instance] && this.manufacturers.length === 0);
		var rangeListDisabled = manufacturerListDisabled || (this.selectedManufacturer !== null && this.ranges.length === 0);
		var modelListDisabled = rangeListDisabled || (this.selectedRange !== null && this.models.length === 0);
		var postcodeInput;
		var typeSelectOptions = this.getTypeSelect();
		var countMessage = this.getCountMessage();
		var logo = this.getLogo();

		if (this.selectedSearchType === SearchTypes.USED || this.selectedSearchType === SearchTypes.NEARLYNEW)
		{
			postcodeInput =
				<Postcode isLoading = {this.state.isLoading[this.props.instance]}
					selectedPostcode = {this.state.selectedPostcode}
					onValueSet = {this.setPostcode}
					promptText = {this.getPostcodePrompt()}
					postcodeError = {this.state.postcodeValidationError} />
		}

		var manufacturerInput =
			<SelectItem selectedItem={this.selectedManufacturer}
				items={this.manufacturers}
				onItemSelect={this.selectManufacturer}
				promptText='Select a make'
				selectClassName='select-make'
				isChunky={this.props.isLeasingLandingPage}
				isDisabled={manufacturerListDisabled}
				isLoading={this.state.isLoading[this.props.instance]}
				validationError={this.validationErrors ? this.validationErrors.manufacturer : null} />

		var rangeInput =
			<SelectItem selectedItem={this.selectedRange}
				 items={this.ranges}
				 onItemSelect={this.selectRange}
				 promptText='Select a range'
				 selectClassName='select-range'
				 isDisabled={rangeListDisabled}
				 isLoading={this.state.isLoading[this.props.instance]}
				 isChunky={this.props.isLeasingLandingPage}
				 validationError={this.validationErrors ? this.validationErrors.range : null} />

		var modelInput =
			<SelectItem selectedItem={this.selectedModel}
				 items={this.models}
				 onItemSelect={this.selectModel}
				 promptText='Select a model'
				 selectClassName='select-model'
				 isDisabled={modelListDisabled}
				 isLoading={this.state.isLoading[this.props.instance]}
				 isChunky={this.props.isLeasingLandingPage}
				 validationError={this.validationErrors ? this.validationErrors.model : null} />

		var priceColumnClass = "column " + this.props.priceSelectionClass;

		var fromPriceInput =
			<div className={priceColumnClass}>
				<Price selectedPrice={this.selectedFromPrice}
					prices={this.fromPrices}
					onItemSelect={this.selectFromPrice}
					promptText='Price from'
					selectClassName='price-from'
					isLoading={this.state.isLoading[this.props.instance]}
					isChunky={this.props.isLeasingLandingPage}
					validationError={this.validationErrors ? this.validationErrors.fromPrice : null} />
			</div>

		var toPriceInput =
			<div className={priceColumnClass}>
				<Price selectedPrice={this.selectedToPrice}
					prices={this.toPrices}
					onItemSelect={this.selectToPrice}
					promptText='Price to'
					selectClassName='price-to'
					isLoading={this.state.isLoading[this.props.instance]}
					isChunky={this.props.isLeasingLandingPage}
					validationError={this.validationErrors ? this.validationErrors.toPrice : null} />
			</div>

		var gridInputs =
			<div className="row">
				<div className="column medium-6">
					{manufacturerInput}
					{rangeInput}
					{modelInput}
				</div>
				<div className="column medium-6">
					<div className="row">
						<div className="column">
							{postcodeInput}
						</div>
					</div>
					<div className="row">
						{fromPriceInput}
						{toPriceInput}
					</div>
				</div>
			</div>

		var stackedInputs =
			<div className="row">
				<div className="column">
					{manufacturerInput}
					{rangeInput}
					{modelInput}
					{postcodeInput}
				</div>
				<div className="column">
					<div className="row">
						{fromPriceInput}
						{toPriceInput}
					</div>
				</div>
			</div>

		return (
			<form className="form" onSubmit={this.submitForm}>
				<div className="row">
				{countMessage}
				{logo}
				</div>
				<div className="row">
					<div className="column">
						{typeSelectOptions}
					</div>
				</div>
				{this.props.stackInputs ? stackedInputs : gridInputs}
				<SubmitButton
					isCentered = {this.props.isLeasingLandingPage}
					promptText = {this.getSubmitButtonPrompt()} />
				<div className="row">
					<div className="column">
					{this.getWidgetAdvancedSearch()}
					</div>
				</div>
			</form>
		);
	},

	getLogo: function() {
		var imageData;

		if (this.props.filterName == FilterNames.LEASING) {
			imageData = {
				url: this.props.leaseLogoUrl, 
				alt: this.props.leaseLogoAltText
			};
		} else {
			imageData = this.state.selectedSearchType[this.props.instance] === SearchTypes.NEW
				? { url: this.props.newLogoUrl, alt: this.props.newLogoAltText }
				: { url: this.props.usedLogoUrl, alt: this.props.usedLogoAltText }
		}

		if (!imageData.url && !imageData.alt) {
			return;
		}

		return (
			<div className="column small-3">
				<div className="for-sale-quick-find__powered-by-logo">
					<img src={imageData.url} alt={imageData.alt} />
				</div>
			</div>
		);
	},

	getCountMessage: function() {
		var columnClass = 'column';
		if (this.props.showForSaleUsedSearchType && this.selectedSearchType === SearchTypes.USED) {
			columnClass += this.props.usedLogoUrl ? ' small-9' : ' small-12';

			return (
				<div className={columnClass}>
					<h2 className="feature-box__heading">Choose from <span className="quick-find-deal-count">{this.props.countUsed}</span> used {this.props.vehicleType} for sale</h2>
				</div>
			);
		}

		if (this.props.showForSaleNewSearchType && this.selectedSearchType === SearchTypes.NEW) {
			columnClass += this.props.newLogoUrl ? ' small-9' : ' small-12';

			return (
				<div className={columnClass}>
					<h2 className="feature-box__heading">Choose from <span className="quick-find-deal-count">{this.props.countNew}</span> new {this.props.vehicleType} for sale</h2>
				</div>
			);
		}

		if (this.props.showForSaleNearlyNewSearchType && this.selectedSearchType === SearchTypes.NEARLYNEW) {
			columnClass += ' small-12';

			return (
				<div className={columnClass}>
					<h2 className="feature-box__heading">Choose from <span className="quick-find-deal-count">{this.props.countNearlynew}</span> nearly new {this.props.vehicleType} for sale</h2>
				</div>
			);
		}

		if (this.props.showLeasingSearchType && this.selectedSearchType === SearchTypes.PERSONAL) {
			columnClass += this.props.leaseLogoUrl ? ' small-9' : ' small-12';
			var headerClass = this.props.isLeasingLandingPage ? 'car-leasing-landing-page__filters__sub-heading' : 'feature-box__heading';
			var plusSign = this.props.countLease !== '' ? '+' : '';
			var spanClass = this.props.isLeasingLandingPage ? 'car-leasing-landing-page__filters__sub-heading--deal-count' : 'quick-find-deal-count';

			return (
				<div className={columnClass}>
					<h2 className={headerClass}>Choose from <span className={spanClass}>{this.props.countLease}{this.props.isLeasingLandingPage ? plusSign : ''}</span> lease deals</h2>
				</div>
			);
		}
	},

	getWidgetAdvancedSearch: function() {	
		return (
			<div className={this.props.isLeasingLandingPage ? "car-leasing-landing-page__filters__advanced-search" : "right"}>
				{this.props.isLeasingLandingPage ? 
				<a href={this.searchUrl} ><span className="car-leasing-landing-page__filters__advanced-search__link">Advanced Search</span> &#9654;</a>
				: <a href={this.searchUrl} className="chevron">Advanced Search</a>}
			</div>
		);
	},

	getTypeSelect: function() {
		if ((!this.props.showForSaleUsedSearchType && !this.props.showForSaleNewSearchType && !this.props.showForSaleNearlyNewSearchType && !this.props.showLeasingSearchType) || this.props.hideAllSearchTypes) {
			return null;
		}

		var forSaleUsedSelector;
		var forSaleNewSelector;
		var forSaleNearlyNewSelector;
		var leasingSelector;

		if (this.props.showForSaleUsedSearchType) {
			forSaleUsedSelector = <div className="radio-option">
					<input id="rdoSearchTypeUsed" className="radio-option__input" type="radio" name="rdoSearchType"
						   disabled={this.state.isLoading[this.props.instance]} value={FilterNames.FOR_SALE + '_' + SearchTypes.USED} checked={this.selectedSearchType === SearchTypes.USED} onChange={this.onTypeSelectChange} />
					<label htmlFor="rdoSearchTypeUsed" className="radio-option__label">
						<span className="radio-option__label__circle"></span>
						&nbsp;<span>Used {this.props.vehicleType} for sale</span>
					</label>
				</div>
		}

		if (this.props.showForSaleNewSearchType) {
			forSaleNewSelector = <div className="radio-option">
				<input id="rdoSearchTypeNew" className="radio-option__input" type="radio" name="rdoSearchType"
					   disabled={this.state.isLoading[this.props.instance]} value={FilterNames.FOR_SALE + '_' + SearchTypes.NEW} checked={this.selectedSearchType === SearchTypes.NEW} onChange={this.onTypeSelectChange} />
				<label htmlFor="rdoSearchTypeNew" className="radio-option__label">
					<span className="radio-option__label__circle"></span>
					&nbsp;<span>New {this.props.vehicleType} for sale</span>
				</label>
			</div>
		}

		if (this.props.showForSaleNearlyNewSearchType) {
			forSaleNearlyNewSelector = <div className="radio-option">
				<input id="rdoSearchTypeNearlyNew" className="radio-option__input" type="radio" name="rdoSearchType"
					   disabled={this.state.isLoading[this.props.instance]} value={FilterNames.FOR_SALE + '_' + SearchTypes.NEARLYNEW} checked={this.selectedSearchType === SearchTypes.NEARLYNEW} onChange={this.onTypeSelectChange} />
				<label htmlFor="rdoSearchTypeNearlyNew" className="radio-option__label">
					<span className="radio-option__label__circle"></span>
					&nbsp;<span>Nearly new {this.props.vehicleType} for sale</span>
				</label>
			</div>
		}

		if (this.props.showLeasingSearchType) {
			leasingSelector = <div className="radio-option">
				<input id="rdoSearchTypeLeasing" className="radio-option__input" type="radio" name="rdoSearchType"
					   disabled={this.state.isLoading[this.props.instance]} value={FilterNames.LEASING + '_' + SearchTypes.PERSONAL} checked={this.selectedSearchType === SearchTypes.PERSONAL} onChange={this.onTypeSelectChange} />
				<label htmlFor="rdoSearchTypeLeasing" className="radio-option__label">
					<span className="radio-option__label__circle"></span>
					&nbsp;<span>New {this.props.vehicleType} to lease</span>
				</label>
			</div>
		}

		return (
			<div className="context-options">
				{forSaleUsedSelector}
				{forSaleNearlyNewSelector}
				{forSaleNewSelector}
				{leasingSelector}
			</div>
		);
	},

	/* #endregion */

	/* #region Event handlers */

	onTypeSelectChange: function(event) {
		var key = event.target.value;

		if (key) {
			var values = key.split('_'); // Should be of form FilterName_SearchType

			if (values.length == 2 && (values[1] === SearchTypes.NEW || values[1] === SearchTypes.NEARLYNEW || values[1] === SearchTypes.USED || values[1] === SearchTypes.PERSONAL)) {
				this.selectSearchType(values[0], values[1]);
			}
		}
	},
 
	selectSearchType: function(filterName, searchType) {
		Actions.selectSearchType(filterName, searchType, this.props.instance);
		Actions.loadManufacturers(this.props.vehicleType, filterName, searchType, this.getFilters(), this.props.instance);
	},

	selectManufacturer: function (manufacturerKey, manufacturerName) {
		Actions.selectManufacturer(manufacturerKey, this.props.instance);
		Actions.loadRanges(this.props.vehicleType, this.state.selectedFilterName[this.props.instance], this.state.selectedSearchType[this.props.instance], manufacturerKey, this.getFilters(), this.props.instance);
		this.trackEvent('Make selected', manufacturerName);
	},

	selectRange: function (rangeKey, rangeName) {
		Actions.selectRange(rangeKey, this.props.instance);
		Actions.loadModels(
			this.props.vehicleType,
			this.state.selectedFilterName[this.props.instance],
			this.state.selectedSearchType[this.props.instance],
			this.state.selectedManufacturer[this.props.instance].key,
			rangeKey,
			this.getFilters(),
			this.props.instance);
		this.trackEvent('Range selected', rangeName);
	},

	selectModel: function (modelKey, modelName) {
		Actions.selectModel(modelKey, this.props.instance);
		Actions.loadSearchUrl(
			this.props.vehicleType,
			this.state.selectedFilterName[this.props.instance],
			this.state.selectedSearchType[this.props.instance],
			this.state.selectedManufacturer[this.props.instance].key,
			this.state.selectedRange[this.props.instance].key,
			modelKey,
			this.getFilters(),
			this.props.instance);
		this.trackEvent('Model selected', modelName);
	},

	setPostcode: function (postcode, update) {
		Actions.setPostcode(postcode, update, this.props.instance);
	},

	selectFromPrice: function (price) {
		if (this.state.selectedToPrice[this.props.instance] && this.state.selectedToPrice[this.props.instance] <= price)
		{
			Actions.validationError({ fromPrice: "From price must be less than to price" }, this.props.instance);
		} else {
			Actions.selectFromPrice(price, this.props.instance);
			this.setFilters({
				toPrice: this.state.selectedToPrice[this.props.instance],
				fromPrice: price,
				postcode: this.state.selectedPostcode
			});
		}
	},

	selectToPrice: function (price) {
		if (this.state.selectedFromPrice[this.props.instance] && this.state.selectedFromPrice[this.props.instance] >= price) {
			Actions.validationError({ toPrice: "To price must be more than from price" }, this.props.instance);
		} else {
			Actions.selectToPrice(price, this.props.instance);
			this.setFilters({
				toPrice: price,
				fromPrice: this.state.selectedFromPrice[this.props.instance],
				postcode: this.state.selectedPostcode
			});
		}
	},

	submitForm: function (e) {
		e.preventDefault();
		if (!this.state.isLoading[this.props.instance]) {

			if (!this.state.selectedSearchType[this.props.instance]) {
				Actions.validationError({
					searchType: 'Please select a search type'
				}, this.props.instance);
			}
			else if (!this.hasPostcodeIfRequired() ||
					(this.state.selectedPostcode && !postcodeHelper.isValid(this.state.selectedPostcode))) {
				Actions.postcodeValidationError(this.state.selectedPostcode, this.props.instance);
			}
			else {
				if (this.state.selectedModel[this.props.instance] !== null) {
					this.trackEvent('Go clicked', 'Model page');
				} else if (this.state.selectedModel[this.props.instance] !== null) {
					this.trackEvent('Go clicked', 'Range page');
				} else if (this.state.selectedManufacturer[this.props.instance] !== null) {
					this.trackEvent('Go clicked', 'Manufacturer page');
				}

				Actions.submitForm(this.state.searchUrl[this.props.instance], this.props.instance);
			}
		}
	},

	/* #endregion */

	/* #region utility functions */

	trackEvent: function (action, label) {
		analytics.trackEvent('Quick Find Dropdowns (' + this.props.vehicleType + ' - ' + this.state.selectedFilterName[this.props.instance] + ')', action, label);
	},

	getSubmitButtonPrompt: function () {
		var result = <span>Go</span>;

		if (!this.props.hideOptionalText) {
			var selectionText;

			if (this.selectedManufacturer && this.selectedModel !== null) {
				selectionText = this.selectedManufacturer.name + ' ' + this.selectedModel.name;
			} else if (this.selectedManufacturer && this.selectedRange !== null) {
				selectionText = this.selectedManufacturer.name + ' ' + this.selectedRange.name;
			} else if (this.selectedManufacturer !== null) {
				selectionText = this.selectedManufacturer.name;
			}

			if (selectionText) {
				result = <span>Go to <strong>{selectionText}</strong></span>
			}
		}

		if (!this.props.hideOptionalText && this.props.isLeasingLandingPage) {
			result = <span><strong>{leasingButtonText}</strong></span>
		}

		return result;
	},

	getFilters: function () {
		var filters = {
			toPrice: this.state.selectedToPrice[this.props.instance],
			fromPrice: this.state.selectedFromPrice[this.props.instance],
			postcode: this.state.selectedPostcode
		};
	},

	setFilters: function(filters) {
		if (this.state.selectedManufacturer[this.props.instance]) {
			if (this.state.selectedRange[this.props.instance]) {
				if (this.state.selectedModel[this.props.instance]) {
					Actions.loadSearchUrl(this.props.vehicleType, this.state.selectedFilterName[this.props.instance], this.state.selectedSearchType[this.props.instance], this.state.selectedManufacturer[this.props.instance].key, this.state.selectedRange[this.props.instance].key, this.state.selectedModel[this.props.instance].key, filters, this.props.instance)
				}
				else {
					Actions.loadModels(this.props.vehicleType, this.state.selectedFilterName[this.props.instance], this.state.selectedSearchType[this.props.instance], this.state.selectedManufacturer[this.props.instance].key, this.state.selectedRange[this.props.instance].key, filters, this.props.instance);
				}
			}
			else {
				Actions.loadRanges(this.props.vehicleType, this.state.selectedFilterName[this.props.instance], this.state.selectedSearchType[this.props.instance], this.state.selectedManufacturer[this.props.instance].key, filters, this.props.instance);
			}
		}
		else {
			Actions.loadManufacturers(this.props.vehicleType, this.state.selectedFilterName[this.props.instance], this.state.selectedSearchType[this.props.instance], filters, this.props.instance);
		}
	},

	hasPostcodeIfRequired: function() {
		if (!this.state.isLoading[this.props.instance] && this.state.isPostcodeRequired[this.props.instance]) {
			return postcodeHelper.isValid(this.state.selectedPostcode);
		}

		return true;
	},

	getPostcodePrompt: function() {
		return this.state.isPostcodeRequired[this.props.instance] ? "Postcode (required)" : "Postcode";
	}
	/* #endregion */
});
