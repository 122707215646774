'use strict';

var assign 	= require('object-assign');

var arrays	= require('../../utils/arrays.js');
var logger	= require('../../utils/logger.js');
var stringHelper	= require('../../utils/string-helper.js');

var FluxChangeStore	= require('./flux-change-store.js');
var FluxConstants 	= require('../constants/flux-constants.js');
var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/taxonomy-selector-constants.js');

var ActionTypes 	= Constants.ActionTypes;
var PayloadSources 	= FluxConstants.PayloadSources;

var storeData = {
	isLoading: true,
	manufacturers: [],
	ranges: [],
	rangeYears: [],
	trims: [],
	isElectric: false,
	selectedManufacturer: null,
	selectedRange: null,
	selectedModel: null,
	selectedDerivative: null
};

function setSelectedManufacturer(manufacturerKey) {
	storeData.selectedManufacturer = arrays.findArrayItemByField(storeData.manufacturers, 'key', stringHelper.formatNameUrlPath(manufacturerKey));
}

function setSelectedRange(rangeKey) {
	storeData.selectedRange = arrays.findArrayItemByField(storeData.ranges, 'key', stringHelper.formatNameUrlPath(rangeKey));
}

function setSelectedModel(modelKey) {
	var index, rangeYear;

	for (index = 0; index < storeData.rangeYears.length; index++) {
		rangeYear = storeData.rangeYears[index];
		storeData.selectedModel = arrays.findArrayItemByField(rangeYear.models, 'key', modelKey);

		if (storeData.selectedModel) {
			break;
		}
	}
}

function setSelectedDerivative(derivativeKey) {
	var index, trim;

	for (index = 0; index < storeData.trims.length; index++) {
		trim = storeData.trims[index];
		storeData.selectedDerivative = arrays.findArrayItemByField(trim.derivatives, 'key', derivativeKey);

		if (storeData.selectedDerivative) {
			break;
		}
	}
}

var Store = assign(new FluxChangeStore(), {
	getState: function () {
		return storeData;
	}
});

function handleServerAction(action) {
	if (action) {
		var handled = false;

		switch(action.type) {

			case ActionTypes.TAXONOMY_VRM_LOOKUP:
				handled = true;
				storeData.isLoading = true;
				storeData.vrmLookupSuccessful = false;
				storeData.manufacturers = [];
				storeData.ranges = [];
				storeData.rangeYears = [];
				storeData.trims = [];
				storeData.selectedDerivative = null;

				break;
			case ActionTypes.TAXONOMY_VRM_LOOKUP_SUCCESS:
				handled = true;
				storeData.isLoading = false;
				storeData.manufacturers = action.data.manufacturers.manufacturers;
				storeData.ranges = action.data.manufacturerWithModels.ranges;			
				storeData.rangeYears = action.data.rangeWithDerivatives.rangeYears;

				break;
			case ActionTypes.TAXONOMY_VRM_LOOKUP_ERROR:
				handled = true;
				storeData.isLoading = false;
				storeData.manufacturers = [];
				storeData.ranges = [];
				storeData.rangeYears = [];
				storeData.trims = [];
				storeData.selectedDerivative = null;

				break;
			case ActionTypes.TAXONOMY_SELECTOR_LOAD_MANUFACTURERS:
				handled = true;
				storeData.isLoading = true;
				storeData.manufacturers = [];
				storeData.ranges = [];
				storeData.rangeYears = [];
				storeData.trims = [];
				storeData.selectedManufacturer = null;
				storeData.selectedRange = null;
				storeData.selectedModel = null;
				storeData.selectedDerivative = null;

				break;
			case ActionTypes.TAXONOMY_SELECTOR_LOAD_MANUFACTURERS_SUCCESS:
				handled = true;
				storeData.isLoading = false;
				storeData.manufacturers = action.data.manufacturers;

				break;
			case ActionTypes.TAXONOMY_SELECTOR_LOAD_MANUFACTURERS_ERROR:
				handled = true;
				storeData.isLoading = false;
				storeData.manufacturers = [];

				break;
			case ActionTypes.TAXONOMY_SELECTOR_LOAD_RANGES:
				handled = true;
				storeData.isLoading = true;
				storeData.rangeYears = [];
				storeData.trims = [];
				storeData.selectedModel = null;
				storeData.selectedDerivative = null;

				break;
			case ActionTypes.TAXONOMY_SELECTOR_LOAD_RANGES_SUCCESS:
				handled = true;
				storeData.isLoading = false;

				// check that the response is the one we are expecting - stops overlapping requests generating an invalid state
				if (storeData.selectedManufacturer !== null && storeData.selectedManufacturer.key === action.data.key) {
					storeData.ranges = action.data.ranges;

					if (storeData.selectedRange) {
						// if range selected by user before load ranges returns, reset selected range to get range years
						setSelectedRange(storeData.selectedRange.key);
						storeData.rangeYears = storeData.selectedRange.rangeYears;
					}
				}

				break;
			case ActionTypes.TAXONOMY_SELECTOR_LOAD_RANGES_ERROR:
				handled = true;
				storeData.isLoading = false;
				storeData.rangeYears = [];

				break;
			case ActionTypes.TAXONOMY_SELECTOR_LOAD_MODELS:
				handled = true;
				storeData.isLoading = true;
				storeData.trims = [];
				storeData.selectedDerivative = null;

				break;
			case ActionTypes.TAXONOMY_SELECTOR_LOAD_MODELS_SUCCESS:
				handled = true;
				storeData.isLoading = false;

				// check that the response is the one we are expecting - stops overlapping requests generating an invalid state
				if (storeData.selectedRange !== null && storeData.selectedRange.key === action.data.key) {
					storeData.rangeYears = action.data.rangeYears;

					if (storeData.selectedModel) {
						// if model selected by user before load models returns, reset selected model to get trims
						setSelectedModel(storeData.selectedModel.key);
						storeData.trims = storeData.selectedModel.trims;
					}
				}

				break;
			case ActionTypes.TAXONOMY_SELECTOR_LOAD_MODELS_ERROR:
				handled = true;
				storeData.isLoading = false;
				storeData.trims = [];

				break;

			case ActionTypes.LOOKUP_VRM:
				handled = true;

				storeData.isLoading = true;
				storeData.errorMessage = null;

				break;

			case ActionTypes.LOOKUP_VRM_SUCCESS:
				handled = true;

				storeData.isLoading = false;
				storeData.vrmLookupSuccessful = true;
				if (action.data.isSuccess) {
					storeData.vrmData = action.data;
				} else {
					storeData.errorMessage = action.data.errorMessage;
				}

				break;

			case ActionTypes.LOOKUP_VRM_ERROR:
				handled = true;

				storeData.isLoading = false;

				if (action.data) {
					storeData.errorMessage = action.data.errorMessage;
				} else {
					storeData.errorMessage = 'An error occured. Please try later.';
				}

				break;
		}

		if (handled) {
			logger.debug('Taxonomy Selector', {
				message: 'Handle server action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

function handleViewAction(action) {
	if (action) {
		var handled = false;

		switch(action.type) {
			case ActionTypes.TAXONOMY_SELECTOR_SELECT_VEHICLE:
				handled = true;
				setSelectedManufacturer(action.data.manufacturerKey);
				setSelectedRange(action.data.rangeKey);
				setSelectedModel(action.data.modelKey);
				storeData.trims = (storeData.selectedModel ? storeData.selectedModel.trims : []) || [];
				setSelectedDerivative(action.data.derivativeKey);

				break;
			case ActionTypes.TAXONOMY_SELECTOR_SELECT_MANUFACTURER:
				handled = true;
				setSelectedManufacturer(action.data.manufacturerKey);

				storeData.ranges = storeData.selectedManufacturer ? storeData.selectedManufacturer.ranges : [];
				storeData.rangeYears = [];
				storeData.trims = [];
				storeData.selectedRange = null;
				storeData.selectedModel = null;
				storeData.selectedDerivative = null;

				break;
			case ActionTypes.TAXONOMY_SELECTOR_SELECT_RANGE:
				handled = true;
				setSelectedRange(action.data.rangeKey);

				storeData.rangeYears = (storeData.selectedRange ? storeData.selectedRange.rangeYears : []) || [];
				storeData.trims = [];
				storeData.selectedModel = null;
				storeData.selectedDerivative = null;

				break;
			case ActionTypes.TAXONOMY_SELECTOR_SELECT_MODEL:
				handled = true;
				setSelectedModel(action.data.modelKey);

				storeData.trims = (storeData.selectedModel ? storeData.selectedModel.trims : []) || [];
				storeData.selectedDerivative = null;

				break;
			case ActionTypes.TAXONOMY_SELECTOR_SELECT_DERIVATIVE:
				handled = true;
				setSelectedDerivative(action.data.derivativeKey);

				break;
		}

		if (handled) {
			logger.debug('Taxonomy Selector', {
				message: 'Handle view action',
				action: action.type,
				data: action.data
			});

			Store.emitChange();
		}
	}
}

Store.dispatchToken = FluxDispatcher.register(function (payload) {
	if (payload && payload.source) {
		if (payload.source === PayloadSources.SERVER_ACTION) {
			handleServerAction(payload.action);
		} else if (payload.source === PayloadSources.VIEW_ACTION) {
			handleViewAction(payload.action);
		}
	}
});

module.exports = Store;