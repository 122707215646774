'use strict';

var $ = require('jquery');

$.getBemClass = function (block, element, modifier) {
	return block + (element ? '__' + element : '') + (modifier ? '--' + modifier : '');
};
$.findByBem = function (block, element, modifier, context) {
	return $('.' + $.getBemClass(block, element, modifier), context);
};

$.fn.childrenByBem = function (block, element, modifier) {
	return this.children('.' + $.getBemClass(block, element, modifier));
};
$.fn.closestByBem = function (block, element, modifier) {
	return this.closest('.' + $.getBemClass(block, element, modifier));
};
$.fn.filterByBem = function (block, element, modifier) {
	return this.filter('.' + $.getBemClass(block, element, modifier));
};
$.fn.findByBem = function (block, element, modifier) {
	return this.find('.' + $.getBemClass(block, element, modifier));
};
$.fn.parentsByBem = function (block, element, modifier) {
	return this.parents('.' + $.getBemClass(block, element, modifier));
};
$.fn.siblingsByBem = function (block, element, modifier) {
	return this.siblings('.' + $.getBemClass(block, element, modifier));
};
$.fn.nextByBem = function (block, element, modifier) {
	return this.next('.' + $.getBemClass(block, element, modifier));
};
$.fn.nextAllByBem = function (block, element, modifier) {
	return this.nextAll('.' + $.getBemClass(block, element, modifier));
};
$.fn.prevByBem = function (block, element, modifier) {
	return this.prev('.' + $.getBemClass(block, element, modifier));
};
$.fn.prevAllByBem = function (block, element, modifier) {
	return this.prevAll('.' + $.getBemClass(block, element, modifier));
};
$.fn.addBemClass = function (block, element, modifier) {
	return this.addClass($.getBemClass(block, element, modifier));
};
$.fn.hasBemClass = function (block, element, modifier) {
	return this.hasClass($.getBemClass(block, element, modifier));
};
$.fn.removeBemClass = function (block, element, modifier) {
	return this.removeClass($.getBemClass(block, element, modifier));
};

$.fn.disable = function (disabledCssClass, disabledText) {
	this.attr('disabled', 'disabled');
	if (disabledCssClass) {
		this.addClass(disabledCssClass);
	}
	disabledText = disabledText || this.attr('data-disabled-text');
	if (disabledText) {
		this.attr('data-enabled-text', this.text());
		this.text(disabledText);
	}
};

$.fn.enable = function (disabledCssClass, enabledText) {
	this.removeAttr('disabled');
	if (disabledCssClass) {
		this.removeClass(disabledCssClass);
	}
	enabledText = enabledText || this.attr('data-enabled-text');
	if (enabledText) {
		this.attr('data-disabled-text', this.text());
		this.text(enabledText);
	}
};
