'use strict';

var $ = require('jquery');
var scrollWatcher = require('../watchers/scroll-watcher.js');

var classNames = {
	moreMenuDesktop: 'more-menu-desktop',
	siteNav: 'site-nav',
	siteSearchTablet: 'site-search-tablet',
	siteSearchForm: 'site-search__form',
	offCanvasWrap: 'off-canvas-wrap'
};

function initSearchButton() {
	$.findByBem(classNames.siteNav, 'link', 'site-search').on('click.search', function (e) {
		e.preventDefault();
		e.stopPropagation();
		var link = $(this);
		if (link.hasBemClass(classNames.siteNav, 'link', 'active')) {
			link.trigger('collapse');
		} else {
			link.trigger('expand');
		}
	}).on('expand.search', function () {
		$(this).addBemClass(classNames.siteNav, 'link', 'active');
		$.findByBem(classNames.moreMenuDesktop).hide();
		$.findByBem(classNames.siteSearchTablet).addBemClass(classNames.siteSearchTablet, null, 'expanded');
		$.findByBem(classNames.siteSearchTablet, 'input').focus();
	}).on('collapse.search', function () {
		$(this).removeBemClass(classNames.siteNav, 'link', 'active');
		$.findByBem(classNames.siteSearchTablet).removeBemClass(classNames.siteSearchTablet, null, 'expanded');
	});
}

function addFormSubmitListener(form) {
	form.addEventListener('submit', function (event) {
		event.preventDefault();
		var googleSearch = window.google.search.cse.element.getElement('searchresults-only0');
		var searchQuery = form.elements.namedItem('query').value;
		googleSearch.execute(searchQuery);
		$.findByBem(classNames.offCanvasWrap).foundation('offcanvas', 'hide', 'move-left');	
	});
}

function initSearchForms() {
	var $searchForms = $.findByBem(classNames.siteSearchForm);
	for (var i = 0; i < $searchForms.length; i++) {
		addFormSubmitListener($searchForms[i]);
	}
}

var self = module.exports = {
	init: function () {
		initSearchButton();
		initSearchForms();
		scrollWatcher.onScroll(self.hide);

		$('body').on('click.search', function (e) {
			var search = $.findByBem(classNames.siteSearchTablet);
			if (search.length && search[0] !== e.target && !$.contains(search[0], e.target)) {
				self.hide();
			}
		});
	},

	hide: function () {
		$.findByBem(classNames.siteNav, 'link', 'site-search').trigger('collapse');
	}
};