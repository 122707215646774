/** @jsx React.DOM */'use strict';

var $ = require('jquery');
var React = require('react');

var analytics 	= require('../../utils/analytics.js');
var logger 		= require('../../utils/logger.js');

var Actions = require('../actions/quick-find-model-actions.js');
var Store 	= require('../stores/quick-find-model-store.js');

var classNames = {
	quickFind: 'quick-find'
}

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		vehicleType: React.PropTypes.string.isRequired,
		filterName: React.PropTypes.string.isRequired,
		showRange: React.PropTypes.bool,
		showModel: React.PropTypes.bool,
		onlyLatestModels: React.PropTypes.bool,
		isModelMandatory: React.PropTypes.bool,
		color: React.PropTypes.string,
		isChunky: React.PropTypes.bool
	},

	selectedManufacturer: null,
	selectedRange: null,
	selectedModel: null,

	manufacturers: [],
	ranges: [],
	rangeYears: [],

	validationErrors: null,

	getDefaultProps: function() {
		return {
			showRange: true,
			showModel: true,
			manufacturerKey: null,
			rangeKey: null,
			modelKey: null,
			onlyLatestModels: false,
			isChunky: false
		};
	},

	getInitialState: function () {
		return Store.getState();
	},

	componentWillMount: function () {
		Store.addChangeListener(this.onStoreChange);
		Actions.initStoreData({
			initialManufacturerKey: this.props.manufacturerKey ? String(this.props.manufacturerKey) : null,
			initialRangeKey: this.props.rangeKey ? String(this.props.rangeKey) : null,
			initialModelKey: this.props.modelKey ? String(this.props.modelKey) : null
		});
	},

	componentDidMount: function () {
		Actions.loadManufacturers(this.props.vehicleType, this.props.filterName);

		if (this.props.showModel && this.state.initialManufacturerKey) {
			Actions.loadRanges(this.props.vehicleType, this.props.filterName, this.state.initialManufacturerKey);
		}

		$.findByBem(classNames.quickFind, null, 'placeholder').hide();
	},

	componentWillUnmount: function () {
		Store.removeChangeListener(this.onStoreChange);
	},

	onStoreChange: function () {
		this.setState(Store.getState());
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		logger.debug('Quick Find Model', {
			message: 'Render',
			state: this.state
		});

		this.selectedManufacturer = this.state.selectedManufacturer[this.props.filterName] || null;
		this.selectedRange = this.state.selectedRange[this.props.filterName] || null;
		this.selectedModel = this.state.selectedModel[this.props.filterName] || null;

		this.manufacturers = this.state.manufacturers[this.props.filterName] || [];
		this.ranges = this.state.ranges[this.props.filterName] || [];
		this.rangeYears = this.state.rangeYears[this.props.filterName] || [];

		this.validationErrors = this.state.validationErrors[this.props.filterName];

		var manufacturerList, rangeList, modelList, submitButton;
		var manufacturerListDisabled = this.state.isLoading && this.manufacturers.length === 0;
		manufacturerList = this.getManufacturerList(manufacturerListDisabled);
		submitButton = this.getSubmitButton();

		if (this.props.showRange) {
			var rangeListDisabled = manufacturerListDisabled || (this.selectedManufacturer !== null && this.ranges.length === 0);
			rangeList = this.getRangeList(rangeListDisabled);

			if (this.props.showModel) {
				var modelListDisabled = rangeListDisabled || (this.selectedRange !== null && this.rangeYears.length === 0);
				modelList = this.getModelList(modelListDisabled);
			}
		}

		return (
			<form className="form" onSubmit={this.submitForm}>
				{manufacturerList}
				{rangeList}
				{modelList}
				{submitButton}
			</form>
		);
	},

	getManufacturerList: function (isDisabled) {
		var defaultText = isDisabled ? 'Loading...' : 'Select a make';
		var selectedValue = this.selectedManufacturer !== null ? this.selectedManufacturer.key : null;

		var listItems;
		if (this.manufacturers) {
			listItems = this.manufacturers.map(function (manufacturer) {
				return <option key={manufacturer.key} value={manufacturer.key}>{manufacturer.name}</option>;
			});
		}

		var validationError;
		if (this.validationErrors && this.validationErrors.manufacturer) {
			validationError = <span className="error">{this.validationErrors.manufacturer}</span>;
		}

		var classNameToUse = this.props.isChunky ? 'form__list form__list--chunky' : 'form__list';

		return (
			<div className="form__entry">
				<select disabled={isDisabled} value={selectedValue} className={classNameToUse} onChange={this.selectManufacturer}>
					<option value="">{defaultText}</option>
					{listItems}
				</select>
				{validationError}
			</div>
		);
	},

	getRangeList: function (isDisabled) {
		var defaultText = isDisabled ? 'Loading...' : 'Select a range' + (this.props.hideOptionalText || this.props.isModelMandatory ? '' : ' (optional)');
		var selectedValue = this.selectedRange !== null ? this.selectedRange.key : null;

		var listItems;
		if (this.ranges) {
			listItems = this.ranges.map(function (range) {
				return <option key={range.key} value={range.key}>{range.name}</option>;
			});
		}

		var validationError;
		if (this.validationErrors && this.validationErrors.range) {
			validationError = <span className="error">{this.validationErrors.range}</span>;
		}

		var classNameToUse = this.props.isChunky ? 'form__list form__list--chunky' : 'form__list';

		return (
			<div className="form__entry">
				<select disabled={isDisabled} value={selectedValue} className={classNameToUse} onChange={this.selectRange}>
					<option value="">{defaultText}</option>
					{listItems}
				</select>
				{validationError}
			</div>
		);
	},

	getModelList: function (isDisabled) {
		var defaultText = isDisabled ? 'Loading...' : 'Select a model' + (this.props.hideOptionalText || this.props.isModelMandatory ? '' : ' (optional)');
		var selectedValue = this.selectedModel !== null ? this.selectedModel.key : null;

		var listItems;
		if (this.rangeYears) {
			listItems = this.rangeYears.map(function (rangeYear) {
				return <optgroup key={rangeYear.name} label={rangeYear.name}>{rangeYear.models.map(function (model) {
					return <option key={model.key} value={model.key}>{model.name}</option>;
				})}</optgroup>;
			});
		}

		var validationError;
		if (this.validationErrors && this.validationErrors.model) {
			validationError = <span className="error">{this.validationErrors.model}</span>;
		}

		var classNameToUse = this.props.isChunky ? 'form__list form__list--chunky' : 'form__list';

		return (
			<div className="form__entry">
				<select disabled={isDisabled} value={selectedValue} className={classNameToUse} onChange={this.selectModel}>
					<option value="">{defaultText}</option>
					{listItems}
				</select>
				{validationError}
			</div>
		);
	},

	getSubmitButton: function () {
		var selectedVehicle;
		if (this.selectedManufacturer && this.selectedModel !== null) {
			selectedVehicle = this.selectedManufacturer.name + ' ' + this.selectedModel.name;
		} else if (this.selectedManufacturer && this.selectedRange !== null) {
			selectedVehicle = this.selectedManufacturer.name + ' ' + this.selectedRange.name;
		} else if (this.selectedManufacturer !== null) {
			selectedVehicle = this.selectedManufacturer.name;
		}

		var buttonText;
		var isDisabled = false;

		if (this.props.isModelMandatory && !this.selectedModel) {
			buttonText = <span>Choose a model</span>;
			isDisabled = true;
		} else if (selectedVehicle && !this.props.hideOptionalText) {
			buttonText = <span>Go to <strong>{selectedVehicle}</strong></span>;
		} else {
 			buttonText = <span>Go</span>;
		}

		return (
			<div className="form__entry">
				<button type="submit" className={"quick-find__button button"}>
					{buttonText}
				</button>
			</div>
		);
	},
	/* #endregion */

	/* #region Event handlers */
	selectManufacturer: function (e) {
		var manufacturerKey = e.target.value;

		if (manufacturerKey) {
			Actions.selectManufacturer(manufacturerKey, this.props.filterName);

			if (this.props.showModel) {
				Actions.loadRanges(this.props.vehicleType, this.props.filterName, manufacturerKey, this.props.onlyLatestModels);
			}

			var manufacturerName = e.target.options[e.target.selectedIndex].text;
			this.trackEvent('Make selected', manufacturerName);
		}
	},

	selectRange: function (e) {
		var rangeKey = e.target.value;

		if (rangeKey) {
			Actions.selectRange(rangeKey, this.props.filterName);

			var rangeName = e.target.options[e.target.selectedIndex].text;
			this.trackEvent('Range selected', rangeName);
		}
	},

	selectModel: function (e) {
		var modelKey = e.target.value;

		if (modelKey) {
			Actions.selectModel(modelKey, this.props.filterName);

			var modelName = e.target.options[e.target.selectedIndex].text;
			this.trackEvent('Model selected', modelName);
		}
	},

	submitForm: function (e) {
		e.preventDefault();
		if (!this.state.isLoading) {
			if (this.selectedModel !== null) {
				Actions.submitForm(this.selectedModel);
				this.trackEvent('Go clicked', 'Model page');
			} else if (this.props.isModelMandatory) {
				var errors = this.getMandatoryModelErrors();
				Actions.validationError(errors, this.props.filterName);
			} else if (this.selectedRange !== null) {
				Actions.submitForm(this.selectedRange);
				this.trackEvent('Go clicked', 'Range page');
			} else if (this.selectedManufacturer !== null) {
				Actions.submitForm(this.selectedManufacturer);
				this.trackEvent('Go clicked', 'Manufacturer page');
			} else {
				Actions.validationError({
					manufacturer: 'Please select a make'
				}, this.props.filterName);
			}
		}
	},

	trackEvent: function (action, label) {
		analytics.trackEvent('Quick Find Dropdowns (' + this.props.vehicleType + ' - ' + this.props.filterName + ')', action, label);
	},

	getMandatoryModelErrors: function() {
		var errors = {
			model: 'Please select a model',
			range: this.selectedRange ? null : 'Please select a range',
			manufacturer: this.selectedManufacturer ? null : 'Please select a manufacturer'
		}

		return errors;
	}

	/* #endregion */
});
