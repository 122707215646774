'use strict';

var assign 		= require('object-assign');
var noUiSlider 	= require('nouislider');
var vanPrices	= require('./van-valuation-prices.js');
var wNumb 		= require('../../vendors/wnumb.js');

var defaultSliderOptions = {
	connect: 'lower',
	format: wNumb({
		decimals: 0
	}),
	placeholderText: null,		// custom bauer option for when slider is a required field
	range: {
		min: 0,
		max: 100
	},
	step: 1,
	tooltips: true
};

var sliderTypes = {
	miles: 'miles',
	mpg: 'mpg',
	price: 'price',
	default: ''
};

function addRangeStepToInputField($inputField, sliderOptions) {
	if ($inputField && $inputField.length > 0 && $inputField.is('[type="number"]')) {
		$inputField.attr('min', sliderOptions.range.min);
		$inputField.attr('max', sliderOptions.range.max);
		$inputField.attr('step', sliderOptions.step);
	}
}

function registerAdjustmentEventHandler(sliderInstance, step, hasMultipleHandles, $inputBtn, increment) {
	if ($inputBtn && $inputBtn.length > 0) {
		$inputBtn.on('click.slider', function () {
			var sliderValue = parseFloat(sliderInstance.get());

			if (increment) {
				sliderValue += parseFloat(step);
			}
			else {
				sliderValue -= parseFloat(step);
			}

			sliderInstance.set(hasMultipleHandles ? [sliderValue, null] : sliderValue);
		});
	}
}

function initSlider($slider, sliderOptions, $inputField1, $inputField2, tooltipFormatter, $inputBtnMinus, $inputBtnPlus) {
	if ($slider.length === 0 && typeof noUiSlider === 'undefined') {
		return;
	}

	sliderOptions = assign({}, defaultSliderOptions, sliderOptions);
	var hasMultipleHandles = Array.isArray(sliderOptions.start) && sliderOptions.start.length > 1;

	if (tooltipFormatter) {
		if (hasMultipleHandles) {
			sliderOptions.tooltips = [tooltipFormatter, tooltipFormatter];
		} else {
			sliderOptions.tooltips = tooltipFormatter;
		}
	}

	if (!$slider.hasClass('slider')) {
		$slider.addClass('slider');
	}

	noUiSlider.create($slider[0], sliderOptions);
	var sliderInstance = $slider[0].noUiSlider;

	if ($inputField1 && $inputField1.length > 0) {
		// wire input fields to handles and vice versa
		addRangeStepToInputField($inputField1, sliderOptions);
		addRangeStepToInputField($inputField2, sliderOptions);

		sliderInstance.on('update', function (value, handle) {
			if (hasMultipleHandles) {
				if (handle === 0) {
					$inputField1.val(value[handle]);
				} else if (handle === 1 && $inputField2 && $inputField2.length > 0) {
					$inputField2.val(value[handle]);
				}
			} else {
				$inputField1.val(value);
			}

			if (sliderOptions.isVanValuation) {
				vanPrices.update(value);
			}
		});

		if (hasMultipleHandles) {
			$inputField1.on('change.slider', function () {
				sliderInstance.set([this.value, null]);
			});
			$inputField2.on('change.slider', function () {
				sliderInstance.set([null, this.value]);
			});
		} else {
			$inputField1.on('change.slider', function () {
				sliderInstance.set(this.value);
			});
		}
	}

	registerAdjustmentEventHandler(sliderInstance, sliderOptions.step, hasMultipleHandles, $inputBtnMinus, false);
	registerAdjustmentEventHandler(sliderInstance, sliderOptions.step, hasMultipleHandles, $inputBtnPlus, true);

	if (sliderOptions.placeholderText) {
		// remove initial values to force slider to be selected
		if (sliderOptions.placeholderText.length > 1) {
			if (sliderOptions.placeholderText[0] !== null && $inputField1 && $inputField1.length > 0) {
				$slider.find('.noUi-handle-lower > .noUi-tooltip').text(sliderOptions.placeholderText[0]);
				$inputField1.val('');
			}
			if (sliderOptions.placeholderText[1] !== null && $inputField2 && $inputField2.length > 0) {
				$slider.find('.noUi-handle-upper > .noUi-tooltip').text(sliderOptions.placeholderText[1]);
				$inputField2.val('');
			}
		} else {
			$slider.find('.noUi-tooltip').text(sliderOptions.placeholderText);
			$inputField1.val('');
			if ($inputField2 && $inputField2.length > 0) {
				$inputField2.val('');
			}
		}
	}

	return sliderInstance;
}

module.exports = {	
	sliderTypes: sliderTypes,
	
	init: function ($element, sliderOptions, $inputField1, $inputField2, tooltipFormatter, $inputBtnMinus, $inputBtnPlus) {
		return initSlider($element, sliderOptions, $inputField1, $inputField2, tooltipFormatter, $inputBtnMinus, $inputBtnPlus);
	},

	initMilesSlider: function ($element, sliderOptions, $inputField1, $inputField2, $inputBtnMinus, $inputBtnPlus) {
		return initSlider($element, sliderOptions, $inputField1, $inputField2, null, $inputBtnMinus, $inputBtnPlus);
	},

	initMpgSlider: function ($element, sliderOptions, $inputField1, $inputField2, $inputBtnMinus, $inputBtnPlus) {
		var tooltipFormatter = wNumb({
			decimals: 0,
			postfix: ' mpg'
		});
		return initSlider($element, sliderOptions, $inputField1, $inputField2, tooltipFormatter, $inputBtnMinus, $inputBtnPlus);
	},

	initPriceSlider: function ($element, sliderOptions, $inputField1, $inputField2, $inputBtnMinus, $inputBtnPlus) {
		var tooltipFormatter = wNumb({
			decimals: 0,
			prefix: '£',
			thousand: ','
		});
		return initSlider($element, sliderOptions, $inputField1, $inputField2, tooltipFormatter, $inputBtnMinus, $inputBtnPlus);
	}
};