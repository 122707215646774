'use strict';

var vendorConsents = require('./vendor-consents.js');
var permutive = window.permutive;

function initPermutive() {
	vendorConsents.addTcfApiHandler(vendorConsents.eventNames.userActionComplete, function () {
		vendorConsents.onRetrieveVendorAndPurposeConsents(function () {
			permutive.consent({ 'opt_in': true, 'token': 'CONSENT_CAPTURED' });
		}, function () {
			permutive.consent({ 'opt_in': false });
			console.log('Permutive consent has not been granted - no data will be collected');
		});
	});

	vendorConsents.addTcfApiHandler(vendorConsents.eventNames.subsequentPageLoad, function () {
		vendorConsents.onRetrieveVendorAndPurposeConsents(function () {
			permutive.consent({ 'opt_in': true, 'token': 'CONSENT_CAPTURED' });
		}, function () {
			permutive.consent({ 'opt_in': false });
			console.log('Permutive consent has not been granted - no data will be collected');
		});
	});
}

module.exports = {
	init: function () {
		initPermutive();
	}
};