/** @jsx React.DOM */'use strict';

var React = require('react');

var FacetDropdown 	= require('./shared/facet-dropdown-view.jsx');
var FacetFlyoutList = require('./shared/facet-flyout-list-view.jsx');

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		filterState: React.PropTypes.object.isRequired,
		isLoading: React.PropTypes.bool,
		isDisabled: React.PropTypes.bool,
		onItemRemove: React.PropTypes.func.isRequired,
		onItemSet: React.PropTypes.func.isRequired,
		searchType: React.PropTypes.bool,
		vehicleType: React.PropTypes.string
	},

	getDefaultProps: function() {
		return {
			vehicleType: 'car'
		};
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		var title = 'How many miles should the ' + this.props.vehicleType + ' have done?';
		if (this.props.searchType === 'Used') {
			return (
				<div className="search-filters__facet">
					<div className="hide-for-large-up">
						<FacetDropdown
							name="mileage"
							defaultText="Mileage"
							isLoading={this.props.isLoading}
							isDisabled={this.props.isDisabled}
							items={this.props.filterState.mileageItems}
							selectedItem={this.props.filterState.selectedMileage}
							onRemove={this.props.onItemRemove}
							onSet={this.props.onItemSet}
						/>
					</div>
					<div className="show-for-large-up">
						<FacetFlyoutList
							label="Mileage"
							name="mileage"
							title={title}
							isLoading={this.props.isLoading}
							isDisabled={this.props.isDisabled}
							items={this.props.filterState.mileageItems}
							selectedItem={this.props.filterState.selectedMileage}
							onRemove={this.props.onItemRemove}
							onSet={this.props.onItemSet}
						/>
					</div>
				</div>
			);
		}

		return null;
	}
	/* #endregion */
});