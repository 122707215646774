'use strict';

var logger = require('./logger.js');

module.exports = {
	pushToDataLayer: function (obj) {
		if (obj) {			
			if (typeof bauerDataLayer !== 'undefined') {
				logger.info('GTM data layer push', obj);
				bauerDataLayer.push(obj);
			} else {				
				logger.warn('GTM data layer not defined');
			}
		}
	}
};