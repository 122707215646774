'use strict';

var $ = require('jquery');

var classNames = {
	panel: 'panel'
};

function loadClickPanels(context) {
	$.findByBem(classNames.panel, null, 'clickable', context).on('click.panel', function (e) {
		e.stopImmediatePropagation();
		
		if (!$(e.target).is('a')) {
			// No link has been clicked, so find the primary link and click it
			var $panelLinks = $(this).find('a.panel__primary-link');
			if($panelLinks.length > 0) {
				$panelLinks[0].click();
			} else {
				$(this).find('a')[0].click();
			}
		}
	});
}

module.exports = {
	init: function (context) {
		loadClickPanels(context);
	}
};