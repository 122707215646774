'use strict';

var keyMirror = require('keymirror');

module.exports = {
	ActionTypes: keyMirror({
		YEAR_DERIVATIVE_INITIALISE_VALUATION_PURPOSE : null,
		YEAR_DERIVATIVE_LOAD_YEARS: null,
		YEAR_DERIVATIVE_LOAD_YEARS_SUCCESS: null,
		YEAR_DERIVATIVE_LOAD_YEARS_ERROR: null,
		YEAR_DERIVATIVE_SELECT_YEAR_PLATE: null,
		YEAR_DERIVATIVE_SELECT_DERIVATIVE: null,
		YEAR_DERIVATIVE_SELECT_DERIVATIVE_LOADING: null,
		YEAR_DERIVATIVE_SELECT_VALUATION_PURPOSE: null,
		YEAR_DERIVATIVE_VALIDATION_ERROR: null
	}),
	ValuationPurpose: {
		BUYING_A_CAR: {
			NAME: 'buying',
			LABEL:'I might buy this |vehicleType|'
		},
		SELLING_A_CAR: {
			NAME: 'selling',
			LABEL:'I am considering selling this |vehicleType|'
		},
		INSURING_A_CAR: {
			NAME: 'insuring',
			LABEL:'For |vehicleType| insurance purposes'
		},
		JUST_CURIOUS: {
			NAME: 'curious',
			LABEL:'Just curious or for work purposes'
		}
	},
	EventCategory: {
		CAR: 'Valuation purpose',
		VAN: 'Valuation purpose van'
	}
};