/** @jsx React.DOM */'use strict';

var $ = require('jquery');
var React = require('react');
var logger = require('../../utils/logger');

var Actions = require('../actions/taxonomy-selector-actions.js');
var Store = require('../stores/vrm-lookup-store.js');

let recaptchaInstance;

module.exports = React.createClass({

	propTypes: {
		mobilePromptText: React.PropTypes.string,
		desktopPromptText: React.PropTypes.string,
		isLoading: React.PropTypes.bool,
		errorMessage: React.PropTypes.string,
		vrmPath: React.PropTypes.string,
		vehicleType: React.PropTypes.string,
		recaptchaSiteKeyV2: React.PropTypes.string.isRequired, // v2 key
		recaptchaSiteKeyV3: React.PropTypes.string.isRequired, // v3 key
	},

	getInitialState: function () {
		const initialState = Store.getState();
		logger.debug('[VRM-LOOKUP] Initial State', { initialState });
		return {
			...initialState,
			isRecaptchaV2Rendered: false, // Flag to track if v2 has been rendered
		};
	},

	componentDidMount: function () {
		var $vrmLookupBtn = $.findByBem('vrm-lookup', 'button');
		$vrmLookupBtn.click('on', function (e) {
			e.preventDefault();
		});
	},

	componentWillMount: function () {
		logger.debug('[VRM-LOOKUP] Component Will Mount');
		Store.addChangeListener(this.onStoreChange);
	},

	componentWillUnmount: function () {
		logger.debug('[VRM-LOOKUP] Component Will Unmount');
		Store.removeChangeListener(this.onStoreChange);
	},

	onStoreChange: function () {
		const newState = Store.getState();
		logger.debug('[VRM-LOOKUP] Store Changed', { newState });
		this.setState(newState);
	},

	render: function () {
		logger.debug('[VRM-LOOKUP] Rendering inline view layout');
		
		var errorMessage;
		var loadingItem;
		var promptText = this.getPromptText();

		if (this.props.isLoading) {
			loadingItem = <span className="vrm-lookup__button__spinner"></span>
		}

		if (this.props.errorMessage) {
			errorMessage = <span className="error">{this.props.errorMessage}</span>;
		}

		return (
			<span>
				<div className="vrm-lookup__inline-layout">
					<input type="text" className="vrm-lookup__input" placeholder="your reg" />
					<button className="vrm-lookup__button button" disabled={this.props.isLoading} onClick={this.onSubmit}>
						{this.props.isLoading ? loadingItem : promptText}
					</button>
				</div>
				
				<div className="vrm-lookup__recaptcha-panel" id="recaptcha-v2"></div> {/* Container for v2 */}

				{errorMessage}
			</span>
		);
	},

	getPromptText: function () {
		return (
			<span>
				<span className="hide-for-large-up">{this.props.mobilePromptText}</span>
				<span className="show-for-large-up">{this.props.desktopPromptText}</span>
			</span>
		);
	},

	onSubmit: function (e) {
		e.preventDefault();
		var vrm = $('.vrm-lookup__input').val();

		logger.debug('[VRM-LOOKUP] Form Submitted', { submittedVrm: vrm });

		if (vrm) {
			// Execute reCAPTCHA v3 first
			window.grecaptcha.execute(this.props.recaptchaSiteKeyV3, { action: 'submit' })
				.then(this.onRecaptchaVerifyV3)
				.catch(() => {
					logger.debug('[VRM-LOOKUP] reCAPTCHA v3 Execution Failed. Falling back to v2.');
					this.renderRecaptchaV2(); // Render v2 if v3 fails

					var $recaptchaPanel = $.findByBem('vrm-lookup', 'recaptcha-panel');
					$recaptchaPanel.show();
				});
		} else {
			logger.debug('[VRM-LOOKUP] VRM is empty on form submission');
		}
	},

	onRecaptchaVerifyV3: function (recaptchaResponse) {
		var vrm = $('.vrm-lookup__input').val();

		logger.debug('[VRM-LOOKUP] reCAPTCHA v3 Verified', { recaptchaResponse });

		if (vrm) {
			vrm = vrm.replace(/\s+/g, '');
			logger.debug('[VRM-LOOKUP] Looking up VRM', { vehicleType: this.props.vehicleType, vrm });
			Actions.lookupVrm(this.props.vehicleType, vrm, recaptchaResponse, this.props.vrmPath, true);
		} else {
			logger.debug('[VRM-LOOKUP] No VRM to lookup after v3 verification');
		}
	},

	onRecaptchaVerifyV2: function (recaptchaResponse) {
		var vrm = $('.vrm-lookup__input').val();

		logger.debug('[VRM-LOOKUP] reCAPTCHA v2 Verified', { recaptchaResponse });

		if (vrm) {
			vrm = vrm.replace(/\s+/g, '');
			logger.debug('[VRM-LOOKUP] Looking up VRM', { vehicleType: this.props.vehicleType, vrm });
			Actions.lookupVrm(this.props.vehicleType, vrm, recaptchaResponse, this.props.vrmPath, false);
		} else {
			logger.debug('[VRM-LOOKUP] No VRM to lookup after v2 verification');
		}
	},

	renderRecaptchaV2: function () {
		// Check if recaptchaInstance exists and reset it if necessary
		if (recaptchaInstance !== null && typeof recaptchaInstance !== 'undefined') {
			window.grecaptcha.reset(recaptchaInstance);

			logger.debug('[VRM-LOOKUP] Existing reCAPTCHA instance reset');
		}

		if (!this.state.isRecaptchaV2Rendered && typeof window.grecaptcha !== 'undefined') {
			// Render reCAPTCHA v2
			recaptchaInstance = window.grecaptcha.render('recaptcha-v2', {
				'sitekey': this.props.recaptchaSiteKeyV2,
				'callback': this.onRecaptchaVerifyV2,
			});
			this.setState({ isRecaptchaV2Rendered: true });
			logger.debug('[VRM-LOOKUP] reCAPTCHA v2 rendered');
		} else {
			logger.debug('[VRM-LOOKUP] reCAPTCHA v2 not available');
		}
	}
});